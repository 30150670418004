import {
  Card,
  FlexChild,
  FlexLayout,
  Grid,
  Skeleton,
} from "@cedcommerce-integration/ounce-ui";
import React from "react";
import "./skelton.css";

export const LOAD_DATA_SOURCES = [1, 2, 3, 4].map((index) => {
  return {
    key: index.toString(),
    nullE: <Skeleton height="0px" line={1} type="custom" width="0px" />,
    Empty: <Skeleton height="22px" line={1} type="custom" width="22px" />,
    image: (
      <FlexLayout halign="start">
        <Skeleton height="60px" line={1} type="custom" width="60px" />
      </FlexLayout>
    ),
    title: (
      <FlexLayout direction="vertical" wrap="noWrap" spacing="tight">
        <Skeleton height="20px" line={1} type="custom" width="100%" />
        <Skeleton height="20px" line={1} type="custom" width="70%" />
      </FlexLayout>
    ),
    status: <Skeleton height="20px" line={1} type="custom" width="100%" />,
    price: <Skeleton height="20px" line={1} type="custom" width="100%" />,
    quantity: <Skeleton height="20px" line={1} type="custom" width="100%" />,
    action: (
      <FlexLayout halign="center">
        <Skeleton height="40px" line={1} type="custom" width="40px" />
      </FlexLayout>
    ),
  };
});

const columns = [
  {
    title: "",
    align: "left",
    dataIndex: "nullE",
    key: "EmpnullEty",
    width: 48,
  },
  {
    title: <Skeleton height="18px" line={1} type="custom" width="18px" />,
    align: "left",
    dataIndex: "Empty",
    key: "Empty",
    width: 32,
  },
  {
    title: <Skeleton height="22px" line={1} type="custom" width="100%" />,
    align: "left",
    dataIndex: "image",
    key: "image",
    width: 76,
  },
  {
    title: <Skeleton height="22px" line={1} type="custom" width="100%" />,
    align: "left",
    dataIndex: "title",
    key: "title",
  },
  {
    align: "center",
    title: <Skeleton height="22px" line={1} type="custom" width="100%" />,
    dataIndex: "price",
    key: "price",
    width: 140,
  },
  {
    align: "center",
    title: <Skeleton height="22px" line={1} type="custom" width="100%" />,
    dataIndex: "quantity",
    key: "quantity",
    width: 140,
  },
  {
    title: <Skeleton height="22px" line={1} type="custom" width="100%" />,
    align: "center",
    dataIndex: "status",
    key: "status",
    width: 160,
  },
  {
    align: "center",
    title: <Skeleton height="22px" line={1} type="custom" width="100%" />,
    dataIndex: "action",
    key: "action",
    width: 100,
    fixed: "right",
  },
];

export const ProductPageSkeleton = () => {
  return (
    <>
      <FlexLayout direction="vertical" spacing="mediumLoose" wrap="noWrap">
        <Skeleton height="56px" type="line" />
        <div className="product-grid data skeleton">
          <Card>
            <FlexLayout spacing="tight" direction="vertical" wrap="noWrap">
              <div className="ced-tab__skeleton mb-5">
                <FlexLayout spacing="tight" wrap="noWrap">
                  {[1, 2, 3, 4, 5].map((index) => {
                    return (
                      <Skeleton
                        key={index}
                        type="custom"
                        line={1}
                        width="180px"
                        height="34px"
                      />
                    );
                  })}
                </FlexLayout>
              </div>
              <FlexLayout direction="vertical" wrap="noWrap">
                <Card extraClass="ced-filter__wrap" cardType="Bordered">
                  <FlexLayout
                    desktopWidth="50"
                    tabWidth="100"
                    mobileWidth="100"
                  >
                    <FlexLayout spacing="tight" wrap="noWrap">
                      <FlexChild
                        desktopWidth="50"
                        tabWidth="50"
                        mobileWidth="50"
                      >
                        <Skeleton
                          type="custom"
                          line={1}
                          width="100%"
                          height="38px"
                        />
                      </FlexChild>
                      <FlexChild
                        desktopWidth="25"
                        tabWidth="25"
                        mobileWidth="25"
                      >
                        <Skeleton
                          type="custom"
                          line={1}
                          width="100%"
                          height="38px"
                        />
                      </FlexChild>
                      <FlexChild
                        desktopWidth="25"
                        tabWidth="25"
                        mobileWidth="25"
                      >
                        <Skeleton
                          type="custom"
                          line={1}
                          width="100%"
                          height="38px"
                        />
                      </FlexChild>
                    </FlexLayout>
                  </FlexLayout>
                </Card>
                <Grid
                  rowKey="key"
                  dataSource={LOAD_DATA_SOURCES}
                  columns={columns}
                  scrollX={800}
                  size="middle"
                  tableLayout="auto"
                />
                <Card cardType="Bordered" extraClass="ced-pagination__wrap">
                  <div className="inte-Pagination">
                    <FlexLayout halign="fill" spacing="tight">
                      <FlexChild
                        desktopWidth="50"
                        tabWidth="50"
                        mobileWidth="50"
                      >
                        <Skeleton
                          height="39px"
                          line={1}
                          type="custom"
                          width="300px"
                        />
                      </FlexChild>
                      <FlexChild
                        desktopWidth="50"
                        tabWidth="50"
                        mobileWidth="50"
                      >
                        <FlexLayout halign="end">
                          <div className="align_right">
                            <Skeleton
                              height="39px"
                              line={1}
                              type="custom"
                              width="300px"
                            />
                          </div>
                        </FlexLayout>
                      </FlexChild>
                    </FlexLayout>
                  </div>
                </Card>
              </FlexLayout>
            </FlexLayout>
          </Card>
        </div>
      </FlexLayout>
    </>
  );
};

export const ProductSkeleton = () => {
  return (
    <>
        <div className="product-grid data skeleton">
            <FlexLayout spacing="tight" direction="vertical" wrap="noWrap">
              <FlexLayout direction="vertical" wrap="noWrap">
                <Card extraClass="ced-filter__wrap" cardType="Bordered">
                  <FlexLayout
                    desktopWidth="50"
                    tabWidth="100"
                    mobileWidth="100"
                  >
                    <FlexLayout spacing="tight" wrap="noWrap">
                      <FlexChild
                        desktopWidth="50"
                        tabWidth="50"
                        mobileWidth="50"
                      >
                        <Skeleton
                          type="custom"
                          line={1}
                          width="100%"
                          height="38px"
                        />
                      </FlexChild>
                      <FlexChild
                        desktopWidth="25"
                        tabWidth="25"
                        mobileWidth="25"
                      >
                        <Skeleton
                          type="custom"
                          line={1}
                          width="100%"
                          height="38px"
                        />
                      </FlexChild>
                      <FlexChild
                        desktopWidth="25"
                        tabWidth="25"
                        mobileWidth="25"
                      >
                        <Skeleton
                          type="custom"
                          line={1}
                          width="100%"
                          height="38px"
                        />
                      </FlexChild>
                    </FlexLayout>
                  </FlexLayout>
                </Card>
                <Grid
                  rowKey="key"
                  dataSource={LOAD_DATA_SOURCES}
                  columns={columns}
                  scrollX={800}
                  size="middle"
                  tableLayout="auto"
                />
                <Card cardType="Bordered" extraClass="ced-pagination__wrap">
                  <div className="inte-Pagination">
                    <FlexLayout halign="fill" spacing="tight">
                      <FlexChild
                        desktopWidth="50"
                        tabWidth="50"
                        mobileWidth="50"
                      >
                        <Skeleton
                          height="39px"
                          line={1}
                          type="custom"
                          width="300px"
                        />
                      </FlexChild>
                      <FlexChild
                        desktopWidth="50"
                        tabWidth="50"
                        mobileWidth="50"
                      >
                        <FlexLayout halign="end">
                          <div className="align_right">
                            <Skeleton
                              height="39px"
                              line={1}
                              type="custom"
                              width="300px"
                            />
                          </div>
                        </FlexLayout>
                      </FlexChild>
                    </FlexLayout>
                  </div>
                </Card>
              </FlexLayout>
            </FlexLayout>
        </div>
    </>
  );
}

export const ProductGridSkeleton = () => {
  const columns = [
    {
      title: "",
      align: "left",
      dataIndex: "nullE",
      key: "EmpnullEty",
      width: 48,
    },
    {
      title: <Skeleton height="18px" line={1} type="custom" width="18px" />,
      align: "left",
      dataIndex: "Empty",
      key: "Empty",
      width: 32,
    },
    {
      title: <Skeleton height="20px" line={1} type="custom" width="100%" />,
      align: "left",
      dataIndex: "image",
      key: "image",
      width: 76,
    },
    {
      title: <Skeleton height="20px" line={1} type="custom" width="100%" />,
      align: "left",
      dataIndex: "title",
      key: "title",
    },
    {
      align: "center",
      title: <Skeleton height="20px" line={1} type="custom" width="100%" />,
      dataIndex: "price",
      key: "price",
      width: 140,
    },
    {
      align: "center",
      title: <Skeleton height="20px" line={1} type="custom" width="100%" />,
      dataIndex: "quantity",
      key: "quantity",
      width: 140,
    },
    {
      title: <Skeleton height="20px" line={1} type="custom" width="100%" />,
      align: "center",
      dataIndex: "status",
      key: "status",
      width: 160,
    },
    {
      align: "center",
      title: <Skeleton height="20px" line={1} type="custom" width="100%" />,
      dataIndex: "action",
      key: "action",
      width: 100,
      fixed: "right",
    },
  ];
  return (
    <>
      <div className="product-grid data skeleton">
        <FlexLayout spacing="tight" direction="vertical" wrap="noWrap">
          <Grid
            rowKey="key"
            dataSource={LOAD_DATA_SOURCES}
            columns={columns}
            scrollX={800}
            size="middle"
            tableLayout="auto"
          />
          <div className="inte-Pagination mt-15 mb-15">
            <FlexLayout halign="fill" spacing="tight">
              <FlexChild desktopWidth="50" tabWidth="50" mobileWidth="50">
                <Skeleton height="39px" line={1} type="custom" width="300px" />
              </FlexChild>
              <FlexChild desktopWidth="50" tabWidth="50" mobileWidth="50">
                <FlexLayout halign="end">
                  <div className="align_right">
                    <Skeleton
                      height="39px"
                      line={1}
                      type="custom"
                      width="300px"
                    />
                  </div>
                </FlexLayout>
              </FlexChild>
            </FlexLayout>
          </div>
        </FlexLayout>
      </div>
    </>
  );
};
