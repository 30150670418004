import {
  Button,
  Card,
  FlexLayout,
  Modal,
  TextStyles,
  Toast,
  ToastWrapper,
} from "@cedcommerce-integration/ounce-ui";
import { AlertTriangle } from "react-feather";
import { useCallback, useState } from "react";
import { withRouter } from "react-router-dom";
import NestedTableComponent from "../../../../OunceComponents/NestedTableComponent";
import NestedPagination from "../../../../OunceComponents/NestedPagination";
import { postMethod } from "../../../../../apiMethods/methods";
import { globalState } from "../../../../../services/globalState";
import { syncShipment } from "../../../../../APIs/PanelAPI";
import { removeBracket } from "../../../../activity/Activities";

const OrderGrid = (props) => {
  let {
    tableColumns,
    dataSource,
    count,
    loader,
    activePage,
    setActivePage,
    pageSize,
    setPageSize,
    payloadState,
    setPayloadState,
  } = props;
  const shoplineShopId = globalState.getLocalStorage("shopLineShopId");

  const [failedModal, setFailedModal] = useState(false);
  const [rowselect, setrowselect] = useState([]);
  // eslint-disable-next-line no-unused-vars
  const [pageSizeOptions, setPageOptions] = useState([
    {
      label: "10",
      value: "10",
    },
    {
      label: "25",
      value: "25",
    },
    {
      label: "50",
      value: "50",
    },
    {
      label: "100",
      value: "100",
    },
  ]);
  const [selectedRows, setSelectedRows] = useState([]);
  const [actionsModal, setActionsModal] = useState({
    active: false,
    content1: "",
    content2: "",
    api: "",
    methodName: "",
    payload: {},
    loader: false,
  });
  const [actionApiReturnMsg, setActionApiReturnMsg] = useState("");
  const [successToast, setSuccessToast] = useState(false);
  const [errorToast, setErrorToast] = useState(false);

  const rowSelection = {
    onChange: (selectedRowKeys, selectedRows) => {
      setSelectedRows(selectedRows);
      setrowselect(selectedRowKeys);
    },
    getCheckboxProps: (record) => ({
      name: record.name,
    }),
  };

  const toggleErrorActive = useCallback(() => {
    setErrorToast((active) => !active);
  }, []);

  const toggleSuccessActive = useCallback(() => {
    setSuccessToast((active) => !active);
  }, []);

  const toastMarkupMore = successToast ? (
    <Toast
      message={actionApiReturnMsg}
      onDismiss={toggleSuccessActive}
      timeout={2000}
      type="success"
    />
  ) : errorToast ? (
    <Toast
      message={actionApiReturnMsg}
      onDismiss={toggleErrorActive}
      timeout={2000}
      type="error"
    />
  ) : null;

  return (
    <>
      {rowselect.length === 0 ? (
        ""
      ) : (
        <div className="ced-selected__row">
          <FlexLayout spacing="mediumTight" valign="center">
            <TextStyles textcolor="light">
              {rowselect.length} orders selected
            </TextStyles>
            <Button
              content="Sync Shipment"
              type="Outlined"
              length="fullBtn"
              onClick={() => {
                let order_id = [];
                selectedRows.forEach((selectedRow) => {
                  let { shoplineOrder_id } = selectedRow;
                  if (shoplineOrder_id) {
                    order_id.push(shoplineOrder_id);
                  }
                });
                let payload = {};
                if (order_id?.length) {
                  if (order_id?.length > 1) {
                    payload = {
                      order_ids: order_id,
                    };
                  } else {
                    payload = {
                      order_id: order_id[0],
                    };
                  }
                }
                setActionsModal({
                  active: true,
                  content1: "Sync Shipment",
                  content2: "sync shipment of",
                  api: syncShipment,
                  loader: false,
                  methodName: postMethod,
                  payload: { ...payload, source_shop_id: shoplineShopId },
                });
              }}
            />
          </FlexLayout>
        </div>
      )}
      {/* <Grid
        columns={tableColumns}
        dataSource={dataSource}
        rowSelection={{
          ...rowSelection,
        }}
        scrollX={800}
      /> */}
      <NestedTableComponent
        columns={tableColumns}
        dataSource={dataSource}
        rowSelection={{
          ...rowSelection,
        }}
        loader={loader}
        expandable={false}
      />
      <Card cardType="Bordered" extraClass="ced-pagination__wrap">
        <NestedPagination
          totalCount={count}
          pageSizeOptions={pageSizeOptions}
          activePage={activePage}
          setActivePage={setActivePage}
          pageSize={pageSize}
          setPageSize={setPageSize}
          payloadState={payloadState}
          setPayloadState={setPayloadState}
        />
      </Card>
      {/* Failed Order Modal */}
      <Modal
        closeOnEscape={true}
        overlayClose={true}
        modalSize="small"
        open={failedModal}
        heading="Order #2334423243"
        close={() => {
          setFailedModal(!failedModal);
        }}
      >
        <FlexLayout spacing="tight" wrap="noWrap">
          <AlertTriangle color="#C4281C" size={20} />
          <TextStyles
            type="Paragraph"
            paragraphTypes="MD-1.4"
            lineHeight="LH-2.0"
          >
            Product out of stock
          </TextStyles>
        </FlexLayout>
      </Modal>
      <Modal
        closeOnEscape={true}
        overlayClose={true}
        modalSize="small"
        open={actionsModal.active}
        heading={actionsModal.content1}
        primaryAction={{
          content: actionsModal.content1,
          loading: actionsModal.loader,
          type: "Primary",
          onClick: async () => {
            setActionsModal({ ...actionsModal, loader: true });
            const { api, methodName, payload } = actionsModal;
            let { success, message, data } = await methodName(api, payload);
            if (success) {
              setSuccessToast(true);
            } else {
              setErrorToast(true);
            }
            if (message) {
              setActionApiReturnMsg(removeBracket(message));
            } else {
              setActionApiReturnMsg(data);
            }
            setActionsModal({
              active: false,
              content1: "",
              api: "",
              methodName: "",
              payload: {},
              loader: false,
            });
          },
        }}
        close={() => {
          setActionsModal({
            active: false,
            content1: "",
            content2: "",
            api: "",
            methodName: "",
            payload: {},
            loader: false,
          });
        }}
      >
        <TextStyles>
          Are you sure you want to {actionsModal.content2} selected orders?
        </TextStyles>
      </Modal>
      <ToastWrapper>{toastMarkupMore}</ToastWrapper>
    </>
  );
};

export default withRouter(OrderGrid);
