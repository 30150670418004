/* eslint-disable react-hooks/exhaustive-deps */
import { Button, Card, FlexLayout, TextStyles } from "@cedcommerce-integration/ounce-ui";
import { useEffect, useState } from "react";
import { withRouter } from "react-router-dom";
import { getMethod } from "../../apiMethods/methods";
import { getStatusCount } from "../../APIs/PanelAPI";
import { Cancelled, Dislike, Like, Refunded } from "../shared/IconSvg";

const OrderStatus = (props) => {
  const { refreshBtnClickedCount, dispatch } = props;

  const [skeleton, setSekelton] = useState(true);
  const [tabsCount, setTabsCount] = useState({
    FULFILLED: 0,
    NOT_STARTED: 0,
    FAILED: 0,
  });
  const [totalOrder, setTotalOrder] = useState(0);

  const getTabsCountFunc = async () => {
    dispatch({ type: "order", value: true });
    setSekelton(true);
    const tabsName = ["Shipped", "Created", "failed"];
    const promises = tabsName.map(async (field) => {
      let payload = {
        "filter[object_type][1]": "source_order",
        "filter[targets.status][1]": field,
      };
      let { success, data, code } = await getMethod(getStatusCount, payload);
      if (success) {
        return data;
      } else if (
          code === "token_expired" ||
          code === "invalid_token" ||
          code === "future_token" ||
          code === "token_decode_error"
        ) {
          props.history.push("/tokenexpired");
        }
    });
    Promise.all(promises).then((results) => {
      const totalCount = results.map((result) => result.count);
      setTabsCount({
        FULFILLED: totalCount[0],
        NOT_STARTED: totalCount[1],
        FAILED: totalCount[2],
      });
      const totalOrderCount = totalCount.reduce((acc, curr) => acc + curr, 0);
      setTotalOrder(totalOrderCount);
      setTimeout(() => {
        setSekelton(false);
        dispatch({ type: "order", value: false });
      }, 500);
    });
  };

  useEffect(() => {
    getTabsCountFunc();
  }, [refreshBtnClickedCount]);

  return (
    <>
      <Card
        cardType="Subdued"
        title={"Order Status"}
        action={
          <Button
            type="TextButton"
            onClick={() => props.history.push("/panel/order-listing")}
          >
            View all
          </Button>
        }
      >
        <FlexLayout
          spacing="loose"
          wrap="wrap"
          desktopWidth="25"
          tabWidth="50"
          mobileWidth="50"
        >
          <Card extraClass="p-12">
            <FlexLayout
              direction="vertical"
              spacing="mediumTight"
              wrap="noWrap"
            >
              <FlexLayout halign="fill" valign="center" wrap="noWrap">
                <TextStyles
                  fontweight="bold"
                  paragraphTypes="MD-1.4"
                  textcolor="light"
                  type="Paragraph"
                  lineHeight="LH-2.0"
                >
                  {/* Today's Order */}
                  All
                </TextStyles>
                {Refunded}
              </FlexLayout>
              <TextStyles
                fontweight="extraBold"
                lineHeight="LH-2.4"
                paragraphTypes="MD-1.4"
                subheadingTypes="XS-1.6"
                textcolor="dark"
                type="SubHeading"
              >
                {skeleton ? <div className="custom-spinner"></div> : totalOrder}
              </TextStyles>
              <Button
                content="View Order"
                type="TextButton"
                onClick={() => {
                  window.scrollTo(0, 0);
                  props.history.push("/panel/order-listing");
                }}
              />
            </FlexLayout>
          </Card>
          <Card extraClass="p-12">
            <FlexLayout
              direction="vertical"
              spacing="mediumTight"
              wrap="noWrap"
            >
              <FlexLayout halign="fill" valign="center" wrap="noWrap">
                <TextStyles
                  fontweight="bold"
                  paragraphTypes="MD-1.4"
                  textcolor="light"
                  type="Paragraph"
                  lineHeight="LH-2.0"
                >
                  Fulfilled
                </TextStyles>
                {Like}
              </FlexLayout>
              <TextStyles
                fontweight="extraBold"
                lineHeight="LH-2.4"
                paragraphTypes="MD-1.4"
                subheadingTypes="XS-1.6"
                textcolor="dark"
                type="SubHeading"
              >
                {skeleton ? (
                  <div className="custom-spinner"></div>
                ) : (
                  tabsCount.FULFILLED
                )}
              </TextStyles>
              <Button
                content="View Order"
                type="TextButton"
                onClick={() => {
                  window.scrollTo(0, 0);
                  props.history.push("/panel/order-listing", {
                    tabFromDashboard: "fulfilled",
                  });
                }}
              />
            </FlexLayout>
          </Card>
          <Card extraClass="p-12">
            <FlexLayout
              direction="vertical"
              spacing="mediumTight"
              wrap="noWrap"
            >
              <FlexLayout halign="fill" valign="center" wrap="noWrap">
                <TextStyles
                  fontweight="bold"
                  paragraphTypes="MD-1.4"
                  textcolor="light"
                  type="Paragraph"
                  lineHeight="LH-2.0"
                >
                  Unfulfilled
                </TextStyles>
                {Dislike}
              </FlexLayout>
              <TextStyles
                fontweight="extraBold"
                lineHeight="LH-2.4"
                paragraphTypes="MD-1.4"
                subheadingTypes="XS-1.6"
                textcolor="dark"
                type="SubHeading"
              >
                {skeleton ? (
                  <div className="custom-spinner"></div>
                ) : (
                  tabsCount.NOT_STARTED
                )}
              </TextStyles>
              <Button
                content="View Order"
                type="TextButton"
                onClick={() => {
                  window.scrollTo(0, 0);
                  props.history.push("/panel/order-listing", {
                    tabFromDashboard: "unfulfilled",
                  });
                }}
              />
            </FlexLayout>
          </Card>
          <Card extraClass="p-12">
            <FlexLayout
              direction="vertical"
              spacing="mediumTight"
              wrap="noWrap"
            >
              <FlexLayout halign="fill" valign="center" wrap="noWrap">
                <TextStyles
                  fontweight="bold"
                  paragraphTypes="MD-1.4"
                  textcolor="light"
                  type="Paragraph"
                  lineHeight="LH-2.0"
                >
                  Failed
                </TextStyles>
                {Cancelled}
              </FlexLayout>
              <TextStyles
                fontweight="extraBold"
                lineHeight="LH-2.4"
                paragraphTypes="MD-1.4"
                subheadingTypes="XS-1.6"
                textcolor="dark"
                type="SubHeading"
              >
                {skeleton ? (
                  <div className="custom-spinner"></div>
                ) : (
                  tabsCount.FAILED
                )}
              </TextStyles>
              <Button
                content="View Order"
                type="TextButton"
                onClick={() => {
                  window.scrollTo(0, 0);
                  props.history.push("/panel/order-listing", {
                    tabFromDashboard: "failed",
                  });
                }}
              />
            </FlexLayout>
          </Card>
        </FlexLayout>
      </Card>
    </>
  );
};

export default withRouter(OrderStatus);
