// login api(s)
export const loginAPI = "user/login";

// get accounts
export const getAccountsAPI = "connector/get/all";
export const tokenUpdateApi = "ebay/account/tokenUpdated";

// get policies
export const getPoliciesAPI = "ebay/policy/refreshPolicies";

//to import product from shopline
export const importProductFromShoplineAPI = "connector/product/import";

// shopline paylment
export const getPaymentChannelsAPI = "shoplinehome/onboard/getPaymentChannels";
export const skipPaymentChannels = "shoplinehome/onboard/skipPaymentChannels"
export const getPaymentSettingsStatus = "shoplinehome/onboard/getPaymentSettingsStatus"

export const getSavedPaymentChannelsAPI =
  "shoplinehome/onboard/getSavedPaymentChannels";

export const savePaymentChannelsAPI =
  "shoplinehome/onboard/savePaymentChannels";
