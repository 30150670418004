import { Card, Skeleton, FlexLayout } from "@cedcommerce-integration/ounce-ui";

const ConfigurationSkeleton = () => {
  return (
    <Card
      title={<Skeleton height="14px" width="100px" type="custom" />}
      subTitle={<Skeleton height="14px" width="200px" type="custom" />}
    >
      <Card cardType="Bordered">
        <FlexLayout direction="vertical" spacing="extraLoose" wrap="noWrap">
          <FlexLayout halign="fill">
            <Skeleton height="10px" width="100px" type="custom" />
            <Skeleton height="30px" width="150px" type="custom" />
          </FlexLayout>
          <FlexLayout spacing="tight">
            <Skeleton height="50px" width="50px" type="custom" />
            <FlexLayout direction="vertical" spacing="loose" wrap="noWrap">
              <Skeleton height="11px" width="100px" type="custom" />
              <Skeleton height="11px" width="200px" type="custom" />
            </FlexLayout>
          </FlexLayout>
        </FlexLayout>
      </Card>

      <Card cardType="Bordered">
        <FlexLayout direction="vertical" spacing="extraLoose" wrap="noWrap">
          <FlexLayout halign="fill" spacing="loose">
            <FlexLayout direction="vertical" spacing="tight" wrap="noWrap">
              <Skeleton height="10px" width="100px" type="custom" />
              <Skeleton height="10px" width="250px" type="custom" />
            </FlexLayout>
            <Skeleton height="30px" width="150px" type="custom" />
          </FlexLayout>
          <FlexLayout direction="vertical" spacing="loose" wrap="noWrap">
            <Skeleton height="11px" width="120px" type="custom" />
            <Skeleton height="11px" width="300px" type="custom" />
          </FlexLayout>
        </FlexLayout>
      </Card>
    </Card>
  );
};

export default ConfigurationSkeleton;
