import { FlexLayout, LRLayout, Select } from "@cedcommerce-integration/ounce-ui";
import React from "react";

const ProductCondition = ({
  templateRef,
  productCondition,
  setProductCondition,
  categoryFeatureOptions,
  validationErrors,
}) => {
  return (
    <LRLayout
      required={true}
      title={"Product Condition"}
      lrHelpText="Ensure your buyers know the exact condition of your product with the product condition feature.
      Many eBay categories require an item condition for a complete and accurate listing. This feature allows you to clearly and accurately describe the condition of your item, ensuring a successful and effective eBay listing. Get the best results for your eBay seller experience by setting the item condition in the app."
    >
      <span ref={(e) => (templateRef.current["productCondition"] = e)}>
        <FlexLayout desktopWidth="100" mobileWidth="100" tabWidth="100">
          <Select
            error={validationErrors["productConditionValidation"][0]}
            value={productCondition}
            options={categoryFeatureOptions}
            onChange={(e) => {
              let { productConditionValidation } = validationErrors;
              productConditionValidation[0] = false;
              setProductCondition(e);
            }}
            placeholder="Select..."
          />
        </FlexLayout>
      </span>
    </LRLayout>
  );
};

export default ProductCondition;
