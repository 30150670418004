import { Button, Card, FlexLayout, TextStyles } from '@cedcommerce-integration/ounce-ui';
import  {useEffect, useState } from 'react';
import { Info, X } from "react-feather";

const StatusData = ({ProductStatusData,Title}) => {
  const [active, setActive] = useState(false);
 
  useEffect(() => {
    if (active) {
      document.body.style.overflow = 'hidden';
      document.body.classList.add('inte-Filter--Open');
    } else {
      document.body.style.removeProperty('overflow');
      document.body.classList.remove('inte-Filter--Open');
    }
  }, [active]);

  return (
    <>
      <div className='product-status'> <Button type="Outlined"
        iconAlign="left"
        icon={<Info size={16} />}
        iconRound={false}
        onClick={() => setActive(!active)}>Status Info</Button></div>

      <div
        className={
          active ? 'inte_FilterSheetWrapper inte_sheetActive' : 'inte_FilterSheetWrapper'
        }>
        <div className="inte-FilterSheet product-status-section">
          <div className="inte-FilterSheet--Header">
            <FlexLayout halign="fill" valign="center">
              <TextStyles
                type="Paragraph"
                paragraphTypes="LG-1.5"
                lineHeight="LH-1.6"
                fontweight="bold">
               {Title}
              </TextStyles>
              <Button
                onClick={() => setActive(!active)}
                type="Plain"
                thickness="thin"
                icon={<X color="#2a2a2a" size={20} />}
                iconRound={false}
                iconAlign="right"></Button>
            </FlexLayout>
          </div>
          <div className="inte-FilterSheet--Body activity-body">
            <Card >
            <FlexLayout direction="vertical" spacing="loose" wrap="noWrap">
              {ProductStatusData.map((item, index) => {
                return (
                   <FlexLayout direction="vertical" spacing="tight" wrap="noWrap" key={index}>
                   <FlexLayout spacing="tight" wrap="noWrap" valign="baseline">
                       {item.icon}
                       <FlexLayout direction="vertical" spacing="extraTight" wrap="noWrap">
                        <TextStyles type="Paragraph" paragraphTypes="MD-1.4" fontweight="extraBold" lineHeight="LH-2.0">
                        {item.text}
                        </TextStyles>
                        <TextStyles type="Paragraph" paragraphTypes="MD-1.4" fontweight="normal" lineHeight="LH-2.0">
                        {item.description}
                        </TextStyles>
                       </FlexLayout>
                    </FlexLayout>
                    <hr className="custom-hr m-0"/>
                   </FlexLayout>
                );
              })}
              </FlexLayout>
            </Card>
          </div>
        </div>
        <div onClick={() => setActive(!active)} className="inte-Backdrop"></div>
      </div>

    </>
  );
};

export default StatusData;
