/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { withRouter } from "react-router-dom";
import {
  Loader,
  TextField,
} from "@cedcommerce-integration/ounce-ui";
import {
  getAccountsAPI,
  getSavedPaymentChannelsAPI,
  loginAPI,
} from "../../../APIs/loginAPI";
import { globalState } from "../../../services/globalState";
import { getMethod, postMethod } from "../../../apiMethods/methods";
import FreschatWrapper from "../../../Freshdesk";

const LoginComponent = (props) => {

  const [credentials, setCredentials] = useState({
      email: "mohdfaiz@cedcommerce.com",
      password: "mohdfaiz@cedcommerce.com_nujma0ie4isso_mohdfaiz@cedcommerce.com",
  });
  const [targetConnected, setTargetConnected] = useState({
    ebay_connected: "",
    shopline_conected: "",
    token_expired: false,
    payment_channel: "",
  });

  const getAccounts = async () => {
    let connected = {
      shopline_conected: "",
      ebay_connected: "",
      token_expired: false,
      payment_channel: "",
    };
    let { success, data, code } = await getMethod(getAccountsAPI);
    if (success) {
      if (
        data?.ebay &&
        data?.ebay?.installed &&
        Array.isArray(data?.ebay?.installed) &&
        data?.ebay?.installed.length > 0
      ) {
        connected.ebay_connected = "_TARGET_CONNECTED_";
        globalState.setLocalStorage(
          "ebayShopId",
          data?.ebay?.installed?.[0]?.["_id"]
        );
      }
      if (
        data?.shopline &&
        data?.shopline?.installed &&
        Array.isArray(data?.shopline?.installed) &&
        data?.shopline?.installed.length > 0
      ) {
        connected.shopline_conected = "_SOURCE_CONNECTED_";
        globalState.setLocalStorage(
          "shopLineShopId",
          data?.shopline?.installed?.[0]?.["_id"]
        );
      }
    } else if (
        code === "token_expired" ||
        code === "invalid_token" ||
        code === "future_token" ||
        code === "token_decode_error"
      )
        connected["token_expired"] = true;

    let { success: successSavedPaymentChannel, data: dataSavedPaymentChannel } =
      await getMethod(getSavedPaymentChannelsAPI);
    if (successSavedPaymentChannel) {
      if (dataSavedPaymentChannel?.value?.payment_channel) {
        globalState.setLocalStorage(
          "payment_channel",
          data?.value?.payment_channel
        );
        connected.payment_channel = "_PAYMENT_CHANNEL_CONNECTED_";
      }
    }
    return connected;
  };

  function parseJwt(token) {
    const base64Url = token?.split(".")[1];
    const base64 = base64Url?.replace(/-/g, "+")?.replace(/_/g, "/");
    const jsonPayload = decodeURIComponent(
      atob(base64)
        .split("")
        .map(function (c) {
          return "%" + ("00" + c.charCodeAt(0).toString(16)).slice(-2);
        })
        .join("")
    );
    return JSON.parse(jsonPayload)?.user_id;
  }

  const submitHandler = async () => {
    let getToken = await postMethod(loginAPI, credentials);
    if (getToken.success) {
      globalState.setLocalStorage("user_authenticated", "true");
      globalState.setLocalStorage("auth_token", getToken.data.token);
      globalState.setLocalStorage("user_id", parseJwt(getToken.data.token));
      setTargetConnected(await getAccounts());
    }
  };

  async function isAppInstalled() {
    globalState.removeLocalStorage("ebayShopId");
    globalState.removeLocalStorage("shopLineShopId");
    const queryParams = window.location.search;
    if (queryParams.split("&")?.[0]?.split("=")?.[1]) {
      globalState.setLocalStorage(
        "auth_token",
        queryParams.split("&")?.[0]?.split("=")?.[1]
      );
      globalState.setLocalStorage("user_authenticated", "true");
    }
    setTargetConnected(await getAccounts());
  }

  useEffect(() => {
    isAppInstalled();
  }, [window.location.href]);

  let BodyRender = null;
  if (
    globalState.getLocalStorage("auth_token")
  ) {
    const queryParams = window.location.search;
    if (queryParams.includes("shop=ebay&connectionStatus=1")) {
      props.history.push("/show/message");
    } else if (
      targetConnected?.ebay_connected === "_TARGET_CONNECTED_" &&
      targetConnected?.shopline_conected === "_SOURCE_CONNECTED_"
    ) {
      if (
        targetConnected?.["payment_channel"] === "_PAYMENT_CHANNEL_CONNECTED_"
      ) {
        props.history.push("/panel");
      } else props.history.push("/payment");
    } else if (targetConnected?.shopline_conected === "_SOURCE_CONNECTED_") {
      props.history.push("/onboarding");
    }
    else {
      BodyRender = (
        <Loader type={"Loader1"} title={""} />
      );
    }
  } else if (window.location.href?.includes("localhost")) {
    BodyRender = (
      <FreschatWrapper>
        <TextField
          name="Email"
          value={credentials.email}
          onChange={(e) => setCredentials({ ...credentials, username: e })}
        />
        <TextField
          name="Password"
          value={credentials.password}
          onChange={(e) => setCredentials({ ...credentials, password: e })}
          type="password"
        />
        <button onClick={() => submitHandler()}>Submit</button>
      </FreschatWrapper>
    );
  }

  return BodyRender;

};

export default withRouter(LoginComponent);
