import {
  Accordion,
  Button,
  Card,
  FallBack,
  FlexChild,
  FlexLayout,
  Modal,
  Notification,
  PageHeader,
  Pagination,
  Progressbar,
  TextStyles,
  Toast,
  ToastWrapper,
} from "@cedcommerce-integration/ounce-ui";
import React, { useEffect, useState } from "react";
import { withRouter } from "react-router-dom";
import { getMethod, postMethod } from "../../../../apiMethods/methods";
import {
  deleteAllActivitiesAPI,
  getActivitesAPI,
  getOngoingActivitiesAPI,
} from "../../../../APIs/PanelAPI";
import { Error, NoNotification, Warning2 } from "../../../shared/IconSvg";
import {
  Activities,
  CompletedActivities,
} from "../Skeleton/ActivitiesSkeleton";
import { removeBracket } from "../../../activity/Activities";

/**
 * @param date date
 * @returns formated value ex:-> 2 min ago || 12:45 today || yesterday || 12/08/2022
 */
export const customDate = (date) => {
  const dt_date1 = new Date(date);
  const dt_date2 = new Date();
  const date1_time_stamp = dt_date1.getTime();
  const date2_time_stamp = dt_date2.getTime();
  const calc = new Date(date2_time_stamp - date1_time_stamp);
  const msPerMinute = 60 * 1000;
  const msPerHour = msPerMinute * 60;
  const msPerDay = msPerHour * 24;
  const msPerMonth = msPerDay * 30;
  const msPerYear = msPerDay * 365;
  const returnDateformat =
    dt_date1.getDate() < 10 ? "0" + dt_date1.getDate() : dt_date1.getDate();
  const returnDate =
    dt_date1.getMonth() +
    1 +
    "/" +
    returnDateformat +
    "/" +
    dt_date1.getFullYear();
  if (calc < msPerMinute) {
    if (Math.round(calc / 1000) >= 0)
      return Math.round(calc / 1000) + " seconds ago";
    else {
      return "0 seconds ago";
    }
  } else if (calc < msPerHour) {
    return Math.round(calc / msPerMinute) + " minutes ago";
  } else if (calc < msPerDay) {
    return Math.round(calc / msPerHour) + " hours ago";
  } else if (calc < msPerMonth) {
    return returnDate;
  } else if (calc < msPerYear) {
    return returnDate;
  } else {
    return returnDate;
  }
};

export const parsedErrorList = (data) => {
  let errObj = {};
  if (data) {
    Object.keys(data)?.forEach((product, index) => {
      let errorValue = {};
      if (Array.isArray(data?.[product]) && data?.[product]?.length > 0) {
        data?.[product].forEach((err) => {
          if (err?.["LongMessage"] && err?.["ShortMessage"])
            errorValue[`${err["LongMessage"]}`] = {
              value: `${err["ShortMessage"]}`,
              icon: err["SeverityCode"] == "Error" ? Error : Warning2,
            };
        });
        errObj[product] = errorValue;
      }
    });
  }
  return errObj;
};

const OngoingActivity = ({
  msgCountInWebpack,
  setShowDot,
  showDot,
  ...props
}) => {
  const [errorModal, setErrorModal] = useState(false);
  const [notificationData, setNotificationData] = useState([]);
  const [count, setCount] = useState(0);
  const [activePage, setActivePage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [pageSizeOptions, setPageSizeOptions] = useState([
    {
      label: "10",
      value: "10",
    },
    {
      label: "25",
      value: "25",
    },
    {
      label: "50",
      value: "50",
    },
    {
      label: "100",
      value: "100",
    },
  ]);
  const [ongoingActivitiesData, setOngoingActivitiesData] = useState([]);
  //toast
  const [successToast, setSuccessToast] = useState({
    active: false,
    message: "",
    success: false,
  });
  const [errorToast, setErrorToast] = useState({
    active: false,
    message: "",
    success: false,
  });
  // clear btn loader
  const [clearBtnLoader, setClearBtnLoader] = useState(false);
  // no activity fallback
  const [noActivityStatus, setNoActivityStatus] = useState(false);
  const [noOngoingActivityStatus, setNoOngoingActivityStatus] = useState(false);
  const [noCompletedActivityStatus, setNoCompletedActivityStatus] =
    useState(false);
  const [timeOutLoader, setTimeOutLoader] = useState(true);
  const [ongoingActivitySkeleton, setOngoingActivitySkeleton] = useState(true);
  const [completedActivitySkeleton, setCompletedActivitySkeleton] =
    useState(true);

  const [errorModal2, setErrorModal2] = useState(false);
  const [errorModalData, setErrModalData] = useState([{}]);
  const [errorModalIndex, setErrorModalIndex] = useState(0);
  const [clicked, setClicked] = useState(0);

  useEffect(() => {
    setShowDot(false);
  }, []);

  useEffect(() => {
    if (showDot) setShowDot(false);
  }, [showDot]);

  useEffect(() => {
    if (noCompletedActivityStatus && noOngoingActivityStatus) {
      setNoActivityStatus(true);
    } else {
      setNoActivityStatus(false);
    }
  }, [noCompletedActivityStatus, noOngoingActivityStatus]);

  const getOngoingActivitiesFunc = async function () {
    const time1 = new Date();
    let ms1 = time1.getTime();
    if (!msgCountInWebpack) setOngoingActivitySkeleton(true);
    let { success, data } = await getMethod(getOngoingActivitiesAPI);
    const time2 = new Date();
    let ms2 = time2.getTime();
    let timeOut = ms2 - ms1;
    if (success) {
      if (data?.rows?.length) {
        let activites = data.rows.map((activity, index) => {
          return {
            text: activity.message,
            description: activity.created_at,
            progress: activity.progress,
          };
        });
        setOngoingActivitiesData(activites);
      }
      if (data?.rows?.length == 0) {
        setNoOngoingActivityStatus(true);
      }
    }
    if (timeOut < 500) {
      setTimeout(() => {
        setOngoingActivitySkeleton(false);
      }, 500 - timeOut);
    } else {
      setOngoingActivitySkeleton(false);
    }
  };
  const getActivitiesFunc = async (payload = {}) => {
    const time1 = new Date();
    let ms1 = time1.getTime();
    if (!msgCountInWebpack) setCompletedActivitySkeleton(true);
    let { success, data, code } = await getMethod(getActivitesAPI, payload);
    const time2 = new Date();
    let ms2 = time2.getTime();
    let timeOut = ms2 - ms1;
    if (success) {
      setCount(data?.count);
      if (data?.rows?.length) {
        let activites = data.rows.map((activity, index) => {
          return {
            text: activity.message,
            description: activity.created_at,
            type:
              activity.severity === "error"
                ? "danger"
                : activity.severity === "success"
                ? "success"
                : "info",
          };
        });
        setNotificationData(activites);
      }
      if (data?.rows?.length == 0) {
        setNoCompletedActivityStatus(true);
      }
    } else {
      if (
        code === "token_expired" ||
        code === "invalid_token" ||
        code === "future_token" ||
        code === "token_decode_error"
      ) {
        props.history.push("/tokenexpired");
      }
    }
    if (timeOut < 500) {
      setTimeout(() => {
        setCompletedActivitySkeleton(false);
      }, 500 - timeOut);
    } else {
      setCompletedActivitySkeleton(false);
    }
  };


  const commonFunction = async (payload = {}) => {
    const time1 = new Date();
    let ms1 = time1.getTime();
    if (!msgCountInWebpack) {
      setCompletedActivitySkeleton(true);
      setOngoingActivitySkeleton(true);
    }
    let {
      success: completedSuccess,
      data: completedData,
      code: completedCode,
    } = await getMethod(getActivitesAPI, payload);
    let { success: ongoingSuccess, data: ongoingData } = await getMethod(
      getOngoingActivitiesAPI
    );

    const time2 = new Date();
    let ms2 = time2.getTime();
    let timeOut = ms2 - ms1;
    if (ongoingSuccess) {
      if (ongoingData?.rows?.length) {
        let activites = ongoingData.rows.map((activity, index) => {
          return {
            text: removeBracket(activity?.message),
            description: activity?.additional_data,
            subdescription: activity?.created_at,
            progress: activity?.progress,
            code: activity?.process_code,
          };
        });
        setOngoingActivitiesData(activites);
      }
      if (ongoingData?.rows?.length == 0) {
        setOngoingActivitiesData([]);
        setNoOngoingActivityStatus(true);
      }
    }
    if (completedSuccess) {
      setCount(completedData?.count);
      if (completedData?.rows?.length) {
        let tempErroModalData = [];
        let activites = completedData.rows.map((activity, index) => {
          let heading = "",
            description = "";
          tempErroModalData.push(parsedErrorList(activity?.additional_data));
          if (activity?.message.includes("</br>")) {
            heading = activity?.message?.split("</br>")[0];
            description = activity?.message?.split("</br>")[1];
            return {
              text: removeBracket(heading),
              description: description,
              subdescription: activity?.created_at,
              type:
                activity?.severity === "error"
                  ? "danger"
                  : activity?.severity === "success"
                  ? "success"
                  : "info",
            };
          } else {
            return {
              text: removeBracket(activity?.message),
              subdescription: activity?.created_at,
              type:
                activity?.severity === "error"
                  ? "danger"
                  : activity?.severity === "success"
                  ? "success"
                  : "info",
            };
          }
        });
        if (!tempErroModalData.length) {
          tempErroModalData.push({});
        }
        setErrModalData(tempErroModalData);

        setNotificationData(activites);
      }
      if (completedData?.rows?.length == 0) {
        setNoCompletedActivityStatus(true);
      }
    } else {
      if (
        completedCode === "token_expired" ||
        completedCode === "invalid_token" ||
        completedCode === "future_token" ||
        completedCode === "token_decode_error"
      ) {
        props.history.push("/tokenexpired");
      }
    }
    if (timeOut < 500) {
      setTimeout(() => {
        setOngoingActivitySkeleton(false);
        setTimeOutLoader(false);
        setCompletedActivitySkeleton(false);
      }, 500 - timeOut);
    } else {
      setOngoingActivitySkeleton(false);
      setTimeOutLoader(false);
      setCompletedActivitySkeleton(false);
    }
  };

  const handleToggle = (index) => {
    if (clicked === index) {
      setClicked(-1);
    } else {
      setClicked(index);
    }
  };

  const callAPI = () => {
    commonFunction({
      count: pageSize,
      activePage: activePage,
    });
  };

  useEffect(() => {
    callAPI();
  }, [msgCountInWebpack, activePage, pageSize]);

  const toastMarkup = successToast.active ? (
    <Toast
      message={successToast.message}
      onDismiss={() =>
        setSuccessToast({ active: false, message: "", success: false })
      }
      timeout={2000}
      type={successToast.success ? "success" : "error"}
    />
  ) : errorToast.active ? (
    <Toast
      message={errorToast.message}
      onDismiss={() =>
        setErrorToast({ active: false, message: "", success: false })
      }
      timeout={2000}
      type="error"
    />
  ) : null;

  return (
    <>
      {noActivityStatus ? (
        <>
          <PageHeader
            t
            title="Activities"
            description="Get an overview of all the ongoing and completed actions performed on the app."
          />
          <FallBack
            FallBackHeight="NormalPage"
            illustration={NoNotification}
            title="No Activities Found!"
          />
        </>
      ) : (
        <>
          <PageHeader
            title="Activities"
            description="Get an overview of all the ongoing and completed actions performed on the app."
          />
          {timeOutLoader ? (
            <Activities />
          ) : (
            <FlexLayout direction="vertical" wrap="noWrap" spacing="loose">
              {
                <>
                  {ongoingActivitiesData.length > 0 && (
                    <Card title="Ongoing Activities" extraClass="header-pb-16">
                      <FlexLayout
                        direction="vertical"
                        wrap="noWrap"
                        spacing="loose"
                      >
                        {ongoingActivitiesData.map((activity) => {
                          return (
                            <>
                              <FlexLayout
                                direction="vertical"
                                wrap="noWrap"
                                spacing="loose"
                              >
                                <FlexLayout
                                  direction="vertical"
                                  spacing="mediumTight"
                                  wrap="noWrap"
                                >
                                  <FlexLayout
                                    direction="vertical"
                                    wrap="noWrap"
                                    spacing="extraTight"
                                  >
                                    <TextStyles
                                      fontweight="bold"
                                      lineHeight="LH-2.0"
                                    >
                                      {activity.text}
                                    </TextStyles>
                                    {activity?.description?.length > 0 && (
                                      <TextStyles
                                        textcolor="light"
                                        lineHeight="LH-2.0"
                                      >
                                        {activity.code === "product_upload"
                                          ? `${activity.description?.product_count} of
                                        ${activity.description?.total_count} Products uploaded on eBay.`
                                          : activity.code === "price_sync"
                                          ? `${activity.description?.product_count} of
                                        ${activity.description?.total_count} Products price sync completed on eBay.`
                                          : activity.code ===
                                              "inventory_sync" &&
                                            `${activity.description?.product_count} of
                                        ${activity.description?.total_count} Products inventory sync completed on eBay.`}
                                      </TextStyles>
                                    )}
                                  </FlexLayout>
                                  <TextStyles
                                    type="Paragraph"
                                    paragraphTypes="XS-1.2"
                                    textcolor="light"
                                    lineHeight="LH-1.6"
                                  >
                                    {customDate(activity?.subdescription)}
                                  </TextStyles>
                                </FlexLayout>
                                <Progressbar
                                  percentage={activity?.progress}
                                  progessThickness="none"
                                  message={`${Math.floor(activity?.progress)}%`}
                                />
                              </FlexLayout>
                            </>
                          );
                        })}
                      </FlexLayout>
                    </Card>
                  )}
                </>
                // )
              }
              {/* Completed Activities */}
              {
                notificationData.length > 0 && (
                  <>
                    {completedActivitySkeleton ? (
                      <CompletedActivities />
                    ) : (
                      <Card
                        title="Completed Activities"
                        extraClass="header-pb-16"
                        action={
                          <Button
                            content="Clear Activities"
                            type="Primary"
                            onClick={() => setErrorModal(!errorModal)}
                          />
                        }
                      >
                        {notificationData.map((item, index) => {
                          return (
                            <>
                              <Notification
                                destroy={false}
                                desciption={
                                  item?.description && (
                                    <p className="inte__text inte__text--light inte__font--normal inte__LineHeight--2 mt-5">
                                      {item?.description} <br />
                                      <Button
                                        type="TextButton"
                                        onClick={() => {
                                          setErrorModalIndex(index);
                                          setErrorModal2(!errorModal2);
                                        }}
                                      >
                                        {" "}
                                        Click Here
                                      </Button>{" "}
                                      to check Products with Errors
                                    </p>
                                  )
                                }
                                subdesciption={customDate(item?.subdescription)}
                                type={item?.type}
                              >
                                <TextStyles
                                  lineHeight="LH-2.0"
                                  content={
                                    <span
                                      dangerouslySetInnerHTML={{
                                        __html: item?.text,
                                      }}
                                    ></span>
                                  }
                                  fontweight="bold"
                                />
                              </Notification>
                            </>
                          );
                        })}
                        <div className="mt-16">
                          {count > 10 && (
                            <Pagination
                              countPerPage={pageSize}
                              currentPage={activePage}
                              onCountChange={(count, c) => {
                                setActivePage(1);
                                setPageSize(count);
                              }}
                              onEnter={(e) => setActivePage(e)}
                              onNext={() => {
                                setActivePage(activePage + 1);
                              }}
                              onPrevious={() => {
                                setActivePage(activePage - 1);
                              }}
                              optionPerPage={pageSizeOptions}
                              totalitem={count}
                            />
                          )}
                        </div>
                      </Card>
                    )}
                  </>
                )
                // )
              }
            </FlexLayout>
          )}
          <Modal
            closeOnEscape={true}
            overlayClose={true}
            open={errorModal}
            close={() => setErrorModal(!errorModal)}
            heading="Clear all activities"
            modalSize="small"
            primaryAction={{
              content: "Clear",
              loading: clearBtnLoader,
              type: "Danger",
              onClick: async () => {
                setClearBtnLoader(true);
                let { success, data, message, code } = await postMethod(
                  deleteAllActivitiesAPI
                );
                if (success) {
                  callAPI();
                  setNotificationData([]);
                  setSuccessToast({
                    active: true,
                    message: message,
                    success: success,
                  });
                } else if (code) {
                  if (
                    code === "token_expired" ||
                    code === "invalid_token" ||
                    code === "future_token" ||
                    code === "token_decode_error"
                  ) {
                    props.history.push("/tokenexpired");
                  }
                } else {
                  setErrorToast({
                    active: true,
                    message: message,
                    success: success,
                  });
                }
                setClearBtnLoader(false);
                setErrorModal(false);
              },
            }}
            secondaryAction={{
              content: "Cancel",
              loading: false,
              onClick: () => setErrorModal(!errorModal),
            }}
          >
            Are you sure you want to clear all activities?
          </Modal>

          <Modal
            closeOnEscape={true}
            overlayClose={true}
            open={errorModal2}
            close={() => setErrorModal2(!errorModal2)}
            heading="Errors"
            modalSize="medium"
            extraClass="variantModal"
          >
            {Object.keys(errorModalData[errorModalIndex])?.length ? (
              <>
                {Object.keys(errorModalData[errorModalIndex])?.map(
                  (item, index) => {
                    return (
                      <Accordion
                        key={index}
                        iconAlign="right"
                        active={clicked === index}
                        onClick={() => handleToggle(index)}
                        title={
                          <FlexLayout spacing="extraTight">
                            <TextStyles
                              fontweight="bold"
                              lineHeight="LH-2.0"
                              paragraphTypes="MD-1.4"
                              textcolor="dark"
                              type="Paragraph"
                            >
                              Product:
                            </TextStyles>
                            <TextStyles
                              fontweight="normal"
                              lineHeight="LH-2.0"
                              paragraphTypes="MD-1.4"
                              textcolor="dark"
                              type="Paragraph"
                            >
                              {" "}
                              {item}
                            </TextStyles>
                          </FlexLayout>
                        }
                      >
                        <>
                          {Object.keys(
                            errorModalData[errorModalIndex]?.[item]
                          )?.map((items) => {
                            return (
                              <React.Fragment key={index}>
                                <Card cardType="Default">
                                  <FlexLayout spacing="tight" wrap="noWrap">
                                    <>
                                      {
                                        errorModalData[errorModalIndex][item][
                                          items
                                        ]["icon"]
                                      }
                                    </>
                                    <FlexChild
                                      desktopWidth="100"
                                      tabWidth="100"
                                      mobileWidth="100"
                                    >
                                      <FlexLayout
                                        direction="vertical"
                                        spacing="tight"
                                      >
                                        <FlexLayout
                                          direction="vertical"
                                          spacing="extraTight"
                                        >
                                          <TextStyles
                                            fontweight="extraBold"
                                            lineHeight="LH-2.0"
                                            paragraphTypes="MD-1.4"
                                            textcolor="dark"
                                            type="Paragraph"
                                          >
                                            {
                                              errorModalData[errorModalIndex][
                                                item
                                              ][items]["value"]
                                            }
                                          </TextStyles>

                                          <TextStyles
                                            alignment="left"
                                            fontweight="normal"
                                            lineHeight="LH-2.0"
                                            paragraphTypes="MD-1.4"
                                            textcolor="light"
                                            type="Paragraph"
                                            utility="none"
                                          >
                                            {items}
                                          </TextStyles>
                                        </FlexLayout>
                                      </FlexLayout>
                                    </FlexChild>
                                  </FlexLayout>
                                </Card>
                              </React.Fragment>
                            );
                          })}
                        </>
                      </Accordion>
                    );
                  }
                )}
              </>
            ) : (
              <>No Error Message</>
            )}
          </Modal>
          <ToastWrapper>{toastMarkup}</ToastWrapper>
        </>
      )}
    </>
  );
};

export default withRouter(OngoingActivity);
