import { bag, box, home, note, setting } from "@cedcommerce-integration/ounce-ui";

export const Menu = [
  {
    id: "dashboard",
    content: "Dashboard",
    path: "dashboard",
    icon: home,
  },
  {
    id: "profile",
    content: "Category Template",
    path: "category-template",
    icon: box,
  },
  {
    id: "products",
    content: "Product List",
    path: "products",
    icon: note,
  },
  {
    id: "orders",
    content: "Order List",
    path: "order-listing",
    icon: bag,
  },
  {
    id: "setting",
    content: "Configuration",
    path: "settings",
    icon: setting,
  },
];

export const SubMenu = [
  {
    id: "faq",
    content: "FAQ",
    path: "faq",
    icon: (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="20"
        height="20"
        viewBox="0 0 30 30"
        fill="none"
      >
        <path
          d="M15 27.5C21.9036 27.5 27.5 21.9036 27.5 15C27.5 8.09644 21.9036 2.5 15 2.5C8.09644 2.5 2.5 8.09644 2.5 15C2.5 21.9036 8.09644 27.5 15 27.5Z"
          stroke="#707070"
          strokeWidth="1.8"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M11.3625 11.25C11.6564 10.4146 12.2364 9.71011 12.9999 9.2614C13.7634 8.81268 14.6611 8.64865 15.534 8.79837C16.4068 8.94809 17.1985 9.40188 17.7688 10.0794C18.3392 10.7569 18.6513 11.6144 18.65 12.5C18.65 15 14.9 16.25 14.9 16.25"
          stroke="#707070"
          strokeWidth="1.8"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M15 21.25H15.0125"
          stroke="#707070"
          strokeWidth="1.8"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    ),
  },
];
