import { PageFooter, TextLink } from "@cedcommerce-integration/ounce-ui";

const Footer = () => {
  return (
    <PageFooter>
      <p className="ced-footer inte__text--light inte__font--normal inte__Paragraph--font--medium inte__LineHeight--2">
        A CedCommerce Inc Product @{new Date().getFullYear()}.{" "}
        <span className="inte__font--normal inte__font--bold">Need Help? </span>
        <TextLink
         extraClass="footer-textlink"
        label="Get Support"
        target="_blank"
        url="https://chat.whatsapp.com/EN8oKJuzO3Z0vvPfKE3wEa"
        />
       {" "}
        or{" "}
        <TextLink
          extraClass="footer-textlink"
          icon={
            <svg
              fill="none"
              height="14"
              viewBox="0 0 22 23"
              width="14"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M18 13.9365V19.9365C18 20.467 17.7893 20.9757 17.4142 21.3507C17.0391 21.7258 16.5304 21.9365 16 21.9365H5C4.46957 21.9365 3.96086 21.7258 3.58579 21.3507C3.21071 20.9757 3 20.467 3 19.9365V8.93652C3 8.40609 3.21071 7.89738 3.58579 7.52231C3.96086 7.14724 4.46957 6.93652 5 6.93652H11M10 14.9365L21 3.93652M21 3.93652H15M21 3.93652V9.93652"
                stroke="currentColor"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
              />
            </svg>
          }
          iconAlign="right"
          label="Book a Call"
          onClick={function noRefCheck() {}}
          target="_blank"
          url="https://calendly.com/cedmeet/shopline-project"
        />
      </p>
    </PageFooter>
  );
};

export default Footer;
