export const Error = (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
  >
    <path
      d="M8.57514 3.21667L1.51681 15C1.37128 15.252 1.29428 15.5377 1.29346 15.8288C1.29265 16.1198 1.36805 16.4059 1.51216 16.6588C1.65627 16.9116 1.86408 17.1223 2.1149 17.2699C2.36571 17.4175 2.65081 17.4968 2.94181 17.5H17.0585C17.3495 17.4968 17.6346 17.4175 17.8854 17.2699C18.1362 17.1223 18.344 16.9116 18.4881 16.6588C18.6322 16.4059 18.7076 16.1198 18.7068 15.8288C18.706 15.5377 18.629 15.252 18.4835 15L11.4251 3.21667C11.2766 2.97175 11.0674 2.76927 10.8178 2.62874C10.5682 2.48821 10.2866 2.41438 10.0001 2.41438C9.71369 2.41438 9.43208 2.48821 9.18248 2.62874C8.93287 2.76927 8.7237 2.97175 8.57514 3.21667V3.21667Z"
      stroke="#C4281C"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M10 7.5V10.8333"
      stroke="#C4281C"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M10 14.1667H10.01"
      stroke="#C4281C"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export const Feed = (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
  >
    <path
      d="M11.6666 1.66667H4.99992C4.55789 1.66667 4.13397 1.84226 3.82141 2.15482C3.50885 2.46738 3.33325 2.89131 3.33325 3.33333V16.6667C3.33325 17.1087 3.50885 17.5326 3.82141 17.8452C4.13397 18.1577 4.55789 18.3333 4.99992 18.3333H14.9999C15.4419 18.3333 15.8659 18.1577 16.1784 17.8452C16.491 17.5326 16.6666 17.1087 16.6666 16.6667V6.66667L11.6666 1.66667Z"
      stroke="#3B424F"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M11.6667 1.66667V6.66667H16.6667"
      stroke="#3B424F"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M13.3334 10.8333H6.66675"
      stroke="#3B424F"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M13.3334 14.1667H6.66675"
      stroke="#3B424F"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M8.33341 7.5H7.50008H6.66675"
      stroke="#3B424F"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export const Dollar = (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
  >
    <g clipPath="url(#clip0_678_55995)">
      <path
        d="M10 0.833336V19.1667"
        stroke="#3B424F"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M14.1667 4.16666H7.91667C7.14312 4.16666 6.40125 4.47396 5.85427 5.02094C5.30729 5.56792 5 6.30978 5 7.08333C5 7.85688 5.30729 8.59874 5.85427 9.14573C6.40125 9.69271 7.14312 10 7.91667 10H12.0833C12.8569 10 13.5987 10.3073 14.1457 10.8543C14.6927 11.4013 15 12.1431 15 12.9167C15 13.6902 14.6927 14.4321 14.1457 14.9791C13.5987 15.526 12.8569 15.8333 12.0833 15.8333H5"
        stroke="#3B424F"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </g>
    <defs>
      <clipPath id="clip0_678_55995">
        <rect width="20" height="20" fill="white" />
      </clipPath>
    </defs>
  </svg>
);

export const AllBadge = (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="8"
    height="8"
    viewBox="0 0 8 8"
    fill="none"
  >
    <rect width="8" height="8" rx="1.6" fill="#9984DB" />
  </svg>
);

export const ActiveBadge = (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="8"
    height="8"
    viewBox="0 0 8 8"
    fill="none"
  >
    <rect width="8" height="8" rx="1.6" fill="#269E6C" />
  </svg>
);

export const InProgressBadge = (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="8"
    height="8"
    viewBox="0 0 8 8"
    fill="none"
  >
    <rect width="8" height="8" rx="1.6" fill="#FF8277" />
  </svg>
);

export const NotUploadedBadge = (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="8"
    height="8"
    viewBox="0 0 8 8"
    fill="none"
  >
    <rect width="8" height="8" rx="1.6" fill="#FEC84B" />
  </svg>
);

export const InactiveBadge = (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="8"
    height="8"
    viewBox="0 0 8 8"
    fill="none"
  >
    <rect width="8" height="8" rx="1.6" fill="#E0E1E3" />
  </svg>
);

export const ErrorBadge = (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="8"
    height="8"
    viewBox="0 0 8 8"
    fill="none"
  >
    <rect width="8" height="8" rx="1.6" fill="#FF0000" />
  </svg>
);

export const UnshippedBadge = (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="8"
    height="8"
    viewBox="0 0 8 8"
    fill="none"
  >
    <rect width="8" height="8" rx="1.6" fill="#F6CECB" />
  </svg>
);

export const ActiveTick = (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="17"
    height="16"
    viewBox="0 0 17 16"
    fill="none"
  >
    <rect x="0.333252" width="16" height="16" rx="8" fill="#27591C" />
    <g clipPath="url(#clip0_4720_65400)">
      <path
        d="M10.9998 6L7.33317 9.66667L5.6665 8"
        stroke="white"
        strokeWidth="1.67"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </g>
    <defs>
      <clipPath id="clip0_4720_65400">
        <rect
          width="8"
          height="8"
          fill="white"
          transform="translate(4.33325 4)"
        />
      </clipPath>
    </defs>
  </svg>
);

export const UnactiveTick = (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="17"
    height="16"
    viewBox="0 0 17 16"
    fill="none"
  >
    <rect x="0.333252" width="16" height="16" rx="8" fill="#E0E0E0" />
    <g clipPath="url(#clip0_4720_65406)">
      <path
        d="M10.9998 6L7.33317 9.66667L5.6665 8"
        stroke="white"
        strokeWidth="1.67"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </g>
    <defs>
      <clipPath id="clip0_4720_65406">
        <rect
          width="8"
          height="8"
          fill="white"
          transform="translate(4.33325 4)"
        />
      </clipPath>
    </defs>
  </svg>
);
