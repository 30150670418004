import { Card, Skeleton, FlexLayout } from "@cedcommerce-integration/ounce-ui";

const CurrencySettingsSkeleton = () => {
  return (
    <Card
      extraClass="currency-skeleton"
      title={<Skeleton height="14px" width="100px" type="custom" />}
      subTitle={<Skeleton height="14px" width="200px" type="custom" />}
      action={<Skeleton height="40px" width="70px" type="custom" />}
    >
      {/* Currency SETTINGS */}
      <FlexLayout direction="vertical" spacing="mediumLoose" wrap="noWrap">
        <Card cardType="Plain">
          <FlexLayout direction="vertical" spacing="tight" wrap="noWrap">
            <Skeleton height="14px" width="65%" type="custom" />
            <FlexLayout
              spacing="loose"
              desktopWidth="50"
              tabWidth="50"
              mobileWidth="50"
            >
              <Skeleton height="42px" width="150px" type="line" />
              <Skeleton height="42px" width="150px" type="line" />
            </FlexLayout>
          </FlexLayout>
        </Card>
      </FlexLayout>
    </Card>
  );
};

export default CurrencySettingsSkeleton;
