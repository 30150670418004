import {
  Card,
  FlexChild,
  FlexLayout,
  List,
  LRLayout,
  PageHeader,
  Skeleton,
} from "@cedcommerce-integration/ounce-ui";
import React from "react";

export const CategoryAddEditSkeleton = () => {
  return (
    <>
      <PageHeader
        action={<Skeleton height="20px" width="150px" type="custom" />}
        title={<Skeleton height="20px" width="200px" type="custom" />}
      />
      <Card>
        <LRLayout
          title={<Skeleton height="25px" width="150px" type="custom" />}
          lrHelpText={
            <FlexLayout direction="vertical" spacing="loose" wrap="noWrap">
              <Skeleton height="20px" width="100%" line={4} type="line" />
            </FlexLayout>
          }
        >
          <Card cardType="Bordered">
            <FlexLayout
              desktopWidth="25"
              mobileWidth="50"
              tabWidth="25"
              spacing="loose"
            >
              {[1, 2, 3, 4, 5].map((index) => {
                return (
                  <FlexLayout key={index} spacing="tight">
                    <Skeleton
                      height="20px"
                      line={1}
                      type="custom"
                      width="20px"
                    />
                    <Skeleton
                      height="20px"
                      line={1}
                      type="custom"
                      width="100px"
                    />
                  </FlexLayout>
                );
              })}
            </FlexLayout>
          </Card>
        </LRLayout>

        <LRLayout
          title={<Skeleton height="25px" width="150px" type="custom" />}
          lrHelpText={
            <FlexLayout direction="vertical" spacing="loose" wrap="noWrap">
              <Skeleton height="20px" line={1} type="line" />
              <div className="ced-list__skeleton">
                <List type="disc">
                  <Skeleton height="20px" line={2} type="line" />
                  <Skeleton height="20px" line={2} type="line" />
                </List>
              </div>
            </FlexLayout>
          }
        >
          <FlexLayout direction="vertical" spacing="tight" wrap="noWrap">
            <FlexLayout
              desktopWidth="50"
              tabWidth="50"
              mobileWidth="100"
              spacing="tight"
            >
              <Skeleton line={1} height="20px" type="custom" width="70%" />
              <Skeleton line={1} height="20px" type="custom" width="70%" />
            </FlexLayout>
            <Card cardType="Bordered">
              <FlexLayout spacing="loose" direction="vertical" wrap="noWrap">
                <Skeleton height="15px" width="100px" type="custom" />
                <FlexLayout direction="vertical" wrap="noWrap" spacing="tight">
                  <FlexLayout
                    desktopWidth="50"
                    tabWidth="50"
                    mobileWidth="100"
                    spacing="tight"
                  >
                    <FlexLayout spacing="tight">
                      <Skeleton
                        height="20px"
                        line={1}
                        type="custom"
                        width="20px"
                        rounded
                      />
                      <Skeleton
                        line={1}
                        height="20px"
                        type="custom"
                        width="150px"
                      />
                    </FlexLayout>
                    <Skeleton line={1} height="38px" type="line" width="100%" />
                  </FlexLayout>

                  <FlexLayout
                    desktopWidth="50"
                    tabWidth="50"
                    mobileWidth="100"
                    spacing="tight"
                  >
                    <FlexLayout spacing="tight">
                      <Skeleton
                        height="20px"
                        line={1}
                        type="custom"
                        width="20px"
                        rounded
                      />
                      <Skeleton
                        line={1}
                        height="20px"
                        type="custom"
                        width="150px"
                      />
                    </FlexLayout>
                    <Skeleton line={1} height="38px" type="line" width="100%" />
                  </FlexLayout>

                  <FlexLayout
                    desktopWidth="50"
                    tabWidth="50"
                    mobileWidth="100"
                    spacing="tight"
                  >
                    <FlexLayout spacing="tight">
                      <Skeleton
                        height="20px"
                        line={1}
                        type="custom"
                        width="20px"
                        rounded
                      />
                      <Skeleton
                        line={1}
                        height="20px"
                        type="custom"
                        width="150px"
                      />
                    </FlexLayout>
                    <Skeleton line={1} height="38px" type="line" width="100%" />
                  </FlexLayout>

                  <FlexLayout
                    desktopWidth="50"
                    tabWidth="50"
                    mobileWidth="100"
                    spacing="tight"
                  >
                    <FlexLayout spacing="tight">
                      <Skeleton
                        height="20px"
                        line={1}
                        type="custom"
                        width="20px"
                        rounded
                      />
                      <Skeleton
                        line={1}
                        height="20px"
                        type="custom"
                        width="150px"
                      />
                    </FlexLayout>
                    <Skeleton line={1} height="38px" type="line" width="100%" />
                  </FlexLayout>
                </FlexLayout>
              </FlexLayout>
            </Card>

            <Card cardType="Bordered">
              <FlexLayout spacing="loose" direction="vertical" wrap="noWrap">
                <FlexLayout halign="fill">
                  <Skeleton height="20px" width="100px" type="custom" />
                  <Skeleton height="43px" width="120px" type="custom" />
                </FlexLayout>
                <Skeleton height="56px" line={1} type="custom" width="100%" />
                <FlexLayout direction="vertical" wrap="noWrap" spacing="tight">
                  <FlexLayout
                    desktopWidth="50"
                    tabWidth="50"
                    mobileWidth="100"
                    spacing="tight"
                  >
                    <Skeleton line={1} height="38px" type="line" width="100%" />
                    <Skeleton line={1} height="38px" type="line" width="100%" />
                  </FlexLayout>

                  <FlexLayout
                    desktopWidth="50"
                    tabWidth="50"
                    mobileWidth="100"
                    spacing="tight"
                  >
                    <Skeleton line={1} height="38px" type="line" width="100%" />
                    <Skeleton line={1} height="38px" type="line" width="100%" />
                  </FlexLayout>
                </FlexLayout>
              </FlexLayout>
            </Card>
          </FlexLayout>
        </LRLayout>

        {/* <LRLayout
          title={<Skeleton height="25px" width="150px" type="custom" />}
          lrHelpText={
            <FlexLayout direction="vertical" spacing="loose" wrap="noWrap">
              <Skeleton height="20px" width="100%" line={4} type="line" />
            </FlexLayout>
          }
        >
          <Skeleton line={1} height="38px" type="line" width="100%" />
        </LRLayout> */}

        <LRLayout
          title={<Skeleton height="25px" width="150px" type="custom" />}
          lrHelpText={
            <FlexLayout direction="vertical" spacing="loose" wrap="noWrap">
              <Skeleton height="20px" width="100%" line={2} type="line" />
              <div className="ced-list__skeleton">
                <List type="disc">
                  <Skeleton height="20px" width="100%" line={2} type="line" />
                  <Skeleton height="20px" width="100%" line={2} type="line" />
                  <Skeleton height="20px" width="100%" line={2} type="line" />
                  <Skeleton height="20px" width="100%" line={2} type="line" />
                </List>
              </div>
            </FlexLayout>
          }
        >
          <Card title={<Skeleton height="25px" width="150px" type="custom" />}>
            <FlexLayout spacing="loose" wrap="noWrap" direction="vertical">
              <FlexLayout spacing="tight" valign="start">
                <Skeleton height="20px" line={1} type="custom" width="20px" />
                <FlexLayout
                  direction="vertical"
                  spacing="tight"
                  childWidth="fullWidth"
                  valign="start"
                >
                  <Skeleton
                    height="20px"
                    line={1}
                    type="custom"
                    width="150px"
                  />

                  <Skeleton
                    height="20px"
                    line={1}
                    type="custom"
                    width="250px"
                  />
                </FlexLayout>
              </FlexLayout>
              <FlexLayout spacing="loose" wrap="wrap" valign="center">
                <FlexChild desktopWidth="33" tabWidth="33" mobileWidth="100">
                  <Skeleton height="20px" width="100%" type="custom" />
                </FlexChild>
                <FlexChild desktopWidth="66" tabWidth="66" mobileWidth="100">
                  <FlexLayout
                    spacing="tight"
                    wrap="noWrap"
                    childWidth="fullWidth"
                  >
                    <FlexLayout
                      spacing="mediumTight"
                      valign="center"
                      wrap="noWrap"
                    >
                      <Skeleton
                        height="20px"
                        line={1}
                        type="custom"
                        width="20px"
                        rounded
                      />
                      <FlexChild
                        desktopWidth="80"
                        tabWidth="80"
                        mobileWidth="80"
                      >
                        <Skeleton
                          height="20px"
                          line={1}
                          type="custom"
                          width="100%"
                        />
                      </FlexChild>
                    </FlexLayout>
                    <FlexLayout
                      spacing="mediumTight"
                      valign="center"
                      wrap="noWrap"
                    >
                      <Skeleton
                        height="20px"
                        line={1}
                        type="custom"
                        width="20px"
                        rounded
                      />
                      <FlexChild
                        desktopWidth="80"
                        tabWidth="80"
                        mobileWidth="80"
                      >
                        <Skeleton
                          height="20px"
                          line={1}
                          type="custom"
                          width="100%"
                        />
                      </FlexChild>
                    </FlexLayout>
                  </FlexLayout>
                </FlexChild>
              </FlexLayout>
              <FlexLayout spacing="loose">
                <FlexChild desktopWidth="33" tabWidth="50" mobileWidth="50">
                  <Skeleton height="43px" width="100%" type="custom" />
                </FlexChild>
                <FlexChild desktopWidth="33" tabWidth="50" mobileWidth="50">
                  <Skeleton height="43px" width="100%" type="custom" />
                </FlexChild>
                <FlexChild desktopWidth="33" tabWidth="100" mobileWidth="100">
                  <Skeleton height="43px" width="100%" type="custom" />
                </FlexChild>
              </FlexLayout>
              <FlexLayout spacing="loose" halign="end">
                <Skeleton height="43px" width="120px" type="custom" />
                <Skeleton height="43px" width="120px" type="custom" />
              </FlexLayout>
              <Skeleton height="56px" line={1} type="custom" width="100%" />
            </FlexLayout>
          </Card>
        </LRLayout>
      </Card>
    </>
  );
};

export const TemplateNameSkeleton = () => {
  return (
    <LRLayout title={<Skeleton height="25px" width="150px" type="custom" />}>
      <FlexLayout direction="vertical" spacing="loose">
        <FlexChild desktopWidth={"100"} tabWidth={"50"} mobileWidth={"100"}>
          <Skeleton height="43px" width="100%" type="custom" />
        </FlexChild>
        <FlexChild desktopWidth={"100"} tabWidth={"50"} mobileWidth={"100"}>
          <Skeleton height="20px" width="100%" type="custom" />
        </FlexChild>
      </FlexLayout>
    </LRLayout>
  );
}

export const PolicySkeleton = () => {
  return (
    <LRLayout
      title={<Skeleton height="25px" width="150px" type="custom" />}
      lrHelpText={<Skeleton height="20px" width="100%" type="custom" />}
    >
      <FlexLayout direction="vertical" spacing="loose">
        {[1, 2, 3].map((index) => {
          return (
            <FlexLayout key={index} direction="vertical" spacing="mediumTight">
              <Skeleton height="20px" width="100px" type="custom" />
              <Skeleton height="43px" width="100%" type="custom" />
            </FlexLayout>
          );
        })}
      </FlexLayout>
    </LRLayout>
  );
}

export const ListingCategorySkeleton = () => {
  return (
    <LRLayout
      title={<Skeleton height="25px" width="150px" type="custom" />}
      lrHelpText={<Skeleton height="20px" width="100%" type="custom" />}
    >
      <FlexLayout direction="vertical" spacing="loose">
        <FlexChild desktopWidth={"100"} tabWidth={"50"} mobileWidth={"100"}>
          <Skeleton height="43px" width="100%" type="custom" />
        </FlexChild>

        {[1, 2, 3, 4].map((index) => {
          return (
            <FlexLayout key={index} direction="vertical" spacing="mediumTight">
              <Skeleton height="20px" width="100px" type="custom" />
              <Skeleton height="43px" width="100%" type="custom" />
            </FlexLayout>
          );
        })}
      </FlexLayout>
    </LRLayout>
  );
}

export const RuleGroupSekeleton = () => {
  return (
    <LRLayout
      title={<Skeleton height="25px" width="150px" type="custom" />}
      lrHelpText={
        <FlexLayout direction="vertical" spacing="loose" wrap="noWrap">
          <Skeleton height="20px" width="100%" line={2} type="line" />
          <div className="ced-list__skeleton">
            <List type="disc">
              <Skeleton height="20px" width="100%" line={2} type="line" />
              <Skeleton height="20px" width="100%" line={2} type="line" />
              <Skeleton height="20px" width="100%" line={2} type="line" />
              <Skeleton height="20px" width="100%" line={2} type="line" />
            </List>
          </div>
        </FlexLayout>
      }
    >
      <Card title={<Skeleton height="25px" width="150px" type="custom" />}>
        <FlexLayout spacing="loose" wrap="noWrap" direction="vertical">
          <FlexLayout spacing="tight" valign="start">
            <Skeleton height="20px" line={1} type="custom" width="20px" />
            <FlexLayout
              direction="vertical"
              spacing="tight"
              childWidth="fullWidth"
              valign="start"
            >
              <Skeleton height="20px" line={1} type="custom" width="150px" />

              <Skeleton height="20px" line={1} type="custom" width="250px" />
            </FlexLayout>
          </FlexLayout>
          <FlexLayout spacing="loose" wrap="wrap" valign="center">
            <FlexChild desktopWidth="33" tabWidth="33" mobileWidth="100">
              <Skeleton height="20px" width="100%" type="custom" />
            </FlexChild>
            <FlexChild desktopWidth="66" tabWidth="66" mobileWidth="100">
              <FlexLayout spacing="tight" wrap="noWrap" childWidth="fullWidth">
                <FlexLayout spacing="mediumTight" valign="center" wrap="noWrap">
                  <Skeleton
                    height="20px"
                    line={1}
                    type="custom"
                    width="20px"
                    rounded
                  />
                  <FlexChild desktopWidth="80" tabWidth="80" mobileWidth="80">
                    <Skeleton
                      height="20px"
                      line={1}
                      type="custom"
                      width="100%"
                    />
                  </FlexChild>
                </FlexLayout>
                <FlexLayout spacing="mediumTight" valign="center" wrap="noWrap">
                  <Skeleton
                    height="20px"
                    line={1}
                    type="custom"
                    width="20px"
                    rounded
                  />
                  <FlexChild desktopWidth="80" tabWidth="80" mobileWidth="80">
                    <Skeleton
                      height="20px"
                      line={1}
                      type="custom"
                      width="100%"
                    />
                  </FlexChild>
                </FlexLayout>
              </FlexLayout>
            </FlexChild>
          </FlexLayout>
          <FlexLayout spacing="loose">
            <FlexChild desktopWidth="33" tabWidth="50" mobileWidth="50">
              <Skeleton height="43px" width="100%" type="custom" />
            </FlexChild>
            <FlexChild desktopWidth="33" tabWidth="50" mobileWidth="50">
              <Skeleton height="43px" width="100%" type="custom" />
            </FlexChild>
            <FlexChild desktopWidth="33" tabWidth="100" mobileWidth="100">
              <Skeleton height="43px" width="100%" type="custom" />
            </FlexChild>
          </FlexLayout>
          <FlexLayout spacing="loose" halign="end">
            <Skeleton height="43px" width="120px" type="custom" />
            <Skeleton height="43px" width="120px" type="custom" />
          </FlexLayout>
          <Skeleton height="56px" line={1} type="custom" width="100%" />
        </FlexLayout>
      </Card>
    </LRLayout>
  );
}