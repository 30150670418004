import { Grid} from "@cedcommerce-integration/ounce-ui";
import React from "react";

function FallbackGrid({
  columns,
  dataSource=[],
  variantColumns = [],
  rowSelection="",
  local = "",
}) {
  return (
    <Grid
      columns={[...columns].filter((col) => col?.visible)}
      dataSource={[...dataSource]}
      locale={{
        emptyText: local,
      }}
      scrollX={800}
      size="middle"
      tableLayout="auto"
      rowSelection={rowSelection}
    />
  );
}

export default FallbackGrid;
