import { Card, FlexLayout, TextStyles } from "@cedcommerce-integration/ounce-ui";
import React from "react";

const ShippingAddress = ({ shippingAddress }) => {
  let {
    first_name,
    last_name,
    zip,
    phone,
    country_code,
    country,
    city,
    address1,
    address2,
  } = shippingAddress;

  return (
    <Card title={"Shipping Address"}>
      <FlexLayout direction="vertical" wrap="noWrap" spacing="loose">
        <TextStyles lineHeight="LH-2.0" fontweight="bold">
          {`${first_name} ${last_name}, ${phone}`}
        </TextStyles>
        <FlexLayout direction="vertical" spacing="mediumTight">
          <TextStyles textcolor="light" lineHeight="LH-2.0">
            {address1}
          </TextStyles>
          <TextStyles
            fontweight="normal"
            lineHeight="LH-2.0"
            paragraphTypes="MD-1.4"
            textcolor="light"
            type="Paragraph"
            utility="inte-world_break"
          >
            {`${address2}, ${city}, ${country}, ${country_code} ${zip}`}
          </TextStyles>
        </FlexLayout>
      </FlexLayout>
    </Card>
  );
};

export default ShippingAddress;
