import {
  Button,
  Card,
  FlexChild,
  FlexLayout,
  PageHeader,
} from "@cedcommerce-integration/ounce-ui";

import ProductStatus from "./ProductStatus";
import OrderStatus from "./OrderStatus";
import { withRouter } from "react-router-dom";
import { RefreshCcw } from "react-feather";
import "../../../src/App.css";
import Activities from "../activity/Activities";

import { useEffect, useReducer, useState } from "react";

const Dashboard = (props) => {
  const { msgCountInWebpack } = props;
  const [refreshBtnClickedCount, setRefreshBtnClickedCount] = useState(0);
  const [refresh, setRefresh] = useState(false);

  const initialState = {
    product: false,
    order: false,
    activities: false,
  };
  const reducer = (state, action) => {
    switch (action.type) {
      case "product":
        return { ...state, product: action.value };
      case "order":
        return { ...state, order: action.value };
      case "activities":
        return { ...state, activities: action.value };
      default:
        return state;
    }
  };
  const [state, dispatch] = useReducer(reducer, initialState);

  useEffect(() => {
    if (state.product && state.order && state.activities) {
      setRefresh(true);
    } else if (!state.product && !state.order && !state.activities) {
      setRefresh(false);
    }
  }, [state]);

  return (
    <div className="ced-dashboard">
      <PageHeader
        action={
          <Button
            onClick={() =>
              setRefreshBtnClickedCount(refreshBtnClickedCount + 1)
            }
            content="Refresh"
            type="Outlined"
            icon={<RefreshCcw size={16} />}
            loading={refresh}
          />
        }
        title="Dashboard"
        description="Get an overview of all the recent updates on all your crucial operations and activities."
      />
      <FlexLayout spacing="mediumLoose" wrap="wrap" valign="start">
        <FlexChild desktopWidth="100" tabWidth="100" mobileWidth="100">
          <>
            <Card>
              <FlexLayout
                direction="vertical"
                spacing="extraLoose"
                wrap="noWrap"
              >
                <ProductStatus
                  refreshBtnClickedCount={refreshBtnClickedCount}
                  dispatch={dispatch}
                />
                <OrderStatus
                  refreshBtnClickedCount={refreshBtnClickedCount}
                  dispatch={dispatch}
                />
                <Activities
                  msgCountInWebpack={msgCountInWebpack}
                  refreshBtnClickedCount={refreshBtnClickedCount}
                  dispatch={dispatch}
                />
              </FlexLayout>
            </Card>
          </>
        </FlexChild>
      </FlexLayout>
    </div>
  );
};
export default withRouter(Dashboard);
