import { Loader } from "@cedcommerce-integration/ounce-ui";
import React, { useEffect } from "react";
import { withRouter } from "react-router-dom";

const RedirectMessage = () => {

  useEffect(() => {
    window.close();
  }, []);

  return (
    <>
      <Loader type="Loader2" />
    </>
  );
};

export default withRouter(RedirectMessage);
