/* eslint-disable react-hooks/exhaustive-deps */
import { Button, Card, FlexLayout, TextStyles } from "@cedcommerce-integration/ounce-ui";
import { useEffect, useState } from "react";
import {
  ClockIcon,
  CrossError,
  NotUploaded,
  UploadUp,
} from "../shared/IconSvg";
import { withRouter } from "react-router-dom";
import { getMethod } from "../../apiMethods/methods";
import { productStatusAPI } from "../../APIs/PanelAPI";

const ProductStatus = (props) => {
  const { refreshBtnClickedCount, dispatch } = props;
  const [productStatus, setProductStatus] = useState({
    uploaded: 0,
    not_uploaded: 0,
    ended: 0,
    errors: 0,
  });
  const [skeleton, setSekelton] = useState(false);

  const getProductStatusFunc = async () => {
    dispatch({ type: "product", value: true });
    const time1 = new Date();
    let ms1 = time1.getTime();
    setSekelton(true);
    let { success, data, code } = await getMethod(productStatusAPI);
    const time2 = new Date();
    let ms2 = time2.getTime();
    let timeOut = ms2 - ms1;
    if (success) {
      if (Array.isArray(data)) {
        let tempStatus = { ...productStatus };
        data.forEach((row) => {
          if (row?._id === "Active") {
            tempStatus["uploaded"] = row?.total;
          } else if (row?._id === null) {
            tempStatus["not_uploaded"] = row?.total;
          } else if (row?._id === "Ended") {
            tempStatus["ended"] = row?.total;
          } else if (row?._id === "Error") {
            tempStatus["errors"] = row?.total;
          }
        });
        setProductStatus(tempStatus);
      }
    } else if (
        code === "token_expired" ||
        code === "invalid_token" ||
        code === "future_token" ||
        code === "token_decode_error"
      ) {
        props.history.push("/tokenexpired");
    }
    
    if (timeOut < 500) {
      setTimeout(() => {
        setSekelton(false);
        dispatch({ type: "product", value: false });
      }, 500 - timeOut);
    } else {
      setSekelton(false);
      dispatch({ type: "product", value: false });
    }
  };

  useEffect(() => {
    refreshBtnClickedCount > 0 && getProductStatusFunc();
  }, [refreshBtnClickedCount]);

  useEffect(() => {
    getProductStatusFunc();
  }, []);

  return (
    <>
      <Card
        cardType="Subdued"
        title={"Product Status"}
        action={
          <Button
            type="TextButton"
            onClick={() => props.history.push("/panel/products")}
          >
            View all
          </Button>
        }
      >
        <FlexLayout
          spacing="loose"
          wrap="wrap"
          desktopWidth="25"
          tabWidth="50"
          mobileWidth="50"
        >
          <Card extraClass="p-12">
            <FlexLayout
              direction="vertical"
              spacing="mediumTight"
              wrap="noWrap"
            >
              <FlexLayout halign="fill" valign="center" wrap="noWrap">
                <TextStyles
                  fontweight="bold"
                  paragraphTypes="MD-1.4"
                  textcolor="light"
                  type="Paragraph"
                  lineHeight="LH-2.0"
                >
                  Uploaded
                </TextStyles>
                {UploadUp}
              </FlexLayout>
              <TextStyles
                fontweight="extraBold"
                lineHeight="LH-2.4"
                paragraphTypes="MD-1.4"
                subheadingTypes="XS-1.6"
                textcolor="dark"
                type="SubHeading"
              >
                {skeleton ? (
                  <div className="custom-spinner"></div>
                ) : (
                  productStatus.uploaded
                )}
              </TextStyles>
              <Button
                content="View Products"
                type="TextButton"
                onClick={() => {
                  window.scrollTo(0, 0);
                  props.history.push("/panel/products", {
                    tabFromDashboard: "uploaded",
                  });
                }}
              />
            </FlexLayout>
          </Card>
          <Card extraClass="p-12">
            <FlexLayout
              direction="vertical"
              spacing="mediumTight"
              wrap="noWrap"
            >
              <FlexLayout halign="fill" valign="center" wrap="noWrap">
                <TextStyles
                  fontweight="bold"
                  paragraphTypes="MD-1.4"
                  textcolor="light"
                  type="Paragraph"
                  lineHeight="LH-2.0"
                >
                  Not Uploaded
                </TextStyles>
                {NotUploaded}
              </FlexLayout>
              <TextStyles
                fontweight="extraBold"
                lineHeight="LH-2.4"
                paragraphTypes="MD-1.4"
                subheadingTypes="XS-1.6"
                textcolor="dark"
                type="SubHeading"
              >
                {skeleton ? (
                  <div className="custom-spinner"></div>
                ) : (
                  productStatus.not_uploaded
                )}
              </TextStyles>
              <Button
                content="View Products"
                type="TextButton"
                onClick={() => {
                  window.scrollTo(0, 0);
                  props.history.push("/panel/products", {
                    tabFromDashboard: "not_uploaded",
                  });
                }}
              />
            </FlexLayout>
          </Card>
          <Card extraClass="p-12">
            <FlexLayout
              direction="vertical"
              spacing="mediumTight"
              wrap="noWrap"
            >
              <FlexLayout halign="fill" valign="center" wrap="noWrap">
                <TextStyles
                  fontweight="bold"
                  paragraphTypes="MD-1.4"
                  textcolor="light"
                  type="Paragraph"
                  lineHeight="LH-2.0"
                >
                  Ended
                </TextStyles>
                {ClockIcon}
              </FlexLayout>
              <TextStyles
                fontweight="extraBold"
                lineHeight="LH-2.4"
                paragraphTypes="MD-1.4"
                subheadingTypes="XS-1.6"
                textcolor="dark"
                type="SubHeading"
              >
                {skeleton ? (
                  <div className="custom-spinner"></div>
                ) : (
                  productStatus.ended
                )}
              </TextStyles>
              <Button
                content="View Products"
                type="TextButton"
                onClick={() => {
                  window.scrollTo(0, 0);
                  props.history.push("/panel/products", {
                    tabFromDashboard: "ended",
                  });
                }}
              />
            </FlexLayout>
          </Card>
          <Card extraClass="p-12">
            <FlexLayout
              direction="vertical"
              spacing="mediumTight"
              wrap="noWrap"
            >
              <FlexLayout halign="fill" valign="center" wrap="noWrap">
                <TextStyles
                  fontweight="bold"
                  paragraphTypes="MD-1.4"
                  textcolor="light"
                  type="Paragraph"
                  lineHeight="LH-2.0"
                >
                  Errors
                </TextStyles>
                {CrossError}
              </FlexLayout>
              <TextStyles
                fontweight="extraBold"
                lineHeight="LH-2.4"
                paragraphTypes="MD-1.4"
                subheadingTypes="XS-1.6"
                textcolor="dark"
                type="SubHeading"
              >
                {skeleton ? (
                  <div className="custom-spinner"></div>
                ) : (
                  productStatus.errors
                )}
              </TextStyles>
              <Button
                content="View Products"
                type="TextButton"
                onClick={() => {
                  window.scrollTo(0, 0);
                  props.history.push("/panel/products", {
                    tabFromDashboard: "errors",
                  });
                }}
              />
            </FlexLayout>
          </Card>
        </FlexLayout>
      </Card>
    </>
  );
};

export default withRouter(ProductStatus);
