/* eslint-disable react-hooks/exhaustive-deps */
import { PageHeader, Tabs, Toast, ToastWrapper } from "@cedcommerce-integration/ounce-ui";
import { useEffect, useState } from "react";
import AccountSettings from "./Components/AccountSettings";
import CurrencySettings from "./Components/CurrencySettings";
import {
  DollarIcon,
  UserAccount,
  PaymentIcon,
  ProductIcon,
  OrderIcon,
} from "../../../shared/IconSvg";
import { postMethod } from "../../../../Apirequest/productListingApi";
import { getDataAPI } from "../../../../APIs/PanelAPI";
import { globalState } from "../../../../services/globalState";
import { withRouter } from "react-router-dom";
import PaymentSettings from "./Components/PaymentSettings";
import ProductSettings from "./Components/ProductSettings";
import OrderSettings from "./Components/OrderSettings";

const Configuration = (props) => {
  const ebayShopId = globalState.getLocalStorage("ebayShopId");
  const shopLineShopId = globalState.getLocalStorage("shopLineShopId");

  const [configurationData, setConfigurationData] = useState({});
  const [selected, setSelected] = useState("account");
  // product settings
  const [selectAttribute, setSelectAttribute] = useState({
    title: false,
    quantity: false,
    category: false,
    barcode: false,
    description: false,
    main_images: false,
    item_specfics: false,
    price: false,
    variation_images: false,
    weight: false,
  });
  const [productSwitch, setProductSwitch] = useState(false);
  const [endProduct, setEndProduct] = useState(false);
  const [itemLocation, setItemLocation] = useState({
    country: "AU",
    zipcode: "",
    location: "",
  });
  const [selectAll, setSelectAll] = useState(false);
  const [matchfromEbayValues, setMatchfromEbayValues] = useState([]);
  const [matchFromEbay, setMatchFromEbay] = useState(false);
  const [productSaveDisabled, setProductSaveDisabled] = useState(true);

  // order settings
  const [shipmentCheckbox, setShipmentCheckbox] = useState({
    syncTracking: false,
    mapping: false,
  });
  const [orderMappingCarrierValues, setOrderMappingCarrierValues] = useState([
    { shoplineAttribute: "", ebayAttribute: "" },
  ]);
  const [syncShipment, setSyncShipment] = useState(true);
  const [orderSaveDisabled, setOrderSaveDisabled] = useState(true);

  //payment settings
  const [paymentSaveDisabled, setPaymentSaveDisabled] = useState(true);

  // currency settings
  const [currency, setCurrency] = useState({
    ebayCurrencyName: "",
    ebayCurrencyValue: "",
    shoplineCurrencyName: "",
    shoplineCurrencyValue: 1,
  });
  const [currencySaveDisabled, setCurrencySaveDisabled] = useState(true);
  // skeleton state
  const [configSkeleton, setConfigSkeleton] = useState(true);
  //toast
  const [successToast, setSuccessToast] = useState({
    active: false,
    message: "",
    success: false,
  });
  const [errorToast, setErrorToast] = useState({
    active: false,
    message: "",
    success: false,
  });

  // Match products from eBay
  const [matchfromShoplineOptions, setMatchFromShoplineOptions] = useState([
    { label: "Title", value: "title", disabled: false },
    { label: "Sku", value: "sku", disabled: false },
  ]);

  const tabs = [
    {
      id: "account",
      content: "Account Settings",
      icon: UserAccount,
    },
    {
      id: "product",
      content: "Product Settings",
      icon: ProductIcon,
    },
    {
      id: "order",
      content: "Order Settings",
      icon: OrderIcon,
    },
    {
      id: "payment",
      content: "Payment Settings",
      icon: PaymentIcon,
    },
    {
      id: "currency",
      content: "Currency Settings",
      icon: DollarIcon,
    },
  ];

  const getData = async () => {
    const time1 = new Date();
    let ms1 = time1.getTime();
    setConfigSkeleton(true);
    let payload = {
      source: {
        shopId: shopLineShopId,
        marketplace: "shopline",
      },
      target: {
        shopId: ebayShopId,
        marketplace: "ebay",
      },
      group_code: ["settings"],
    };
    let { success, data, code } = await postMethod(getDataAPI, payload);
    const time2 = new Date();
    let ms2 = time2.getTime();
    let timeOut = ms2 - ms1;
    if (success) {
      if (Array.isArray(data)) {
        setConfigurationData(data[0]?.value);
        if (data[0]?.value?.hasOwnProperty("currencyConversion")) {
          let temp = { ...currency };
          temp["ebayCurrencyName"] =
            data[0]?.value?.currencyConversion?.ebayCurrencyName;
          temp["ebayCurrencyValue"] =
            data[0]?.value?.currencyConversion?.ebayCurrencyValue;
          temp["shoplineCurrencyName"] =
            data[0]?.value?.currencyConversion?.shoplineCurrencyName;
          temp["shoplineCurrencyValue"] =
            data[0]?.value?.currencyConversion?.shoplineCurrencyValue;
          setCurrency(temp);
        }
      }
    } else if (
        code === "token_expired" ||
        code === "invalid_token" ||
        code === "future_token" ||
        code === "token_decode_error"
      ) {
        props.history.push("/tokenexpired");
      }
    if (timeOut < 500) {
      setTimeout(() => {
        setConfigSkeleton(false);
      }, 500 - timeOut);
    } else setConfigSkeleton(false);
  };

  const productSettings = () => {
    if (configurationData) {
      if (Object.keys(configurationData)?.length) {
        if (
          configurationData?.["appToEbay"]?.["autoProductSync"]?.[
            "productSync"
          ] &&
          Object.keys(
            configurationData?.["appToEbay"]?.["autoProductSync"]?.[
              "productSync"
            ]
          )?.length
        ) {
          let tempSync = { ...selectAttribute };
          Object.keys(
            configurationData?.["appToEbay"]?.["autoProductSync"]?.[
              "productSync"
            ]
          ).forEach((field, index) => {
            tempSync[field] = true;
            if (index === Object.keys(selectAttribute).length - 1) {
              setSelectAll(true);
            }
          });
          setSelectAttribute(tempSync);
          setProductSwitch(true);
        } else if (
          !configurationData?.["appToEbay"]?.["autoProductSync"]?.[
            "productSync"
          ]
        ) {
          setProductSwitch(false);
        }
        if (
          configurationData?.["ebay_locations"] &&
          configurationData?.["ebay_locations"].length
        ) {
          let tempLocation = { ...itemLocation };
          tempLocation["country"] =
            configurationData["ebay_locations"]?.[0]?.["country"];
          tempLocation["zipcode"] =
            configurationData["ebay_locations"]?.[0]?.["zipcode"];
          tempLocation["location"] =
            configurationData["ebay_locations"]?.[0]?.["location"];
          setItemLocation(tempLocation);
        }
        if (configurationData.hasOwnProperty("autoEndProduct")) {
          setEndProduct(configurationData?.["autoEndProduct"]);
        }
        if (
          configurationData?.["match_from_ebay"] &&
          configurationData?.["match_from_ebay"]?.length
        ) {
          let temp = [];
          let tempOptions = [...matchfromShoplineOptions];
          configurationData?.["match_from_ebay"].forEach((attribute, index) => {
            temp.push({
              shoplineAttribute: attribute.shopline_attribute,
              ebayAttribute: attribute.ebay_attribute,
            });
            tempOptions.forEach((option, index) => {
              if (option.value === attribute.shopline_attribute) {
                tempOptions[index]["disabled"] = true;
              }
            });
          });
          setMatchFromShoplineOptions(tempOptions);
          setMatchfromEbayValues(temp);
          setMatchFromEbay(true);
        } else if (!configurationData?.["match_from_ebay"]) {
          setMatchFromEbay(false);
        }
      }
    }
  };

  const orderSettings = () => {
    if (configurationData) {
      if (Object.keys(configurationData)?.length) {
        let tempSyncTracking = { ...shipmentCheckbox };
        if (
          configurationData?.["syncOrderShipment"]?.hasOwnProperty(
            "syncTrackingDetails"
          )
        ) {
          tempSyncTracking["syncTracking"] =
            configurationData?.["syncOrderShipment"]?.["syncTrackingDetails"];
          setSyncShipment(true);
        }
        if (
          Array.isArray(
            configurationData?.["syncOrderShipment"]?.[
              "mappingOfShippingCarrier"
            ]
          )
        ) {
          tempSyncTracking["mapping"] = true;
          let temp = [];
          configurationData["syncOrderShipment"]?.[
            "mappingOfShippingCarrier"
          ].forEach((attribute, index) => {
            temp.push({
              shoplineAttribute: attribute.shoplineAttribute,
              ebayAttribute: attribute.ebayAttribute,
            });
          });
          setOrderMappingCarrierValues(temp);
          setSyncShipment(true);
        } else {
          tempSyncTracking["mapping"] = false;
        }
        setShipmentCheckbox(tempSyncTracking);
      }
    }
  };

  useEffect(() => {
    productSettings();
    orderSettings();
  }, [configurationData]);

  useEffect(() => {
    getData();
  }, []);

  const toastMarkup = successToast.active ? (
    <Toast
      message={successToast.message}
      onDismiss={() =>
        setSuccessToast({ active: false, message: "", success: false })
      }
      timeout={2000}
      type={successToast.success ? "success" : "error"}
    />
  ) : errorToast.active ? (
    <Toast
      message={errorToast.message}
      onDismiss={() =>
        setErrorToast({ active: false, message: "", success: false })
      }
      timeout={2000}
      type="error"
    />
  ) : null;

  return (
    <>
      <PageHeader
        title="Configuration"
        description="Choose and manage all your crucial marketplace settings for seamless multichannel selling."
      />
      <Tabs
        alignment="vertical"
        selected={selected}
        value={tabs}
        onChange={(e) => setSelected(e)}
      >
        {selected === "account" && (
          <AccountSettings
            data={configurationData}
            skeleton={configSkeleton}
          />
        )}
        {selected === "product" && (
          <ProductSettings
            selectAttribute={selectAttribute}
            setSelectAttribute={setSelectAttribute}
            productSwitch={productSwitch}
            setProductSwitch={setProductSwitch}
            endProduct={endProduct}
            setEndProduct={setEndProduct}
            itemLocation={itemLocation}
            setItemLocation={setItemLocation}
            selectAll={selectAll}
            setSelectAll={setSelectAll}
            matchfromEbayValues={matchfromEbayValues}
            setMatchfromEbayValues={setMatchfromEbayValues}
            matchFromEbay={matchFromEbay}
            setMatchFromEbay={setMatchFromEbay}
            skeleton={configSkeleton}
            setSuccessToast={setSuccessToast}
            setErrorToast={setErrorToast}
            matchfromShoplineOptions={matchfromShoplineOptions}
            setMatchFromShoplineOptions={setMatchFromShoplineOptions}
            buttonDisabled={productSaveDisabled}
            setButtonDisabled={setProductSaveDisabled}
          />
        )}
        {selected === "order" && (
          <OrderSettings
            shipmentCheckbox={shipmentCheckbox}
            setShipmentCheckbox={setShipmentCheckbox}
            orderMappingCarrierValues={orderMappingCarrierValues}
            setOrderMappingCarrierValues={setOrderMappingCarrierValues}
            syncShipment={syncShipment}
            setSyncShipment={setSyncShipment}
            skeleton={configSkeleton}
            setSuccessToast={setSuccessToast}
            setErrorToast={setErrorToast}
            buttonDisabled={orderSaveDisabled}
            setButtonDisabled={setOrderSaveDisabled}
          />
        )}
        {selected === "payment" && (
          <PaymentSettings
            buttonDisabled={paymentSaveDisabled}
            setButtonDisabled={setPaymentSaveDisabled}
          />
        )}
        {selected === "currency" && (
          <CurrencySettings
            skeleton={configSkeleton}
            currency={currency}
            setCurrency={setCurrency}
            setSuccessToast={setSuccessToast}
            setErrorToast={setErrorToast}
            buttonDisabled={currencySaveDisabled}
            setButtonDisabled={setCurrencySaveDisabled}
          />
        )}
      </Tabs>
      <ToastWrapper>{toastMarkup}</ToastWrapper>
    </>
  );
};

export default withRouter(Configuration);
