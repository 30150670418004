export const RequiredIcon = (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
  >
    <rect width="24" height="24" rx="12" fill="#27591C" />
    <path
      d="M17.3332 8L9.99984 15.3333L6.6665 12"
      stroke="white"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export const OptionalIcon = (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
  >
    <rect width="24" height="24" rx="12" fill="#E0E0E0" />
    <path
      d="M17.3332 8L9.99984 15.3333L6.6665 12"
      stroke="white"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
