import {
  FallBack,
} from "@cedcommerce-integration/ounce-ui";
import React from "react";

export const SessionExpired = (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="201"
    height="200"
    viewBox="0 0 201 200"
    fill="none"
  >
    <rect x="0.5" width="200" height="200" rx="100" fill="#F0F2FA" />
    <g filter="url(#filter0_i_231_72167)">
      <path
        d="M106.099 37.6746H92.9888V46.207H106.099V37.6746Z"
        fill="#F0EDFA"
      />
    </g>
    <g filter="url(#filter1_i_231_72167)">
      <path
        d="M56.4052 54.6706L49.291 61.7812L54.465 66.9525L61.5792 59.8419L56.4052 54.6706Z"
        fill="#F0EDFA"
      />
    </g>
    <g filter="url(#filter2_i_231_72167)">
      <path
        d="M139.599 59.3179L146.713 66.4285L151.348 61.7958L144.234 54.6852L139.599 59.3179Z"
        fill="#F0EDFA"
      />
    </g>
    <g filter="url(#filter3_i_231_72167)">
      <path
        d="M100.61 170.999C135.465 170.999 163.72 142.758 163.72 107.921C163.72 73.0831 135.465 44.8418 100.61 44.8418C65.7554 44.8418 37.5 73.0831 37.5 107.921C37.5 142.758 65.7554 170.999 100.61 170.999Z"
        fill="url(#paint0_linear_231_72167)"
      />
    </g>
    <g filter="url(#filter4_i_231_72167)">
      <path
        d="M148.976 107.538C148.062 133.897 126.415 155.076 99.7382 155.076C73.0611 155.076 51.4146 133.897 50.5 107.538C51.4146 81.1794 73.0611 60.0007 99.7382 60.0007C126.415 59.8484 148.062 81.027 148.976 107.538Z"
        fill="#F0EDFA"
      />
    </g>
    <g filter="url(#filter5_i_231_72167)">
      <circle cx="99.959" cy="107.459" r="17.459" fill="#D92D20" />
    </g>
    <path
      d="M97.4683 97.4841H102.379L101.069 111.125H98.7778L97.4683 97.4841Z"
      fill="white"
    />
    <ellipse cx="99.9236" cy="115.49" rx="2.45535" ry="2.18253" fill="white" />
    <g filter="url(#filter6_i_231_72167)">
      <path
        d="M145.141 109.78H139.9C139.01 109.78 138.317 108.713 138.317 107.342C138.317 105.971 139.01 104.904 139.9 104.904H145.141C146.031 104.904 146.724 105.971 146.724 107.342C146.724 108.713 145.933 109.78 145.141 109.78Z"
        fill="url(#paint1_linear_231_72167)"
      />
    </g>
    <g filter="url(#filter7_i_231_72167)">
      <path
        d="M60.2375 109.78H54.9959C54.1059 109.78 53.4136 108.713 53.4136 107.342C53.4136 105.971 54.1059 104.904 54.9959 104.904H60.2375C61.1276 104.904 61.8199 105.971 61.8199 107.342C61.8199 108.713 61.0287 109.78 60.2375 109.78Z"
        fill="url(#paint2_linear_231_72167)"
      />
    </g>
    <g filter="url(#filter8_i_231_72167)">
      <path
        d="M100.406 151.979C99.0339 151.979 97.9668 151.287 97.9668 150.397V145.155C97.9668 144.265 99.0339 143.573 100.406 143.573C101.778 143.573 102.845 144.265 102.845 145.155V148.697C102.845 149.828 102.801 151.142 101.793 151.656C101.394 151.86 100.913 151.979 100.406 151.979Z"
        fill="url(#paint3_linear_231_72167)"
      />
    </g>
    <g filter="url(#filter9_i_231_72167)">
      <path
        d="M100.406 70.4382C99.0339 70.4382 97.9668 69.7377 97.9668 68.837V63.6331C97.9668 62.7324 99.0339 62.0319 100.406 62.0319C101.778 62.0319 102.845 62.7324 102.845 63.6331V67.05C102.845 68.3052 102.731 69.7593 101.564 70.2201C101.215 70.3577 100.819 70.4382 100.406 70.4382Z"
        fill="url(#paint4_linear_231_72167)"
      />
    </g>
    <g filter="url(#filter10_i_231_72167)">
      <path
        d="M112.347 25.6375V33.8652C112.347 37.0648 109.756 39.5026 106.707 39.5026H92.6823C89.481 39.5026 87.042 36.9124 87.042 33.8652V25.6375C87.042 22.4378 89.6335 20 92.6823 20H106.707C109.908 20 112.347 22.5902 112.347 25.6375Z"
        fill="url(#paint5_linear_231_72167)"
      />
    </g>
    <g filter="url(#filter11_i_231_72167)">
      <path
        d="M89.1763 34.7793C88.7189 34.7793 88.4141 34.4746 88.4141 34.0175V24.5709C88.4141 24.1138 88.7189 23.8091 89.1763 23.8091C89.6336 23.8091 89.9385 24.1138 89.9385 24.5709V32.8732C89.9385 33.6311 89.9342 34.7793 89.1763 34.7793Z"
        fill="url(#paint6_linear_231_72167)"
      />
    </g>
    <g filter="url(#filter12_i_231_72167)">
      <path
        d="M92.6841 34.7793C92.2268 34.7793 91.9219 34.4746 91.9219 34.0175V24.5709C91.9219 24.1138 92.2268 23.8091 92.6841 23.8091C93.1414 23.8091 93.4463 24.1138 93.4463 24.5709V34.0175C93.4463 34.4746 93.1414 34.7793 92.6841 34.7793Z"
        fill="url(#paint7_linear_231_72167)"
      />
    </g>
    <g filter="url(#filter13_i_231_72167)">
      <path
        d="M96.3408 34.7793C95.8835 34.7793 95.5786 34.4746 95.5786 34.0175V24.5709C95.5786 24.1138 95.8835 23.8091 96.3408 23.8091C96.7981 23.8091 97.103 24.1138 97.103 24.5709V32.8732C97.103 33.6311 97.0987 34.7793 96.3408 34.7793Z"
        fill="url(#paint8_linear_231_72167)"
      />
    </g>
    <g filter="url(#filter14_i_231_72167)">
      <path
        d="M99.8477 34.7793C99.3903 34.7793 99.0854 34.4746 99.0854 34.0175V24.5709C99.0854 24.1138 99.3903 23.8091 99.8477 23.8091C100.305 23.8091 100.61 24.1138 100.61 24.5709V34.0175C100.61 34.4746 100.305 34.7793 99.8477 34.7793Z"
        fill="url(#paint9_linear_231_72167)"
      />
    </g>
    <g filter="url(#filter15_i_231_72167)">
      <path
        d="M103.507 34.7793C103.05 34.7793 102.745 34.4746 102.745 34.0175V24.5709C102.745 24.1138 103.05 23.8091 103.507 23.8091C103.964 23.8091 104.269 24.1138 104.269 24.5709V32.8732C104.269 33.6311 104.265 34.7793 103.507 34.7793Z"
        fill="url(#paint10_linear_231_72167)"
      />
    </g>
    <g filter="url(#filter16_i_231_72167)">
      <path
        d="M107.012 34.7793C106.554 34.7793 106.25 34.4746 106.25 34.0175V24.5709C106.25 24.1138 106.554 23.8091 107.012 23.8091C107.469 23.8091 107.774 24.1138 107.774 24.5709V34.0175C107.774 34.4746 107.317 34.7793 107.012 34.7793Z"
        fill="url(#paint11_linear_231_72167)"
      />
    </g>
    <g filter="url(#filter17_i_231_72167)">
      <path
        d="M110.52 34.7793C110.062 34.7793 109.757 34.4746 109.757 34.0175V24.5709C109.757 24.1138 110.062 23.8091 110.52 23.8091C110.977 23.8091 111.282 24.1138 111.282 24.5709V34.0175C111.282 34.4746 110.977 34.7793 110.52 34.7793Z"
        fill="url(#paint12_linear_231_72167)"
      />
    </g>
    <g filter="url(#filter18_i_231_72167)">
      <path
        d="M57.7746 55.3485L50.1526 62.9667C48.4757 64.6428 45.7318 64.6428 43.9025 62.9667L39.4818 58.5482C37.8049 56.8722 37.8049 54.1296 39.4818 52.3012L47.1038 44.683C48.7806 43.007 51.5245 43.007 53.3538 44.683L57.7746 49.1016C59.4514 50.93 59.4514 53.6725 57.7746 55.3485Z"
        fill="url(#paint13_linear_231_72167)"
      />
    </g>
    <g filter="url(#filter19_i_231_72167)">
      <path
        d="M142.836 55.3485L150.458 62.9667C152.135 64.6428 154.879 64.6428 156.708 62.9667L161.129 58.5482C162.805 56.8722 162.805 54.1296 161.129 52.3012L153.507 44.683C151.83 43.007 149.086 43.007 147.257 44.683L142.836 49.1016C141.159 50.93 141.159 53.6725 142.836 55.3485Z"
        fill="url(#paint14_linear_231_72167)"
      />
    </g>
    <path
      d="M182.04 159.08C183.995 159.08 185.58 157.495 185.58 155.54C185.58 153.585 183.995 152 182.04 152C180.085 152 178.5 153.585 178.5 155.54C178.5 157.495 180.085 159.08 182.04 159.08Z"
      fill="white"
    />
    <path
      d="M180.535 22.0526C182.274 22.0526 183.685 20.6423 183.685 18.9026C183.685 17.1629 182.274 15.7526 180.535 15.7526C178.795 15.7526 177.385 17.1629 177.385 18.9026C177.385 20.6423 178.795 22.0526 180.535 22.0526Z"
      fill="#C5B8EA"
    />
    <path
      d="M168.385 35.7526C172.251 35.7526 175.385 32.6186 175.385 28.7526C175.385 24.8866 172.251 21.7526 168.385 21.7526C164.519 21.7526 161.385 24.8866 161.385 28.7526C161.385 32.6186 164.519 35.7526 168.385 35.7526Z"
      fill="white"
    />
    <path
      d="M48.3848 185.753C52.2507 185.753 55.3848 182.619 55.3848 178.753C55.3848 174.887 52.2507 171.753 48.3848 171.753C44.5188 171.753 41.3848 174.887 41.3848 178.753C41.3848 182.619 44.5188 185.753 48.3848 185.753Z"
      fill="white"
    />
    <path
      d="M193.385 161C194.489 161 195.385 160.104 195.385 159C195.385 157.895 194.489 157 193.385 157C192.28 157 191.385 157.895 191.385 159C191.385 160.104 192.28 161 193.385 161Z"
      fill="#C5B8EA"
    />
    <path
      d="M36.0128 30.301C38.8573 30.301 41.1633 27.995 41.1633 25.1505C41.1633 22.306 38.8573 20 36.0128 20C33.1683 20 30.8623 22.306 30.8623 25.1505C30.8623 27.995 33.1683 30.301 36.0128 30.301Z"
      fill="white"
    />
    <path
      d="M19.5123 30.3C20.9759 30.3 22.1623 29.1136 22.1623 27.65C22.1623 26.1864 20.9759 25 19.5123 25C18.0487 25 16.8623 26.1864 16.8623 27.65C16.8623 29.1136 18.0487 30.3 19.5123 30.3Z"
      fill="#DCDAE3"
    />
    <path
      d="M34.8906 195C36.5475 195 37.8906 193.657 37.8906 192C37.8906 190.343 36.5475 189 34.8906 189C33.2338 189 31.8906 190.343 31.8906 192C31.8906 193.657 33.2338 195 34.8906 195Z"
      fill="#C5B8EA"
    />
    <defs>
      <filter
        id="filter0_i_231_72167"
        x="92.9888"
        y="32.6746"
        width="18.1099"
        height="13.5324"
        filterUnits="userSpaceOnUse"
        color-interpolation-filters="sRGB"
      >
        <feFlood flood-opacity="0" result="BackgroundImageFix" />
        <feBlend
          mode="normal"
          in="SourceGraphic"
          in2="BackgroundImageFix"
          result="shape"
        />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dx="8" dy="-7" />
        <feGaussianBlur stdDeviation="2.5" />
        <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
        <feColorMatrix
          type="matrix"
          values="0 0 0 0 0.262745 0 0 0 0 0.105882 0 0 0 0 0.737255 0 0 0 0.28 0"
        />
        <feBlend
          mode="normal"
          in2="shape"
          result="effect1_innerShadow_231_72167"
        />
      </filter>
      <filter
        id="filter1_i_231_72167"
        x="49.291"
        y="49.6706"
        width="17.2881"
        height="17.2819"
        filterUnits="userSpaceOnUse"
        color-interpolation-filters="sRGB"
      >
        <feFlood flood-opacity="0" result="BackgroundImageFix" />
        <feBlend
          mode="normal"
          in="SourceGraphic"
          in2="BackgroundImageFix"
          result="shape"
        />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dx="8" dy="-7" />
        <feGaussianBlur stdDeviation="2.5" />
        <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
        <feColorMatrix
          type="matrix"
          values="0 0 0 0 0.262745 0 0 0 0 0.105882 0 0 0 0 0.737255 0 0 0 0.28 0"
        />
        <feBlend
          mode="normal"
          in2="shape"
          result="effect1_innerShadow_231_72167"
        />
      </filter>
      <filter
        id="filter2_i_231_72167"
        x="139.599"
        y="49.6852"
        width="16.749"
        height="16.7433"
        filterUnits="userSpaceOnUse"
        color-interpolation-filters="sRGB"
      >
        <feFlood flood-opacity="0" result="BackgroundImageFix" />
        <feBlend
          mode="normal"
          in="SourceGraphic"
          in2="BackgroundImageFix"
          result="shape"
        />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dx="8" dy="-7" />
        <feGaussianBlur stdDeviation="2.5" />
        <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
        <feColorMatrix
          type="matrix"
          values="0 0 0 0 0.262745 0 0 0 0 0.105882 0 0 0 0 0.737255 0 0 0 0.28 0"
        />
        <feBlend
          mode="normal"
          in2="shape"
          result="effect1_innerShadow_231_72167"
        />
      </filter>
      <filter
        id="filter3_i_231_72167"
        x="37.5"
        y="39.8418"
        width="131.221"
        height="131.158"
        filterUnits="userSpaceOnUse"
        color-interpolation-filters="sRGB"
      >
        <feFlood flood-opacity="0" result="BackgroundImageFix" />
        <feBlend
          mode="normal"
          in="SourceGraphic"
          in2="BackgroundImageFix"
          result="shape"
        />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dx="5" dy="-5" />
        <feGaussianBlur stdDeviation="4.5" />
        <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
        <feColorMatrix
          type="matrix"
          values="0 0 0 0 0.262745 0 0 0 0 0.105882 0 0 0 0 0.737255 0 0 0 1 0"
        />
        <feBlend
          mode="normal"
          in2="shape"
          result="effect1_innerShadow_231_72167"
        />
      </filter>
      <filter
        id="filter4_i_231_72167"
        x="50.5"
        y="52.9999"
        width="106.477"
        height="102.076"
        filterUnits="userSpaceOnUse"
        color-interpolation-filters="sRGB"
      >
        <feFlood flood-opacity="0" result="BackgroundImageFix" />
        <feBlend
          mode="normal"
          in="SourceGraphic"
          in2="BackgroundImageFix"
          result="shape"
        />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dx="8" dy="-7" />
        <feGaussianBlur stdDeviation="4" />
        <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
        <feColorMatrix
          type="matrix"
          values="0 0 0 0 0.262745 0 0 0 0 0.105882 0 0 0 0 0.737255 0 0 0 0.27 0"
        />
        <feBlend
          mode="normal"
          in2="shape"
          result="effect1_innerShadow_231_72167"
        />
      </filter>
      <filter
        id="filter5_i_231_72167"
        x="82.5"
        y="85"
        width="39.918"
        height="39.918"
        filterUnits="userSpaceOnUse"
        color-interpolation-filters="sRGB"
      >
        <feFlood flood-opacity="0" result="BackgroundImageFix" />
        <feBlend
          mode="normal"
          in="SourceGraphic"
          in2="BackgroundImageFix"
          result="shape"
        />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dx="8" dy="-7" />
        <feGaussianBlur stdDeviation="2.5" />
        <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
        <feColorMatrix
          type="matrix"
          values="0 0 0 0 0.596078 0 0 0 0 0.121569 0 0 0 0 0.0862745 0 0 0 0.28 0"
        />
        <feBlend
          mode="normal"
          in2="shape"
          result="effect1_innerShadow_231_72167"
        />
      </filter>
      <filter
        id="filter6_i_231_72167"
        x="138.317"
        y="103.904"
        width="8.40625"
        height="5.87566"
        filterUnits="userSpaceOnUse"
        color-interpolation-filters="sRGB"
      >
        <feFlood flood-opacity="0" result="BackgroundImageFix" />
        <feBlend
          mode="normal"
          in="SourceGraphic"
          in2="BackgroundImageFix"
          result="shape"
        />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dy="-2" />
        <feGaussianBlur stdDeviation="0.5" />
        <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
        <feColorMatrix
          type="matrix"
          values="0 0 0 0 0.556863 0 0 0 0 0.462745 0 0 0 0 0.843137 0 0 0 0.9 0"
        />
        <feBlend
          mode="normal"
          in2="shape"
          result="effect1_innerShadow_231_72167"
        />
      </filter>
      <filter
        id="filter7_i_231_72167"
        x="53.4136"
        y="104.904"
        width="8.40625"
        height="4.87566"
        filterUnits="userSpaceOnUse"
        color-interpolation-filters="sRGB"
      >
        <feFlood flood-opacity="0" result="BackgroundImageFix" />
        <feBlend
          mode="normal"
          in="SourceGraphic"
          in2="BackgroundImageFix"
          result="shape"
        />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset />
        <feGaussianBlur stdDeviation="0.5" />
        <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
        <feColorMatrix
          type="matrix"
          values="0 0 0 0 0.556863 0 0 0 0 0.462745 0 0 0 0 0.843137 0 0 0 0.9 0"
        />
        <feBlend
          mode="normal"
          in2="shape"
          result="effect1_innerShadow_231_72167"
        />
      </filter>
      <filter
        id="filter8_i_231_72167"
        x="97.9668"
        y="143.573"
        width="6.87793"
        height="8.40631"
        filterUnits="userSpaceOnUse"
        color-interpolation-filters="sRGB"
      >
        <feFlood flood-opacity="0" result="BackgroundImageFix" />
        <feBlend
          mode="normal"
          in="SourceGraphic"
          in2="BackgroundImageFix"
          result="shape"
        />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dx="3" />
        <feGaussianBlur stdDeviation="1" />
        <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
        <feColorMatrix
          type="matrix"
          values="0 0 0 0 0.556863 0 0 0 0 0.462745 0 0 0 0 0.843137 0 0 0 0.9 0"
        />
        <feBlend
          mode="normal"
          in2="shape"
          result="effect1_innerShadow_231_72167"
        />
      </filter>
      <filter
        id="filter9_i_231_72167"
        x="97.9668"
        y="62.0319"
        width="6.87793"
        height="8.40631"
        filterUnits="userSpaceOnUse"
        color-interpolation-filters="sRGB"
      >
        <feFlood flood-opacity="0" result="BackgroundImageFix" />
        <feBlend
          mode="normal"
          in="SourceGraphic"
          in2="BackgroundImageFix"
          result="shape"
        />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dx="2" />
        <feGaussianBlur stdDeviation="1" />
        <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
        <feColorMatrix
          type="matrix"
          values="0 0 0 0 0.556863 0 0 0 0 0.462745 0 0 0 0 0.843137 0 0 0 0.9 0"
        />
        <feBlend
          mode="normal"
          in2="shape"
          result="effect1_innerShadow_231_72167"
        />
      </filter>
      <filter
        id="filter10_i_231_72167"
        x="81.042"
        y="14"
        width="31.3052"
        height="25.5026"
        filterUnits="userSpaceOnUse"
        color-interpolation-filters="sRGB"
      >
        <feFlood flood-opacity="0" result="BackgroundImageFix" />
        <feBlend
          mode="normal"
          in="SourceGraphic"
          in2="BackgroundImageFix"
          result="shape"
        />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dx="-10" dy="-6" />
        <feGaussianBlur stdDeviation="3" />
        <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
        <feColorMatrix
          type="matrix"
          values="0 0 0 0 0.262745 0 0 0 0 0.105882 0 0 0 0 0.737255 0 0 0 1 0"
        />
        <feBlend
          mode="normal"
          in2="shape"
          result="effect1_innerShadow_231_72167"
        />
      </filter>
      <filter
        id="filter11_i_231_72167"
        x="88.4141"
        y="23.8091"
        width="2.52441"
        height="11.9702"
        filterUnits="userSpaceOnUse"
        color-interpolation-filters="sRGB"
      >
        <feFlood flood-opacity="0" result="BackgroundImageFix" />
        <feBlend
          mode="normal"
          in="SourceGraphic"
          in2="BackgroundImageFix"
          result="shape"
        />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dx="2" dy="3" />
        <feGaussianBlur stdDeviation="0.5" />
        <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
        <feColorMatrix
          type="matrix"
          values="0 0 0 0 0.556863 0 0 0 0 0.462745 0 0 0 0 0.843137 0 0 0 0.9 0"
        />
        <feBlend
          mode="normal"
          in2="shape"
          result="effect1_innerShadow_231_72167"
        />
      </filter>
      <filter
        id="filter12_i_231_72167"
        x="91.9219"
        y="23.8091"
        width="2.52441"
        height="11.9702"
        filterUnits="userSpaceOnUse"
        color-interpolation-filters="sRGB"
      >
        <feFlood flood-opacity="0" result="BackgroundImageFix" />
        <feBlend
          mode="normal"
          in="SourceGraphic"
          in2="BackgroundImageFix"
          result="shape"
        />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dx="2" dy="3" />
        <feGaussianBlur stdDeviation="0.5" />
        <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
        <feColorMatrix
          type="matrix"
          values="0 0 0 0 0.556863 0 0 0 0 0.462745 0 0 0 0 0.843137 0 0 0 0.9 0"
        />
        <feBlend
          mode="normal"
          in2="shape"
          result="effect1_innerShadow_231_72167"
        />
      </filter>
      <filter
        id="filter13_i_231_72167"
        x="95.5786"
        y="23.8091"
        width="2.52441"
        height="11.9702"
        filterUnits="userSpaceOnUse"
        color-interpolation-filters="sRGB"
      >
        <feFlood flood-opacity="0" result="BackgroundImageFix" />
        <feBlend
          mode="normal"
          in="SourceGraphic"
          in2="BackgroundImageFix"
          result="shape"
        />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dx="2" dy="3" />
        <feGaussianBlur stdDeviation="0.5" />
        <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
        <feColorMatrix
          type="matrix"
          values="0 0 0 0 0.556863 0 0 0 0 0.462745 0 0 0 0 0.843137 0 0 0 0.9 0"
        />
        <feBlend
          mode="normal"
          in2="shape"
          result="effect1_innerShadow_231_72167"
        />
      </filter>
      <filter
        id="filter14_i_231_72167"
        x="99.0854"
        y="23.8091"
        width="2.52441"
        height="11.9702"
        filterUnits="userSpaceOnUse"
        color-interpolation-filters="sRGB"
      >
        <feFlood flood-opacity="0" result="BackgroundImageFix" />
        <feBlend
          mode="normal"
          in="SourceGraphic"
          in2="BackgroundImageFix"
          result="shape"
        />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dx="2" dy="3" />
        <feGaussianBlur stdDeviation="0.5" />
        <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
        <feColorMatrix
          type="matrix"
          values="0 0 0 0 0.556863 0 0 0 0 0.462745 0 0 0 0 0.843137 0 0 0 0.9 0"
        />
        <feBlend
          mode="normal"
          in2="shape"
          result="effect1_innerShadow_231_72167"
        />
      </filter>
      <filter
        id="filter15_i_231_72167"
        x="102.745"
        y="23.8091"
        width="2.52441"
        height="11.9702"
        filterUnits="userSpaceOnUse"
        color-interpolation-filters="sRGB"
      >
        <feFlood flood-opacity="0" result="BackgroundImageFix" />
        <feBlend
          mode="normal"
          in="SourceGraphic"
          in2="BackgroundImageFix"
          result="shape"
        />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dx="2" dy="3" />
        <feGaussianBlur stdDeviation="0.5" />
        <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
        <feColorMatrix
          type="matrix"
          values="0 0 0 0 0.556863 0 0 0 0 0.462745 0 0 0 0 0.843137 0 0 0 0.9 0"
        />
        <feBlend
          mode="normal"
          in2="shape"
          result="effect1_innerShadow_231_72167"
        />
      </filter>
      <filter
        id="filter16_i_231_72167"
        x="106.25"
        y="23.8091"
        width="2.52441"
        height="11.9702"
        filterUnits="userSpaceOnUse"
        color-interpolation-filters="sRGB"
      >
        <feFlood flood-opacity="0" result="BackgroundImageFix" />
        <feBlend
          mode="normal"
          in="SourceGraphic"
          in2="BackgroundImageFix"
          result="shape"
        />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dx="2" dy="3" />
        <feGaussianBlur stdDeviation="0.5" />
        <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
        <feColorMatrix
          type="matrix"
          values="0 0 0 0 0.556863 0 0 0 0 0.462745 0 0 0 0 0.843137 0 0 0 0.9 0"
        />
        <feBlend
          mode="normal"
          in2="shape"
          result="effect1_innerShadow_231_72167"
        />
      </filter>
      <filter
        id="filter17_i_231_72167"
        x="109.757"
        y="23.8091"
        width="2.52441"
        height="11.9702"
        filterUnits="userSpaceOnUse"
        color-interpolation-filters="sRGB"
      >
        <feFlood flood-opacity="0" result="BackgroundImageFix" />
        <feBlend
          mode="normal"
          in="SourceGraphic"
          in2="BackgroundImageFix"
          result="shape"
        />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dx="2" dy="3" />
        <feGaussianBlur stdDeviation="0.5" />
        <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
        <feColorMatrix
          type="matrix"
          values="0 0 0 0 0.556863 0 0 0 0 0.462745 0 0 0 0 0.843137 0 0 0 0.9 0"
        />
        <feBlend
          mode="normal"
          in2="shape"
          result="effect1_innerShadow_231_72167"
        />
      </filter>
      <filter
        id="filter18_i_231_72167"
        x="32.2241"
        y="37.426"
        width="26.8081"
        height="26.7977"
        filterUnits="userSpaceOnUse"
        color-interpolation-filters="sRGB"
      >
        <feFlood flood-opacity="0" result="BackgroundImageFix" />
        <feBlend
          mode="normal"
          in="SourceGraphic"
          in2="BackgroundImageFix"
          result="shape"
        />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dx="-10" dy="-6" />
        <feGaussianBlur stdDeviation="3" />
        <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
        <feColorMatrix
          type="matrix"
          values="0 0 0 0 0.262745 0 0 0 0 0.105882 0 0 0 0 0.737255 0 0 0 1 0"
        />
        <feBlend
          mode="normal"
          in2="shape"
          result="effect1_innerShadow_231_72167"
        />
      </filter>
      <filter
        id="filter19_i_231_72167"
        x="135.578"
        y="37.426"
        width="26.8081"
        height="26.7977"
        filterUnits="userSpaceOnUse"
        color-interpolation-filters="sRGB"
      >
        <feFlood flood-opacity="0" result="BackgroundImageFix" />
        <feBlend
          mode="normal"
          in="SourceGraphic"
          in2="BackgroundImageFix"
          result="shape"
        />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dx="-10" dy="-6" />
        <feGaussianBlur stdDeviation="3" />
        <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
        <feColorMatrix
          type="matrix"
          values="0 0 0 0 0.262745 0 0 0 0 0.105882 0 0 0 0 0.737255 0 0 0 1 0"
        />
        <feBlend
          mode="normal"
          in2="shape"
          result="effect1_innerShadow_231_72167"
        />
      </filter>
      <linearGradient
        id="paint0_linear_231_72167"
        x1="100.61"
        y1="44.8418"
        x2="161.042"
        y2="171.023"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="#413BBC" />
        <stop offset="1" stop-color="#A9A6E2" />
      </linearGradient>
      <linearGradient
        id="paint1_linear_231_72167"
        x1="142.521"
        y1="104.904"
        x2="143.847"
        y2="110.086"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="#413BBC" />
        <stop offset="1" stop-color="#A9A6E2" />
      </linearGradient>
      <linearGradient
        id="paint2_linear_231_72167"
        x1="57.6167"
        y1="104.904"
        x2="58.943"
        y2="110.086"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="#413BBC" />
        <stop offset="1" stop-color="#A9A6E2" />
      </linearGradient>
      <linearGradient
        id="paint3_linear_231_72167"
        x1="100.406"
        y1="143.573"
        x2="104.993"
        y2="149.605"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="#413BBC" />
        <stop offset="1" stop-color="#A9A6E2" />
      </linearGradient>
      <linearGradient
        id="paint4_linear_231_72167"
        x1="100.406"
        y1="62.0319"
        x2="104.993"
        y2="68.0633"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="#413BBC" />
        <stop offset="1" stop-color="#A9A6E2" />
      </linearGradient>
      <linearGradient
        id="paint5_linear_231_72167"
        x1="99.6945"
        y1="20"
        x2="107.488"
        y2="41.1025"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="#413BBC" />
        <stop offset="1" stop-color="#A9A6E2" />
      </linearGradient>
      <linearGradient
        id="paint6_linear_231_72167"
        x1="89.1763"
        y1="23.8091"
        x2="92.7352"
        y2="24.9298"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="#413BBC" />
        <stop offset="1" stop-color="#A9A6E2" />
      </linearGradient>
      <linearGradient
        id="paint7_linear_231_72167"
        x1="92.6841"
        y1="23.8091"
        x2="96.243"
        y2="24.9298"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="#413BBC" />
        <stop offset="1" stop-color="#A9A6E2" />
      </linearGradient>
      <linearGradient
        id="paint8_linear_231_72167"
        x1="96.3408"
        y1="23.8091"
        x2="99.8997"
        y2="24.9298"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="#413BBC" />
        <stop offset="1" stop-color="#A9A6E2" />
      </linearGradient>
      <linearGradient
        id="paint9_linear_231_72167"
        x1="99.8477"
        y1="23.8091"
        x2="103.407"
        y2="24.9298"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="#413BBC" />
        <stop offset="1" stop-color="#A9A6E2" />
      </linearGradient>
      <linearGradient
        id="paint10_linear_231_72167"
        x1="103.507"
        y1="23.8091"
        x2="107.066"
        y2="24.9298"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="#413BBC" />
        <stop offset="1" stop-color="#A9A6E2" />
      </linearGradient>
      <linearGradient
        id="paint11_linear_231_72167"
        x1="107.012"
        y1="23.8091"
        x2="110.571"
        y2="24.9298"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="#413BBC" />
        <stop offset="1" stop-color="#A9A6E2" />
      </linearGradient>
      <linearGradient
        id="paint12_linear_231_72167"
        x1="110.52"
        y1="23.8091"
        x2="114.078"
        y2="24.9298"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="#413BBC" />
        <stop offset="1" stop-color="#A9A6E2" />
      </linearGradient>
      <linearGradient
        id="paint13_linear_231_72167"
        x1="48.6282"
        y1="43.426"
        x2="58.5906"
        y2="64.2276"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="#413BBC" />
        <stop offset="1" stop-color="#A9A6E2" />
      </linearGradient>
      <linearGradient
        id="paint14_linear_231_72167"
        x1="151.982"
        y1="43.426"
        x2="161.945"
        y2="64.2276"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="#413BBC" />
        <stop offset="1" stop-color="#A9A6E2" />
      </linearGradient>
    </defs>
  </svg>
);

  /* Session Expired */
export function ShowLogOutScreen() {
  return (
    <FallBack
      FallBackHeight="FullPage"
      illustration={SessionExpired}
      title="Session Expired!"
      subTitle="Your session has Expired. Kindly refresh your web page."
    />
  );
}
