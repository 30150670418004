import React, { useEffect, useState } from "react";
import OrderGrid from "./OrderGrid";
import {
  Badge,
  Button,
  FallBack,
  FlexLayout,
  TextStyles,
} from "@cedcommerce-integration/ounce-ui";
import { getMethod } from "../../../../../apiMethods/methods";
import { getOrderAPI } from "../../../../../APIs/PanelAPI";
import Filters from "./Filters";
import { withRouter } from "react-router-dom";
import { NoOrder, NoSearchOrder } from "../../../../shared/IconSvg";
import FallbackGrid from "../../../../shared/FallbackGrid";
import {
  OrderGridSkeleton,
  OrderSkeleton,
} from "../../Skeleton/OrderListingSearch";

function Fulfilled(props) {
  let { tableColumns, fallbackStatusNoOrdersFulfilled } = props;

  const [orderSkeleton, setOrderSkeleton] = useState(true);
  const [gridSkeleton, setGridSkeleton] = useState(true);
  const [orderData, setOrderData] = useState([]);
  const [dataCount, setDataCount] = useState(0);
  const [activePage, setActivePage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [payloadState, setPayloadState] = useState({
    activePage: 1,
    count: 10,
  });
  const [locale, setLocale] = useState("");
  // no search order found
  const [
    fallbackStatusNoSearchOrdersFulfilled,
    setFallbackStatusNoSearchOrdersFulfilled,
  ] = useState(false);

  const getOrderDataFunc = async (payloadData) => {
    const time1 = new Date();
    let ms1 = time1.getTime();
    setFallbackStatusNoSearchOrdersFulfilled(false);
    setGridSkeleton(true);
    let payload = {
      "filter[object_type][1]": "source_order",
      "filter[targets.status][1]": "Shipped",
    };

    payload = { ...payload, ...payloadData };
    let { success, data } = await getMethod(getOrderAPI, payload);
    const time2 = new Date();
    let ms2 = time2.getTime();
    let timeOut = ms2 - ms1;
    if (success) {
      if (
        (JSON.stringify(payloadData).includes("marketplace_reference_id") ||
          JSON.stringify(payloadData).includes("created_at")) &&
        data?.["rows"].length == 0
      ) {
        setFallbackStatusNoSearchOrdersFulfilled(true);
      }
      if (data?.["rows"]) {
        setDataCount(data?.count);
        let tempOrderData = [];
        tempOrderData = data.rows.map((orderDataObj, index) => {
          let {
            customer,
            targets,
            marketplace_reference_id,
            items,
            created_at,
            cif_order_id,
          } = orderDataObj;
          const shoplineId = targets?.filter((target) => {
            return target?.["marketplace"] === "shopline" && target?.order_id;
          })?.[0]?.order_id;
          let tempObj = {};
          tempObj["key"] = index;
          tempObj["shoplineOrder_id"] = shoplineId ?? "";
          tempObj["order_id"] = (
            <FlexLayout direction="vertical" spacing="extraTight" wrap="noWrap">
              {marketplace_reference_id ? (
                <FlexLayout spacing="extraTight" wrap="noWrap">
                  <TextStyles fontweight="bold" type="Paragraph" paragraphTypes="MD-1.4" lineHeight="LH-2.0">eBay:</TextStyles>
                  <TextStyles textcolor="light" utility="text-ellipsis" type="Paragraph" paragraphTypes="MD-1.4" lineHeight="LH-2.0">
                    {marketplace_reference_id}
                  </TextStyles>
                </FlexLayout>
              ) : (
                <></>
              )}
              {shoplineId ? (
                <FlexLayout spacing="extraTight" wrap="noWrap">
                  <TextStyles fontweight="bold" type="Paragraph" paragraphTypes="MD-1.4" lineHeight="LH-2.0">Shopline:</TextStyles>
                  <TextStyles textcolor="light" utility="text-ellipsis" type="Paragraph" paragraphTypes="MD-1.4" lineHeight="LH-2.0">
                    {shoplineId}
                  </TextStyles>
                </FlexLayout>
              ) : (
                <></>
              )}
            </FlexLayout>
          );
          tempObj["full_name"] = <TextStyles textcolor="light" type="Paragraph" paragraphTypes="MD-1.4" lineHeight="LH-2.0">{customer?.name}</TextStyles>;
          tempObj["created_date"] = <TextStyles textcolor="light" type="Paragraph" paragraphTypes="MD-1.4" lineHeight="LH-2.0">{new Date(created_at).toISOString().split("T")[0]}</TextStyles>;
          tempObj["order_status"] = (
            <Badge type="Positive-300" badgeTextColor="light">
              Fulfilled
            </Badge>
          );
          tempObj["quantity"] = <TextStyles textcolor="light" type="Paragraph" paragraphTypes="MD-1.4" lineHeight="LH-2.0">{items.reduce((total, current) => {
            return total + Number(current.qty);
          }, 0)}</TextStyles>;
          tempObj["price"] = <TextStyles textcolor="light" type="Paragraph" paragraphTypes="MD-1.4" lineHeight="LH-2.0">{`$${items.reduce((total, current) => {
            return Number(total) + Number(current.price);
          }, 0)}`}</TextStyles>;
          tempObj["actions"] = (
            <Button
              type="TextButton"
              content="View"
              onClick={() => {
                window.scrollTo(0, 0);
                props.history.push(`/panel/order-view?id=${cif_order_id}`);
              }}
            />
          );
          return tempObj;
        });
        setOrderData(tempOrderData);
      }
    }
    if (timeOut < 500) {
      setTimeout(() => {
        setGridSkeleton(false);
        setOrderSkeleton(false);
      }, 500 - timeOut);
    } else {
      setGridSkeleton(false);
      setOrderSkeleton(false);
    }
  };

  useEffect(() => {
    getOrderDataFunc({
      ...payloadState,
      activePage: activePage,
      count: pageSize,
    });
  }, [activePage, pageSize, payloadState]);

  useEffect(() => {
    if (fallbackStatusNoOrdersFulfilled) {
      setLocale(
        <FallBack
          FallBackHeight="NormalPage"
          illustration={NoOrder}
          title="No Order Found!"
          subTitle="Seems like you haven't received any orders yet!"
        />
      );
    }
    if (fallbackStatusNoSearchOrdersFulfilled) {
      setLocale(
        <FallBack
          FallBackHeight="NormalPage"
          illustration={NoSearchOrder}
          title="No Order Found!"
          subTitle="Your request does not match the listed orders."
        />
      );
    }
  }, [fallbackStatusNoOrdersFulfilled, fallbackStatusNoSearchOrdersFulfilled]);

  return (
    <>
      {orderSkeleton ? (
        <OrderSkeleton />
      ) : (
        <>
          {fallbackStatusNoOrdersFulfilled ? (
            <></>
          ) : (
            <Filters
              setActivePage={setActivePage}
              payloadState={payloadState}
              setPayloadState={setPayloadState}
            />
          )}
          {gridSkeleton ? (
            <OrderGridSkeleton />
          ) : fallbackStatusNoSearchOrdersFulfilled ||
            fallbackStatusNoOrdersFulfilled ? (
            <FallbackGrid
              columns={tableColumns}
              local={locale}
              dataSource={orderData}
            />
          ) : (
            <OrderGrid
              tableColumns={tableColumns}
              dataSource={orderData}
              getOrderDataFunc={getOrderDataFunc}
              count={dataCount}
              activePage={activePage}
              setActivePage={setActivePage}
              pageSize={pageSize}
              setPageSize={setPageSize}
              payloadState={payloadState}
              setPayloadState={setPayloadState}
            />
          )}
        </>
      )}
    </>
  );
}

export default withRouter(Fulfilled);
