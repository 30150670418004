/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { getAccountsAPI } from "../../../APIs/loginAPI";
import { getMethod, postMethod } from "../../../apiMethods/methods";
import OnboardingLayout from "../layout/OnboardingLayout";
import Settings from "./Settings";
import "./onboarding.css";
import { parseJwt } from "../../../App";
import { freshChatWidget } from "../../../constants/constant";
import { capitalizeFirstLetter } from "../../../function";

function Onboarding() {
  const [connectorGetAll, setConnectorGetAll] = useState({});
  async function getAccounts() {
    let { success, data } = await getMethod(getAccountsAPI);
    success && setConnectorGetAll(data);
  }
  useEffect(() => {
    getAccounts();
  }, []);

  const win = window;

  useEffect(() => {
    const script = document.createElement("script");
    script.setAttribute("chat", true);
    script.src = "//in.fw-cdn.com/30398529/224630.js";
    document.head.appendChild(script);
    return () => {
      // Removal of the older script
      const scriptToRemove = document.querySelector(
        'script[src="//in.fw-cdn.com/30398529/224630.js"]'
      );
      const scriptToRemoveId = document.getElementById("freshchat-js-sdk");
      scriptToRemove?.parentNode?.removeChild(scriptToRemove);
      scriptToRemoveId?.parentNode?.removeChild(scriptToRemoveId);
      win?.fcWidget?.destroy();
    };
  }, []);

  const freshchatAPICall = async () => {
    const authToken = sessionStorage.getItem("auth_token");
    const token = parseJwt(authToken);

    const marketplace = connectorGetAll?.ebay?.title ?? "eBay";
    const platform = connectorGetAll?.shopline?.title ?? "Shopline";
    const status = connectorGetAll?.ebay?.installed ?? [];

    const { success, data } = await postMethod(
      "frontend/freshchat/getRestoreId",
      {}
    );

    let userData = {};
    if (success && data) {
      let user = data["data"];
      userData = {
        email: user?.email ?? "",
        siteId: token.user_id,
        externalId: token.user_id,
        name: user?.name ?? "",
        shopURL: user?.shops[0]?.domain ?? "",
        restoreId: user?.["freshchat_restore_id"] ?? null,
      };
    }

    const script = document.createElement("script");
    script.setAttribute("chat", true);
    script.src = "//in.fw-cdn.com/30398529/224630.js";
    document.head.appendChild(script);

    win.fcSettings = {
      onInit: function () {
        win.fcWidgetMessengerConfig = {
          tags: ["shopline_welcome"],
          externalId: userData?.externalId,
          restoreId: userData?.restoreId ?? null,
          // open: true,
          siteId: userData?.siteId,
        };
        window?.fcWidget?.user?.get(function (resp) {
          window?.fcWidget?.user?.setProperties({
            Config: status.length ? "Yes" : "No",
            Platform: capitalizeFirstLetter(platform),
            Marketplace: marketplace,
            Status: "Trial",
          });
        });
        win?.fwcrm?.on("widget:loaded", function () {
          win?.fcWidget?.setTags(["shopline_welcome"]);
          win?.fcWidget?.setExternalId(userData?.externalId);
          win?.fcWidget?.user?.setFirstName(userData?.name?.split(" ")?.[0]);
          win?.fcWidget?.user?.setEmail(userData?.email);
        });
        win?.fcWidget?.on("user:created", function (resp) {
          const status = resp.status,
            data = resp.data;
          if (status === 200 && !userData?.restoreId) {
            if (data?.restoreId) {
              saveRestoreFunc(data.restoreId);
            }
          }
        });
      },
    };
  };

  async function saveRestoreFunc(id) {
    await postMethod("frontend/freshchat/saveRestoreId", {
      freshchat_restore_id: id,
    });
  }

  useEffect(() => {
    if (Object.keys(connectorGetAll)?.length > 0) {
      freshchatAPICall();
    } else {
      window?.fcWidget?.destroy();
    }
  }, [connectorGetAll]);
  
  return (
    <OnboardingLayout>
      <Settings />
    </OnboardingLayout>
  );
}

export default Onboarding;
