import React, { useEffect } from "react";

const FreschatWrapper = (props) => {
  const win= window;
  const { children } = props;
  useEffect(() => {
    const script = document.createElement("script");
    script.setAttribute("chat", true);
    script.src = "//in.fw-cdn.com/30398529/224630.js";
    document.head.appendChild(script);
  }, []);
  useEffect(() => {
    win.fcSettings = {
      onInit: function () {
        win?.fwcrm?.on("widget:loaded", function () {
          win?.fcWidget?.setTags(["shopline_welcome"]);
        });
      },
    };
  }, [win]);
  return <>{children}</>;
};
export default FreschatWrapper;
