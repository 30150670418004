/* eslint-disable react-hooks/exhaustive-deps */
import {
  ActionList,
  Button,
  Card,
  FallBack,
  FlexLayout,
  Modal,
  PageHeader,
  Select,
  Skeleton,
  TextStyles,
  Toast,
  ToastWrapper,
} from "@cedcommerce-integration/ounce-ui";
import React, { useEffect, useState } from "react";
import { ChevronDown, ChevronUp, Download, RefreshCw } from "react-feather";
import { withRouter } from "react-router-dom";
import {
  importProductFromShoplineAPI,
  postPublishOnEbay,
  syncProductFromEbayAPI,
} from "../../../../../Apirequest/productApiEndpoints";
import {
  getMethod,
  postMethod,
} from "../../../../../Apirequest/productListingApi";
import {
  getProfileDataAPI,
  productStatusAPI,
} from "../../../../../APIs/PanelAPI";
import { globalState } from "../../../../../services/globalState";
import { DownloadIcon, NoProductsvg } from "../../../../shared/IconSvg";

import ProductList from "./Components/ProductList";

const Products = (props) => {
  const [active, setActive] = useState(false);
  // category template name
  const [templateName, setTemplateName] = useState("");
  const [templateProductCount, setTemplateProductCount] = useState(0);
  const [templateNameOptions, setTemplateNameOptions] = useState([]);
  const [bulkUploadLoader, setBulkUploadLoader] = useState(true);
  //toast
  const [toast, setToast] = useState({
    active: false,
    message: "",
    success: false,
  });

  const [createTemplateModal, setCreateTemplateModal] = useState(false);
  const [publishModal, setPublishModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const [productGridskeleton, setProductGridskeleton] = useState(true);
  // actions
  const [actionsModal, setActionsModal] = useState({
    active: false,
    content1: "",
    content2: "",
    content3: "",
    api: "",
    methodName: "",
    payload: {},
    loader: false,
  });

  // fallback - no products
  const [fallbackStatusNoProducts, setFallbackStatusNoProducts] =
    useState(false);

  const shopLineShopId = globalState.getLocalStorage("shopLineShopId");
  const ebayShopId = globalState.getLocalStorage("ebayShopId");

  const getCategoryTemplateName = async () => {
    const time1 = new Date();
    let ms1 = time1.getTime();
    setBulkUploadLoader(true);
    let payload = {
      count: 10,
    };
    let { success, data, code } = await getMethod(getProfileDataAPI, payload); // 2 times
    const time2 = new Date();
    let ms2 = time2.getTime();
    let timeOut = ms2 - ms1;
    if (success) {
      let templateNames = data?.rows
        ?.filter(
          (template, index) =>
            !(
              template.hasOwnProperty("product_update_in_progress") &&
              template["product_update_in_progress"]
            )
        )
        ?.map((template, index) => {
          return {
            label: template.name,
            // value: template?._id?.$oid,
            value:template.name,
            count: template?.product_count?.[0]?.count ?? 0,
          };
        });
      if (templateNames?.length) {
        templateNames?.sort((a, b) => {
          const label1 = a.label.toUpperCase();
          const label2 = b.label.toUpperCase();
          if (label1 < label2) return -1;
          if (label1 > label2) return 1;
          return 0;
        });
      }
      setTemplateNameOptions(templateNames);
    } else if (
        code === "token_expired" ||
        code === "invalid_token" ||
        code === "future_token" ||
        code === "token_decode_error"
      ) {
        props.history.push("/tokenexpired");
      }
    if (timeOut < 500) {
      setTimeout(() => {
        setBulkUploadLoader(false);
      }, 500 - timeOut);
    } else {
      setBulkUploadLoader(false);
    }
  };

  const getStatusWiseCount = async () => {
    let { success, data, code } = await getMethod(productStatusAPI);
    if (success) {
      let temp = 0;
      data.forEach((status, index) => {
        temp += status?.total;
      });
      if (!temp) setFallbackStatusNoProducts(true);
    } else if (
        code === "token_expired" ||
        code === "invalid_token" ||
        code === "future_token" ||
        code === "token_decode_error"
      ) {
        props.history.push("/tokenexpired");
      }
  };

  const BulkUpload = () => {
    if (templateNameOptions.length) setPublishModal(true);
    else setCreateTemplateModal(true);
  };

  const PublishOnEbay = async () => {
    setLoading(true);
    let payload = {
      profile_id: templateName,
      source: { marketplace: "shopline", shopId: shopLineShopId },
      target: { marketplace: "ebay", shopId: ebayShopId },
      operationType: "product_upload",
    };

    let { success, message, code } = await postMethod(
      postPublishOnEbay,
      payload
    );
    if (success) {
      setToast({ active: true, message: message, success: success });
    } else if (code) {
      if (
        code === "token_expired" ||
        code === "invalid_token" ||
        code === "future_token" ||
        code === "token_decode_error"
      ) {
        props.history.push("/tokenexpired");
      }
    } else {
      setToast({ active: true, message: message, success: success });
    }
    setTemplateName("");
    setTemplateProductCount(0);
    setPublishModal(false);
    setLoading(false);
  };

  const toastMarkup = toast.active ? (
    <Toast
      message={toast.message}
      onDismiss={() => setToast({ active: false, message: "", success: false })}
      timeout={2000}
      type={toast.success ? "success" : "error"}
    />
  ) : (
    <></>
  );

  useEffect(() => {
    getCategoryTemplateName();
    getStatusWiseCount();
  }, []);

  return (
    <>
      {fallbackStatusNoProducts ? (
        <>
          <PageHeader
            title="Products"
            description={
              "Product List lets you manage and sort all your eBay listings, including bulk syncing and importing actions."
            }
          />
          <FallBack
            FallBackHeight="NormalPage"
            illustration={NoProductsvg}
            title="No Product Available!"
            subTitle="Please ensure that your Product is synchronized with the app."
            action={
              <Button
                content="Import Product"
                icon={DownloadIcon}
                iconAlign="left"
                onAction={() => {
                  setActionsModal({
                    active: true,
                    content1: "Import from Shopline",
                    content2: "import all products from Shopline",
                    content3: "Import Now",
                    api: importProductFromShoplineAPI,
                    loader: false,
                    methodName: postMethod,
                    payload: {
                      source: {
                        marketplace: "shopline",
                        shopId: shopLineShopId,
                      },
                      target: { marketplace: "ebay", shopId: ebayShopId },
                      app_code: { shopline: "shopline" },
                    },
                  });
                }}
              />
            }
          />
        </>
      ) : (
        <>
          <PageHeader
            action={
              <FlexLayout spacing="mediumTight" wrap="wrap">
                <ActionList
                  customClass="product-actions"
                  activator={
                    productGridskeleton ? (
                      <Skeleton height="40px" width="155px" type="custom" />
                    ) : (
                      <Button
                        icon={active ? <ChevronUp /> : <ChevronDown />}
                        iconAlign="right"
                        onClick={() => setActive(!active)}
                        type="Outlined"
                      >
                        More Actions
                      </Button>
                    )
                  }
                  open={active}
                  onClose={() => setActive(false)}
                  options={[
                    {
                      items: [
                        {
                          content: "Import from Shopline",
                          prefixIcon: <Download size={20} />,
                          onClick: () => {
                            setActionsModal({
                              active: true,
                              content1: "Import from Shopline",
                              content2: "import all products from Shopline",
                              content3: "Import Now",
                              api: importProductFromShoplineAPI,
                              loader: false,
                              methodName: postMethod,
                              payload: {
                                source: {
                                  marketplace: "shopline",
                                  shopId: shopLineShopId,
                                },
                                target: {
                                  marketplace: "ebay",
                                  shopId: ebayShopId,
                                },
                                app_code: { shopline: "shopline" },
                              },
                            });
                          },
                        },
                        {
                          content: "Sync Products Status from eBay",
                          prefixIcon: <RefreshCw size={20} />,
                          onClick: () => {
                            setActionsModal({
                              active: true,
                              content1: "Sync Products Status from eBay",
                              content2: "Sync Products Status from eBay",
                              content3: "Start Sync",
                              api: syncProductFromEbayAPI,
                              loader: false,
                              methodName: postMethod,
                              payload: {
                                source: {
                                  marketplace: "shopline",
                                  shopId: shopLineShopId,
                                },
                                target: {
                                  marketplace: "ebay",
                                  shopId: ebayShopId,
                                },
                              },
                            });
                          },
                        },
                      ],
                    },
                  ]}
                />
                {bulkUploadLoader ? (
                  <Skeleton height="40px" width="117px" type="custom" />
                ) : (
                  <Button type="Primary" onClick={BulkUpload}>
                    Bulk Upload
                  </Button>
                )}
              </FlexLayout>
            }
            title="Products"
            description={
              "Product List lets you manage and sort all your eBay listings, including bulk syncing and importing actions."
            }
          />
          <ProductList
            templateNameOptions={templateNameOptions}
            productGridskeleton={productGridskeleton}
            setProductGridskeleton={setProductGridskeleton}
          />
          {/* Bulk Upoload Create template Modal */}
          <Modal
            closeOnEscape={true}
            overlayClose={true}
            open={createTemplateModal}
            close={() => setCreateTemplateModal(!createTemplateModal)}
            heading="Upload Products on eBay"
            modalSize="small"
            primaryAction={{
              content: "Create Template",
              loading: false,
              onClick: () => props.history.push("/panel/create-template"),
            }}
          >
            Category Templates are not created yet. Please create a template to
            upload the product on eBay.
          </Modal>
          {/* Bulk Upoload Product Modal */}
          <Modal
            closeOnEscape={true}
            overlayClose={true}
            open={publishModal}
            close={() => {
              setTemplateName("");
              setTemplateProductCount(0);
              setPublishModal(!publishModal);
            }}
            heading="Upload Products on eBay"
            modalSize="small"
            primaryAction={{
              content: "Upload on eBay",
              loading: loading,
              onClick: PublishOnEbay,
              disable: !templateName || templateProductCount === 0,
            }}
          >
            <FlexLayout direction="vertical" spacing="tight" wrap="noWrap">
              <TextStyles
                alignment="left"
                fontweight="extraBold"
                lineHeight="LH-2.0"
                paragraphTypes="MD-1.4"
                textcolor="dark"
                type="Paragraph"
                utility="none"
              >
                Choose the template which you want to upload on eBay
              </TextStyles>
              <Card cardType="Bordered">
                <Select
                  name="Category Template Name"
                  onChange={(e) => {
                    setTemplateName(e);
                    let matchedTemplateProductCount = templateNameOptions.find(
                      (name) => name.value === e
                    )?.count;
                    setTemplateProductCount(matchedTemplateProductCount);
                  }}
                  options={templateNameOptions}
                  value={templateName}
                  selectHelp={
                    templateName &&
                    `This template contains ${templateProductCount} products.`
                  }
                  searchEable
                />
              </Card>
            </FlexLayout>
          </Modal>
        </>
      )}
      {/* confirmation modal */}
      <Modal
        closeOnEscape={true}
        overlayClose={true}
        modalSize="small"
        open={actionsModal.active}
        heading={actionsModal.content1}
        primaryAction={{
          content: actionsModal.content3,
          loading: actionsModal.loader,
          type: "Primary",
          onClick: async () => {
            setActionsModal({ ...actionsModal, loader: true });
            const { api, methodName, payload } = actionsModal;
            let { success, message, code } = await methodName(
              api,
              payload
            );
            if (success) {
              setToast({ active: true, message: message, success: success });
            } else if (code) {
              if (
                code === "token_expired" ||
                code === "invalid_token" ||
                code === "future_token" ||
                code === "token_decode_error"
              ) {
                props.history.push("/tokenexpired");
              }
            } else {
              setToast({ active: true, message: message, success: success });
            }
            setActionsModal({
              active: false,
              content1: "",
              content2: "",
              content3: "",
              api: "",
              methodName: "",
              payload: {},
              loader: false,
            });
          },
        }}
        close={() => {
          setActionsModal({
            active: false,
            content1: "",
            content2: "",
            content3: "",
            api: "",
            methodName: "",
            payload: {},
            loader: false,
          });
        }}
      >
        <TextStyles>
          Do you want to proceed with {actionsModal.content2}?
        </TextStyles>
      </Modal>
      <ToastWrapper>{toastMarkup}</ToastWrapper>
    </>
  );
};

export default withRouter(Products);
