/* eslint-disable react-hooks/exhaustive-deps */
import {
  Button,
  Card,
  CheckBox,
  FlexChild,
  FlexLayout,
  Select,
  TextField,
  TextStyles,
  Toast,
  ToastWrapper,
} from "@cedcommerce-integration/ounce-ui";
import { useCallback, useEffect, useState } from "react";
import { ExternalLink } from "react-feather";
import { withRouter } from "react-router-dom";
import { getMethod } from "../../../apiMethods/methods";
import { getAccountsAPI } from "../../../APIs/loginAPI";
import { environment } from "../../../environment/environment";
import { globalState } from "../../../services/globalState";
import { publicIpv4 } from "public-ip";

const officeIPs = ["103.97.184.122", "103.97.184.106"];

const Settings = (props) => {
  const [checked, setChecked] = useState({
    basic_prerequisite: false,
    connect_account: false,
  });
  const [location, setLocation] = useState({
    zipcode: "",
    location: "",
  });
  const [errorToast, setErrorToast] = useState(false);
  const [successToast, setSuccessToast] = useState(false);
  const [accountType, setAccountType] = useState("live");
  const [ip, setIp] = useState("");

  const handleChecked = (criteria) => {
    let tempChecked = { ...checked };
    tempChecked[criteria] = !tempChecked[criteria];
    setChecked(tempChecked);
  };
  const handleLocation = (e, field) => {
    let tempLocation = { ...location };
    tempLocation[field] = e;
    setLocation(tempLocation);
  };

  const toggleErrorActive = useCallback(() => {
    setErrorToast((active) => !active);
  }, []);

  const toggleSuccessActive = async () => {
    setSuccessToast((active) => !active);
  };

  const toastMarkup = successToast ? (
    <Toast
      message="Account Successfully Connected"
      onDismiss={toggleSuccessActive}
      timeout={2000}
      type="success"
    />
  ) : errorToast ? (
    <Toast
      message="Oops! The account Connection was Unsuccessful"
      onDismiss={toggleErrorActive}
      timeout={2000}
      type="error"
    />
  ) : null;

  const getAccounts = async () => {
    let connected = {
      shopline_conected: "",
      ebay_connected: "",
    };
    let { success, data } = await getMethod(getAccountsAPI);
    if (success) {
      if (
        data?.ebay &&
        data?.ebay?.installed &&
        Array.isArray(data?.ebay?.installed) &&
        data?.ebay?.installed.length > 0
      ) {
        globalState.setLocalStorage(
          "ebayShopId",
          data?.ebay?.installed?.[0]?.["_id"]
        );
        connected.ebay_connected = "_TARGET_CONNECTED_";
      }
      if (
        data?.shopline &&
        data?.shopline?.installed &&
        Array.isArray(data?.shopline?.installed) &&
        data?.shopline?.installed.length > 0
      ) {
        globalState.setLocalStorage(
          "shopLineShopId",
          data?.shopline?.installed?.[0]?.["_id"]
        );
        connected.shopline_conected = "_SOURCE_CONNECTED_";
      }
    }
    return connected;
  };

  const getIP = async () => {
    let recievedIP = await publicIpv4();
    setIp(recievedIP);
  };

  useEffect(() => {
    getIP();
  }, []);

  useEffect(() => {
    if (successToast) {
      setTimeout(() => {
        props.history.push("payment");
      }, 2000);
    }
  }, [successToast]);

  const connectWithEbay = () => {
    let mode = accountType === "live" ? "live" : "sandbox";
    let test = window.open(
      `${environment.API_ENDPOINT
      }connector/get/installationForm?code=ebay&site_id=15&mode=${mode}&bearer=${globalState.getLocalStorage(
        "auth_token"
      )}`,
      "popUpWindow",
      "height=500,width=800,left=100,top=100,resizable=yes,scrollbars=yes,toolbar=yes,menubar=no,location=no,directories=no, status=yes"
    );
    if (test) {
      const int = setInterval(async () => {
        if (test?.closed) {
          let temp = await getAccounts();
          if (
            temp?.["ebay_connected"] === "_TARGET_CONNECTED_" &&
            temp?.["shopline_conected"] === "_SOURCE_CONNECTED_"
          ) {
            setSuccessToast(true);
          } else {
            setErrorToast(true);
            props.history.push("/onboarding");
          }
          clearInterval(int);
        }
      }, 1000);
    }
  };

  const callFunc = async () => {
    globalState.setLocalStorage("ebay_locations", JSON.stringify(location));
    connectWithEbay();
  };

  return (
    <Card cardType="Shadowed" extraClass="mt-20">
      <FlexLayout direction="vertical" spacing="loose">
        <Card cardType="Bordered"
          title={
            <TextStyles
              fontweight="extraBold"
              subheadingTypes="XS-1.6"
              textcolor="dark"
              lineHeight="LH-2.0"
              type="SubHeading"
              utility="required-icon"
            >
              Basic Prerequisite
            </TextStyles>
          }
          subTitle="Connect Today and start selling your products on eBay with
        industry-leading features. Please confirm the prerequisites
        before moving ahead."
        >


          <FlexLayout direction="vertical" spacing="extraTight">
            <CheckBox
              id=""
              labelVal={
                <span className="inte__LineHeight--2">
                  eBay Seller Account should be <span className="inte__font--extraBold ">active</span> and
                  <span className="inte__font--extraBold "> available </span>for selling
                </span>
              }
              name="Name"
              checked={checked.basic_prerequisite}
              onClick={() => handleChecked("basic_prerequisite")}
            />
            <div className="basic-prerequisite">
              <FlexLayout direction="vertical" spacing="extraTight">
                <Button
                  type="TextButton"
                  icon={<ExternalLink size={16} />}
                  iconAlign="right"
                  onClick={() => {
                    window.open(
                      "https://www.ebay.com/help/policies/business-policy/business-policies?id=4212",
                      "_blank"
                    );
                  }}
                >
                  Opt Business Policy
                </Button>
                <Button
                  type="TextButton"
                  icon={<ExternalLink size={16} />}
                  iconAlign="right"
                  onClick={() => {
                    window.open(
                      "https://signin.ebay.com/ws/eBayISAPI.dll?SignIn&UsingSSL=1&siteid=0&co_partnerId=2&pageType=2493094&ru=https%3A%2F%2Fwww.ebay.com%2Fhelp%2Fselling%2Ffees-credits-invoices%2Fsetting-changing-payment-method-fees-selling-costs%3Fid%3D5270",
                      "_blank"
                    );
                  }}
                >
                  Automatic Payment Account Added
                </Button>
                <Button
                  type="TextButton"
                  icon={<ExternalLink size={16} />}
                  iconAlign="right"
                  onClick={() => {
                    window.open(
                      "https://www.ebay.com/help/selling/listings/selling-limits?id=4107",
                      "_blank"
                    );
                  }}
                >
                  Listing Limit Not Exhausted
                </Button>
              </FlexLayout>
            </div>
          </FlexLayout>

        </Card>
        <Card cardType="Bordered"
          title={<TextStyles
            fontweight="extraBold"
            subheadingTypes="XS-1.6"
            textcolor="dark"
            lineHeight="LH-2.0"
            type="SubHeading"
            utility="required-icon"
          >
            Connect eBay(Australia) Account
          </TextStyles>}
          subTitle={
            <FlexLayout direction="vertical" spacing="mediumTight" wrap="noWrap">
              <p className="inte__text--light inte__LineHeight--2">
                Connect your
                <span className="inte__font--extraBold"> eBay Seller Account </span>
                with the app to manage
                <span className="inte__font--extraBold"> Products</span> and{" "}
                <span className="inte__font--extraBold">Orders.</span>
              </p>

              <TextStyles
                fontweight="bold"
                lineHeight="LH-2.0"
                paragraphTypes="MD-1.4"
                textcolor="light"
                type="Paragraph"
                utility="text-italic"
              >
                The app can only connect with one eBay Seller Account at a time. You
                may need to uninstall and start over with a different account if you
                have multiple eBay Seller Accounts in Australia.
              </TextStyles>
            </FlexLayout>
          }
        >
          <CheckBox
            id=""
            labelVal={privacyPolicyText()}
            name="Name"
            checked={checked.connect_account}
            onClick={() => handleChecked("connect_account")}
          />
        </Card>
        <Card
          alt="Card media"
          cardType="Bordered"
          title={
            <TextStyles
              fontweight="extraBold"
              subheadingTypes="XS-1.6"
              textcolor="dark"
              lineHeight="LH-2.0"
              type="SubHeading"
              utility="required-icon"
            >
              Item Location
            </TextStyles>
          }
          utility="required-icon"
          subTitle={
            <FlexLayout spacing="extraTight" halign="">
              <TextStyles
                paragraphTypes="MD-1.4"
                textcolor="light"
                lineHeight="LH-2.0"
                type="Paragraph"
              >
                Enter a valid Item zip code and location for completing your
                authentication.
              </TextStyles>
            </FlexLayout>
          }
        >
          <FlexLayout
            desktopWidth="50"
            tabWidth="50"
            mobileWidth="50"
            spacing="loose"
            halign=""
          >
            <TextField
              autocomplete="off"
              value={location.zipcode}
              onChange={(e) => handleLocation(e, "zipcode")}
              placeHolder="Enter zipcode..."
              type="text"
              maxlength={12}
            />
            <TextField
              autocomplete="off"
              value={location.location}
              onChange={(e) => handleLocation(e, "location")}
              placeHolder="Enter location..."
              type="text"
              maxlength={30}
            />
          </FlexLayout>
        </Card>
        <FlexLayout halign="end" spacing="tight" valign="center">
          <FlexChild desktopWidth="20" tabWidth="20" mobileWidth="33">
            {officeIPs.includes(ip) && (
              <Select
                popoverContainer="element"
                thickness="thin"
                options={[
                  { label: "Live", value: "live" },
                  { label: "Sandbox", value: "sandbox" },
                ]}
                value={accountType}
                onChange={(e) => setAccountType(e)}
              />
            )}
          </FlexChild>
          <Button
            thickness="thin"
            type="Primary"
            onClick={() => callFunc()}
            disable={
              !(checked.basic_prerequisite &&
                checked.connect_account &&
                location.zipcode &&
                location.location)
            }
          >
            Connect with eBay
          </Button>
        </FlexLayout>
      </FlexLayout>
      <ToastWrapper>{toastMarkup}</ToastWrapper>
    </Card>
  );
};

const privacyPolicyText = () => {
  return (
    <>
      Accept{" "}
      <a
        target="_blank"
        rel="noreferrer"
        href="https://cedcommerce.com/privacy-policy"
        onClick={(e) => {
          e.stopPropagation();
        }}
      >
        privacy policy
      </a>{" "}
      of CedCommerce
    </>
  );
};

export default withRouter(Settings);
