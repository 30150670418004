/* eslint-disable react-hooks/exhaustive-deps */
import {
  Badge,
  Card,
  FlexChild,
  FlexLayout,
  Grid,
  PageHeader,
  Tag,
  TextStyles,
} from "@cedcommerce-integration/ounce-ui";
import Slider from "react-slick";
import { ChevronLeft, ChevronRight } from "react-feather";
import queryString from "query-string";
import React, { useEffect, useState } from "react";
import { getViewProduct } from "../../../../../Apirequest/productApiEndpoints";
import { getMethod } from "../../../../../Apirequest/productListingApi";
import { globalState } from "../../../../../services/globalState";
import ProductViewSkeleton from "../../Skeleton/ProductViewSkeleton";
import NoImage from "../../../../../assets/images/notfound.png";

import { ActiveTick, UnactiveTick } from "../productListing/Components/IconSvg";

const ViewProducts = (props) => {
  const shopLineShopId = globalState.getLocalStorage("shopLineShopId");
  const ebayShopId = globalState.getLocalStorage("ebayShopId");
  const [id, setId] = useState("");
  const [type, setType] = useState("");
  const [parentData, setParentData] = useState({});
  const [variantData, setVariantData] = useState([]);
  const [totalVariantQuantity, setTotalVariantQuantity] = useState(0);
  //carausel
  const [nav1, setNav1] = useState();
  const [nav2, setNav2] = useState();
  const [slider1, setSlider1] = useState([]);
  const [additionalImage, setAdditionalImage] = useState([]);
  const [pageSkeleton, setPageSkeleton] = useState(true);
  // badge status
  const [badgesData, setBadgesData] = useState({
    badgeStatus: "",
    badgeBackgroundColor: "",
    badgeTextColor: "",
    badges: {
      Uploaded: { text: "Uploaded", color: "#269E6C", txtColor: "light" },
      "Not Uploaded": {
        text: "Not Uploaded",
        color: "#FEC84B",
        txtColor: "dark",
      },
      "Uploaded with Error": { color: "#FF8277", txtColor: "light" },
      Ended: { text: "Ended", color: "#E0E1E3", txtColor: "dark" },
      Error: { text: "Errors", color: "#FF0000", txtColor: "light" },
    },
  });

  const getBadgeAndError = (parentProduct) => {
    let badgeStatusTempValue = "";
    switch (parentProduct?.edited?.status) {
      case "Error":
        badgeStatusTempValue = "Error";
        break;
      case "Ended":
        badgeStatusTempValue = "Ended";
        break;
      case "Active":
        badgeStatusTempValue = "Uploaded";
        break;
      default:
        badgeStatusTempValue = "Not Uploaded";
        break;
    }
    setBadgesData({
      ...badgesData,
      badgeStatus: badgeStatusTempValue,
      badgeBackgroundColor: badgesData["badges"][badgeStatusTempValue]["color"],
      badgeTextColor: badgesData["badges"][badgeStatusTempValue]["txtColor"],
    });
  };

  const viewProductFunc = async (id) => {
    const time1 = new Date();
    let ms1 = time1.getTime();
    setPageSkeleton(true);
    const tempPayload = {
      target_marketplace: "ebay",
      source_marketplace: "shopline",
      sourceShopID: shopLineShopId,
      targetShopID: ebayShopId,
      container_id: id,
    };
    let { success, data, code } = await getMethod(getViewProduct, tempPayload);
    const time2 = new Date();
    let ms2 = time2.getTime();
    let timeOut = ms2 - ms1;
    if (success) {
      if (Array.isArray(data?.rows)) {
        setAdditionalImage(data?.rows[0]?.additional_images);
        let temp = data.rows[0]?.additional_images?.map((url, index) => {
          return {
            id: index + 1,
            url: url,
          };
        });
        setSlider1(temp);
        if (data?.rows?.length) {
          let variantArr = [];
          data.rows.forEach((item, index) => {
            if (
              item?.visibility === "Catalog and Search" &&
              (item?.type === "simple" || item?.type === "variation")
            ) {
              setParentData(item);
              getBadgeAndError(item);
            } else if (
              item?.type === "simple" &&
              item?.visibility === "Not Visible Individually"
            ) {
              variantArr.push(item);
            }
          });
          setVariantData(variantArr);
        }
      }
    } else if (
        code === "token_expired" ||
        code === "invalid_token" ||
        code === "future_token" ||
        code === "token_decode_error"
      ) {
        props.history.push("/tokenexpired");
      }
    if (timeOut < 500) {
      setTimeout(() => {
        setPageSkeleton(false);
      }, 500 - timeOut);
    } else setPageSkeleton(false);
  };
  const getParams = () => {
    let { id, type } = queryString.parse(props.location.search);
    if (id) {
      setId(id);
    }
    if (type) {
      setType(type);
    }
  };

  useEffect(() => {
    let tempTotalQuant = 0;
    variantData.forEach((data) => {
      if (data?.quantity) tempTotalQuant += data.quantity;
      setTotalVariantQuantity(tempTotalQuant);
    });
  }, [variantData]);

  useEffect(() => {
    getParams();
  }, []);

  useEffect(() => {
    if (id) {
      viewProductFunc(id);
    }
  }, [id]);

  const VariantColumns = [
    {
      dataIndex: "image",
      key: "image",
      title: "Image",
      width: 80,
    },
    {
      dataIndex: "name",
      key: "name",
      title: "Name",
      width: 150,
    },
    {
      dataIndex: "price",
      align: "center",
      key: "price",
      title: "Price",
      width: 150,
    },
    {
      dataIndex: "inventory_track",
      align: "left",
      key: "inventory_track",
      title: "Inventory Strategy",
      width: 150,
    },
    {
      dataIndex: "quantity",
      align: "center",
      key: "quantity",
      title: "Quantity",
      width: 150,
    },
    {
      dataIndex: "weight",
      align: "center",
      key: "weight",
      title: "Weight",
      width: 150,
    },
  ];
  const VariantData =
    variantData.length > 0
      ? variantData.map((data, index) => {
          return {
            image: data?.["variant_image"] ? (
              <img
                src={data?.["variant_image"]}
                alt="title"
                width={48}
                height={48}
                style={{ borderRadius: "4px" }}
              />
            ) : (
              <img
                src={NoImage}
                width="60px"
                height="60px"
                style={{ borderRadius: "4px" }}
                alt=""
              />
            ),
            name: (
              <FlexLayout
                direction="vertical"
                spacing="mediumTight"
                wrap="noWrap"
              >
                <TextStyles
                  fontweight="bold"
                  paragraphTypes="MD-1.4"
                  textcolor="dark"
                  type="Paragraph"
                >
                  {data?.title}
                </TextStyles>
                <FlexLayout spacing="extraTight" wrap="noWrap">
                  <TextStyles
                    fontweight="bold"
                    paragraphTypes="MD-1.4"
                    textcolor="dark"
                    type="Paragraph"
                  >
                    SKU:
                  </TextStyles>
                  <span style={{ wordBreak: "break-all" }}>
                    <TextStyles
                      paragraphTypes="MD-1.4"
                      textcolor="light"
                      type="Paragraph"
                    >
                      {data?.sku}
                    </TextStyles>
                  </span>
                </FlexLayout>
                {data?.barcode && (
                  <FlexLayout spacing="extraTight" wrap="noWrap">
                    <TextStyles
                      fontweight="bold"
                      paragraphTypes="MD-1.4"
                      textcolor="dark"
                      type="Paragraph"
                    >
                      Barcode:
                    </TextStyles>
                    <span style={{ wordBreak: "break-all" }}>
                      <TextStyles
                        fontweight="bold"
                        paragraphTypes="MD-1.4"
                        textcolor="light"
                        type="Paragraph"
                      >
                        {data?.barcode}
                      </TextStyles>
                    </span>
                  </FlexLayout>
                )}
              </FlexLayout>
            ),
            inventory_track: (
              <FlexLayout
                direction="vertical"
                spacing="mediumTight"
                wrap="noWrap"
              >
                <div className="active-status tick-icon">
                  <FlexLayout
                    spacing="mediumTight"
                    wrap="noWrap"
                    valign="start"
                  >
                    {data?.inventory_tracked ? ActiveTick : UnactiveTick}
                    <TextStyles
                      lineHeight="LH-2.0"
                      paragraphTypes="MD-1.4"
                      textcolor="light"
                      type="Paragraph"
                    >
                      Track Quantity
                    </TextStyles>
                  </FlexLayout>
                </div>
              </FlexLayout>
            ),
            price: (
              <TextStyles
                paragraphTypes="MD-1.4"
                textcolor="light"
                type="Paragraph"
              >
                {`$${data?.price}`}
              </TextStyles>
            ),
            quantity: (
              <TextStyles
                paragraphTypes="MD-1.4"
                textcolor="light"
                type="Paragraph"
              >
                {data?.quantity.toString().padStart(2,0)}
              </TextStyles>
            ),
            weight: (
              <TextStyles
                paragraphTypes="MD-1.4"
                textcolor="light"
                type="Paragraph"
              >
                {`${data?.weight} ${data?.weight_unit}`}
              </TextStyles>
            ),
          };
        })
      : [];

  return (
    <>
      {pageSkeleton ? (
        <ProductViewSkeleton type={type} />
      ) : (
        <>
          <PageHeader
            title={parentData?.title}
            metaData={
              <Badge
                type="Positive-300"
                size="small"
                badgeTextColor={badgesData["badgeTextColor"]}
                customBgColor={badgesData["badgeBackgroundColor"]}
                children={badgesData["badgeStatus"]}
              />
            }
            reverseNavigation={true}
            onClick={(e) => props.history.push("/panel/products")}
          />
          <Card>
            <FlexLayout spacing="loose" valign="start">
              <FlexChild desktopWidth="66" tabWidth="100" mobileWidth="100">
                <>
                  <Card cardType="Bordered">
                    <FlexLayout
                      spacing="mediumLoose"
                      wrap="noWrap"
                      wrapTab="noWrap"
                      wrapMob="wrap"
                    >
                      <FlexChild
                        desktopWidth="25"
                        tabWidth="25"
                        mobileWidth="100"
                      >
                        <FlexLayout
                          desktopWidth="100"
                          direction="vertical"
                          spacing="mediumTight"
                          wrap="noWrap"
                        >
                          {/* FIRST CAROUSEL */}
                          <Slider
                            slidesToScroll={1}
                            ref={(slider1) => setNav1(slider1)}
                            asNavFor={nav2}
                            swipeToSlide={true}
                            slidesToShow={1}
                            arrows={false}
                            focusOnSelect={true}
                          >
                            {additionalImage.map((items) => {
                              return <img key={items} src={items} alt="img" />;
                            })}
                          </Slider>
                          {additionalImage?.length > 1 && (
                            <div className="slick-second-carousel">
                              <Slider
                                focusOnSelect={true}
                                arrows
                                prevArrow={
                                  <span className="prev-arrow">
                                    <ChevronLeft size={16} />
                                  </span>
                                }
                                nextArrow={
                                  <span className="next-arrow">
                                    <ChevronRight size={16} />
                                  </span>
                                }
                                asNavFor={nav1}
                                ref={(slider2) => setNav2(slider2)}
                                slidesToShow={
                                  parentData?.additional_images.length > 4
                                    ? 4
                                    : parentData?.additional_images.length
                                }
                              >
                                  {slider1.map((items) => {
                                  return (
                                    <div key={items?.id} className="mob-caraousel">
                                      <img
                                        src={items.url}
                                        width={"100%"}
                                        height={
                                          slider1.length > 4 ? "100%" : "80"
                                        }
                                        alt="img"
                                      />
                                    </div>
                                  );
                                })}
                              </Slider>
                            </div>
                          )}
                        </FlexLayout>
                      </FlexChild>

                      <FlexChild
                        desktopWidth="75"
                        tabWidth="75"
                        mobileWidth="100"
                      >
                        <FlexLayout
                          direction="vertical"
                          wrap="noWrap"
                          spacing="mediumLoose"
                        >
                          <TextStyles
                            lineHeight="LH-2.0"
                            paragraphTypes="MD-1.4"
                            textcolor="light"
                            type="Paragraph"
                          >
                            <div
                              className="inte__text--light"
                              dangerouslySetInnerHTML={{
                                __html: parentData?.description,
                              }}
                            ></div>
                          </TextStyles>
                          {type === "simple" && (
                            <FlexLayout spacing="tight">
                              {parentData?.sku && (
                                <FlexLayout spacing="extraTight" wrap="noWrap">
                                  <TextStyles
                                    fontweight="bold"
                                    paragraphTypes="MD-1.4"
                                    textcolor="dark"
                                    type="Paragraph"
                                  >
                                    SKU:
                                  </TextStyles>
                                  <TextStyles
                                    fontweight="bold"
                                    paragraphTypes="MD-1.4"
                                    textcolor="light"
                                    type="Paragraph"
                                  >
                                    {parentData?.sku}
                                  </TextStyles>
                                </FlexLayout>
                              )}
                              {parentData?.price && (
                                <FlexLayout spacing="extraTight" wrap="noWrap">
                                  <TextStyles
                                    fontweight="bold"
                                    paragraphTypes="MD-1.4"
                                    textcolor="dark"
                                    type="Paragraph"
                                  >
                                    Price:
                                  </TextStyles>
                                  <TextStyles
                                    fontweight="bold"
                                    paragraphTypes="MD-1.4"
                                    textcolor="light"
                                    type="Paragraph"
                                  >
                                    {`$${parentData?.price}`}
                                  </TextStyles>
                                </FlexLayout>
                              )}
                              {parentData?.quantity && (
                                <FlexLayout spacing="extraTight" wrap="noWrap">
                                  <TextStyles
                                    fontweight="bold"
                                    paragraphTypes="MD-1.4"
                                    textcolor="dark"
                                    type="Paragraph"
                                  >
                                    Quantity:
                                  </TextStyles>
                                  <TextStyles
                                    fontweight="bold"
                                    paragraphTypes="MD-1.4"
                                    textcolor="light"
                                    type="Paragraph"
                                  >
                                    {parentData?.quantity}
                                  </TextStyles>
                                </FlexLayout>
                              )}
                              {parentData?.barcode ? (
                                <FlexLayout spacing="extraTight" wrap="noWrap">
                                  <TextStyles
                                    utility="text-nowrap"
                                    fontweight="bold"
                                    paragraphTypes="MD-1.4"
                                    textcolor="dark"
                                    type="Paragraph"
                                  >
                                    Barcode:
                                  </TextStyles>
                                  <TextStyles
                                    fontweight="bold"
                                    paragraphTypes="MD-1.4"
                                    textcolor="light"
                                    type="Paragraph"
                                  >
                                    {parentData?.barcode}
                                  </TextStyles>
                                </FlexLayout>
                              ) : (
                                <></>
                              )}
                              {parentData?.weight !== null ? (
                                <FlexLayout spacing="extraTight" wrap="noWrap">
                                  <TextStyles
                                    utility="text-nowrap"
                                    fontweight="bold"
                                    paragraphTypes="MD-1.4"
                                    textcolor="dark"
                                    type="Paragraph"
                                  >
                                    Weight:
                                  </TextStyles>
                                  <TextStyles
                                    fontweight="bold"
                                    paragraphTypes="MD-1.4"
                                    textcolor="light"
                                    type="Paragraph"
                                  >
                                    {`${parentData?.weight}${parentData?.weight_unit}`}
                                  </TextStyles>
                                </FlexLayout>
                              ) : (
                                <></>
                              )}
                            </FlexLayout>
                          )}
                        </FlexLayout>
                      </FlexChild>
                    </FlexLayout>
                  </Card>

                  {VariantData.length && type === "variation" ? (
                    <Card cardType="Bordered">
                      <Card cardType="Plain" title={"Product Variant"}>
                        <Grid
                          columns={VariantColumns}
                          dataSource={VariantData}
                          tableLayout="fixed"
                          scrollX={600}
                        />
                      </Card>
                    </Card>
                  ) : (
                    <></>
                  )}
                </>
              </FlexChild>
              {/* right Part */}
              <FlexChild desktopWidth="33" tabWidth="100" mobileWidth="100">
                <FlexLayout spacing="loose" valign="start">
                  <FlexChild desktopWidth="100" tabWidth="50" mobileWidth="100">
                    <Card
                      cardType="Bordered"
                      title={"Product Details"}
                      extraClass="header-pb-16"
                    >
                      <FlexLayout
                        direction="vertical"
                        spacing="loose"
                        wrap="noWrap"
                      >
                        {parentData?.categories?.length ? (
                          <hr className="custom-hr m-0" />
                        ) : null}
                        {parentData?.categories?.length ? (
                          <FlexLayout
                            direction="vertical"
                            spacing="tight"
                            wrap="noWrap"
                          >
                            <TextStyles
                              fontweight="bold"
                              lineHeight="LH-2.0"
                              paragraphTypes="MD-1.4"
                              textcolor="dark"
                              type="Paragraph"
                            >
                              Category
                            </TextStyles>
                            <FlexLayout spacing="tight">
                              {parentData?.categories?.map(
                                (category, index) => {
                                  return <Tag key={category}>{category}</Tag>;
                                }
                              )}
                            </FlexLayout>
                          </FlexLayout>
                        ) : null}
                        {parentData?.brand ? (
                          <hr className="custom-hr m-0" />
                        ) : null}
                        {parentData?.brand ? (
                          <FlexLayout
                            direction="vertical"
                            spacing="tight"
                            wrap="noWrap"
                          >
                            <TextStyles
                              fontweight="bold"
                              lineHeight="LH-2.0"
                              paragraphTypes="MD-1.4"
                              textcolor="dark"
                              type="Paragraph"
                            >
                              Brand
                            </TextStyles>

                            <Tag>{parentData?.brand}</Tag>
                          </FlexLayout>
                        ) : null}
                        {parentData?.tags?.length ? (
                          <hr className="custom-hr m-0" />
                        ) : null}
                        {parentData?.tags?.length ? (
                          <FlexLayout
                            direction="vertical"
                            spacing="tight"
                            wrap="noWrap"
                          >
                            <TextStyles
                              fontweight="bold"
                              lineHeight="LH-2.0"
                              paragraphTypes="MD-1.4"
                              textcolor="dark"
                              type="Paragraph"
                            >
                              Tags
                            </TextStyles>
                            <FlexLayout spacing="tight">
                              {parentData?.tags?.map((tag) => {
                                return <Tag key={tag}>{tag}</Tag>;
                              })}
                            </FlexLayout>
                          </FlexLayout>
                        ) : null}
                        {variantData.length ? (
                          <hr className="custom-hr m-0" />
                        ) : null}
                        {variantData.length ? (
                          <FlexLayout
                            direction="vertical"
                            spacing="tight"
                            wrap="noWrap"
                          >
                            <TextStyles
                              fontweight="bold"
                              lineHeight="LH-2.0"
                              paragraphTypes="MD-1.4"
                              textcolor="dark"
                              type="Paragraph"
                            >
                              Quantity
                            </TextStyles>

                            <Tag>{totalVariantQuantity}</Tag>
                          </FlexLayout>
                        ) : null}
                        {parentData?.product_type && (
                          <hr className="custom-hr m-0" />
                        )}
                        {type === "simple" &&
                        parentData.hasOwnProperty("inventory_tracked") ? (
                          <FlexLayout
                            direction="vertical"
                            spacing="tight"
                            wrap="noWrap"
                          >
                            <TextStyles
                              fontweight="bold"
                              lineHeight="LH-2.0"
                              paragraphTypes="MD-1.4"
                              textcolor="dark"
                              type="Paragraph"
                            >
                              Inventory Strategy
                            </TextStyles>
                            <FlexLayout
                              direction="vertical"
                              spacing="extraTight"
                            >
                              <div className="active-status tick-icon">
                                <FlexLayout
                                  spacing="mediumTight"
                                  wrap="noWrap"
                                  valign="start"
                                >
                                  {parentData.inventory_tracked
                                    ? ActiveTick
                                    : UnactiveTick}
                                  <TextStyles
                                    lineHeight="LH-2.0"
                                    paragraphTypes="MD-1.4"
                                    textcolor="light"
                                    type="Paragraph"
                                  >
                                    Track Quantity
                                  </TextStyles>
                                </FlexLayout>
                              </div>
                            </FlexLayout>
                            <hr className="custom-hr m-0" />
                          </FlexLayout>
                        ) : null}
                        {parentData?.product_type ? (
                          <FlexLayout
                            direction="vertical"
                            spacing="tight"
                            wrap="noWrap"
                          >
                            <TextStyles
                              fontweight="bold"
                              lineHeight="LH-2.0"
                              paragraphTypes="MD-1.4"
                              textcolor="dark"
                              type="Paragraph"
                            >
                              Product Type
                            </TextStyles>
                            <FlexLayout spacing="tight">
                              <Tag>{parentData?.product_type}</Tag>
                            </FlexLayout>
                          </FlexLayout>
                        ) : null}
                        {parentData?.vendor?.length && (
                          <hr className="custom-hr m-0" />
                        )}
                        {parentData?.vendor?.length ? (
                          <FlexLayout
                            direction="vertical"
                            spacing="tight"
                            wrap="noWrap"
                          >
                            <TextStyles
                              fontweight="bold"
                              lineHeight="LH-2.0"
                              paragraphTypes="MD-1.4"
                              textcolor="dark"
                              type="Paragraph"
                            >
                              Vendor
                            </TextStyles>
                            <FlexLayout spacing="tight">
                              {parentData?.vendor?.map((vendor, index) => {
                                return <Tag key={vendor}>{vendor}</Tag>;
                              })}
                            </FlexLayout>
                          </FlexLayout>
                        ) : null}
                      </FlexLayout>
                    </Card>
                  </FlexChild>
                </FlexLayout>
              </FlexChild>
            </FlexLayout>
          </Card>
        </>
      )}
    </>
  );
};

export default ViewProducts;
