import { FlexLayout, TextStyles } from "@cedcommerce-integration/ounce-ui";
import CedLogo from "../../../assets/icons/cedlogo.svg";

const OnboardingHeader = () => {
  return (
    <FlexLayout spacing="loose" wrap="noWrap">
      <div className="ced-logo">
        <img src={CedLogo} alt="cedcommerce logo" />
      </div>
      <FlexLayout direction="vertical" spacing="mediumTight" wrap="noWrap">
        <FlexLayout direction="vertical" wrap="noWrap">
        <TextStyles type="Heading" headingTypes="LG-2.8" lineHeight="LH-3.6" fontweight="extraBold" utility="max-w">
            Welcome to CedCommerce eBay Integration
          </TextStyles>
        </FlexLayout>
        <TextStyles
         lineHeight="LH-2.4"
         subheadingTypes="XS-1.6"
         textcolor="light"
         type="SubHeading"
        >
          Thank you for choosing us! Enjoy a frictionless multichannel
          experience with our enterprise-grade features. Hope you have a great
          time selling with our multichannel app!
        </TextStyles>
      </FlexLayout>
    </FlexLayout>
  );
};

export default OnboardingHeader;
