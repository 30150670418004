/* eslint-disable react-hooks/exhaustive-deps */
import {
  Alert,
  AutoComplete,
  Button,
  Card,
  FlexChild,
  FlexLayout,
  List,
  Loader,
  LRLayout,
  PageHeader,
  Select,
  Tag,
  TextField,
  TextStyles,
  Toast,
  ToastWrapper,
} from "@cedcommerce-integration/ounce-ui";
import { RequiredIcon } from "./components/IconSvg";
import { Trash2 } from "react-feather";
import {
  getAllConnected,
  getAttributesByProductQuery,
  getAttributesCategoryWise,
  getCategoriesApi,
  getCategoryFeatures,
  getCategoryPrediction,
  getEditTemplate,
  getParentCategories,
  getPoliciesApi1,
  getSavedTemplate,
} from "../../../../Apirequest/templatesApi";
import React, { useCallback, useEffect, useRef, useState } from "react";
import RuleGroup from "./components/RuleGroup";
import { site_id } from "../../../../constants/constant";
import { globalState } from "../../../../services/globalState";
import AdditionalInformation from "./AdditionalInformation";
import ProductCondition from "./components/ProductCondition";
import queryString from "query-string";
import { withRouter } from "react-router-dom";
import { postMethod } from "../../../../apiMethods/methods";
import {
  TemplateNameSkeleton,
  PolicySkeleton,
  ListingCategorySkeleton,
} from "../Skeleton/CategoryAddEditSkeleton";
import { useDebounce } from "../../../shared/DebounceComponent";
import { validateProfileAPI } from "../../../../APIs/PanelAPI";

function CreateTemplate(props) {
  const [finalLoading, setFinalLoading] = useState(false);
  // STATE VARIABLES START
  const [templateName, setTemplateName] = useState("");
  const [categoryId, setCategoryId] = useState("");
  const [shopID, setShopID] = useState(
    globalState.getLocalStorage("ebayShopId")
  );
  const [ebayWarehouseId, setEbayWarehouseId] = useState("");
  const [shoplineShopId, setShoplineShopId] = useState(
    globalState.getLocalStorage("shopLineShopId")
  );
  const [shoplineWarehouseId, setShoplineWarehouseId] = useState("");
  const [categoryMapping, setCategoryMapping] = useState([]);
  // category name
  const [categoryInput, setCategoryInput] = useState("");
  const [categoryTag, setCategoryTag] = useState("");
  const [editCategoryInput, setEditCategoryInput] = useState("");

  const [barcodeOptions, setBarcodeOptions] = useState([]);
  const [categoryFeatureOptions, setCategoryFeatureOptions] = useState([]);
  const [shoplineAttributes, setShoplineAttributes] = useState([]);
  const [requiredAttributesMapping, setRequiredAttributesMapping] = useState({
    mapping: [],
    counter: 0,
    options: [],
  });
  const [optionalAttributesMapping, setOptionalAttributesMapping] = useState({
    mapping: [],
    counter: 0,
    options: [],
  });

  const deselectedOptions = [];
  const [loaderOverlayActive, setLoaderOverlayActive] = useState(false);
  const [
    primaryCategorySearchPredictionOptions,
    setPrimaryCategorySearchPredictionOptions,
  ] = useState(deselectedOptions);
  //  policies
  const [policyOptions, setPolicyOptions] = useState({});
  const [shippingValue, setShippingValue] = useState("");
  const [paymentValue, setPaymentValue] = useState("");
  const [returnValue, setReturnValue] = useState("");

  // refresh
  const [refresh, setRefresh] = useState(false);
  //query
  const [query, setQuery] = useState("");
  // condition - or/any
  const [condition, setCondition] = useState("or");
  const [sentenceQuery, setSentenceQuery] = useState("");
  const [filtersArray, setFiltersArray] = useState([
    [{ attribute: "", condition: "", value: "" }],
  ]);
  // product condition
  const [productCondition, setProductCondition] = useState("");
  //rule group override listing
  const [override, setOverride] = useState(false);

  const [Id, setId] = useState("");
  const [editId, setEditId] = useState({});
  // loader
  const [saveBtnLoader, setSaveBtnLoader] = useState(false);

  // toasts
  const [errorToast, setErrorToast] = useState({ active: false, content: "" });
  const [successToast, setSuccessToast] = useState({
    active: false,
    content: "",
  });
  const [saveErrorToast, setSaveErrorToast] = useState(false);
  //error
  const [validationErrors, setValidationErrors] = useState({
    templateNameValidation: [false],
    policyValidation: new Array(3).fill(false),
    productConditionValidation: [false],
    optionalValidation: [],
    requiredValidation: [],
    ruleGroupValidation: [],
    categoryValidation: new Array(6).fill(false),
  });
  const [runQueryCountDisableSave, setrunQueryCountDisableSave] =
    useState(false);
  //skeleton states
  const [nameSkeleton, setNameSkeleton] = useState(false);
  const [polciySkeleton, setPolicySkeleton] = useState(true);
  const [listingCategorySkeleton, setListingCatgorySkeleton] = useState(true);

  const val = useDebounce(categoryInput, setCategoryInput);

  const toggleErrorActive = useCallback(() => {
    setErrorToast((obj) => ({ active: !obj.active, content: "" }));
  }, []);

  const toggleSuccessActive = useCallback(() => {
    setSuccessToast((obj) => ({ active: !obj.active, content: "" }));
  }, []);

  // error scroll
  const templateRef = useRef({});
  const [refName, setRefName] = useState("");

  useEffect(() => {
    if (successToast.active) {
      setTimeout(() => {
        props.history.push("/panel/category-template");
      }, 2000);
    }
  }, [successToast.active]);

  const toastMarkup = successToast.active ? (
    <Toast
      message={successToast.content}
      onDismiss={toggleSuccessActive}
      timeout={2000}
      type="success"
    />
  ) : errorToast.active ? (
    <Toast
      message={errorToast.content}
      onDismiss={toggleErrorActive}
      timeout={2000}
      type="error"
    />
  ) : null;

  const savetoastMarkup = saveErrorToast ? (
    <Toast
      message={"Please fill all the required fields."}
      onDismiss={() => setSaveErrorToast(false)}
      timeout={3000}
      type="error"
    />
  ) : null;

  const getExtractedDataForCategory = (categoryData) => {
    let temp = [];
    temp = categoryData.map((data) => {
      return {
        label: data?.name,
        value: data?.marketplace_id,
        level: data?.level,
        choices: [],
        default_condition: "",
      };
    });
    return temp;
  };

  const extractBarcodeCategoryOptions = (dataCategoryFeatures) => {
    let barcode_options = [];
    let categoryFeature_options = [];
    if (dataCategoryFeatures) {
      Object.keys(dataCategoryFeatures).forEach((key) => {
        switch (key) {
          case "BestOfferEnabled":
            if (dataCategoryFeatures[key]) {
              barcode_options.push("BestOfferEnabled");
            }
            break;
          case "VariationsEnabled":
            if (dataCategoryFeatures[key]) {
              barcode_options.push("VariationsEnabled");
            }
            break;
          case "ISBNEnabled":
            barcode_options.push("ISBNEnabled");
            break;
          case "UPCEnabled":
            barcode_options.push("UPCEnabled");
            break;
          case "EANEnabled":
            barcode_options.push("EANEnabled");
            break;
          case "ConditionEnabled":
            if (
              dataCategoryFeatures[key] === "Required" ||
              dataCategoryFeatures[key] === "Enabled"
            ) {
              if (
                dataCategoryFeatures?.["ConditionValues"] &&
                dataCategoryFeatures?.["ConditionValues"]?.["Condition"]
              )
                dataCategoryFeatures["ConditionValues"]["Condition"].forEach(
                  (value, index) => {
                    categoryFeature_options.push({
                      label: value.DisplayName,
                      value: value.ID.toString(),
                    });
                  }
                );
            }
            break;
          default:
            return;
        }
      });
    }
    setBarcodeOptions(barcode_options);
    setCategoryFeatureOptions(categoryFeature_options);
  };

  const extractEbayRecommendedAttributes = (dataObj) => {
    let ebayAttributes = Object.keys(dataObj).map((e) => {
      return {
        label: dataObj[e],
        value: e,
      };
    });
    return ebayAttributes;
  };

  const getExtractedDataForAttribute = (
    attributeData,
    shopifyAttrOptions = []
  ) => {
    let requiredAttributes = [];
    let optionalAttributes = [];
    attributeData.forEach((data) => {
      let tempObj = {
        label: data?.name,
        value: data?.name,
        variation: data?.variation,
        ebayRecommendedAttributesOptions: extractEbayRecommendedAttributes(
          data?.values
        ),
        shoplineAttributesOptions: shoplineAttributes.length
          ? shoplineAttributes
          : shopifyAttrOptions,
        optionsCheck: [
          {
            label: "Set eBay Recommended Attributes",
            value: "EbayRecommendedAttributes",
          },
          {
            label: "Set Shopline Attributes",
            value: "ShoplineAttributes",
          },
          {
            label: "Set Custom",
            value: "Custom",
          },
        ],
        selectedShoplineAttributeValueType: "",
        selectedShoplineAttributeValue: "",
      };
      if (data?.required) {
        tempObj["attributeNumber"] = data?.sort_order;
        requiredAttributes.push(tempObj);
      } else {
        optionalAttributes.push(tempObj);
      }
    });
    return { requiredAttributes, optionalAttributes };
  };

  const policyApi = async () => {
    const time1 = new Date();
    let ms1 = time1.getTime();
    setPolicySkeleton(true);
    let temp = {};
    let shippingPolicy = [];
    let paymentPolicy = [];
    let returnPolicy = [];
    let {
      success: successShipping,
      data: fetchedPoliciesArrayShipping,
    } = await getPoliciesApi1({ policy: "shipping", shop_id: shopID });
    if (successShipping) {
      fetchedPoliciesArrayShipping.forEach((policy) => {
        shippingPolicy.push({
          label: policy?.["title"],
          value: policy?.["data"]?.["profileId"].toString(),
        });
      });
    }
    let {
      success: successPayment,
      data: fetchedPoliciesArrayPayment,
    } = await getPoliciesApi1({ policy: "payment", shop_id: shopID });

    if (successPayment) {
      fetchedPoliciesArrayPayment.forEach((policy) => {
        paymentPolicy.push({
          label: policy?.["title"],
          value: policy?.["data"]?.["profileId"].toString(),
        });
      });
    }
    let {
      success: successReturn,
      data: fetchedPoliciesArrayReturn,
      code,
    } = await getPoliciesApi1({ policy: "return", shop_id: shopID });
    const time2 = new Date();
    let ms2 = time2.getTime();
    let timeOut = ms2 - ms1;
    if (successReturn) {
      fetchedPoliciesArrayReturn.forEach((policy) => {
        returnPolicy.push({
          label: policy?.["title"],
          value: policy?.["data"]?.["profileId"].toString(),
        });
      });
    } else if (
        code === "token_expired" ||
        code === "invalid_token" ||
        code === "future_token" ||
        code === "token_decode_error"
      ) {
        props.history.push("/tokenexpired");
      }
    
    temp["shipping_policy"] = shippingPolicy;
    temp["payment_policy"] = paymentPolicy;
    temp["return_policy"] = returnPolicy;
    setPolicyOptions(temp);
    if (timeOut < 500) {
      setTimeout(() => {
        setPolicySkeleton(false);
      }, 500 - timeOut);
    } else setPolicySkeleton(false);
  };
  // get categories
  const getCategory = async (
    requestObj,
    categoryMapping,
    setCategoryMapping
  ) => {
    const time1 = new Date();
    let ms1 = time1.getTime();
    let timeOut = 0;
    setListingCatgorySkeleton(true);
    let {
      success: successMappingCategorywise,
      data: mappingCategorywise,
      code,
    } = await getCategoriesApi({ ...requestObj, shop_id: shopID });
    const time2 = new Date();
    let ms2 = time2.getTime();
    timeOut = ms2 - ms1;
    if (successMappingCategorywise) {
      if (
        Array.isArray(mappingCategorywise) &&
        mappingCategorywise.length > 0
      ) {
        let extractedData = getExtractedDataForCategory(mappingCategorywise);
        let temp = [...categoryMapping];
        temp.push({
          options: extractedData,
          label: `Category Level ${extractedData[0]["level"]}`,
          value: "",
          levelNumber: extractedData[0]["level"],
        });
        setCategoryMapping(temp);
      }
      if (
        Array.isArray(mappingCategorywise) &&
        mappingCategorywise.length === 0
      ) {
        const temp = [...categoryMapping];
        const lastLevel = temp[temp.length - 1];
        const lastLevelValue = lastLevel?.["value"];
        setCategoryId(lastLevelValue);
        let {
          success,
          data: featuresData,
          code,
        } = await getCategoryFeatures({
          category_id: lastLevelValue,
          shop_id: shopID,
        });
        if (success) {
          featuresData?.[0] && extractBarcodeCategoryOptions(featuresData?.[0]);
        } else if (
            code === "token_expired" ||
            code === "invalid_token" ||
            code === "future_token" ||
            code === "token_decode_error"
          ) {
            props.history.push("/tokenexpired");
          }
        let {
          success: successattribCategory,
          data: attributeCategoryData,
          code: attributesCode,
        } = await getAttributesCategoryWise({
          category_id: lastLevelValue,
          shop_id: shopID,
        });
        const time3 = new Date();
        let ms3 = time3.getTime();
        timeOut = ms3 - ms1;
        if (
          successattribCategory &&
          Array.isArray(attributeCategoryData) &&
          attributeCategoryData.length > 0
        ) {
          let extractData = getExtractedDataForAttribute(attributeCategoryData);
          let requiredArr = [];
          extractData.requiredAttributes.forEach((attribute) => {
            let tempObject = {
              eBayAttribute: attribute.value,
              shoplineAttribute: "",
              selectedShoplineAttributeValueType: "",
            };
            requiredArr.push(tempObject);
          });
          setRequiredAttributesMapping({
            ...requiredAttributesMapping,
            mapping: requiredArr,
            options: extractData?.requiredAttributes,
          });
          setOptionalAttributesMapping({
            ...optionalAttributesMapping,
            options: extractData?.optionalAttributes,
          });
        } else if (attributesCode) {
          if (
            attributesCode === "token_expired" ||
            attributesCode === "invalid_token" ||
            attributesCode === "future_token" ||
            attributesCode === "token_decode_error"
          ) {
            props.history.push("/tokenexpired");
          }
        } else {
          setRequiredAttributesMapping({
            mapping: [],
            options: [],
          });
          setOptionalAttributesMapping({
            mapping: [],
            options: [],
          });
        }
      }
    } else if (
        code === "token_expired" ||
        code === "invalid_token" ||
        code === "future_token" ||
        code === "token_decode_error"
      ) {
        props.history.push("/tokenexpired");
      }
    if (timeOut < 500) {
      setTimeout(() => {
        setListingCatgorySkeleton(false);
      }, 500 - timeOut);
    } else setListingCatgorySkeleton(false);
  };
  //    get shopline attributes
  const getShoplineAttributes = async () => {
    let {
      success,
      data: shoplineAttributeData,
      code,
    } = await getAttributesByProductQuery({
      marketplace: "shopify",
      query: "(price>-1)",
    });
    if (success) {
      let temp = [];
      temp = shoplineAttributeData.map((attribute) => {
        return {
          label: attribute?.title,
          value: attribute?.code,
        };
      });
      setShoplineAttributes(temp);
    } else if (
        code === "token_expired" ||
        code === "invalid_token" ||
        code === "future_token" ||
        code === "token_decode_error"
      ) {
        props.history.push("/tokenexpired");
      }
  };

  const hitPredictionAPI = async (
    passedObj,
    setPrimaryCategorySearchPredictionOptions
  ) => {
    let { success, data, code } = await getCategoryPrediction(
      passedObj
    );
    if (success) {
      let temp = data.map((suggestion) => {
        let returnedObj = {};
        returnedObj.value = suggestion.path;
        returnedObj.label = suggestion.path;
        returnedObj.marketplace_id = suggestion.marketplace_id;
        return returnedObj;
      });
      setPrimaryCategorySearchPredictionOptions(temp);
    } else if (
        code === "token_expired" ||
        code === "invalid_token" ||
        code === "future_token" ||
        code === "token_decode_error"
      ) {
        props.history.push("/tokenexpired");
      }
  };
  const getAllconnectedAccounts = async () => {
    let { success, data, code } = await getAllConnected();
    if (success) {
      setShopID(data?.ebay?.installed[0]?.["_id"]);
      setEbayWarehouseId(data?.ebay?.installed[0]?.warehouses[0]?.["_id"]);
      setShoplineShopId(data?.shopline?.installed[0]?.["_id"]);
      setShoplineWarehouseId(
        data?.shopline?.installed[0]?.warehouses[0]?.["_id"]
      );
    } else if (
        code === "token_expired" ||
        code === "invalid_token" ||
        code === "future_token" ||
        code === "token_decode_error"
      ) {
        props.history.push("/tokenexpired");
      }
  };

  const getParams = () => {
    let { id } = queryString.parse(props.location.search);
    if (id) {
      setId(id);
    }
  };

  useEffect(() => {
    setFinalLoading(true);
    getAllconnectedAccounts();
    getParams();
  }, []);

  useEffect(() => {
    if (Id === "") {
      getCategory({ level: 1 }, categoryMapping, setCategoryMapping);
      getShoplineAttributes();
    }
    policyApi();
  }, [Id]);

  const verify = useCallback(
    (value) => {
      let passedObj = {
        site_id: site_id,
        shop_id: shopID,
      };
      if (value.includes(">>")) {
        passedObj = {
          ...passedObj,
          ...{
            path: value,
          },
        };
      } else {
        passedObj = {
          ...passedObj,
          ...{
            category_name: value,
          },
        };
      }
      hitPredictionAPI(passedObj, setPrimaryCategorySearchPredictionOptions);
    },
    [shopID]
  );
  useEffect(() => {
    verify(categoryInput);
  }, [val]);

  const hitGetParentCategoriesFromPrediction = async (
    selectedPrediction,
    categoryMapping,
    setCategoryMapping
  ) => {
    let passedObj = {
      category_id: selectedPrediction.marketplace_id,
      site_id: site_id,
      shop_id: shopID,
    };
    let { success, data, code } = await getParentCategories(passedObj);
    if (success) {
      let tempMapping = [...categoryMapping];
      setBarcodeOptions([]);
      setCategoryFeatureOptions([]);

      tempMapping.splice(1);
      data.forEach((dataLevel, index) => {
        if (!tempMapping[index]) {
          tempMapping[index] = {};
          let extractData = getExtractedDataForCategory(
            dataLevel?.same_level_categories
          );
          tempMapping[index]["options"] = extractData;
        }
        tempMapping[index]["label"] = `Category Level ${index + 1}`;
        tempMapping[index]["value"] = dataLevel?.category?.marketplace_id;
        tempMapping[index]["levelNumber"] = `${index + 1}`;
      });
      let lastLevel = data.at(data.length - 1)?.category?.marketplace_id;
      getCategory(
        {
          parent_category_id: lastLevel,
        },
        tempMapping,
        setCategoryMapping
      );
      setCategoryMapping(tempMapping);
    } else if (
        code === "token_expired" ||
        code === "invalid_token" ||
        code === "future_token" ||
        code === "token_decode_error"
      ) {
        props.history.push("/tokenexpired");
      }
  };
  const removeTag = () => {
    setCategoryMapping([]);
    setCategoryInput("");
    setCategoryTag("");
    getCategory({ level: 1 }, [], setCategoryMapping);
  };
  const renderCategorySearchPrediction = () => {
    return (
      <FlexLayout direction="vertical" spacing="mediumTight" wrap="noWrap">
        <span ref={(e) => (templateRef.current["category"] = e)}>
          <AutoComplete
           extraClass="ced-autocomplete"
            clearButton
            clearFunction={() => setCategoryInput("")}
            onChange={(value) => {
              setCategoryInput(value);
            }}
            onClick={(e) => {
              const selectedValue = primaryCategorySearchPredictionOptions.find(
                (option) => option.value === e
              );
              hitGetParentCategoriesFromPrediction(
                selectedValue,
                categoryMapping,
                setCategoryMapping
              );
              setCategoryInput("");
            }}
            onEnter={function noRefCheck() {}}
            options={primaryCategorySearchPredictionOptions}
            placeHolder="Search Category"
            value={categoryInput}
          />
        </span>
        {categoryTag ? (
          <Tag
            destroy={() => {
              removeTag();
            }}
          >
            {categoryTag}
          </Tag>
        ) : (
          <></>
        )}
      </FlexLayout>
    );
  };

  const renderCategoryMapping = () => {
    let structuredMapping = [];
    if (categoryMapping.length) {
      structuredMapping = (
        <FlexLayout
          desktopWidth="100"
          mobileWidth="100"
          tabWidth="100"
          spacing="loose"
        >
          {categoryMapping.map((level, index) => {
            return (
              <Select
                error={
                  level?.value
                    ? false
                    : validationErrors["categoryValidation"][index]
                }
                key={`Category-${level?.levelNumber}`}
                name={level?.label}
                value={level?.value}
                options={level?.options}
                onChange={(e) => {
                  let tempMapping = [...categoryMapping];
                  tempMapping.splice(index + 1);
                  tempMapping[index]["value"] = e;
                  if (tempMapping[index]["value"]) {
                    getCategory(
                      {
                        parent_category_id: tempMapping?.[index]?.["value"],
                      },
                      tempMapping,
                      setCategoryMapping
                    );
                    setCategoryMapping(tempMapping);
                  }
                  let { categoryValidation } = validationErrors;
                  categoryValidation[index] = false;
                }}
                required
              />
            );
          })}
        </FlexLayout>
      );
    }
    return structuredMapping;
  };

  const renderRequiredAttributeMappingStructure = () => {
    let temp = { ...requiredAttributesMapping };
    let structure = [];
    structure = requiredAttributesMapping["options"].map(
      (mappedObject, index) => {
        return (
          <FlexLayout direction="vertical" spacing="tight" wrap="noWrap" key={mappedObject?.attributeNumber}>
            <FlexLayout
              desktopWidth="50"
              tabWidth="50"
              mobileWidth="50"
              valign="center"
            >
              <FlexLayout spacing="tight" valign="center" wrap="noWrap">
                <div className="LH-0">{RequiredIcon}</div>
                <TextStyles
                  type="Paragraph"
                  paragraphTypes="MD-1.4"
                  lineHeight="LH-2.0"
                >
                  {mappedObject.value}
                </TextStyles>
              </FlexLayout>

              <Select
                error={
                  validationErrors["requiredValidation"]?.[index]?.[
                    "attributeValue"
                  ]
                }
                placeholder="Please Select"
                options={mappedObject?.optionsCheck}
                value={
                  temp["mapping"][index]["selectedShoplineAttributeValueType"]
                }
                onChange={(e) => {
                  temp["mapping"][index]["selectedShoplineAttributeValueType"] =
                    e;
                  setRequiredAttributesMapping(temp);
                  let { requiredValidation } = validationErrors;
                  if (requiredValidation.length) {
                    requiredValidation[index]["attributeValue"] = false;
                  }
                }}
              />
            </FlexLayout>
            {requiredAttributesMapping["mapping"][index][
              "selectedShoplineAttributeValueType"
            ] ? (
              <FlexLayout
                desktopWidth="50"
                tabWidth="50"
                mobileWidth="50"
                halign="end"
              >
                {requiredAttributesMapping["mapping"][index][
                  "selectedShoplineAttributeValueType"
                ] === "EbayRecommendedAttributes" ? (
                  <Select
                    error={
                      validationErrors["requiredValidation"]?.[index]?.[
                        "shoplineAttribute"
                      ]
                    }
                    placeholder="Please Select"
                    options={mappedObject?.ebayRecommendedAttributesOptions}
                    value={temp["mapping"][index]["shoplineAttribute"]}
                    onChange={(e) => {
                      temp["mapping"][index]["shoplineAttribute"] = e;
                      setRequiredAttributesMapping(temp);
                      let { requiredValidation } = validationErrors;
                      if (requiredValidation.length) {
                        requiredValidation[index]["shoplineAttribute"] = false;
                      }
                    }}
                  />
                ) : requiredAttributesMapping["mapping"][index][
                    "selectedShoplineAttributeValueType"
                  ] === "ShoplineAttributes" ? (
                  <Select
                    error={
                      validationErrors["requiredValidation"]?.[index]?.[
                        "shoplineAttribute"
                      ]
                    }
                    placeholder="Please Select"
                    options={mappedObject?.shoplineAttributesOptions}
                    value={temp["mapping"][index]["shoplineAttribute"]}
                    onChange={(e) => {
                      temp["mapping"][index]["shoplineAttribute"] = e;
                      setRequiredAttributesMapping(temp);
                      let { requiredValidation } = validationErrors;
                      if (requiredValidation.length) {
                        requiredValidation[index]["shoplineAttribute"] = false;
                      }
                    }}
                  />
                ) : (
                  requiredAttributesMapping["mapping"][index][
                    "selectedShoplineAttributeValueType"
                  ] === "Custom" && (
                    <TextField
                      error={
                        validationErrors["requiredValidation"]?.[index]?.[
                          "shoplineAttribute"
                        ]
                      }
                      id={`customValue-${index}`}
                      placeHolder="Enter Custom Attributes"
                      value={temp["mapping"][index]["shoplineAttribute"]}
                      onChange={(e) => {
                        let temp = { ...requiredAttributesMapping };
                        temp["mapping"][index]["shoplineAttribute"] = e;
                        setRequiredAttributesMapping(temp);
                        let { requiredValidation } = validationErrors;
                        if (requiredValidation.length) {
                          requiredValidation[index][
                            "shoplineAttribute"
                          ] = false;
                        }
                      }}
                    />
                  )
                )}
              </FlexLayout>
            ) : null}
          </FlexLayout>
        );
      }
    );
    return structure;
  };

  const renderOptionalAttributeMappingStructure = () => {
    let temp = { ...optionalAttributesMapping };
    return (
      <FlexLayout direction="vertical" wrap="noWrap" spacing="loose">
        {temp["mapping"].length === 0 ? (
          <Alert type="info" destroy={false}>
            <p className="inte__text--dark">
              Optional attributes can be added by clicking{" "}
              <span className="inte__font--extraBold"> 'Add Attributes'</span>{" "}
              above.
            </p>
          </Alert>
        ) : (
          temp["mapping"].map((mappedObject, index) => {
            let filteredObj = optionalAttributesMapping["options"].find(
              (item) =>
                optionalAttributesMapping["mapping"][index]["eBayAttribute"] ===
                item.value
            );
            return (
              <FlexLayout direction="vertical" spacing="tight" key={`optional-${index}`}>
                <FlexLayout spacing="tight" valign="start">
                  <FlexChild childWidth="fullWidth">
                    <FlexLayout
                      spacing="tight"
                      desktopWidth="50"
                      tabWidth="50"
                      mobileWidth="50"
                    >
                      <Select
                        onChange={(e) => {
                          let temp = { ...optionalAttributesMapping };
                          temp["mapping"][index][
                            "selectedShoplineAttributeValueType"
                          ] = "";
                          temp["mapping"][index]["shoplineAttribute"] = "";
                          temp["mapping"][index]["eBayAttribute"] = e;

                          setOptionalAttributesMapping(temp);
                        }}
                        placeholder="Please Select..."
                        value={temp["mapping"][index]["eBayAttribute"]}
                        options={temp["options"]}
                        required
                        searchEable
                      />
                      <FlexLayout
                        direction="vertical"
                        spacing="tight"
                        wrap="noWrap"
                      >
                        <Select
                          disabled={
                            temp["mapping"][index]["eBayAttribute"] === ""
                          }
                          value={
                            temp["mapping"][index][
                              "selectedShoplineAttributeValueType"
                            ]
                          }
                          options={filteredObj?.["optionsCheck"]}
                          onChange={(e) => {
                            let temp = { ...optionalAttributesMapping };
                            temp["mapping"][index]["shoplineAttribute"] = "";
                            temp["mapping"][index][
                              "selectedShoplineAttributeValueType"
                            ] = e;
                            setOptionalAttributesMapping(temp);
                          }}
                          placeholder="Please Select..."
                          required
                        />
                        {optionalAttributesMapping["mapping"][index][
                          "selectedShoplineAttributeValueType"
                        ] === "EbayRecommendedAttributes" ? (
                          <Select
                            disabled={
                              temp["mapping"][index]["eBayAttribute"] === ""
                            }
                            value={temp["mapping"][index]["shoplineAttribute"]}
                            options={
                              filteredObj?.["ebayRecommendedAttributesOptions"]
                            }
                            onChange={(e) => {
                              let temp = { ...optionalAttributesMapping };
                              temp["mapping"][index]["shoplineAttribute"] = e;
                              setOptionalAttributesMapping(temp);
                            }}
                            placeholder="Please Select..."
                            required
                            searchEable
                          />
                        ) : optionalAttributesMapping["mapping"][index][
                            "selectedShoplineAttributeValueType"
                          ] === "ShoplineAttributes" ? (
                          <Select
                            disabled={
                              temp["mapping"][index]["eBayAttribute"] === ""
                            }
                            value={temp["mapping"][index]["shoplineAttribute"]}
                            options={filteredObj?.["shoplineAttributesOptions"]}
                            onChange={(e) => {
                              let temp = { ...optionalAttributesMapping };
                              temp["mapping"][index]["shoplineAttribute"] = e;
                              setOptionalAttributesMapping(temp);
                            }}
                            placeholder="Please Select..."
                            required
                            searchEable
                          />
                        ) : (
                          optionalAttributesMapping["mapping"][index][
                            "selectedShoplineAttributeValueType"
                          ] === "Custom" && (
                            <TextField
                              thickness="thin"
                              placeHolder="Enter Custom Attributes"
                              value={
                                temp["mapping"][index]["shoplineAttribute"]
                              }
                              onChange={(e) => {
                                let temp = { ...optionalAttributesMapping };
                                temp["mapping"][index]["shoplineAttribute"] = e;
                                setOptionalAttributesMapping(temp);
                              }}
                            />
                          )
                        )}
                      </FlexLayout>
                    </FlexLayout>
                  </FlexChild>
                  <Button
                    icon={<Trash2 />}
                    type="DangerOutlined"
                    thickness="thin"
                    onClick={() => removeOptionalAttribute(index)}
                  />
                </FlexLayout>
              </FlexLayout>
            );
          })
        )}
      </FlexLayout>
    );
  };

  const addOptionalAttribute = () => {
    let temp = { ...optionalAttributesMapping };
    let optionalArr = [];
    let tempObject = {
      eBayAttribute: "",
      shoplineAttribute: "",
      selectedShoplineAttributeValueType: "",
    };
    optionalArr.push(tempObject);
    temp["mapping"] = [...temp["mapping"], ...optionalArr];
    setOptionalAttributesMapping(temp);
  };

  const removeOptionalAttribute = (indexNum) => {
    let temp = { ...optionalAttributesMapping };
    temp["options"].forEach((options, index) => {
      if (options.value === temp["mapping"][indexNum].eBayAttribute)
        temp["options"][index].disabled = false;
    });
    temp["mapping"].splice(indexNum, 1);
    setOptionalAttributesMapping(temp);
  };
  const prepareData = () => {
    let Data = [];
    Data.push({
      prepareQuery: {
        query: query,
        querySentence: sentenceQuery,
        queryArray: filtersArray[0],
      },
      shipping_policy: shippingValue,
      payment_policy: paymentValue,
      return_policy: returnValue,
      storefront_category: "",
      selectedConfigurableAttributes: [],
      site_id: site_id,
      shop_id: shopID,
      name: templateName,
      primaryCategoryMappingName: categoryTag ?? editCategoryInput,
      primaryCategoryMapping: categoryMapping.map((category) => {
        return {
          value: category.value,
          levelNumber: category.levelNumber,
        };
      }),
      enableSecondaryCategory: false,
      secondaryCategoryMapping: [],
      bestofferenabled: barcodeOptions.indexOf("BestOfferEnabled") > -1,
      VariationsEnabled: barcodeOptions.indexOf("VariationsEnabled") > -1,
      ISBNEnabled: barcodeOptions.indexOf("ISBNEnabled") > -1,
      UPCEnabled: barcodeOptions.indexOf("UPCEnabled") > -1,
      EANEnabled: barcodeOptions.indexOf("EANEnabled") > -1,
      category_feature: productCondition,
    });
    return Data;
  };

  const prepareAttributesMapping = () => {
    let attributes_mapping = {};
    attributes_mapping["requiredAttributesMapping"] = requiredAttributesMapping[
      "mapping"
    ].map((attribute) => {
      return {
        eBayAttribute: attribute.eBayAttribute,
        selectedShoplineAttributeValueType:
          attribute.selectedShoplineAttributeValueType,
        shoplineAttribute: attribute.shoplineAttribute,
      };
    });
    attributes_mapping["optionalAttributesMapping"] = optionalAttributesMapping[
      "mapping"
    ].map((attribute) => {
      return {
        eBayAttribute: attribute.eBayAttribute,
        selectedShoplineAttributeValueType:
          attribute.selectedShoplineAttributeValueType,
        shoplineAttribute: attribute.shoplineAttribute,
      };
    });
    attributes_mapping.customAttributesMapping = [];
    return attributes_mapping;
  };

  const prepareTargets = (data, attributesMapping) => {
    let targets = [];
    targets.push({
      taregt_marketplace: "ebay",
      data: data,
      attributes_mapping: attributesMapping,
      shops: [
        {
          shop_id: shopID,
          active: 1,
          warehouses: [
            {
              warehouse_id: ebayWarehouseId,
              active: 1,
              data: data,
              attributes_mapping: attributesMapping,
              sources: [
                {
                  source_marketplace_name: "shopline",
                  data: data,
                  attributes_mapping: attributesMapping,
                  shops: [
                    {
                      shop_id: shoplineShopId,
                      active: 1,
                      warehouses: [
                        {
                          warehouse_id: shoplineWarehouseId,
                          active: 1,
                          Rule: "Not decided yet",
                          data: data,
                          attributes_mapping: attributesMapping,
                        },
                      ],
                    },
                  ],
                },
              ],
            },
          ],
        },
      ],
    });
    return targets;
  };

  const errorHandlingFunc = (payload) => {
    let returnedError;
    let validationObject = { ...validationErrors };
    let payloadData = { ...payload?.data?.data[0] };
    let tempRefName = "";
    if (!payloadData.name) {
      tempRefName = "name";
      validationObject["templateNameValidation"][0] = true;
      returnedError = true;
    }
    payloadData?.primaryCategoryMapping.forEach((attribute, index) => {
      if (!attribute.value) {
        validationObject["categoryValidation"][index] = true;
        returnedError = true;
        if (!tempRefName) tempRefName = "category";
      }
    });
    if (payload?.data?.attributes_mapping?.requiredAttributesMapping.length) {
      payload?.data?.attributes_mapping?.requiredAttributesMapping.forEach(
        (attribute, index) => {
          let attributeErrors = {};
          if (!attribute.selectedShoplineAttributeValueType) {
            attributeErrors["attributeValue"] = true;
          } else if (!attribute.shoplineAttribute) {
            attributeErrors["shoplineAttribute"] = true;
          }
          if (Object.keys(attributeErrors).length) {
            validationObject["requiredValidation"][index] = attributeErrors;
            returnedError = true;
            if (!tempRefName) tempRefName = "requiredAttributes";
          }
        }
      );
    }
    if (!payloadData?.payment_policy) {
      validationObject["policyValidation"][0] = true;
      returnedError = true;
    }
    if (!payloadData?.shipping_policy) {
      validationObject["policyValidation"][1] = true;
      returnedError = true;
    }
    if (!payloadData?.return_policy) {
      validationObject["policyValidation"][2] = true;
      returnedError = true;
    }
    if (
      !payloadData?.payment_policy ||
      !payloadData?.shipping_policy ||
      !payloadData?.return_policy
    ) {
      if (!tempRefName) tempRefName = "policy";
    }
    if (productCondition || categoryFeatureOptions.length) {
      if (!payloadData.category_feature) {
        validationObject["productConditionValidation"][0] = true;
        returnedError = true;
        if (!tempRefName) tempRefName = "productCondition";
      }
    }
    payloadData?.["prepareQuery"]?.["queryArray"].forEach((query, index) => {
      let attributeErrors = {};
      if (!query.attribute) {
        attributeErrors["attribute"] = true;
      } else {
        attributeErrors["attribute"] = false;
      }
      if (!query.condition) {
        attributeErrors["condition"] = true;
      } else {
        attributeErrors["condition"] = false;
      }
      if (!query.value) {
        attributeErrors["value"] = true;
      } else {
        attributeErrors["value"] = false;
      }
      if (Object.keys(attributeErrors).length) {
        validationObject["ruleGroupValidation"][index] = attributeErrors;
        for (let x in attributeErrors) {
          if (attributeErrors[x]) {
            if (!tempRefName) tempRefName = "ruleGroup";
            returnedError = true;
          }
        }
      }
    });
    setValidationErrors(validationObject);
    setRefName(tempRefName);
    return returnedError;
  };

  const parseMsg = (data) => {
    let msg = "";
    if (
      data?.["duplicate keys"] &&
      Array.isArray(data?.["duplicate keys"]) &&
      data?.["duplicate keys"].length > 0
    ) {
      msg = data?.["duplicate keys"].reduce(
        (acc, curr, index, arr) =>
          `${acc} ${curr} ${
            index !== arr.length - 1 ? (arr.length === 2 ? "& " : ", ") : ""
          }`,
        ""
      );
    }
    return `${msg} already exists for different template`;
  };

  const saveTemplate = async () => {
    if (runQueryCountDisableSave) {
      setErrorToast({
        active: true,
        content:
          "No products lie under the given conditions, Please try something different",
      });
    } else {
      setSaveBtnLoader(true);
      let Data = prepareData();
      let attributesMapping = prepareAttributesMapping();
      let targets = prepareTargets(Data, attributesMapping);
      let payload = {
        useRefinProduct: true,
        data: {
          name: templateName.trim(),
          query: query,
          category_id: categoryId,
          overWriteExistingProducts: override,
          data: Data,
          attributes_mapping: attributesMapping,
          targets: targets,
        },
      };
      let returnedError = errorHandlingFunc(payload);
      if (Id) {
        payload["data"]["_id"] = editId;
      }
      if (!returnedError) {
        const validateProfilePayload = {
          validate_on: {
            name: templateName.trim(),
            query: query.trim(),
          },
        };
        if (Id) {
          validateProfilePayload["profile_id"] = Id;
        }
        if (!query) {
          setRefName("ruleGroup");
          setErrorToast({
            active: true,
            content:
              "Kindly click on Run Query button to fetch affected products",
          });
        } else {
          let {
            success,
            data: templateData,
            code,
          } = await postMethod(validateProfileAPI, validateProfilePayload);
          if (success) {
            let {
              success: getSavedTemplateSuccess,
              message: getSavedTemplateMsg,
            } = await getSavedTemplate(payload);
            if (getSavedTemplateSuccess) {
              setSuccessToast({ active: true, content: getSavedTemplateMsg });
            }
          } else if (code) {
            if (
              code === "token_expired" ||
              code === "invalid_token" ||
              code === "future_token" ||
              code === "token_decode_error"
            ) {
              props.history.push("/tokenexpired");
            }
          } else {
            setErrorToast({ active: true, content: parseMsg(templateData) });
          }
        }
      } else {
        setSaveErrorToast(true);
      }
      setSaveBtnLoader(false);
    }
    setTimeout(() => {
      setRefName("");
    }, [200]);
  };

  const fillAllFields = (data) => {
    setTemplateName(data[0]?.name);
    setPaymentValue(data[0]?.payment_policy);
    setShippingValue(data[0]?.shipping_policy);
    setReturnValue(data[0]?.return_policy);
  };

  const RefreshAtrributes = async () => {
    setLoaderOverlayActive(true);
    setRefresh(true);
    setRequiredAttributesMapping({
      mapping: [],
      counter: 0,
      options: [],
    });
    setOptionalAttributesMapping({
      mapping: [],
      counter: 0,
      options: [],
    });
    const temp = [...categoryMapping];
    const lastLevel = temp[temp.length - 1];
    const lastLevelValue = lastLevel?.["value"];
    let {
      success: successattribCategorywise,
      data: attributeCategorywise,
      code,
    } = await getAttributesCategoryWise({
      category_id: lastLevelValue,
      shop_id: shopID,
    });
    setFinalLoading(false);
    if (
      successattribCategorywise &&
      Array.isArray(attributeCategorywise) &&
      attributeCategorywise.length > 0
    ) {
      setRefresh(false);
      let extractData = getExtractedDataForAttribute(attributeCategorywise);

      let createdArr = [];
      extractData.requiredAttributes.forEach((attribute) => {
        let tempObject = {
          eBayAttribute: attribute.value,
          shopifyAttribute: "",
          selectedShopifyAttributeValueType: "",
        };
        createdArr.push(tempObject);
      });
      setRequiredAttributesMapping({
        ...requiredAttributesMapping,
        mapping: createdArr,
        options: extractData?.requiredAttributes,
      });
      setOptionalAttributesMapping({
        ...optionalAttributesMapping,
        options: extractData?.optionalAttributes,
      });
    } else if (code) {
      if (
        code === "token_expired" ||
        code === "invalid_token" ||
        code === "future_token" ||
        code === "token_decode_error"
      ) {
        props.history.push("/tokenexpired");
      }
    } else {
      setRequiredAttributesMapping({
        mapping: [],
        counter: 0,
        options: [],
      });
      setOptionalAttributesMapping({
        mapping: [],
        counter: 0,
        options: [],
      });
    }
    setLoaderOverlayActive(false);
    setRefresh(false);
  };

  const populateAttributeMapping = async (
    attributeData,
    attributeTypeMapping,
    setAttributeTypeMapping,
    temp,
    shopifyAttrOptions,
    attributeType
  ) => {
    const lastLevel = { ...temp.at(temp.length - 1) };
    const lastLevelValue = lastLevel?.["value"];
    let dataCategoryFeatures = await getCategoryFeatures({
      category_id: lastLevelValue,
      shop_id: shopID,
    });
    if (dataCategoryFeatures.success && dataCategoryFeatures?.data?.length) {
      if (dataCategoryFeatures["data"][0]) {
        extractBarcodeCategoryOptions(dataCategoryFeatures["data"][0]);
      }
    }
    let {
      success: successattribCategorywise,
      data: attributeCategorywise,
    } = await getAttributesCategoryWise({
      category_id: lastLevelValue,
      shop_id: shopID,
    });
    setFinalLoading(false);
    if (
      successattribCategorywise &&
      Array.isArray(attributeCategorywise) &&
      attributeCategorywise.length > 0
    ) {
      let extractData = getExtractedDataForAttribute(
        attributeCategorywise,
        shopifyAttrOptions
      );
      let createdArr = [];
      if (attributeType === "requiredAttributes") {
        extractData[attributeType].forEach((attribute, index) => {
          let tempObject = {
            eBayAttribute: attribute.value,
            shoplineAttribute: attributeData[index]?.shoplineAttribute,
            selectedShoplineAttributeValueType:
              attributeData[index]?.selectedShoplineAttributeValueType,
          };
          createdArr.push(tempObject);
        });
      } else if (attributeType === "optionalAttributes") {
        attributeData.forEach((attribute, index) => {
          let tempObject = {
            eBayAttribute: attribute.eBayAttribute,
            shoplineAttribute: attribute.shoplineAttribute,
            selectedShoplineAttributeValueType:
              attribute.selectedShoplineAttributeValueType,
          };
          createdArr.push(tempObject);
        });
      }
      setAttributeTypeMapping({
        ...attributeTypeMapping,
        mapping: createdArr,
        options: extractData?.[attributeType],
      });
    }
  };

  const getEditTemplateFunc = async () => {
    let {
      success,
      data: editToBeData,
      code,
    } = await getEditTemplate({ id: Id });
    if (success) {
      setFiltersArray([editToBeData?.[0]?.data?.[0]?.prepareQuery?.queryArray]);
      let {
        data,
        query,
        overWriteExistingProducts,
        category_id,
      } = editToBeData[0];
      setCondition(query.includes("&&") ? "and" : "or");
      fillAllFields(data);
      setEditId(editToBeData[0]?._id);
      setCategoryId(category_id);
      setProductCondition(data[0]?.category_feature);
      setEditCategoryInput(data[0]?.primaryCategoryMappingName);
      setOverride(overWriteExistingProducts);
      const mappingData = [...data[0].primaryCategoryMapping];
      const attributeMappingData = { ...editToBeData?.[0]?.attributes_mapping };
      if (mappingData.length) {
        let {
          success: successMappingCategorywise,
          data: mappingCategorywise,
        } = await getCategoriesApi({
          level: 1,
          shop_id: shopID,
        });
        let temp1 = [];
        let { success, data: shoplineAttributeData } =
          await getAttributesByProductQuery({
            marketplace: "shopify",
            query: "(price>-1)",
          });
        if (success) {
          temp1 = shoplineAttributeData.map((attribute) => {
            return {
              label: attribute?.title,
              value: attribute?.code,
            };
          });
          setShoplineAttributes(temp1);
        }
        let temp = [...categoryMapping];
        if (successMappingCategorywise) {
          if (mappingCategorywise.length) {
            let extractData = getExtractedDataForCategory(mappingCategorywise);
            temp.push({
              options: extractData,
              label: `Category Level ${extractData[0]["level"]}`,
              value: mappingData[0].value,
              levelNumber: extractData[0]["level"],
            });
          }
        }
        const test = mappingData.map(async (data, index) => {
          let tempObj = {};
          tempObj["parent_category_id"] = data["value"];
          return await getCategoriesApi({
            ...tempObj,
            shop_id: shopID,
          });
        });
        const allPromise = Promise.all(test);
        allPromise
          .then((values) => {
            values.forEach((value, index) => {
              const { success, data } = value;
              if (success) {
                if (data.length) {
                  let extractData = getExtractedDataForCategory(data);
                  if (mappingData[index + 1]?.value)
                    temp.push({
                      options: extractData,
                      label: `Category Level ${extractData[0]["level"]}`,
                      value: mappingData[index + 1]?.value,
                      levelNumber: extractData[0]["level"],
                    });
                }
              }
            });
            const {
              requiredAttributesMapping: requiredAttributesMappingData,
              optionalAttributesMapping: optionalAttributesMappingData,
            } = attributeMappingData;
            populateAttributeMapping(
              requiredAttributesMappingData,
              requiredAttributesMapping,
              setRequiredAttributesMapping,
              temp,
              temp1,
              "requiredAttributes"
            );
            populateAttributeMapping(
              optionalAttributesMappingData,
              optionalAttributesMapping,
              setOptionalAttributesMapping,
              temp,
              temp1,
              "optionalAttributes"
            );
            setCategoryMapping(temp);
          })
          .catch((err) => {
            console.log(err);
          });
      }
    } else if (
        code === "token_expired" ||
        code === "invalid_token" ||
        code === "future_token" ||
        code === "token_decode_error"
      ) {
        props.history.push("/tokenexpired");
      }
  };

  useEffect(() => {
    if (Id) {
      getEditTemplateFunc();
    }
  }, [Id]);

  useEffect(() => {
    setTimeout(() => {
      setNameSkeleton(false);
    }, 0);
  }, []);

  useEffect(() => {
    let tempCategoryTag = "";
    categoryMapping?.forEach((category, index) => {
      if (index !== categoryMapping.length - 1 && index !== 0) {
        tempCategoryTag += " >> ";
      }
      category?.options?.forEach((optionObj) => {
        if (category.value === optionObj.value) {
          if (index === categoryMapping.length - 1) {
            tempCategoryTag += " >> ";
          }
          tempCategoryTag += optionObj.label;
        }
      });
    });
    setCategoryTag(tempCategoryTag);
  }, [categoryMapping]);

  useEffect(() => {
    if (refName) {
      templateRef.current[refName].scrollIntoView({
        behavior: "smooth",
        block: "center",
        inline: "nearest",
      });
    }
  }, [refName]);

  return (
    <>
      <PageHeader
        sticky
        title={Id ? "Edit Template" : "Setup Template"}
        reverseNavigation
        onClick={() => props.history.push("/panel/category-template")}
        description={
          Id
            ? "Start editing your existing Category Template."
            : "Let's begin creating a new Category Template."
        }
        action={
          <Button
            type="Primary"
            thickness="thin"
            onClick={() => saveTemplate()}
            loading={saveBtnLoader}
            disable={Id ? finalLoading : false}
          >
            Save
          </Button>
        }
      />
      <Card>
        {nameSkeleton ? (
          <TemplateNameSkeleton />
        ) : (
          <LRLayout
            required={true}
            title={"Template Name"}
            lrHelpText="Enter a unique name for the product template you wish to create."
          >
            <TextField
              ref={(e) => (templateRef.current["name"] = e)}
              error={validationErrors["templateNameValidation"][0]}
              placeHolder="Enter Template Name"
              showHelp="Template name should not exceed 80 characters."
              value={templateName}
              onChange={(e) => {
                setTemplateName(e);
                let { templateNameValidation } = validationErrors;
                templateNameValidation[0] = false;
              }}
              maxlength={80}
            />
          </LRLayout>
        )}
        {/* business policies */}
        {polciySkeleton ? (
          <PolicySkeleton />
        ) : (
          <LRLayout
            required={true}
            title={"Assign Business Policy"}
            lrHelpText={
              "Select your Payment, Shipping, and Return Policy to experience a frictionless selling experience."
            }
          >
            <span ref={(e) => (templateRef.current["policy"] = e)}>
              <FlexLayout
                desktopWidth="100"
                mobileWidth="100"
                tabWidth="100"
                spacing="loose"
              >
                <Select
                  required
                  error={validationErrors["policyValidation"][0]}
                  name={"Payment Policy"}
                  options={policyOptions["payment_policy"]}
                  value={paymentValue}
                  onChange={(e) => {
                    let { policyValidation } = validationErrors;
                    policyValidation[0] = false;
                    setPaymentValue(e);
                  }}
                  placeholder="Please Select"
                />
                <Select
                  required
                  error={validationErrors["policyValidation"][1]}
                  name={"Shipping Policy"}
                  options={policyOptions["shipping_policy"]}
                  value={shippingValue}
                  onChange={(e) => {
                    let { policyValidation } = validationErrors;
                    policyValidation[1] = false;
                    setShippingValue(e);
                  }}
                  placeholder="Please Select"
                />
                <Select
                  required
                  error={validationErrors["policyValidation"][2]}
                  name={"Return Policy"}
                  options={policyOptions["return_policy"]}
                  value={returnValue}
                  onChange={(e) => {
                    let { policyValidation } = validationErrors;
                    policyValidation[2] = false;
                    setReturnValue(e);
                  }}
                  placeholder="Please Select"
                />
              </FlexLayout>
            </span>
          </LRLayout>
        )}
        {/* category list */}
        {listingCategorySkeleton ? (
          <ListingCategorySkeleton />
        ) : (
          <LRLayout
            required={true}
            title={"Select Listing Category"}
            lrHelpText="Select a Listing Category for this template, and further map eBay attributes with Shopline attributes or set a custom value."
          >
            <FlexLayout direction="vertical" spacing="loose">
              {renderCategorySearchPrediction()}
              {categoryMapping.length ? renderCategoryMapping() : <></>}
            </FlexLayout>
          </LRLayout>
        )}
        {/* Additional Information Start */}
        {barcodeOptions.length ? (
          <AdditionalInformation barcodeOptions={barcodeOptions} />
        ) : (
          <></>
        )}
        {/* Additional Information End */}
        {/* attribute mapping */}
        {refresh ? <Loader type="Loader2" /> : <></>}
        {requiredAttributesMapping["mapping"].length > 0 ||
        optionalAttributesMapping["options"].length > 0 ? (
          <LRLayout
            required={true}
            title="Attribute Settings"
            lrHelpText={
              <FlexLayout
                direction="vertical"
                spacing="extraTight"
                wrap="noWrap"
              >
                <TextStyles
                  type="Paragraph"
                  paragraphTypes="MD-1.4"
                  textcolor="light"
                  lineHeight="LH-2.0"
                >
                  Map eBay Category attributes to your Shopline Product
                  attributes or Set a Custom value based on the eBay Product
                  category selected. Attributes are of two kinds- Required (Must
                  Fill) and Optional.
                </TextStyles>
                <List type="disc">
                  <TextStyles
                    type="Paragraph"
                    paragraphTypes="MD-1.4"
                    lineHeight="LH-2.0"
                  >
                    <b>Required: </b> These are a set of eBay attributes that
                    you must map with your Shopline store product attributes.
                    You can also set a Custom Valid value for the same as per
                    your requirement.
                  </TextStyles>
                  <TextStyles
                    type="Paragraph"
                    paragraphTypes="MD-1.4"
                    lineHeight="LH-2.0"
                  >
                    <b>Optional: </b> This is an optional set of attributes you
                    may or may not map per your requirements.
                  </TextStyles>
                </List>
              </FlexLayout>
            }
          >
            <FlexLayout
              direction="vertical"
              spacing="mediumLoose"
              wrap="noWrap"
            >
              <FlexLayout halign="end">
                <Button
                  loading={loaderOverlayActive}
                  thickness="thin"
                  type="Primary"
                  onClick={() => RefreshAtrributes()}
                >
                  Refresh eBay Attributes
                </Button>
              </FlexLayout>
              <FlexLayout direction="vertical" wrap="noWrap" spacing="tight">
                <FlexLayout childWidth="fullWidth">
                  <FlexChild desktopWidth="50" tabWidth="50" mobileWidth="50">
                    <TextStyles
                      type="SubHeading"
                      subheadingTypes="XS-1.6"
                      lineHeight="LH-2.4"
                      fontweight="bold"
                    >
                      eBay Attributes
                    </TextStyles>
                  </FlexChild>
                  <FlexChild desktopWidth="50" tabWidth="50" mobileWidth="50">
                    <TextStyles
                      type="SubHeading"
                      subheadingTypes="XS-1.6"
                      lineHeight="LH-2.4"
                      fontweight="bold"
                    >
                      Shopline Attributes
                    </TextStyles>
                  </FlexChild>
                </FlexLayout>
                <FlexLayout direction="vertical" spacing="tight" wrap="noWrap">
                  {/* required attributes */}
                  {requiredAttributesMapping["options"].length > 0 ? (
                    <Card
                      cardType="Bordered"
                      extraClass="header-pb-16"
                      title={
                        <TextStyles
                          type="Paragraph"
                          paragraphTypes="MD-1.4"
                          lineHeight="LH-2.0"
                          utility="required-icon"
                        >
                          Required
                        </TextStyles>
                      }
                    >
                      <span
                        ref={(e) =>
                          (templateRef.current["requiredAttributes"] = e)
                        }
                      >
                        <FlexLayout
                          direction="vertical"
                          spacing="loose"
                          wrap="noWrap"
                        >
                          {renderRequiredAttributeMappingStructure()}
                        </FlexLayout>
                      </span>
                    </Card>
                  ) : (
                    <></>
                  )}
                  {/* optional attributes  */}
                  {optionalAttributesMapping["options"].length > 0 ? (
                    <Card
                      cardType="Bordered"
                      extraClass="header-pb-16"
                      action={
                        <Button
                          type="Outlined"
                          onClick={() => addOptionalAttribute()}
                        >
                          Add Attributes
                        </Button>
                      }
                      title={
                        <TextStyles
                          type="Paragraph"
                          paragraphTypes="MD-1.4"
                          lineHeight="LH-2.0"
                        >
                          Optional
                        </TextStyles>
                      }
                    >
                      {renderOptionalAttributeMappingStructure()}
                    </Card>
                  ) : (
                    <></>
                  )}
                </FlexLayout>
              </FlexLayout>
            </FlexLayout>
          </LRLayout>
        ) : (
          <></>
        )}
        {/* attribute mapping */}
        {/* Product Condition Start */}
        {categoryFeatureOptions.length ? (
          <ProductCondition
            templateRef={templateRef}
            productCondition={productCondition}
            setProductCondition={setProductCondition}
            categoryFeatureOptions={categoryFeatureOptions}
            validationErrors={validationErrors}
          />
        ) : (
          <></>
        )}
        {/* Product Condition End */}
        {/* rule group */}
        <RuleGroup
          templateRef={templateRef}
          condition={condition}
          setCondition={setCondition}
          shop_id={shopID}
          shoplineShopId={shoplineShopId}
          override={override}
          setOverride={setOverride}
          sentenceQuery={sentenceQuery}
          setSentenceQuery={setSentenceQuery}
          filtersArray={filtersArray}
          setFiltersArray={setFiltersArray}
          query={query}
          setQuery={setQuery}
          validationErrors={validationErrors}
          setValidationErrors={setValidationErrors}
          setSaveErrorToast={setSaveErrorToast}
          setrunQueryCountDisableSave={setrunQueryCountDisableSave}
        />
      </Card>
      <ToastWrapper>{toastMarkup}</ToastWrapper>
      <ToastWrapper>{savetoastMarkup}</ToastWrapper>
    </>
  );
}

export default withRouter(CreateTemplate);
