/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import NestedTableComponent from "../../../../../OunceComponents/NestedTableComponent";
import {
  ActionList,
  Badge,
  Button,
  Card,
  FallBack,
  FlexChild,
  FlexLayout,
  Modal,
  TextStyles,
  ToolTip,
} from "@cedcommerce-integration/ounce-ui";
import {
  ChevronDown,
  DollarSign,
  FileText,
  RefreshCw,
  Trash2,
  Upload,
} from "react-feather";
import NestedPagination from "../../../../../OunceComponents/NestedPagination";
import { getMethod } from "../../../../../../Apirequest/productListingApi";
import {
  getProductAPI,
  getProductCountAPI,
} from "../../../../../../Apirequest/productApiEndpoints";
import { withRouter } from "react-router-dom";
import { listingContext } from "./ProductList";
import Filters from "./Filter";
import { endFromeBay, uploadProductAPI } from "../../../../../../APIs/PanelAPI";
import { postMethod } from "../../../../../../apiMethods/methods";
import { globalState } from "../../../../../../services/globalState";
import { importProductFromShoplineAPI } from "../../../../../../APIs/loginAPI";
import {
  EyeIcon,
  NoProductSearch,
  Warning2,
} from "../../../../../shared/IconSvg";
import FallbackGrid from "../../../../../shared/FallbackGrid";
import { Error } from "./IconSvg";
import {
  ProductGridSkeleton,
  ProductSkeleton,
} from "../../../Skeleton/ProductListing";
import NoImage from "../../../../../../assets/images/notfound.png";
import { CircleIcon } from "../../IconSvg";

export const parsedErrorList = (products) => {
  let errObj = {};
  products.forEach((product) => {
    if (
      product.errors &&
      Array.isArray(product.errors) &&
      product.errors.length > 0
    ) {
      product.errors.forEach((err) => {
        if (err?.["LongMessage"] && err?.["ShortMessage"])
          errObj[`${err["LongMessage"]}`] = {
            value: `${err["ShortMessage"]}`,
            severityCode: err["SeverityCode"],
          };
      });
    }
  });
  return errObj;
};

function All(props) {
  let {
    columns,
    variantColumns,
    setColumns,
    massAction,
    setActionsModal,
    totalSelectedRows,
    setTotalSelectedRows,
    selectedRowsObj,
    setSelectedRowsObj,
    locale1,
    fallbackStatusNoProductsAll,
    getStatusWiseCount,
    templateNameOptions,
  } = props;

  const [productSkeleton, setProductSkeleton] = useState(true);
  const shopLineShopId = globalState.getLocalStorage("shopLineShopId");
  const ebayShopId = globalState.getLocalStorage("ebayShopId");
  const [gridData, setGridData] = useState([]);
  const [errorModal, setErrorModal] = useState(false);
  const [rowselect, setrowselect] = useState([]);
  const [errorModalData, setErrModalData] = useState([{}]);
  const [errorModalIndex, setErrorModalIndex] = useState(0);
  // eslint-disable-next-line no-unused-vars
  const [pageSizeOptions, setPageSizeOptions] = useState([
    {
      label: "10",
      value: "10",
    },
    {
      label: "25",
      value: "25",
    },
    {
      label: "50",
      value: "50",
    },
    {
      label: "100",
      value: "100",
    },
  ]);
  const [pageSize, setPageSize] = useState(10);
  const [activePage, setActivePage] = useState(1);
  const [active, setActive] = useState(false);
  const [totalCount, setTotalCount] = useState(0);
  const [payloadState, setPayloadState] = useState({
    activePage: 1,
    count: 10,
  });
  const [gridSkeleton, setGridSkeleton] = useState(true);
  // eslint-disable-next-line no-unused-vars
  const [badges, setBadges] = useState({
    Active: { text: "Uploaded", color: "#269E6C", txtColor: "light" },
    NotUploaded: { text: "Not Uploaded", color: "#FEC84B", txtColor: "dark" },
    WithError: { color: "#FF8277", txtColor: "light" },
    Ended: { text: "Ended", color: "#E0E1E3", txtColor: "dark" },
    Error: { text: "Errors", color: "#FF0000", txtColor: "light" },
  });
  const [componentMount, setComponentMount] = useState(true);
  const [locale, setLocale] = useState("");
  const [
    fallbackStatusNoSearchProductsAll,
    setFallbackStatusNoSearchProductsAll,
  ] = useState(false);

  const getProducts = async (payload) => {
    const time1 = new Date();
    let ms1 = time1.getTime();
    setFallbackStatusNoSearchProductsAll(false);
    setGridSkeleton(true);
    let { success: getProductSuccess, data: getProductData } = await getMethod(
      getProductAPI,
      {
        ...payload,
        productOnly: true,
        sortBy: "_id",
      }
    );
    let {
      success: getProductCountSuccess,
      data: getProductCountData,
      code,
    } = await getMethod(getProductCountAPI, {
      ...payload,
      productOnly: true,
      sortBy: "_id",
    });
    const time2 = new Date();
    let ms2 = time2.getTime();
    let timeOut = ms2 - ms1;
    if (getProductSuccess) {
      if (getProductData?.["rows"]) {
        let tempProductData = [];
        let tempErroModalData = [];
        tempProductData = getProductData?.["rows"].map(
          (productDataObj, index) => {
            let {
              main_image,
              title,
              type,
              product_type,
              variant_attributes,
              container_id,
              brand,
              source_product_id,
              items,
              profile,
            } = productDataObj;
            let tempRows = {};
            tempRows["source_product_id"] = source_product_id;
            tempRows["key"] = source_product_id;
            tempRows["image"] = main_image ? (
              <img
                src={main_image}
                width="60px"
                height="60px"
                style={{ borderRadius: "4px" }}
                alt=""
              />
            ) : (
              <img
                src={NoImage}
                width="60px"
                height="60px"
                style={{ borderRadius: "4px" }}
                alt=""
              />
            );
            tempRows["variants"] =
              type === "variation"
                ? variantData(items, source_product_id, tempRows["key"])
                : [];
            tempRows["title"] = (
              <FlexLayout
                direction="vertical"
                spacing="extraTight"
                wrap="noWrap"
              >
                <FlexLayout spacing="mediumTight" wrap="noWrap" valign="start">
                  <TextStyles
                    fontweight="bold"
                    lineHeight="LH-2.0"
                    paragraphTypes="MD-1.4"
                    textcolor="dark"
                    type="Paragraph"
                  >
                    {title}
                  </TextStyles>
                  {items[0]?.listing_id && (
                    <ToolTip
                      helpText="View on eBay"
                      popoverContainer="element"
                      position="top"
                      type="dark"
                      open={false}
                    >
                      <span
                        className="LH-0 c-pointer"
                        style={{ width: "30px" }}
                        onClick={() =>
                          window.open(
                            `https://www.ebay.com.au/itm/${items[0]?.listing_id}`
                          )
                        }
                      >
                        {EyeIcon}
                      </span>
                    </ToolTip>
                  )}
                </FlexLayout>
                {renderBarcodeSku(items, source_product_id, variant_attributes)}
              </FlexLayout>
            );
            tempRows["price"] =
              variant_attributes.length === 0 ? (
                <TextStyles
                  paragraphTypes="MD-1.4"
                  textcolor="light"
                  type="Paragraph"
                >
                  {`$${items[0]?.["price"]}`}
                </TextStyles>
              ) : (
                renderPrice(items, source_product_id)
              );
            tempRows["quantity"] =
              variant_attributes.length === 0 ? (
                productDataObj.hasOwnProperty("inventory_tracked") &&
                !productDataObj["inventory_tracked"] ? (
                  <TextStyles
                    paragraphTypes="MD-1.4"
                    textcolor="light"
                    type="Paragraph"
                  >
                    Inventory not tracked
                  </TextStyles>
                ) : (
                  <TextStyles
                    content={`${items[0]?.["quantity"]} in Stock`}
                    paragraphTypes="MD-1.4"
                    textcolor={items[0]?.["quantity"] ? "light" : "negative"}
                    type="Paragraph"
                    lineHeight="LH-2.0"
                  />
                )
              ) : (
                renderQuanity(items, source_product_id)
              );
            tempRows["status"] = renderStatus(items, source_product_id, index);
            tempRows["template"] = profile?.profile_name ? (
              <TextStyles
                paragraphTypes="MD-1.4"
                textcolor="light"
                type="Paragraph"
              >
                {profile?.profile_name}
              </TextStyles>
            ) : (
              <>-</>
            );
            tempRows["brand"] = brand ? (
              <TextStyles
                paragraphTypes="MD-1.4"
                textcolor="light"
                type="Paragraph"
              >
                {brand}
              </TextStyles>
            ) : (
              "-"
            );
            tempRows["product_type"] = product_type ? (
              <TextStyles
                paragraphTypes="MD-1.4"
                textcolor="light"
                type="Paragraph"
              >
                {product_type}
              </TextStyles>
            ) : (
              <></>
            );
            tempRows["action"] = (
              <Button
                content="View"
                type="TextButton"
                onClick={() => {
                  window.scrollTo(0, 0);
                  props.history.push(
                    `/panel/view-products?id=${container_id}&type=${type}`
                  );
                }}
              />
            );
            tempErroModalData.push(parsedErrorList(items));
            return tempRows;
          }
        );
        if (!tempErroModalData.length) {
          tempErroModalData.push({});
        }
        setErrModalData(tempErroModalData);
        setGridData(tempProductData);
      }
    }
    if (getProductCountSuccess) {
      if (
        (JSON.stringify(payload).includes("items.title") ||
          JSON.stringify(payload).includes("source_product_id") ||
          JSON.stringify(payload).includes("items.sku") ||
          JSON.stringify(payload).includes("type") ||
          JSON.stringify(payload).includes("items.quantity") ||
          JSON.stringify(payload).includes("items.price") ||
          JSON.stringify(payload).includes("profile.profile_name")) &&
        getProductCountData?.["count"] === 0
      ) {
        setFallbackStatusNoSearchProductsAll(true);
      }
      let { count } = getProductCountData;
      setTotalCount(count);
    } else if (
      code === "token_expired" ||
      code === "invalid_token" ||
      code === "future_token" ||
      code === "token_decode_error"
    ) {
      props.history.push("/tokenexpired");
    }
    if (timeOut < 500) {
      setTimeout(() => {
        setGridSkeleton(false);
        setProductSkeleton(false);
      }, 500 - timeOut);
    } else {
      setGridSkeleton(false);
      setProductSkeleton(false);
    }
  };

  const variantData = (items, source_product_id, parentKey = "") => {
    let variant = items
      .map((data, index) => {
        if (data.source_product_id !== source_product_id) {
          return {
            key: index,
            parentKey: parentKey,
            image: (
              <img
                src={data?.main_image ? data?.main_image : NoImage}
                width="60px"
                height="60px"
                style={{ borderRadius: "4px" }}
                alt=""
              />
            ),
            title: (
              <FlexLayout
                direction="vertical"
                spacing="extraTight"
                wrap="noWrap"
              >
                <TextStyles
                  fontweight="bold"
                  lineHeight="LH-2.0"
                  paragraphTypes="MD-1.4"
                  textcolor="dark"
                  type="Paragraph"
                >
                  {data?.title ? data?.title : "-"}
                </TextStyles>
                {data?.["sku"] ? (
                  <FlexLayout spacing="extraTight" wrap="noWrap">
                    <TextStyles
                      utility="text-nowrap"
                      fontweight="bold"
                      paragraphTypes="MD-1.4"
                      textcolor="dark"
                      type="Paragraph"
                      lineHeight="LH-2.0"
                    >
                      SKU:
                    </TextStyles>
                    <TextStyles
                      utility="text-ellipsis"
                      paragraphTypes="MD-1.4"
                      textcolor="light"
                      type="Paragraph"
                      lineHeight="LH-2.0"
                    >
                      {data?.["sku"]}
                    </TextStyles>
                  </FlexLayout>
                ) : (
                  <></>
                )}
                {data?.["barcode"] ? (
                  <FlexLayout spacing="extraTight" wrap="noWrap">
                    <TextStyles
                      utility="text-nowrap"
                      fontweight="bold"
                      paragraphTypes="MD-1.4"
                      textcolor="dark"
                      type="Paragraph"
                      lineHeight="LH-2.0"
                    >
                      Barcode:
                    </TextStyles>
                    <TextStyles
                      paragraphTypes="MD-1.4"
                      textcolor="light"
                      type="Paragraph"
                      lineHeight="LH-2.0"
                    >
                      {data?.["barcode"]}
                    </TextStyles>
                  </FlexLayout>
                ) : (
                  <></>
                )}
              </FlexLayout>
            ),
            price: (
              <TextStyles
                paragraphTypes="MD-1.4"
                textcolor="light"
                type="Paragraph"
              >
                {data?.price || data?.price === 0 ? `$${data?.price}` : "-"}
              </TextStyles>
            ),
            // variant quantity
            quantity:
              (!data?.["inventory_tracked"] &&
                data?.["inventory_policy"] === "deny") ||
              (data?.["inventory_tracked"] &&
                data?.["inventory_policy"] === "continue") ? (
                <TextStyles
                  paragraphTypes="MD-1.4"
                  textcolor="light"
                  type="Paragraph"
                >
                  Inventory not tracked
                </TextStyles>
              ) : (
                <TextStyles
                  paragraphTypes="MD-1.4"
                  textcolor="light"
                  type="Paragraph"
                >
                  {data?.quantity || data?.quantity === 0
                    ? `${data?.quantity}`
                    : "-"}
                </TextStyles>
              ),
            type: "child",
          };
        }
      })
      .filter((items) => items !== undefined);
    return variant;
  };

  const renderBarcodeSku = (items, source_product_id, variant_attributes) => {
    let temp = "";
    if (!variant_attributes?.length) {
      temp = (
        <>
          {items[0]?.["sku"] ? (
            <FlexLayout spacing="extraTight" wrap="noWrap">
              <TextStyles
                utility="text-nowrap"
                fontweight="bold"
                paragraphTypes="MD-1.4"
                textcolor="dark"
                type="Paragraph"
                lineHeight="LH-2.0"
              >
                SKU:
              </TextStyles>
              <TextStyles
                utility="text-ellipsis"
                paragraphTypes="MD-1.4"
                textcolor="light"
                type="Paragraph"
                lineHeight="LH-2.0"
              >
                {items[0]?.["sku"]}
              </TextStyles>
            </FlexLayout>
          ) : (
            <></>
          )}
          {items[0]?.["barcode"] ? (
            <FlexLayout spacing="extraTight" wrap="noWrap">
              <TextStyles
                utility="text-nowrap"
                fontweight="bold"
                paragraphTypes="MD-1.4"
                textcolor="dark"
                type="Paragraph"
                lineHeight="LH-2.0"
              >
                Barcode:
              </TextStyles>
              <TextStyles
                paragraphTypes="MD-1.4"
                textcolor="light"
                type="Paragraph"
                lineHeight="LH-2.0"
              >
                {items[0]?.["barcode"]}
              </TextStyles>
            </FlexLayout>
          ) : (
            <></>
          )}
        </>
      );
    }
    return temp;
  };

  const renderPrice = (items, source_product_id) => {
    let temp = "";
    if (items.length === 2) {
      items.forEach((item) => {
        if (source_product_id !== item.source_product_id) {
          temp = (
            <TextStyles
              content={`$${item?.["price"]}`}
              paragraphTypes="MD-1.4"
              textcolor={item?.["price"] ? "light" : "negative"}
              type="Paragraph"
            />
          );
        }
      });
    } else {
      let min = null;
      let max = 0;
      items.forEach((item) => {
        if (source_product_id !== item.source_product_id) {
          if (max < item.price) {
            max = item.price;
          } else if (min === null || min > item.price) {
            min = item.price;
          }
        }
      });
      temp = (
        <TextStyles
          content={`$${min} ~ $${max}`}
          paragraphTypes="MD-1.4"
          textcolor="light"
          type="Paragraph"
        />
      );
    }
    return temp;
  };

  const renderQuanity = (items, source_product_id) => {
    let temp = "";
    let tempQuant = 0;
    items.forEach((item) => {
      if (
        source_product_id !== item.source_product_id &&
        item?.inventory_tracked
      ) {
        tempQuant += item.quantity;
      }
    });
    let variantInventoryNotTracked = false;
    variantInventoryNotTracked = items
      .filter((item) => source_product_id !== item.source_product_id)
      .every(
        (item) =>
          item.hasOwnProperty("inventory_tracked") &&
          !item.inventory_tracked &&
          item?.inventory_policy === "deny"
      );
    if (!variantInventoryNotTracked) {
      variantInventoryNotTracked = items
        .filter((item) => source_product_id !== item.source_product_id)
        .every(
          (item) =>
            item.hasOwnProperty("inventory_tracked") &&
            item.inventory_tracked &&
            item?.inventory_policy === "continue"
        );
    }
    temp = variantInventoryNotTracked ? (
      <TextStyles paragraphTypes="MD-1.4" textcolor="light" type="Paragraph">
        Inventory not tracked
      </TextStyles>
    ) : (
      <FlexLayout direction="vertical" wrap="noWrap" spacing="extraTight">
        <TextStyles
          content={`${tempQuant} in Stock`}
          paragraphTypes="MD-1.4"
          textcolor={tempQuant ? "dark" : "negative"}
          type="Paragraph"
          fontweight="bold"
          lineHeight="LH-2.0"
        />
        <TextStyles
          paragraphTypes="MD-1.4"
          textcolor="light"
          type="Paragraph"
          fontweight="normal"
          lineHeight="LH-2.0"
        >
          {`${items?.length - 1} Variants`}
        </TextStyles>
      </FlexLayout>
    );
    return temp;
  };

  const renderStatus = (items, source_product_id, index) => {
    let temp = "";
    items.forEach((item) => {
      if (source_product_id === item.source_product_id) {
        if (item?.status === "Error") {
          temp = (
            <span className="errorBtn">
              <Button
                content="Errors"
                type="TextButton"
                icon={Error}
                iconAlign="left"
                onClick={() => {
                  setErrorModalIndex(index);
                  setErrorModal(!errorModal);
                }}
              />
            </span>
          );
        } else if (item?.status) {
          if (item?.errors?.length) {
            temp = (
              <FlexLayout
                direction="vertical"
                spacing="extraTight"
                wrap="noWrap"
                valign="start"
              >
                <span className="errorBtn">
                  <Button
                    content="Errors"
                    type="TextButton"
                    icon={Error}
                    iconAlign="left"
                    onClick={() => {
                      setErrorModalIndex(index);
                      setErrorModal(!errorModal);
                    }}
                  />
                </span>
                <div className="active-status">
                  <FlexLayout
                    spacing="mediumTight"
                    wrap="noWrap"
                    valign="center"
                  >
                    <CircleIcon color={badges[`${item?.status}`]["color"]} />
                    <TextStyles
                      lineHeight="LH-2.0"
                      paragraphTypes="MD-1.4"
                      textcolor="light"
                      type="Paragraph"
                    >
                      {badges[`${item?.status}`]["text"]}
                    </TextStyles>
                  </FlexLayout>
                </div>
              </FlexLayout>
            );
          } else {
            temp = (
              <Badge
                badgeTextColor={badges[`${item?.status}`]["txtColor"]}
                size="regular"
                customBgColor={badges[`${item?.status}`]["color"]}
              >
                {badges[`${item?.status}`]["text"]}
              </Badge>
            );
          }
        } else {
          temp = (
            <Badge
              badgeTextColor={badges["NotUploaded"]["txtColor"]}
              size="regular"
              customBgColor={badges["NotUploaded"]["color"]}
            >
              Not Uploaded
            </Badge>
          );
        }
      }
    });
    return temp;
  };

  const rowSelection = {
    preserveSelectedRowKeys: true,
    selectedRowKeys: totalSelectedRows,
    onChange: (selectedRowKey, selectedRows) => {
      setrowselect(selectedRowKey);
    },
    onSelect: (singleRow, type) => {},
    onSelectAll: (type, row) => {},
    getCheckboxProps: (record) => ({
      name: record.name,
    }),
  };

  useEffect(() => {
    getProducts({
      ...payloadState,
      activePage: activePage,
      count: pageSize,
    });
    if (!componentMount) getStatusWiseCount();
    setComponentMount(false);
  }, [activePage, pageSize, payloadState, massAction]);

  useEffect(() => {
    if (fallbackStatusNoProductsAll) {
      setLocale(locale1);
    }
    if (fallbackStatusNoSearchProductsAll) {
      setLocale(
        <FallBack
          FallBackHeight="NormalPage"
          illustration={NoProductSearch}
          title="No Product Available!"
          subTitle="Your request does not match the listed Products."
        />
      );
    }
  }, [fallbackStatusNoProductsAll, fallbackStatusNoSearchProductsAll]);

  useEffect(() => {
    let temp = { ...selectedRowsObj };
    Object.keys(selectedRowsObj).forEach((key, index1) => {
      selectedRowsObj[key].forEach((row, index) => {
        if (rowselect.includes(row)) {
          temp[key].splice(index, 1);
        }
      });
    });
    temp[activePage] = rowselect;
    setSelectedRowsObj(temp);
  }, [rowselect]);

  useEffect(() => {
    let q = Math.floor(totalSelectedRows.length / pageSize);
    let r = totalSelectedRows.length % pageSize;
    let page = 0;
    if (r < pageSize || r === 0) {
      page = q + 1;
    }
    let x = 0;
    let obj = {};
    for (let i = 0; i < page; i++) {
      x += 1;
      if (x === page) {
        obj[x] = totalSelectedRows.slice(
          i * pageSize,
          totalSelectedRows.length
        );
      } else {
        obj[x] = totalSelectedRows.slice(i * pageSize, i * pageSize + pageSize);
      }
    }
    setSelectedRowsObj(obj);
  }, [pageSize]);

  useEffect(() => {
    let temp = [];
    Object.keys(selectedRowsObj).forEach((key) => {
      selectedRowsObj[key].forEach((row) => {
        if (!temp.includes(row)) {
          temp.push(row);
        }
      });
    });
    setTotalSelectedRows(temp);
  }, [selectedRowsObj]);

  useEffect(() => {
    setTotalSelectedRows([]);
    setSelectedRowsObj({});
  }, []);

  return (
    <>
      {productSkeleton ? (
        <ProductSkeleton />
      ) : (
        <>
          {fallbackStatusNoProductsAll ? (
            <></>
          ) : (
            <listingContext.Provider>
              <Filters
                columns={columns}
                setColumns={setColumns}
                setActivePage={setActivePage}
                payloadState={payloadState}
                setPayloadState={setPayloadState}
                templateNameOptions={templateNameOptions}
              />
            </listingContext.Provider>
          )}
          {totalSelectedRows.length === 0 ? (
            ""
          ) : (
            <div className="ced-selected__row">
              <FlexLayout spacing="mediumTight" valign="center">
                <TextStyles textcolor="light">
                  {`${totalSelectedRows.length} products selected`}
                </TextStyles>
                <ActionList
                  open={active}
                  activator={
                    <Button
                      thickness="thin"
                      icon={<ChevronDown />}
                      iconAlign="right"
                      onClick={() => setActive(!active)}
                      type="Outlined"
                    >
                      Select Actions
                    </Button>
                  }
                  onClose={() => setActive(false)}
                  options={[
                    {
                      items: [
                        {
                          content: "Upload Products",
                          onClick: () => {
                            setActionsModal({
                              active: true,
                              content1: "Upload Products",
                              content2: "upload",
                              api: uploadProductAPI,
                              loader: false,
                              methodName: postMethod,
                              payload: {
                                source_product_ids: totalSelectedRows,
                                source: {
                                  marketplace: "shopline",
                                  shopId: shopLineShopId,
                                },
                                target: {
                                  marketplace: "ebay",
                                  shopId: ebayShopId,
                                },
                              },
                            });
                          },
                          prefixIcon: <Upload size={20} />,
                        },
                        {
                          content: "Sync Inventory",
                          onClick: () => {
                            setActionsModal({
                              active: true,
                              content1: "Sync Inventory",
                              content2: "sync inventory of",
                              api: uploadProductAPI,
                              loader: false,
                              methodName: postMethod,
                              payload: {
                                source_product_ids: totalSelectedRows,
                                source: {
                                  marketplace: "shopline",
                                  shopId: shopLineShopId,
                                },
                                target: {
                                  marketplace: "ebay",
                                  shopId: ebayShopId,
                                },
                                operationType: "inventory_sync",
                              },
                            });
                          },
                          prefixIcon: <FileText size={20} />,
                        },
                        {
                          content: "Sync Price",
                          onClick: () => {
                            setActionsModal({
                              active: true,
                              content1: "Sync Price",
                              content2: "sync price of",
                              api: uploadProductAPI,
                              loader: false,
                              methodName: postMethod,
                              payload: {
                                source_product_ids: totalSelectedRows,
                                source: {
                                  marketplace: "shopline",
                                  shopId: shopLineShopId,
                                },
                                target: {
                                  marketplace: "ebay",
                                  shopId: ebayShopId,
                                },
                                operationType: "price_sync",
                              },
                            });
                          },
                          prefixIcon: <DollarSign size={20} />,
                        },
                        {
                          content: "Sync Product",
                          onClick: () => {
                            let postData = "";
                            totalSelectedRows.forEach((selectedRow, index) => {
                              if (index !== 0 && index !== selectedRow - 1)
                                postData += ",";
                              postData += selectedRow;
                            });
                            setActionsModal({
                              active: true,
                              content1: "Sync Product",
                              content2: "sync",
                              api: importProductFromShoplineAPI,
                              loader: false,
                              methodName: postMethod,
                              payload: {
                                marketplace: "shopline",
                                data: {
                                  ids: postData,
                                },
                                source: {
                                  marketplace: "shopline",
                                  shopId: shopLineShopId,
                                },
                                target: {
                                  marketplace: "ebay",
                                  shopId: ebayShopId,
                                },
                              },
                            });
                          },
                          prefixIcon: <RefreshCw size={20} />,
                        },
                        {
                          content: "End from eBay",
                          onClick: () => {
                            setActionsModal({
                              active: true,
                              content1: "End Now",
                              content2: "end",
                              api: endFromeBay,
                              loader: false,
                              methodName: postMethod,
                              payload: {
                                source_product_ids: totalSelectedRows,
                                source: {
                                  marketplace: "shopline",
                                  shopId: shopLineShopId,
                                },
                                target: {
                                  marketplace: "ebay",
                                  shopId: ebayShopId,
                                },
                              },
                            });
                          },
                          prefixIcon: <Trash2 size={20} />,
                        },
                      ],
                    },
                  ]}
                />
              </FlexLayout>
            </div>
          )}
          <>
            {gridSkeleton ? (
              <ProductGridSkeleton />
            ) : fallbackStatusNoSearchProductsAll ||
              fallbackStatusNoProductsAll ? (
              <FallbackGrid
                columns={columns}
                dataSource={gridData}
                rowSelection={{}}
                local={locale}
              />
            ) : (
              <>
                <NestedTableComponent
                  columns={columns}
                  dataSource={gridData}
                  variantColumns={variantColumns}
                  rowSelection={{
                    ...rowSelection,
                    checkStrictly: false,
                  }}
                />
                <Card cardType="Bordered" extraClass="ced-pagination__wrap">
                  <NestedPagination
                    totalCount={totalCount}
                    pageSizeOptions={pageSizeOptions}
                    activePage={activePage}
                    setActivePage={setActivePage}
                    pageSize={pageSize}
                    setPageSize={setPageSize}
                    payloadState={payloadState}
                    setPayloadState={setPayloadState}
                  />
                </Card>
              </>
            )}
            <Modal
              closeOnEscape={true}
              overlayClose={true}
              open={errorModal}
              close={() => setErrorModal(!errorModal)}
              heading="Errors"
              modalSize="medium"
              extraClass="ErrorModal"
            >
              {Object.keys(errorModalData[errorModalIndex])?.length ? (
                <>
                  {Object.keys(errorModalData[errorModalIndex])?.map(
                    (item, index) => {
                      return (
                        <React.Fragment key={index}>
                          <Card cardType="Default">
                            <FlexLayout spacing="tight" wrap="noWrap">
                              <>
                                {errorModalData[errorModalIndex][item][
                                  "severityCode"
                                ] === "Error"
                                  ? Error
                                  : Warning2}
                              </>
                              <FlexChild
                                desktopWidth="100"
                                tabWidth="100"
                                mobileWidth="100"
                              >
                                <FlexLayout
                                  direction="vertical"
                                  spacing="tight"
                                >
                                  <FlexLayout
                                    direction="vertical"
                                    spacing="extraTight"
                                  >
                                    <TextStyles
                                      fontweight="extraBold"
                                      lineHeight="LH-2.0"
                                      paragraphTypes="MD-1.4"
                                      textcolor="dark"
                                      type="Paragraph"
                                    >
                                      {
                                        errorModalData[errorModalIndex][item][
                                          "value"
                                        ]
                                      }
                                    </TextStyles>
                                    <TextStyles
                                      alignment="left"
                                      fontweight="normal"
                                      lineHeight="LH-2.0"
                                      paragraphTypes="MD-1.4"
                                      textcolor="light"
                                      type="Paragraph"
                                      utility="none"
                                    >
                                      <>{item}</>
                                    </TextStyles>
                                  </FlexLayout>
                                </FlexLayout>
                              </FlexChild>
                            </FlexLayout>
                          </Card>
                        </React.Fragment>
                      );
                    }
                  )}
                </>
              ) : (
                <>No Error Message</>
              )}
            </Modal>
          </>
        </>
      )}
    </>
  );
}

export default withRouter(All);
