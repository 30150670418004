/* eslint-disable react-hooks/exhaustive-deps */
import {
  Button,
  Card,
  CheckBox,
  FlexChild,
  FlexLayout,
  FormChild,
  FormElement,
  Select,
  Switcher,
  TextField,
  TextStyles,
  Toast,
  ToastWrapper,
} from "@cedcommerce-integration/ounce-ui";
import React, { useEffect, useState } from "react";
import { Trash2 } from "react-feather";
import { postMethod } from "../../../../../Apirequest/productListingApi";
import { saveDataAPI } from "../../../../../APIs/PanelAPI";
import { globalState } from "../../../../../services/globalState";
import { ProductSettingsSkeleton } from "../../Skeleton/MarketplaceConfigSkeleton";

function ProductSettings(props) {
  let {
    selectAttribute,
    setSelectAttribute,
    productSwitch,
    setProductSwitch,
    endProduct,
    setEndProduct,
    itemLocation,
    setItemLocation,
    selectAll,
    setSelectAll,
    matchfromEbayValues,
    setMatchfromEbayValues,
    matchFromEbay,
    setMatchFromEbay,
    skeleton,
    setErrorToast,
    setSuccessToast,
    matchfromShoplineOptions,
    setMatchFromShoplineOptions,
    buttonDisabled,
    setButtonDisabled,
  } = props;
  const ebayShopId = globalState.getLocalStorage("ebayShopId");
  const shopLineShopId = globalState.getLocalStorage("shopLineShopId");
  const [saveLoader, setSaveLoader] = useState(false);

  // eslint-disable-next-line no-unused-vars
  const [matchfromEbayOptions, setMatchFromEbayOptions] = useState([
    { label: "Title", value: "Title" },
    { label: "Sku", value: "SKU" },
  ]);
  //error validation
  const [errorsValidation, setErrorsValidation] = useState({
    itemLocation: new Array(3).fill(false),
    matchFromeBay: [{ shopline: false, ebay: false }],
  });
  const [activeToast, setActiveToast] = useState(false);
  const [prevState, setPrevState] = useState({});

  const selectAllAttributeFunc = () => {
    setSelectAll(!selectAll);
    let tempSelect = { ...selectAttribute };
    Object.keys(tempSelect).forEach((field) => {
      tempSelect[field] = !selectAll;
    });
    setSelectAttribute(tempSelect);
  };

  const selectAttributeFunc = (field) => {
    let temp = { ...selectAttribute };
    temp[field] = !temp[field];
    setSelectAttribute(temp);
    let flag = false;
    Object.keys(temp).forEach((attribute) => {
      if (!temp[attribute]) {
        flag = true;
      }
    });
    if (flag) {
      setSelectAll(false);
    } else {
      setSelectAll(true);
    }
  };

  const matchFromEbayHandler = (e, index) => {
    let tempMatchfromEbayValues = [...matchfromEbayValues];
    let tempObj = {};
    tempObj["shoplineAttribute"] = e;
    if (e === "sku") {
      tempObj["ebayAttribute"] = "SKU";
    } else if (e === "title") {
      tempObj["ebayAttribute"] = "Title";
    }
    tempMatchfromEbayValues[index] = tempObj;
    setMatchfromEbayValues(tempMatchfromEbayValues);

    let tempMatchFromShoplineOptions = [...matchfromShoplineOptions];

    tempMatchFromShoplineOptions.forEach((options, innerIndex) => {
      if (options.value === e && index === 0) {
        if (innerIndex === 0) {
          tempMatchFromShoplineOptions[0]["disabled"] = true;
          tempMatchFromShoplineOptions[1]["disabled"] = false;
        } else {
          tempMatchFromShoplineOptions[0]["disabled"] = false;
          tempMatchFromShoplineOptions[1]["disabled"] = true;
        }
      } else if (options.value === e) {
        tempMatchFromShoplineOptions[innerIndex]["disabled"] = true;
      }
    });
    setMatchFromShoplineOptions(tempMatchFromShoplineOptions);
  };

  const productsync = () => {
    let temp = {};
    Object.keys(selectAttribute).forEach((value) => {
      if (selectAttribute[value]) {
        temp[value] = true;
      }
    });
    return temp;
  };

  const ItemLocationHandler = (value, fieldType) => {
    let tempItemLocation = { ...itemLocation };
    tempItemLocation[fieldType] = value;
    setItemLocation(tempItemLocation);
  };

  const prePareData = () => {
    let payload = {
      source: {
        shopId: shopLineShopId,
        marketplace: "shopline",
      },
      target: {
        shopId: ebayShopId,
        marketplace: "ebay",
      },
      data: [
        {
          group_code: "settings",
          data: {
            match_from_ebay: matchFromEbay
              ? matchfromEbayValues.map((mappingData) => {
                  return {
                    shopline_attribute: mappingData.shoplineAttribute,
                    ebay_attribute: mappingData.ebayAttribute,
                  };
                })
              : false,
            ebay_locations: [
              {
                country: itemLocation.country,
                zipcode: itemLocation.zipcode,
                location: itemLocation.location,
              },
            ],
            autoEndProduct: endProduct,
            appToEbay: {
              autoProductSync: {
                productSync: productSwitch ? productsync() : false,
              },
            },
          },
        },
      ],
    };
    return payload;
  };
  const errorHandlingFunc = () => {
    let flag = false;
    let tempErrors = { ...errorsValidation };
    if (!itemLocation["country"]) {
      tempErrors["itemLocation"][0] = true;
      flag = true;
    }
    if (!itemLocation["zipcode"]) {
      tempErrors["itemLocation"][1] = true;
      flag = true;
    }
    if (!itemLocation["location"]) {
      tempErrors["itemLocation"][2] = true;
      flag = true;
    }

    if (matchFromEbay) {
      matchfromEbayValues.forEach((attribute, index) => {
        let tempObj = {};
        if (!attribute.shoplineAttribute) {
          tempObj["shopline"] = true;
          flag = true;
        }
        if (!attribute.ebayAttribute) {
          tempObj["ebay"] = true;
          flag = true;
        }
        tempErrors["matchFromeBay"][index] = tempObj;
      });
    }
    setErrorsValidation(tempErrors);
    if (flag) setActiveToast(true);
    return flag;
  };

  const saveMarketPlace = async () => {
    setSaveLoader(true);
    let payload = prePareData();
    let returnedError = errorHandlingFunc();
    if (!returnedError) {
      let { success, message, code } = await postMethod(saveDataAPI, payload);
      if (success) {
        setSuccessToast({
          active: true,
          message: "Product settings updated successfully",
          success: success,
        });
      } else if (code) {
        if (
          code === "token_expired" ||
          code === "invalid_token" ||
          code === "future_token" ||
          code === "token_decode_error"
        ) {
          props.history.push("/tokenexpired");
        }
      } else {
        setErrorToast({
          active: true,
          message: message,
          success: success,
        });
      }
    }
    setSaveLoader(false);
  };

  const tagMarkup = activeToast ? (
    <Toast
      message="Please fill all the required fields."
      onDismiss={() => setActiveToast(false)}
      timeout={3000}
      type="error"
    />
  ) : (
    <></>
  );

  const deleteMatchFromEbay = (index) => {
    let tempOptions = [...matchfromShoplineOptions];
    let temp = [...matchfromEbayValues];

    tempOptions.forEach((option, innerIndex) => {
      if (option.value === temp[index]["shoplineAttribute"]) {
        tempOptions[innerIndex]["disabled"] = false;
      }
    });
    setMatchFromShoplineOptions(tempOptions);
    temp.splice(index, 1);
    setMatchfromEbayValues(temp);

    let tempError = { ...errorsValidation };
    tempError["matchFromeBay"].splice(index, 1);
    setErrorsValidation(tempError);
  };

  useEffect(() => {
    let tempPrevState = { ...prevState };
    tempPrevState["productSwitch"] = productSwitch;
    tempPrevState["selectAll"] = selectAll;
    tempPrevState["selectAttribute"] = selectAttribute;
    tempPrevState["endProduct"] = endProduct;
    tempPrevState["itemLocation"] = itemLocation;
    tempPrevState["matchFromEbay"] = matchFromEbay;
    tempPrevState["matchfromEbayValues"] = matchfromEbayValues;
    setPrevState(tempPrevState);
  }, []);

  useEffect(() => {
    let tempCurrentState = {
      productSwitch: productSwitch,
      selectAll: selectAll,
      selectAttribute: selectAttribute,
      endProduct: endProduct,
      itemLocation: itemLocation,
      matchFromEbay: matchFromEbay,
      matchfromEbayValues: matchfromEbayValues,
    };
    if (JSON.stringify(prevState) === JSON.stringify(tempCurrentState)) {
      setButtonDisabled(true);
    }
  }, [
    productSwitch,
    selectAll,
    selectAttribute,
    endProduct,
    itemLocation,
    matchFromEbay,
    matchfromEbayValues,
  ]);
  return (
    <>
      {skeleton ? (
        <ProductSettingsSkeleton />
      ) : (
        <Card
          title={"Product Settings"}
          subTitle={
            "Set your preferences for products' attribute syncing on eBay marketplace."
          }
          action={
            <Button
              type="Primary"
              onClick={() => {
                saveMarketPlace();
                setButtonDisabled(true);
              }}
              loading={saveLoader}
              disable={buttonDisabled}
            >
              Save
            </Button>
          }
          extraClass="product-setting header-pb-16"
        >
          <FlexLayout direction="vertical" wrap="noWrap" spacing="loose">
            {/* Product Syncing */}
            <Card cardType="Bordered">
              <FlexLayout
                spacing="extraTight"
                direction="vertical"
                wrap="noWrap"
              >
                <Switcher
                  checked={productSwitch}
                  name={"Product Syncing"}
                  onChange={() => {
                    setProductSwitch(!productSwitch);
                    setButtonDisabled(false);
                  }}
                  textAligh="right"
                />
                <div style={{ marginLeft: "4.72rem" }}>
                  <FlexLayout
                    direction="vertical"
                    wrap="noWrap"
                    spacing="loose"
                  >
                    <TextStyles
                      type="Paragraph"
                      paragraphTypes="MD-1.4"
                      textcolor="light"
                      lineHeight="LH-2.0"
                    >
                      When switched to Yes, Selected attributes of the product
                      will be revise from App to eBay. You can disable any
                      attribute if you don't want to revise its value.
                    </TextStyles>
                    {productSwitch ? (
                      <FlexLayout
                        spacing="tight"
                        direction="vertical"
                        wrap="noWrap"
                      >
                        <CheckBox
                          id="two"
                          checked={selectAll}
                          labelVal="Select All"
                          name="SelectAll"
                          onClick={() => {
                            selectAllAttributeFunc();
                            setButtonDisabled(false);
                          }}
                        />
                        <hr className="custom-hr m-0" />
                        <FlexLayout
                          direction="vertical"
                          spacing="loose"
                          wrap="noWrap"
                        >
                          <FlexLayout
                            desktopWidth="25"
                            tabWidth="50"
                            mobileWidth="50"
                            spacing="loose"
                          >
                            {Object.keys(selectAttribute).map(
                              (field, index) => {
                                return (
                                  <CheckBox key={field}
                                    id={index}
                                    checked={selectAttribute[field]}
                                    labelVal={`${
                                      field.split("_")[0][0].toUpperCase() +
                                      field.split("_")[0].slice(1)
                                    }  ${
                                      field.split("_")[1]
                                        ? field.split("_")[1][0].toUpperCase() +
                                          field.split("_")[1].slice(1)
                                        : ""
                                    }`}
                                    name="Title"
                                    onClick={() => {
                                      selectAttributeFunc(field);
                                      setButtonDisabled(false);
                                    }}
                                  />
                                );
                              }
                            )}
                          </FlexLayout>
                        </FlexLayout>
                      </FlexLayout>
                    ) : (
                      <></>
                    )}
                  </FlexLayout>
                </div>
              </FlexLayout>
            </Card>

            {/* End Product */}
            <Card cardType="Bordered">
              <FlexLayout
                spacing="extraTight"
                direction="vertical"
                wrap="noWrap"
              >
                <Switcher
                  checked={endProduct}
                  name="End Product"
                  onChange={() => {
                    setEndProduct(!endProduct);
                    setButtonDisabled(false);
                  }}
                  textAligh="right"
                />
                <div style={{ marginLeft: "4.72rem" }}>
                  <TextStyles
                    lineHeight="LH-2.0"
                    paragraphTypes="MD-1.4"
                    textcolor="light"
                    type="Paragraph"
                  >
                    The end Product option lets you end the Product listing on
                    eBay. Enabling it will remove the listing from the eBay
                    website when the product gets deleted from Shopline. The
                    product will then no longer be available on the marketplace.
                  </TextStyles>
                </div>
              </FlexLayout>
            </Card>

            {/* Item Location */}
            <Card cardType="Bordered">
              <FlexLayout direction="vertical" wrap="noWrap" spacing="loose">
                <TextStyles
                  fontweight="extraBold"
                  lineHeight="LH-2.0"
                  paragraphTypes="MD-1.4"
                  utility="required-icon"
                >
                  Item Location
                </TextStyles>
                <TextStyles
                  lineHeight="LH-2.0"
                  paragraphTypes="MD-1.4"
                  textcolor="light"
                >
                  The Item Location section on the app allows you to specify the
                  geographical location of your item on eBay. It includes
                  selecting the item's country, zip code, and specific location.
                  The information helps buyers to know the location of the item
                  they are interested in. It increases the buyer's trust in the
                  seller since it gives them more transparency.
                </TextStyles>
                <FlexLayout
                  desktopWidth="33"
                  tabWidth="50"
                  mobileWidth="100"
                  spacing="mediumTight"
                >
                  <FormElement>
                    <FormChild>
                      <TextField
                        error={errorsValidation["itemLocation"][1]}
                        autocomplete="off"
                        clearFunction={function noRefCheck() {}}
                        helpIcon=""
                        id=""
                        placeHolder="Zip Code"
                        value={itemLocation.zipcode}
                        onChange={(e) => {
                          ItemLocationHandler(e, "zipcode");
                          let { itemLocation } = errorsValidation;
                          itemLocation[1] = false;
                          setButtonDisabled(false);
                        }}
                      />
                    </FormChild>
                  </FormElement>
                  <FormElement>
                    <FormChild>
                      <TextField
                        error={errorsValidation["itemLocation"][2]}
                        autocomplete="off"
                        clearFunction={function noRefCheck() {}}
                        helpIcon=""
                        id=""
                        placeHolder="Location"
                        value={itemLocation.location}
                        onChange={(e) => {
                          ItemLocationHandler(e, "location");
                          let { itemLocation } = errorsValidation;
                          itemLocation[2] = false;
                          setButtonDisabled(false);
                        }}
                      />
                    </FormChild>
                  </FormElement>
                </FlexLayout>
              </FlexLayout>
            </Card>

            {/* Match product */}
            <Card cardType="Bordered" extraClass="title-break">
              <FlexLayout
                spacing="extraTight"
                direction="vertical"
                wrap="noWrap"
              >
                <Switcher
                  checked={matchFromEbay}
                  name="Match products from eBay"
                  onChange={() => {
                    setMatchFromEbay(!matchFromEbay);
                    setButtonDisabled(false);
                  }}
                  textAligh="right"
                />
                <div style={{ marginLeft: "4.72rem" }}>
                  <FlexLayout
                    direction="vertical"
                    spacing="loose"
                    wrap="noWrap"
                  >
                    <FlexLayout
                      direction="vertical"
                      spacing="loose"
                      wrap="noWrap"
                    >
                      <TextStyles
                        lineHeight="LH-2.0"
                        paragraphTypes="MD-1.4"
                        textcolor="light"
                      >
                        The feature allows you to set the attribute preference
                        between the Title or SKU to map your existing eBay
                        listings to your Shopline listings. Enabling helps you
                        easily match your eBay products with your Shopline
                        products and keep them in sync. By setting the attribute
                        preference, you can ensure that the correct product is
                        matched and avoid confusion or discrepancies. The
                        feature is handy for sellers who have a lot of products
                        on both platforms and want to keep them in sync with
                        ease when selling through the app.
                      </TextStyles>
                      {matchFromEbay ? (
                        <FlexLayout halign="end">
                          <Button
                            type="Outlined"
                            onClick={() => {
                              let tempMatchfromEbayValues = [
                                ...matchfromEbayValues,
                              ];
                              tempMatchfromEbayValues.push({
                                shoplineAttribute: "",
                                ebayAttribute: "",
                              });
                              setMatchfromEbayValues(tempMatchfromEbayValues);
                              setButtonDisabled(false);
                            }}
                            disable={matchfromEbayValues.length === 2}
                          >
                            Add Attributes
                          </Button>
                        </FlexLayout>
                      ) : (
                        <></>
                      )}
                    </FlexLayout>
                    {matchFromEbay ? (
                      matchfromEbayValues.map((mappingData, index) => {
                        const { shoplineAttribute, ebayAttribute } =
                          mappingData;
                        console.log("mappingData", mappingData);
                        return (
                          <FlexLayout key={mappingData?.shoplineAttribute} spacing="tight" valign="end">
                            <FlexChild childWidth="fullWidth">
                              <FlexLayout
                                spacing="loose"
                                desktopWidth="50"
                                tabWidth="50"
                                mobileWidth="50"
                              >
                                <Select
                                  error={
                                    errorsValidation["matchFromeBay"]?.[
                                      index
                                    ]?.["shopline"]
                                  }
                                  options={matchfromShoplineOptions}
                                  value={shoplineAttribute}
                                  name="Shopline Attribute"
                                  onChange={(e) => {
                                    matchFromEbayHandler(e, index);
                                    let { matchFromeBay } = errorsValidation;
                                    let errorObj = {};
                                    errorObj["shopline"] = false;
                                    errorObj["ebay"] = false;
                                    matchFromeBay[index] = errorObj;
                                    setButtonDisabled(false);
                                  }}
                                />
                                <Select
                                  error={
                                    errorsValidation["matchFromeBay"]?.[
                                      index
                                    ]?.["ebay"]
                                  }
                                  options={matchfromEbayOptions}
                                  value={ebayAttribute}
                                  name="eBay Attribute"
                                  onChange={() => {}}
                                  disabled
                                />
                              </FlexLayout>
                            </FlexChild>
                            <Button
                              icon={<Trash2 />}
                              type="DangerOutlined"
                              thickness="large"
                              onClick={() => {
                                deleteMatchFromEbay(index);
                                setButtonDisabled(false);
                              }}
                              disable={matchfromEbayValues.length === 1}
                            />
                          </FlexLayout>
                        );
                      })
                    ) : (
                      <></>
                    )}
                  </FlexLayout>
                </div>
              </FlexLayout>
            </Card>
          </FlexLayout>
          <ToastWrapper>{tagMarkup}</ToastWrapper>
        </Card>
      )}
    </>
  );
}

export default ProductSettings;
