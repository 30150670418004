import {
  AdvanceFilter,
  Card,
  Datepicker,
  FlexChild,
  FlexLayout,
  Popover,
  Select,
  Tag,
  TextField,
} from "@cedcommerce-integration/ounce-ui";
import React, { useEffect, useState } from "react";
import { Filter, Search } from "react-feather";
import { useDebounce } from "../../../../shared/DebounceComponent";
import { formatDate } from "../../../../shared/dateFromat";
import { globalState } from "../../../../../services/globalState";
import moment from "moment";

const Filters = (props) => {
  let { setActivePage, payloadState, setPayloadState } = props;

  const eBayShopId = globalState.getLocalStorage("ebayShopId");

  const [quantityOptionsValue, setQuantityOptionsValue] = useState("");
  const [priceOptionsValue, setPriceOptionsValue] = useState("Equal");
  const [searchText, setSearchText] = useState("");
  const [flag, setFlag] = useState(false);
  const [showTags, setShowTags] = useState(false);

  const [parsedImportedAtValue, setParsedImportedAtValue] = useState("");
  const [parsedToImportedAtValue, setParsedToImportedAtValue] = useState("");

  const [disabledApply, setDisabledApply] = useState(true);
  const [disabledReset, setDisabledReset] = useState(true);

  const [date, setDate] = useState({
    from: "",
    to: "",
  });
  const [momentState, setMomentState] = useState({
    from: "",
    to: "",
  });
  const [quantity, setQuantity] = useState({
    between: { min_quantity: "", max_quantity: "" },
    otherThanBetween: { quantity: "" },
  });
  const [price, setPrice] = useState({
    between: { min_price: "", max_price: "" },
    otherThanBetween: { price: "" },
  });

  const [openQuantTag, setOpenQuantTag] = useState(false);
  const [openPriceTag, setOpenPriceTag] = useState(false);
  const [openDateTag, setOpenDateTag] = useState(false);

  const [tags, setTags] = useState({
    dateTag: { from: "", to: "" },
    quantityTag: {
      min_quantity: "",
      max_quantity: "",
    },
    otherThanBetweenQuantTag: "",
    priceTag: {
      min_price: "",
      max_price: "",
    },
    otherThanBetweenPriceTag: "",
  });
  const [quantityTagName, setQuantityTagName] = useState("");
  const [priceTagName, setPriceTagName] = useState("");
  //  debouncing
  const val = useDebounce(searchText, setSearchText);

  const dateFilter = (dateObj) => {
    let flag = false;
    let tempData = [];
    let payload = {};
    if (Object.keys(dateObj).length === 0) {
      dateObj = { ...date };
    }
    Object.keys(dateObj).forEach((key, index) => {
      if (dateObj[key]) {
        tempData[index] = dateObj[key];
        flag = true;
      } else tempData[index] = "";
    });
    let tempPayload = { ...payloadState };
    let tempTags = { ...tags };
    tempData.forEach((data, index) => {
      if (data) {
        if (index) {
          payload["filter[created_at][7][to]"] = data
            .split("/")
            .reverse()
            .join("-");
          tempTags["dateTag"]["to"] = data;
        } else {
          payload["filter[created_at][7][from]"] = data
            .split("/")
            .reverse()
            .join("-");
          tempTags["dateTag"]["from"] = data;
        }
      } else {
        if (index) {
          delete tempPayload["filter[created_at][7][to]"];
          tempTags["dateTag"]["to"] = "";
        } else {
          delete tempPayload["filter[created_at][7][from]"];
          tempTags["dateTag"]["from"] = "";
        }
      }
    });
    setTags(tempTags);
    return { ...tempPayload, ...payload };
  };

  const quantityFilter = (quantityObj) => {
    let betweenTag = false;
    let flag = false;
    let temp = [];
    let payload = {};
    if (Object.keys(quantityObj).length === 0) {
      quantityObj = { ...quantity };
    }
    let tempTags = { ...tags };
    if (quantityOptionsValue === "between") {
      betweenTag = true;
      Object.keys(quantityObj.between).forEach((key, index) => {
        if (quantityObj.between[key]) {
          temp[index] = quantityObj.between[key];
          flag = true;
        }
      });
    } else {
      tempTags["otherThanBetweenQuantTag"] =
        quantity.otherThanBetween["quantity"];
    }
    if (flag) {
      payload["filter[items.quantity][7][from]"] = temp[0] ? temp[0] : "";
      payload["filter[items.quantity][7][to]"] = temp[1] ? temp[1] : "";
      if (betweenTag) {
        tempTags["quantityTag"]["min_quantity"] = temp[0] ? temp[0] : "";
        tempTags["quantityTag"]["max_quantity"] = temp[1] ? temp[1] : "";
      }
    }
    setTags(tempTags);
    return payload;
  };

  const priceFilter = (priceObj) => {
    let betweenTag = false;
    let flag = false;
    let temp = [];
    let payload = {};
    if (Object.keys(priceObj).length === 0) {
      priceObj = { ...price };
    }
    let tempTags = { ...tags };
    if (priceOptionsValue === "between") {
      betweenTag = true;
      Object.keys(priceObj.between).forEach((key, index) => {
        if (priceObj.between[key]) {
          temp[index] = priceObj.between[key];
          flag = true;
        }
      });
    } else {
      tempTags["otherThanBetweenPriceTag"] = price.otherThanBetween["price"];
    }
    if (flag) {
      payload["filter[items.price][7][from]"] = temp[0] ? temp[0] : "";
      payload["filter[items.price][7][to]"] = temp[1] ? temp[1] : "";
      if (betweenTag) {
        tempTags["priceTag"]["min_price"] = temp[0] ? temp[0] : "";
        tempTags["priceTag"]["max_price"] = temp[1] ? temp[1] : "";
      }
    }
    setTags(tempTags);
    return payload;
  };

  const applyFilter = (filterData = {}, msg = "", passedDate = "") => {
    setActivePage(1);
    TagName();
    let tempPayload = {};
    if (msg === "date") {
      tempPayload = dateFilter(filterData);
    } else {
      tempPayload = dateFilter({});
    }
    setPayloadState(tempPayload);

    disableApplyFunc(passedDate);
  };

  const disableApplyFunc = (passedDate) => {
    let tempDate;
    if (passedDate) {
      tempDate = passedDate;
    } else {
      tempDate = { ...date };
    }

    let flag = false;
    Object.keys(tempDate).forEach((field) => {
      if (tempDate?.[field]) {
        flag = true;
      }
    });
    if (!flag) {
      setDisabledApply(true);
    }
  };

  const reset = () => {
    let tempDate = { ...date };
    tempDate["from"] = "";
    tempDate["to"] = "";
    setDate(tempDate);
    let tempTags = { ...tags };
    tempTags["dateTag"]["from"] = "";
    tempTags["dateTag"]["to"] = "";
    setTags(tempTags);
    let tempMoment = { ...momentState };
    tempMoment["from"] = "";
    tempMoment["to"] = "";
    setMomentState(tempMoment);
    setPayloadState({
      activePage: 1,
      count: 10,
    });
    disableApplyFunc(tempDate);
    setActivePage(1);
    setSearchText("");
    setParsedImportedAtValue("");
  };

  const clearFilter = () => {
    let flag = false;
    for (let field in tags["dateTag"]) {
      if (tags["dateTag"][field]) flag = true;
    }
    if (!flag) {
      let tempTag = { ...date };
      for (let field in tempTag) {
        tempTag[field] = "";
      }
      setDate(tempTag);
      setMomentState(tempTag);
      disableApplyFunc(tempTag);
    }
  };

  const dateTagFunc = () => {
    let tag;
    let temp = [];
    let count = 0;
    Object.keys(tags.dateTag).forEach((key, index) => {
      if (tags.dateTag[key]) {
        temp[index] = tags.dateTag[key];
        count++;
      }
    });
    if (count) {
      tag =
        count > 1 ? (
          <Popover
            popoverWidth={265}
            open={openDateTag}
            activator={
              <Tag
                count={"+1"}
                destroy={() => {
                  let tempDate = { ...date };
                  tempDate["from"] = "";
                  tempDate["to"] = "";
                  setDate(tempDate);
                  applyFilter(tempDate, "date", tempDate);
                  setOpenDateTag(false);
                  let { dateTag } = tags;
                  dateTag["from"] = "";
                  dateTag["to"] = "";

                  let tempMoment = { ...momentState };
                  tempMoment["from"] = "";
                  tempMoment["to"] = "";
                  setMomentState(tempMoment);
                  setParsedImportedAtValue("");
                }}
                popover
                togglePopup={() => setOpenDateTag(!openDateTag)}
                toggle={openDateTag}
              >
                Date: From({temp[0]})
              </Tag>
            }
            onClose={() => setOpenDateTag(!openDateTag)}
            popoverContainer="element"
          >
            <FlexLayout spacing="mediumTight" wrap={"noWrap"}>
              <Tag>From: {temp[0]}</Tag>
              <Tag>To: {temp[1]}</Tag>
            </FlexLayout>
          </Popover>
        ) : (
          <Tag
            destroy={() => {
              let tempDate = { ...date };
              tempDate["from"] = "";
              tempDate["to"] = "";
              setDate(tempDate);
              applyFilter(tempDate, "date", tempDate);
              setOpenDateTag(!openDateTag);
              let { dateTag } = tags;
              dateTag["from"] = "";
              dateTag["to"] = "";

              let tempMoment = { ...momentState };
              tempMoment["from"] = "";
              tempMoment["to"] = "";
              setMomentState(tempMoment);
              setParsedImportedAtValue("");
            }}
            togglePopup={() => setOpenQuantTag(!openQuantTag)}
          >
            Date: {temp[0] ? `From(${temp[0]})` : `To(${temp[1]})`}
          </Tag>
        );
    }
    return tag;
  };

  const TagName = () => {
    // quantity
    if (quantityOptionsValue === "equal") setQuantityTagName("=");
    if (quantityOptionsValue === "not_equal") setQuantityTagName("!=");
    if (quantityOptionsValue === "greater_than_or_equal_to")
      setQuantityTagName(">=");
    if (quantityOptionsValue === "less_than_or_equal_to")
      setQuantityTagName("<=");
    // price
    if (priceOptionsValue === "equal") setPriceTagName("=");
    if (priceOptionsValue === "not_equal") setPriceTagName("!=");
    if (priceOptionsValue === "greater_than_or_equal_to") setPriceTagName(">=");
    if (priceOptionsValue === "less_than_or_equal_to") setPriceTagName("<=");
  };

  const quantityTag = () => {
    let tag;
    let temp = [];
    let count = 0;
    Object.keys(tags.quantityTag).forEach((key, index) => {
      if (tags.quantityTag[key]) {
        temp[index] = tags.quantityTag[key];
        count++;
      }
    });

    if (
      quantityOptionsValue !== "between" &&
      quantityOptionsValue !== "" &&
      tags.otherThanBetweenQuantTag !== ""
    ) {
      tag = (
        <Tag
          destroy={() => {
            let tempQuantity = { ...quantity };
            tempQuantity.otherThanBetween["quantity"] = "";
            setQuantity(tempQuantity);
            applyFilter(tempQuantity, "quantity");
            let temp = tags;
            temp.otherThanBetweenQuantTag = "";
            setTags(temp);
            setQuantityOptionsValue("");
          }}
          togglePopup={() => setOpenQuantTag(!openQuantTag)}
        >
          Quantity: {quantityTagName}
          {tags.otherThanBetweenQuantTag}
        </Tag>
      );
    }
    if (count) {
      tag =
        count > 1 ? (
          <Popover
            open={openQuantTag}
            activator={
              <Tag
                count={"+1"}
                destroy={() => {
                  let tempQuantity = { ...quantity };
                  tempQuantity.between["min_quantity"] = "";
                  tempQuantity.between["max_quantity"] = "";
                  setQuantity(tempQuantity);
                  applyFilter(tempQuantity, "quantity");
                  setOpenQuantTag(!openQuantTag);
                  let { quantityTag } = tags;
                  quantityTag["min_quantity"] = "";
                  quantityTag["max_quantity"] = "";
                }}
                popover
                togglePopup={() => setOpenQuantTag(!openQuantTag)}
              >
                Quantity: Min({temp[0]})
              </Tag>
            }
            onClose={() => setOpenQuantTag(!openQuantTag)}
            popoverContainer="body"
          >
            <FlexLayout spacing="loose">
              <Tag>Min: {temp[0]}</Tag>
              <Tag>Max: {temp[1]}</Tag>
            </FlexLayout>
          </Popover>
        ) : (
          <Tag
            destroy={() => {
              let tempQuantity = { ...quantity };
              tempQuantity.between["min_quantity"] = "";
              tempQuantity.between["max_quantity"] = "";
              setQuantity(tempQuantity);
              applyFilter(tempQuantity, "quantity");
              let { quantityTag } = tags;
              quantityTag["min_quantity"] = "";
              quantityTag["max_quantity"] = "";
            }}
            togglePopup={() => setOpenQuantTag(!openQuantTag)}
          >
            Quantity: {temp[0] ? `Min(${temp[0]})` : `Max(${temp[1]})`}
          </Tag>
        );
    }
    return tag;
  };

  const priceTag = () => {
    let tag;
    let temp = [];
    let count = 0;
    Object.keys(tags.priceTag).forEach((key, index) => {
      if (tags.priceTag[key]) {
        temp[index] = tags.priceTag[key];
        count++;
      }
    });

    if (
      priceOptionsValue !== "between" &&
      priceOptionsValue !== "" &&
      tags.otherThanBetweenPriceTag !== ""
    ) {
      tag = (
        <Tag
          destroy={() => {
            let tempPrice = { ...price };
            tempPrice.otherThanBetween["price"] = "";
            setPrice(tempPrice);
            applyFilter(tempPrice, "price");
            let temp = tags;
            temp.otherThanBetweenPriceTag = "";
            setTags(temp);
            setPriceOptionsValue("");
          }}
          togglePopup={() => setOpenQuantTag(!openQuantTag)}
        >
          Price: {priceTagName}
          {tags.otherThanBetweenPriceTag}
        </Tag>
      );
    }
    if (count) {
      tag =
        count > 1 ? (
          <Popover
            open={openPriceTag}
            activator={
              <Tag
                count={"+1"}
                destroy={() => {
                  let tempPrice = { ...price };
                  tempPrice.between["min_price"] = "";
                  tempPrice.between["max_price"] = "";
                  setPrice(tempPrice);
                  applyFilter(tempPrice, "price");
                  setOpenPriceTag(!openPriceTag);
                  let { priceTag } = tags;
                  priceTag["min_price"] = "";
                  priceTag["max_price"] = "";
                }}
                popover
                togglePopup={() => setOpenPriceTag(!openPriceTag)}
              >
                Price: Min({temp[0]})
              </Tag>
            }
            onClose={() => setOpenPriceTag(!openPriceTag)}
            popoverContainer="body"
          >
            <FlexLayout spacing="mediumTight">
              <Tag>Min: {temp[0]}</Tag>
              <Tag>Max: {temp[1]}</Tag>
            </FlexLayout>
          </Popover>
        ) : (
          <Tag
            destroy={() => {
              let tempPrice = { ...price };
              tempPrice.between["min_price"] = "";
              tempPrice.between["max_price"] = "";
              setPrice(tempPrice);
              applyFilter(tempPrice, "price");
              let { priceTag } = tags;
              priceTag["min_price"] = "";
              priceTag["max_price"] = "";
            }}
            togglePopup={() => setOpenPriceTag(!openPriceTag)}
          >
            Price: {temp[0] ? `Min(${temp[0]})` : `Max(${temp[1]})`}
          </Tag>
        );
    }
    return tag;
  };

  useEffect(() => {
    if (flag) {
      setActivePage(1);
      if (!val) {
        let tempPayload = { ...payloadState };
        tempPayload["activePage"] = 1;
        delete tempPayload["filter[marketplace_reference_id][3]"];
        setPayloadState(tempPayload);
      } else {
        setPayloadState({
          ...payloadState,
          activePage: 1,
          "filter[marketplace_reference_id][3]": searchText,
        });
      }
    }
  }, [val]);

  useEffect(() => {
    let flag = false;
    for (let x in tags?.dateTag) {
      if (tags?.dateTag[x]) {
        flag = true;
      }
    }
    if (flag) {
      setDisabledReset(false);
      setShowTags(true);
    } else {
      setDisabledReset(true);
      setShowTags(false);
    }
  }, [tags]);

  useEffect(() => {
    setFlag(true);
  }, []);

  return (
    <Card cardType="Bordered" extraClass="ced-filter__wrap">
      <FlexLayout direction="vertical" wrap="noWrap" spacing="tight">
        <FlexLayout spacing="tight" wrap="noWrap">
          <TextField
            thickness="thin"
            showHelp=""
            placeHolder="Enter Order ID"
            innerPreIcon={<Search size={20} />}
            value={searchText}
            onChange={(e) => setSearchText(e)}
            clearButton
            clearFunction={() => {
              setSearchText("");
            }}
          />
          <AdvanceFilter
            button="Filter"
            disableApply={disabledApply}
            disableReset={disabledReset}
            filters={[
              {
                children: (
                  <FlexLayout direction="vertical" spacing="mediumTight">
                    <Datepicker
                      format="DD/MM/YYYY"
                      name="From"
                      onChange={(e, d) => {
                        let tempDate = { ...date };
                        tempDate["from"] = d;
                        setParsedImportedAtValue(d);
                        let tempMoment = { ...momentState };
                        if (
                          d > parsedToImportedAtValue &&
                          parsedToImportedAtValue
                        ) {
                          tempDate["to"] = "";
                          tempMoment["to"] = "";
                          setParsedImportedAtValue("");
                        }
                        setDate(tempDate);
                        tempMoment["from"] = e;
                        setMomentState(tempMoment);
                        if (tempDate["from"] || tempDate["to"]) {
                          setDisabledApply(false);
                        } else {
                          setDisabledApply(true);
                        }
                      }}
                      value={momentState["from"] !== "" && momentState["from"]}
                      picker="date"
                      placeholder="DD/MM/YYYY"
                      thickness="thick"
                      showToday
                      // disabledDate={(date, d) => {
                      //   let currentDate = new Date();
                      //   let year = currentDate.toLocaleString("default", {
                      //     year: "numeric",
                      //   });
                      //   let month = currentDate.toLocaleString("default", {
                      //     month: "2-digit",
                      //   });
                      //   let day = currentDate.toLocaleString("default", {
                      //     day: "2-digit",
                      //   });
                      //   let formattedDate = day + "/" + month + "/" + year;
                      //   const fromDate = date._d.toISOString();
                      //   const parsedFromDate = formatDate(fromDate);
                      //   console.log(parsedFromDate, formattedDate, "dateP");
                      //   if (parsedFromDate <= formattedDate) {
                      //     return false;
                      //   } else {
                      //     return true;
                      //   }
                      // }}
                    />
                    <Datepicker
                      format="DD/MM/YYYY"
                      name="To"
                      onChange={(e, d) => {
                        let tempDate = { ...date };
                        tempDate["to"] = d;
                        setDate(tempDate);
                        let tempMoment = { ...momentState };
                        tempMoment["to"] = e;
                        setMomentState(tempMoment);
                        setParsedToImportedAtValue(d);
                        if (tempDate["from"] || tempDate["to"]) {
                          setDisabledApply(false);
                        } else {
                          setDisabledApply(true);
                        }
                      }}
                      value={momentState["to"] !== "" && momentState["to"]}
                      picker="date"
                      placeholder="DD/MM/YYYY"
                      thickness="thick"
                      showToday
                      // disabledDate={(D) => {
                      //   let currentDate = new Date();
                      //   let year = currentDate.toLocaleString("default", {
                      //     year: "numeric",
                      //   });
                      //   let month = currentDate.toLocaleString("default", {
                      //     month: "2-digit",
                      //   });
                      //   let day = currentDate.toLocaleString("default", {
                      //     day: "2-digit",
                      //   });
                      //   let formattedDate = day + "/" + month + "/" + year;
                      //   const fromDate = D._d.toISOString();
                      //   const parsedFromDate = formatDate(fromDate);
                      //   console.log(
                      //     parsedFromDate,
                      //     parsedImportedAtValue,
                      //     "dateF1"
                      //   );
                      //   if (
                      //     parsedImportedAtValue <= parsedFromDate &&
                      //     parsedFromDate <= formattedDate
                      //   ) {
                      //     return false;
                      //   }
                      //   // else if (!parsedImportedAtValue) {
                      //   //   return false;
                      //   // }
                      //   else {
                      //     return true;
                      //   }
                      // }}
                    />
                  </FlexLayout>
                ),
                name: "Date",
              },
            ]}
            heading="Filters"
            icon={<Filter color="#2a2a2a" size={20} />}
            type="Outlined"
            onApply={() => {
              applyFilter();
            }}
            resetFilter={() => {
              reset();
            }}
            onClose={clearFilter}
          />
        </FlexLayout>
        {/* Filter Badges */}
        {showTags && (
          <FlexChild childWidth="fullWidth">
            <FlexLayout spacing="tight">
              {dateTagFunc()}
              {priceTag()}
            </FlexLayout>
          </FlexChild>
        )}
      </FlexLayout>
    </Card>
  );
};

export default Filters;
