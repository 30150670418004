// product section
export const getProductAPI = "connector/product/getRefineProducts";
export const getProductCountAPI = "connector/product/getRefineProductCount";
export const getStatusCount = "connector/product/getStatusWiseCount";
export const getViewProduct = "connector/product/getProduct";

export const importProductFromShoplineAPI = "connector/product/import";
export const syncProductFromEbayAPI = 'ebay/product/matchFromEbay'
export const postPublishOnEbay = "ebay/product/upload";

