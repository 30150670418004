import {
  Card,
  FlexChild,
  FlexLayout,
  Grid,
  PageHeader,
  Skeleton,
} from "@cedcommerce-integration/ounce-ui";
import React from "react";

const ProductViewSkeleton = ({ type }) => {
  const urlString = window.location.href;
  const url = new URL(urlString);
  url.search = "";
  const dataSoureces = [
    {
      image: <Skeleton height="48px" line={1} type="custom" width="48px" />,
      name: (
        <div className="order_name">
          <FlexLayout direction="vertical" spacing="tight">
            <Skeleton height="20px" line={1} type="custom" width="90%" />
            <Skeleton height="20px" line={1} type="custom" width="70%" />
          </FlexLayout>
        </div>
      ),
      quantity: <Skeleton height="20px" line={1} type="custom" width="80%" />,
      price_unit: <Skeleton height="20px" line={1} type="custom" width="80%" />,
      weight_unit: (
        <Skeleton height="20px" line={1} type="custom" width="80%" />
      ),
    },
    {
      image: <Skeleton height="48px" line={1} type="custom" width="48px" />,
      name: (
        <div className="order_name">
          <FlexLayout direction="vertical" spacing="tight">
            <Skeleton height="20px" line={1} type="custom" width="90%" />
            <Skeleton height="20px" line={1} type="custom" width="70%" />
          </FlexLayout>
        </div>
      ),
      quantity: <Skeleton height="20px" line={1} type="custom" width="80%" />,
      price_unit: <Skeleton height="20px" line={1} type="custom" width="80%" />,
      weight_unit: (
        <Skeleton height="20px" line={1} type="custom" width="80%" />
      ),
    },
  ];
  const columns = [
    {
      dataIndex: "image",
      key: "image",
      title: <Skeleton height="20px" line={1} type="custom" width="70%" />,
      width: 100,
    },
    {
      dataIndex: "name",
      key: "name",
      title: <Skeleton height="20px" line={1} type="custom" width="70%" />,
      width: 250,
    },
    {
      dataIndex: "quantity",
      key: "quantity",
      title: <Skeleton height="20px" line={1} type="custom" width="70%" />,
      width: 130,
    },
    {
      dataIndex: "price_unit",
      key: "price_unit",
      title: <Skeleton height="20px" line={1} type="custom" width="70%" />,
      width: 130,
    },
    {
      dataIndex: "weight_unit",
      key: "wight_unit",
      title: <Skeleton height="20px" line={1} type="custom" width="70%" />,
      width: 130,
    },
  ];

  return (
    <>
      <PageHeader
        title={
          <FlexLayout spacing="tight" valign="center">
            <Skeleton height="36px" width="36px" type="custom" />
            <Skeleton height="20px" width="350px" type="custom" />
          </FlexLayout>
        }
      />
      <Card cardType="Default">
        <FlexLayout spacing="loose" valign="start">
          <FlexChild desktopWidth="66" tabWidth="100" mobileWidth="100">
            <>
              <Card cardType="Bordered">
                <FlexLayout spacing="loose">
                  <FlexChild desktopWidth="">
                    <FlexLayout direction="vertical" spacing="mediumTight">
                      <Skeleton
                        height="140px"
                        line={1}
                        type="custom"
                        width="180px"
                      />
                      <FlexLayout spacing="mediumTight">
                        <Skeleton
                          height="39px"
                          line={1}
                          type="custom"
                          width="39px"
                        />
                        <Skeleton
                          height="39px"
                          line={1}
                          type="custom"
                          width="39px"
                        />
                        <Skeleton
                          height="39px"
                          line={1}
                          type="custom"
                          width="39px"
                        />
                        <Skeleton
                          height="39px"
                          line={1}
                          type="custom"
                          width="39px"
                        />
                      </FlexLayout>
                    </FlexLayout>
                  </FlexChild>
                  <FlexChild desktopWidth="66">
                    <FlexLayout direction="vertical" spacing="extraLoose">
                      <FlexLayout direction="vertical" spacing="mediumTight">
                        <Skeleton height="10px" line={1} type="line" />
                        <Skeleton height="10px" line={1} type="line" />
                        <Skeleton height="10px" line={1} type="line" />
                        <Skeleton height="10px" line={1} type="line" />
                        <Skeleton height="10px" line={1} type="line" />
                        <Skeleton height="10px" line={1} type="line" />
                      </FlexLayout>
                      {type === "simple" && (
                        <FlexLayout spacing="loose">
                          <Skeleton
                            height="15px"
                            line={1}
                            type="custom"
                            width="200px"
                          />
                          <Skeleton
                            height="15px"
                            line={1}
                            type="custom"
                            width="200px"
                          />
                        </FlexLayout>
                      )}
                    </FlexLayout>
                  </FlexChild>
                </FlexLayout>
              </Card>
              {type === "variation" && (
                <Card
                  cardType="Bordered"
                  title={
                    <Skeleton
                      height="16px"
                      line={1}
                      type="custom"
                      width="150px"
                    />
                  }
                >
                  <Grid
                    tableLayout="auto"
                    columns={columns}
                    dataSource={dataSoureces}
                  />
                </Card>
              )}
            </>
          </FlexChild>
          <FlexChild desktopWidth="33" tabWidth="100" mobileWidth="100">
            <FlexLayout spacing="extraLoose" direction="vertical">
              <Card
                cardType="Bordered"
                title={
                  <Skeleton
                    height="16px"
                    line={1}
                    type="custom"
                    width="150px"
                  />
                }
              >
                <FlexLayout direction="vertical" spacing="tight">
                  <FlexLayout spacing="tight" direction="vertical">
                    <FlexLayout direction="vertical" spacing="tight">
                      <Skeleton
                        height="12px"
                        line={1}
                        type="custom"
                        width="50px"
                      />
                      <Skeleton
                        height="30px"
                        line={1}
                        type="custom"
                        width="55px"
                      />
                    </FlexLayout>
                    <hr className="custom-hr" />
                  </FlexLayout>
                  <FlexLayout spacing="tight" direction="vertical">
                    <FlexLayout direction="vertical" spacing="tight">
                      <Skeleton
                        height="12px"
                        line={1}
                        type="custom"
                        width="50px"
                      />
                      <FlexLayout spacing="tight">
                        <Skeleton
                          height="30px"
                          line={1}
                          type="custom"
                          width="55px"
                        />
                        <Skeleton
                          height="30px"
                          line={1}
                          type="custom"
                          width="55px"
                        />
                        <Skeleton
                          height="30px"
                          line={1}
                          type="custom"
                          width="55px"
                        />
                      </FlexLayout>
                    </FlexLayout>
                    <hr className="custom-hr" />
                  </FlexLayout>
                  <FlexLayout spacing="tight" direction="vertical">
                    <FlexLayout direction="vertical" spacing="tight">
                      <Skeleton
                        height="12px"
                        line={1}
                        type="custom"
                        width="50px"
                      />
                      <Skeleton
                        height="30px"
                        line={1}
                        type="custom"
                        width="55px"
                      />
                    </FlexLayout>
                    <hr className="custom-hr" />
                  </FlexLayout>
                  <FlexLayout spacing="tight" direction="vertical">
                    <FlexLayout direction="vertical" spacing="tight">
                      <Skeleton
                        height="12px"
                        line={1}
                        type="custom"
                        width="50px"
                      />
                      <FlexLayout spacing="tight">
                        <Skeleton
                          height="30px"
                          line={1}
                          type="custom"
                          width="55px"
                        />
                        <Skeleton
                          height="30px"
                          line={1}
                          type="custom"
                          width="55px"
                        />
                        <Skeleton
                          height="30px"
                          line={1}
                          type="custom"
                          width="55px"
                        />
                      </FlexLayout>
                    </FlexLayout>
                    <hr className="custom-hr" />
                  </FlexLayout>
                  <FlexLayout spacing="tight" direction="vertical">
                    <FlexLayout direction="vertical" spacing="tight">
                      <Skeleton
                        height="12px"
                        line={1}
                        type="custom"
                        width="50px"
                      />
                      <Skeleton
                        height="30px"
                        line={1}
                        type="custom"
                        width="55px"
                      />
                    </FlexLayout>
                  </FlexLayout>
                </FlexLayout>
              </Card>
            </FlexLayout>
          </FlexChild>
        </FlexLayout>
      </Card>
    </>
  );
};

export default ProductViewSkeleton;
