import {  Grid } from "@cedcommerce-integration/ounce-ui";
import React from "react";

function NestedTableComponent({
  columns,
  dataSource,
  variantColumns = [],
  rowSelection = "",
  local = "",
  loader = false,
  expandable = true,
}) {
  return (
    <Grid
      columns={[...columns].filter((col) => col?.visible)}
      dataSource={[...dataSource]}
      locale={{
        emptyText: local,
      }}
      scrollX={800}
      size="middle"
      tableLayout="fixed"
      rowSelection={rowSelection}
      expandable={ expandable &&
        {
          showExpandColumn: true,
          expandedRowRender: (col) => {
            return (
              <Grid
                columns={[...variantColumns]}
                dataSource={col?.variants ?? []}
                size="middle"
                tableLayout="auto"
              />
            );
          },
          rowExpandable: (record) =>
            record?.variants && record.variants.length > 0,
        }
      }
      loading={loader}
    />
  );
}

export default NestedTableComponent;
