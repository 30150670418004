/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from "react";

export const useDebounce = (value, setInputValue, delay = 1000) => {
  const [debounceValue, setDebounceValue] = useState("");
  if (value === null) value = "";
  useEffect(() => {
    const handler = setTimeout(() => {
      setInputValue(value.trim());
      setDebounceValue(value.trim());
    }, delay);

    return () => {
      clearTimeout(handler);
    };
  }, [value, delay]);

  return debounceValue;
};
