import {
  Card,
  FlexChild,
  FlexLayout,
  Grid,
  PageHeader,
  Skeleton,
} from "@cedcommerce-integration/ounce-ui";
import React from "react";
import "./skelton.css";

const key = [1, 2, 3, 4, 5, 6];
const dataSources = key.map((val, index) => {
  return {
    key: index.toString(),
    nullE: <Skeleton height="0px" line={1} type="custom" width="0px" />,
    Empty: <Skeleton height="22px" line={1} type="custom" width="22px" />,
    targetorder_id: (
      <Skeleton height="20px" line={1} type="custom" width="80%" />
    ),
    sourceorder_id: (
      <div className="skeleton--center">
        <Skeleton height="20px" line={1} type="custom" width="80%" />
      </div>
    ),
    name: (
      <div className="skeleton--center">
        <Skeleton height="20px" line={1} type="custom" width="80%" />
      </div>
    ),
    date: (
      <div className="skeleton--center">
        <Skeleton height="20px" line={1} type="custom" width="80%" />
      </div>
    ),
    order_status: (
      <div className="skeleton--center">
        <Skeleton height="20px" line={1} type="custom" width="80%" />
      </div>
    ),
    order_total: (
      <div className="skeleton--center">
        <Skeleton height="20px" line={1} type="custom" width="80%" />
      </div>
    ),
    inventory: (
      <div className="skeleton--center">
        <Skeleton height="20px" line={1} type="custom" width="80%" />
      </div>
    ),
  };
});

const columns = [
  {
    title: <Skeleton height="18px" line={1} type="custom" width="18px" />,
    align: "left",
    dataIndex: "Empty",
    key: "Empty",
    width: 32,
  },
  {
    align: "left",
    dataIndex: "targetorder_id",
    title: <Skeleton height="20px" line={1} type="custom" width="100%" />,
    width: 190,
  },
  {
    align: "center",
    dataIndex: "sourceorder_id",
    title: <Skeleton height="20px" line={1} type="custom" width="100%" />,
    width: 210,
  },
  {
    align: "center",
    dataIndex: "name",
    title: <Skeleton height="20px" line={1} type="custom" width="100%" />,
    width: 180,
  },
  {
    align: "center",
    dataIndex: "date",
    title: <Skeleton height="20px" line={1} type="custom" width="100%" />,
    width: 200,
  },
  {
    align: "center",
    dataIndex: "order_status",
    title: <Skeleton height="20px" line={1} type="custom" width="100%" />,
    width: 200,
  },
  {
    align: "center",
    dataIndex: "order_total",
    title: <Skeleton height="20px" line={1} type="custom" width="100%" />,
    width: 175,
  },
  {
    align: "center",
    dataIndex: "inventory",
    title: <Skeleton height="20px" line={1} type="custom" width="100%" />,
    width: 150,
  },
];
 
export const OrderPageSkeleton = () => {
  return (
    <>
      <PageHeader
        action={<Skeleton height="37px" width="150px" type="custom" />}
        title="Orders"
        description="Order List enable you to fetch orders from eBay Marketplace instantly and create them on your Shopline store."
      />
      <Card>
        <FlexLayout spacing="tight" direction="vertical" wrap="noWrap">
          <div className="ced-tab__skeleton mb-10">
            <FlexLayout spacing="tight" wrap="noWrap">
              {[1, 2, 3, 4].map((index) => {
                return (
                  <Skeleton
                    key={index}
                    type="custom"
                    line={1}
                    width="180px"
                    height="34px"
                  />
                );
              })}
            </FlexLayout>
          </div>
          <FlexLayout direction="vertical" wrap="noWrap">
          <Card extraClass="ced-filter__wrap" cardType="Bordered">
          <FlexLayout desktopWidth="50" tabWidth="100" mobileWidth="100">
            <FlexLayout spacing="tight" wrap="noWrap">
              <FlexChild desktopWidth="50" tabWidth="50" mobileWidth="50">
                <Skeleton type="custom" line={1} width="100%" height="38px" />
              </FlexChild>
              <FlexChild desktopWidth="25" tabWidth="25" mobileWidth="25">
                <Skeleton type="custom" line={1} width="100%" height="38px" />
              </FlexChild>
            </FlexLayout>
          </FlexLayout>
          </Card>
          <Grid
            dataSource={dataSources}
            columns={columns}
            scrollX={800}
            size="middle"
            tableLayout="auto"
          />
           <Card cardType="Bordered" extraClass="ced-pagination__wrap">
          <div className="inte-Pagination">
            <FlexLayout halign="fill" spacing="tight">
              <FlexChild desktopWidth="50" tabWidth="50" mobileWidth="50">
                <Skeleton height="39px" line={1} type="custom" width="300px" />
              </FlexChild>
              <FlexChild desktopWidth="50" tabWidth="50" mobileWidth="50">
                <FlexLayout halign="end">
                  <div className="align_right">
                    <Skeleton
                      height="39px"
                      line={1}
                      type="custom"
                      width="300px"
                    />
                  </div>
                </FlexLayout>
              </FlexChild>
            </FlexLayout>
          </div>
          </Card>
          </FlexLayout>
        </FlexLayout>
      </Card>
    </>
  );
}; 

export const OrderSkeleton = () => {
  return (
    <>
      <FlexLayout direction="vertical" wrap="noWrap">
        <Card cardType="Bordered" extraClass="ced-filter__wrap">
          <FlexLayout desktopWidth="50" tabWidth="100" mobileWidth="100">
            <FlexLayout spacing="tight" wrap="noWrap">
              <FlexChild desktopWidth="50" tabWidth="50" mobileWidth="50">
                <Skeleton type="custom" line={1} width="100%" height="38px" />
              </FlexChild>
              <FlexChild desktopWidth="25" tabWidth="25" mobileWidth="25">
                <Skeleton type="custom" line={1} width="100%" height="38px" />
              </FlexChild>
            </FlexLayout>
          </FlexLayout>
        </Card>
        <Grid
          dataSource={dataSources}
          columns={columns}
          scrollX={800}
          size="middle"
          tableLayout="auto"
        />
           <Card cardType="Bordered" extraClass="ced-pagination__wrap">
        <div className="inte-Pagination">
          <FlexLayout halign="fill" spacing="tight">
            <FlexChild desktopWidth="50" tabWidth="50" mobileWidth="50">
              <Skeleton height="39px" line={1} type="custom" width="300px" />
            </FlexChild>
            <FlexChild desktopWidth="50" tabWidth="50" mobileWidth="50">
              <FlexLayout halign="end">
                <div className="align_right">
                  <Skeleton
                    height="39px"
                    line={1}
                    type="custom"
                    width="300px"
                  />
                </div>
              </FlexLayout>
            </FlexChild>
          </FlexLayout>
        </div>
        </Card>
      </FlexLayout>
    </>
  );
}

export const OrderGridSkeleton = () => {
  return (
    <>
      <FlexLayout spacing="tight" direction="vertical" wrap="noWrap">
        <Grid
          dataSource={dataSources}
          columns={columns}
          scrollX={800}
          size="middle"
          tableLayout="auto"
        />
        <div className="inte-Pagination">
          <FlexLayout halign="fill" spacing="tight">
            <FlexChild desktopWidth="50" tabWidth="50" mobileWidth="50">
              <Skeleton height="39px" line={1} type="custom" width="300px" />
            </FlexChild>
            <FlexChild desktopWidth="50" tabWidth="50" mobileWidth="50">
              <FlexLayout halign="end">
                <div className="align_right">
                  <Skeleton
                    height="39px"
                    line={1}
                    type="custom"
                    width="300px"
                  />
                </div>
              </FlexLayout>
            </FlexChild>
          </FlexLayout>
        </div>
      </FlexLayout>
      {/* </> */}
    </>
  );
};
