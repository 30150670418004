import { environment } from "../environment/environment";
import { globalState } from "./globalState";
import { isUndefined } from "util";

const message = "Sorry, the request was unsuccessful. Please come back later.";

const getHeaders = () => {
  const headers = {
    Authorization: "Bearer " + globalState.getBearerToken(),
    "Ced-Source-Name": "shopline",
    "Ced-Target-Name": "ebay",
    appTag: "shopline_ebay",
    appCode: "eyJzaG9wbGluZSI6InNob3BsaW5lIiAsICJlYmF5IjoiZWJheSJ9",
  };
  if (globalState.getLocalStorage("shopLineShopId"))
    headers["Ced-Source-Id"] = globalState.getLocalStorage("shopLineShopId");
  if (globalState.getLocalStorage("ebayShopId"))
    headers["Ced-Target-Id"] = globalState.getLocalStorage("ebayShopId");
  return headers;
};

export const requests = {
  getRequest: (endpoint, params, fullUrl, hideLoader) => {
    if (isUndefined(hideLoader) || !hideLoader) {
      window.showLoader = true;
    }
    let paramsString = "";
    if (!isUndefined(params)) {
      paramsString += "?";
      for (let i = 0; i < Object.keys(params).length; i++) {
        const end = i < Object.keys(params).length - 1 ? "&" : "";
        paramsString +=
          Object.keys(params)[i] +
          "=" +
          encodeURIComponent(params[Object.keys(params)[i]]) +
          end;
      }
    }
    if (isUndefined(fullUrl) || !fullUrl) {
      return fetch(environment.API_ENDPOINT + endpoint + paramsString, {
        method: "GET",
        headers: getHeaders(),
      })
        .then((res) => {
          if (isUndefined(hideLoader) || !hideLoader) {
            window.showLoader = false;
          }
          return res.json();
        })
        .then((res) => {
          if (!isUndefined(res.code)) {
            if (res.code === "token_expired") {
            }
          }
          return res;
        })
        .catch((e) => {
          return { success: false, message: message, code: e };
        });
    } else {
      return fetch(endpoint + paramsString, {
        method: "GET",
        headers: getHeaders(),
      })
        .then((res) => {
          if (isUndefined(hideLoader) || !hideLoader) {
            window.showLoader = false;
          }
          return res.json();
        })
        .then((res) => {
          if (!isUndefined(res.code)) {
            if (res.code === "token_expired") {
            }
          }
          return res;
        })
        .catch((e) => {
          return { success: false, message: message, code: e };
        });
    }
  },
  postRequest: (endpoint, data, fullUrl, hideLoader) => {
    if (isUndefined(hideLoader) || !hideLoader) {
      window.showLoader = true;
    }
    if (isUndefined(fullUrl) || !fullUrl) {
      return fetch(environment.API_ENDPOINT + endpoint, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          ...getHeaders(),
        },
        body: JSON.stringify(data),
      })
        .then((res) => {
          if (isUndefined(hideLoader) || !hideLoader) {
            window.showLoader = false;
          }
          return res.json();
        })
        .then((res) => {
          if (!isUndefined(res.code)) {
            if (res.code === "token_expired") {
            }
          }
          return res;
        })
        .catch((e) => {
          return { success: false, message: message, code: e };
        });
    } else {
      return fetch(endpoint, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          ...getHeaders(),
        },
        body: JSON.stringify(data),
      })
        .then((res) => {
          if (isUndefined(hideLoader) || !hideLoader) {
            window.showLoader = false;
          }
          return res.json();
        })
        .then((res) => {
          if (!isUndefined(res.code)) {
            if (res.code === "token_expired") {
            }
          }
          return res;
        })
        .catch((e) => {
          return { success: false, message: message, code: e };
        });
    }
  },
};
