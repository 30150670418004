import {
  ActionList,
  Button,
  FlexLayout,
  ToolTip,
  Topbar,
} from "@cedcommerce-integration/ounce-ui";
import React, { useState } from "react";
import { Bell, FileText, HelpCircle } from "react-feather";
import { NavLink, withRouter } from "react-router-dom";
import { Menu } from "./Menu";

const PanelHeader = (props) => {
  const { showDot } = props;
  const [active, setActive] = useState(false);

  return (
    <Topbar
      connectLeft={
        <div className="desk-menu">
          <FlexLayout valign="center" spacing="none">
            {Menu.map((item, index) => {
              return (
                <React.Fragment key={index}>
                  <NavLink
                    className={({ isActive }) => (isActive ? "active" : "")}
                    to={`/panel/${item.path}`}
                    onClick={()=> window.scrollTo(0,0)}
                  >
                    {item.content}
                  </NavLink>
                </React.Fragment>
              );
            })}
          </FlexLayout>
        </div>
      }
      connectRight={
        <FlexLayout valign="center" spacing="tight">
          <ActionList
            open={active}
            activator={
              <Button
                type="Outlined"
                thickness="thin"
                iconAlign="right"
                onClick={() => setActive(!active)}
                icon={<HelpCircle size={20} />}
              />
            }
            onClose={() => setActive(false)}
            options={[
              {
                items: [
                  {
                    content: "Documentation",
                    onClick: () =>
                      window.open(
                        "https://docs.cedcommerce.com/shopline/cedcommerce-ebay-integration/",
                        "_blank"
                      ),
                    prefixIcon: <FileText size={20} />,
                  },
                ],
              },
            ]}
          />
          <ToolTip
            helpText="Get an overview of all the Completed and Ongoing Activities here."
            popoverContainer="element"
            position="bottom"
            extraClass="notification-tooltip"
            open={false}
          >
            <Button
              type="Outlined"
              thickness="thin"
              onClick={() => {
                props.history.push(`/panel/ongoingactivity`);
              }}
              icon={
                <>
                  <Bell size={20} />
                  {showDot && (
                    <span className="bell-active">
                      <svg
                        width="10"
                        height="10"
                        viewBox="0 0 10 10"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <circle cx="5" cy="5" r="5" fill="#D62C20" />
                      </svg>
                    </span>
                  )}
                </>
              }
            />
          </ToolTip>
        </FlexLayout>
      }
    />
  );
};

export default withRouter(PanelHeader);
