// product section
export const getProductAPI = "connector/product/getRefineProducts";
export const getProductCountAPI = "connector/product/getRefineProductCount";

// product actions
export const importProductFromShoplineAPI = "connector/product/import";
export const uploadProductAPI = "ebay/product/upload";
export const inventorySyncAPI = "connector/product/inventorySync";
export const priceSyncAPI = "connector/product/priceSync";
export const endFromeBay = "ebay/product/deleteProduct";

// template api
export const validateProfileAPI= 'connector/profile/validateProfile'
export const getProfileDataAPI = "connector/profile/getProfileData";
export const getProfileDataCountAPI = "connector/profile/getProfileDataCount";
export const deleteProfileAPI = "connector/profile/deleteProfile";

// configuration
export const saveDataAPI = "connector/config/saveConfig";
export const getDataAPI = "connector/config/getConfig";

//dashboard
export const getProductStatusAPI = "connector/product/getStatusWiseCount";
export const getActivitesAPI = "connector/get/allNotifications";
export const getOngoingActivitiesAPI = "connector/get/allQueuedTasks";

export const deleteAllActivitiesAPI = "connector/get/clearNotifications";

// order
export const getOrderAPI = "connector/order/getAll";
export const fetOrderAPI = "ebay/order/fetchorder";
export const getSingleOrderAPI = "connector/order/get";
export const getStatusCount = "connector/order/getCount";
export const getStatusOrder = "connector/order/getAll";

// order actions
export const syncShipment = "shoplinehome/request/syncOrderShipment";

export const productStatusAPI = "ebay/product/productStatus"