/* eslint-disable react-hooks/exhaustive-deps */
import {
  Alert,
  Button,
  Card,
  FlexChild,
  FlexLayout,
  Select,
  Skeleton,
  TextStyles,
  Toast,
  ToastWrapper,
  ToolTip,
} from "@cedcommerce-integration/ounce-ui";
import React, { useEffect, useState } from "react";
import { RefreshCcw } from "react-feather";
import { getMethod, postMethod } from "../../../../../apiMethods/methods";
import {
  getPaymentChannelsAPI,
  getSavedPaymentChannelsAPI,
  savePaymentChannelsAPI,
} from "../../../../../APIs/loginAPI";
import { globalState } from "../../../../../services/globalState";
import PaymentSettingsSkeleton from "./PaymentSettingsSkeleton";

const parsePaymentChannelOptions = (paymentMethods) => {
  let preparedOptions = paymentMethods.map((method) => ({
    label: method?.pay_channel_name,
    value: method?.pay_channel_id,
  }));
  return preparedOptions;
};

const PaymentSettings = (props) => {
  let { buttonDisabled, setButtonDisabled } = props;

  const [pageLoading, setPageLoading] = useState(false);

  const [savedPreviousPaymentChannel, setSavedPreviousPaymentChannel] =
    useState("");
  const [noPaymentChannelsFound, setnoPaymentChannelsFound] = useState(false);
  const [paymentChannelOptions, setPaymentChannelOptions] = useState([]);
  const [refreshOptionsBtnLoader, setRefreshOptionsBtnLoader] = useState(false);
  const [paymentChannelSkeletonStatus, setPaymentChannelSkeletonStatus] =
    useState(true);
  const [savePaymentChannelSaveBtnLoader, setSavePaymentChannelSaveBtnLoader] =
    useState(false);

  //toast
  const [successToast, setSuccessToast] = useState({
    active: false,
    message: "",
    success: false,
  });
  const [errorToast, setErrorToast] = useState({
    active: false,
    message: "",
    success: false,
  });

  const toastMarkup = successToast.active ? (
    <Toast
      message={successToast.message}
      onDismiss={() =>
        setSuccessToast({ active: false, message: "", success: false })
      }
      timeout={2000}
      type={successToast.success ? "success" : "error"}
    />
  ) : errorToast.active ? (
    <Toast
      message={errorToast.message}
      onDismiss={() =>
        setErrorToast({ active: false, message: "", success: false })
      }
      timeout={2000}
      type="error"
    />
  ) : null;

  const callSavedPaymentFunc = async () => {
    const time1 = new Date();
    let ms1 = time1.getTime();
    setPageLoading(true);
    let { success, data, code } = await getMethod(getSavedPaymentChannelsAPI);
    const time2 = new Date();
    let ms2 = time2.getTime();
    let timeOut = ms2 - ms1;
    if (success) {
      if (data?.value?.payment_channel) {
        setSavedPreviousPaymentChannel(data?.value?.payment_channel);
      }
    } else if (
      code === "token_expired" ||
      code === "invalid_token" ||
      code === "future_token" ||
      code === "token_decode_error"
    ) {
      props.history.push("/tokenexpired");
    }
    if (timeOut < 500) {
      setTimeout(() => {
        setPageLoading(false);
      }, 500 - timeOut);
    } else setPageLoading(false);
  };

  const callGetPaymentOptionsFunc = async () => {
    const time1 = new Date();
    let ms1 = time1.getTime();
    setRefreshOptionsBtnLoader(true);
    let { success, data, code } = await getMethod(getPaymentChannelsAPI);
    const time2 = new Date();
    let ms2 = time2.getTime();
    let timeOut = ms2 - ms1;
    if (success) {
      if (data == null || data.length === 0) {
        setnoPaymentChannelsFound(true);
      } else {
        setPaymentChannelOptions(parsePaymentChannelOptions(data));
        setnoPaymentChannelsFound(false);
      }
    } else if (
      code === "token_expired" ||
      code === "invalid_token" ||
      code === "future_token" ||
      code === "token_decode_error"
    ) {
      props.history.push("/tokenexpired");
    }
    setPaymentChannelSkeletonStatus(false);
    if (timeOut < 500) {
      setTimeout(() => {
        setRefreshOptionsBtnLoader(false);
      }, 5000 - timeOut);
    } else setRefreshOptionsBtnLoader(false);
  };

  useEffect(() => {
    callSavedPaymentFunc();
    callGetPaymentOptionsFunc();
  }, []);

  const savePaymentChannelFunc = async (selectedPaymentChannel) => {
    setSavePaymentChannelSaveBtnLoader(true);
    const payload = {
      source_shop_id: globalState.getLocalStorage("shopLineShopId"),
      target_shop_id: globalState.getLocalStorage("ebayShopId"),
      payment_id: savedPreviousPaymentChannel,
    };
    let { success, code } = await postMethod(
      savePaymentChannelsAPI,
      payload
    );
    if (success) {
      setSuccessToast({
        active: true,
        message: "Custom payment method updated successfully",
        success: success,
      });
    } else if (code) {
      if (
        code === "token_expired" ||
        code === "invalid_token" ||
        code === "future_token" ||
        code === "token_decode_error"
      ) {
        props.history.push("/tokenexpired");
      }
    } else {
      setErrorToast({
        active: true,
        message: "Custom payment method not updated successfully",
        success: success,
      });
    }
    setSavePaymentChannelSaveBtnLoader(false);
  };

  return pageLoading ? (
    <PaymentSettingsSkeleton />
  ) : (
    <>
      {/* Order Settings*/}
      <Card>
        <FlexLayout direction="vertical" spacing="loose">
          <Card
            cardType="Bordered"
            extraClass="config-payment header-pb-16"
            title={"Payment Method"}
            subTitle={subTitleText()}
            action={
              <Button
                type="Primary"
                onClick={() => {
                  savePaymentChannelFunc();
                  setButtonDisabled(true);
                }}
                loading={savePaymentChannelSaveBtnLoader}
                disable={buttonDisabled || savedPreviousPaymentChannel === ""}
              >
                Save
              </Button>
            }
          >
            <FlexLayout direction="vertical" spacing="loose" wrap="noWrap">
              {paymentChannelSkeletonStatus ? (
                <FlexLayout valign="center" halign="fill" spacing="loose">
                  <FlexChild childWidth="fullWidth">
                    <Skeleton height="44px" width="150px" type="line" />
                  </FlexChild>
                  <Skeleton height="44px" width="44px" type="custom" />
                </FlexLayout>
              ) : noPaymentChannelsFound ? (
                <FlexLayout valign="start" halign="fill" spacing="loose">
                  <FlexChild childWidth="fullWidth">
                    {refreshOptionsBtnLoader ? (
                      <Skeleton type="custom" width="100%" height="44px" />
                    ) : (
                      <Alert
                        destroy={false}
                        onClose={function noRefCheck() {}}
                        type="info"
                      >
                        <TextStyles
                          lineHeight="LH-2.0"
                          paragraphTypes="MD-1.4"
                          textcolor="dark"
                          type="Paragraph"
                          utility="none"
                        >
                          Payment method not available
                        </TextStyles>
                      </Alert>
                    )}
                  </FlexChild>
                  <ToolTip
                    helpText="Click the button to fetch your Custom Payment Method from Shopline."
                    popoverContainer="element"
                    position="left"
                    open={false}
                  >
                    <Button
                      icon={<RefreshCcw width={18} />}
                      type="Outlined"
                      onClick={() => {
                        callGetPaymentOptionsFunc();
                      }}
                      loading={refreshOptionsBtnLoader}
                    />
                  </ToolTip>
                </FlexLayout>
              ) : (
                <FlexLayout valign="center" halign="fill" spacing="loose">
                  <FlexChild childWidth="fullWidth">
                    {refreshOptionsBtnLoader ? (
                      <Skeleton type="custom" width="100%" height="44px" />
                    ) : (
                      <Select
                        onChange={(e) => {
                          setSavedPreviousPaymentChannel(e);
                          setButtonDisabled(false);
                        }}
                        options={paymentChannelOptions}
                        value={savedPreviousPaymentChannel}
                        placeholder="Please select"
                      />
                    )}
                  </FlexChild>
                  <ToolTip
                    helpText="Click the button to fetch your Custom Payment Method from Shopline."
                    popoverContainer="element"
                    position="left"
                    open={false}
                  >
                    <Button
                      icon={<RefreshCcw width={18} />}
                      type="Outlined"
                      onClick={() => {
                        callGetPaymentOptionsFunc();
                      }}
                      loading={refreshOptionsBtnLoader}
                    />
                  </ToolTip>
                </FlexLayout>
              )}
            </FlexLayout>
          </Card>
        </FlexLayout>
      </Card>
      <ToastWrapper>{toastMarkup}</ToastWrapper>
    </>
  );
};
export default PaymentSettings;

const subTitleText = () => {
  return (
    <p className="inte__text--light inte__LineHeight--2">
      Please select your preferred Custom Payment method for successful order
      sync between channels. <br />
      <Button
        onClick={() => {
          window.open(
            "https://docs.cedcommerce.com/shopline/cedcommerce-ebay-integration/?section=how-to-create-custom-payment-method",
            "_blank"
          );
        }}
        type="TextButton"
      >
        Learn more
      </Button>{" "}
      about custom payment method in Shopline.
    </p>
  );
};
