import {
  Card,
  CheckBox,
  FlexLayout,
  LRLayout,
  TextStyles,
} from "@cedcommerce-integration/ounce-ui";
import React from "react";

const AdditionalInformation = ({ barcodeOptions }) => {
  return (
    <LRLayout
      required={true}
      title={"Additional Information"}
      lrHelpText={
        <FlexLayout direction="vertical" spacing="loose" wrap="noWrap">
          <TextStyles
            textcolor="light"
            type="Paragraph"
            paragraphTypes="MD-1.4"
            lineHeight="LH-2.0"
          >
            Easily view available options for your selected category with the
            ‘Checked Options’ feature. The checked options shows only the
            options relevant to your selected category, making your listing
            process smoother.
          </TextStyles>
          <TextStyles
            textcolor="light"
            type="Paragraph"
            paragraphTypes="MD-1.4"
            lineHeight="LH-2.0"
          >
            For instance, if the ‘Variation enabled’ option is unticked, then
            you must avoid listings products under the selected category.
          </TextStyles>
        </FlexLayout>
      }
    >
      <Card cardType="Bordered">
        <FlexLayout
          desktopWidth="25"
          mobileWidth="50"
          tabWidth="25"
          spacing="loose"
        >
          <CheckBox
            id={"BestOffer"}
            labelVal="Best offer enabled"
            name="Best offer enabled"
            onClick={function noRefCheck() {}}
            disabled
          />
          <CheckBox
            id={"Variation-specific"}
            checked={barcodeOptions.indexOf("VariationsEnabled") > -1}
            labelVal="Variations enabled"
            onChange={() => {}}
            disabled
          />
          <CheckBox
            id={"ISBNenabled"}
            checked={barcodeOptions.indexOf("ISBNEnabled") > -1}
            labelVal="ISBN enabled"
            onChange={() => {}}
            disabled
          />
          <CheckBox
            id={"UPCEnabled"}
            checked={barcodeOptions.indexOf("UPCEnabled") > -1}
            labelVal="UPC enabled"
            onChange={() => {}}
            disabled
          />
          <CheckBox
            id={"EANEnabled"}
            checked={barcodeOptions.indexOf("EANEnabled") > -1}
            labelVal="EAN enabled"
            onChange={() => {}}
            disabled
          />
        </FlexLayout>
      </Card>
    </LRLayout>
  );
};

export default AdditionalInformation;
