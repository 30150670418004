import { Card, FlexChild, FlexLayout, Skeleton } from "@cedcommerce-integration/ounce-ui";
import React from "react";

export const Activities = () => {
  return (
    <>
      <Card title={<Skeleton height="25px" width="150px" type="custom" />}>
        <FlexLayout direction="vertical" spacing="extraLoose" wrap="noWrap">
          {[1, 2, 3].map((index) => {
            return (
              <>
                <FlexLayout direction="vertical" spacing="loose">
                  <FlexLayout
                    spacing="loose"
                    wrap="noWrap"
                    valign="start"
                    key={index}
                  >
                    <FlexChild desktopWidth="25" mobileWidth="50" tabWidth="50">
                      <FlexLayout
                        direction="vertical"
                        spacing="tight"
                        wrap="noWrap"
                        childWidth="fullWidth"
                      >
                        <Skeleton
                          type="custom"
                          line={1}
                          height="14px"
                          width="50%"
                        />
                        <Skeleton type="custom" height="14px" width="75%" />
                      </FlexLayout>
                    </FlexChild>
                  </FlexLayout>
                  <Skeleton height="12px" line={1} type="line" width="150px" />
                </FlexLayout>
              </>
            );
          })}
        </FlexLayout>
      </Card>
      <Card
        title={<Skeleton height="18px" width="150px" type="custom" />}
        action={<Skeleton height="32px" width="150px" type="custom" />}
      >
        <FlexLayout direction="vertical" spacing="loose" wrap="noWrap">
          {[1, 2, 3, 4].map((index) => {
            return (
              <FlexLayout key={index} spacing="loose" direction="vertical" wrap="noWrap">
                <FlexLayout
                  spacing="loose"
                  wrap="noWrap"
                  valign="start"
                  key={index}
                >
                  <Skeleton type="custom" width="20px" height="20px" rounded />
                  <FlexChild desktopWidth="25" mobileWidth="50" tabWidth="50">
                    <FlexLayout
                      direction="vertical"
                      spacing="tight"
                      wrap="noWrap"
                      childWidth="fullWidth"
                    >
                      <Skeleton
                        type="line"
                        line={1}
                        height="15px"
                        width="20%"
                      />
                      <Skeleton type="custom" height="15px" width="75%" />
                    </FlexLayout>
                  </FlexChild>
                </FlexLayout>
                <hr className="custom-hr" />
              </FlexLayout>
            );
          })}
          <FlexLayout halign="fill" spacing="extraTight">
            <Skeleton height="39px" line={1} type="custom" width="150px" />
            <Skeleton height="39px" line={1} type="custom" width="150px" />
          </FlexLayout>
        </FlexLayout>
      </Card>
    </>
  );
};

export const CompletedActivities = () => {
  return (
    <Card
      title="Completed Activities"
      extraClass="header-pb-16"
      action={<Skeleton height="32px" width="150px" type="custom" />}
    >
      <FlexLayout direction="vertical" spacing="loose" wrap="noWrap">
        {[1, 2, 3, 4].map((index) => {
          return (
            <FlexLayout key={index} spacing="loose" direction="vertical" wrap="noWrap">
              <FlexLayout
                spacing="loose"
                wrap="noWrap"
                valign="start"
                key={index}
              >
                <Skeleton type="custom" width="20px" height="20px" rounded />
                <FlexChild desktopWidth="25" mobileWidth="50" tabWidth="50">
                  <FlexLayout
                    direction="vertical"
                    spacing="tight"
                    wrap="noWrap"
                    childWidth="fullWidth"
                  >
                    <Skeleton type="line" line={1} height="15px" width="20%" />
                    <Skeleton type="custom" height="15px" width="75%" />
                  </FlexLayout>
                </FlexChild>
              </FlexLayout>
              <hr className="custom-hr" />
            </FlexLayout>
          );
        })}
        <FlexLayout halign="fill" spacing="extraTight">
          <Skeleton height="39px" line={1} type="custom" width="150px" />
          <Skeleton height="39px" line={1} type="custom" width="150px" />
        </FlexLayout>
      </FlexLayout>
    </Card>
  );
}