import { Loader } from "@cedcommerce-integration/ounce-ui";
import React, { useEffect, useState } from "react";
import { withRouter } from "react-router-dom";
import { postMethod } from "../../apiMethods/methods";
import { globalState } from "../../services/globalState";
import { saveDataAPI } from "../../APIs/PanelAPI";
import { getPoliciesAPI } from "../../APIs/loginAPI";

const Completed = (props) => {
  const [percentage, setPercentage] = useState(0);

  setTimeout(() => {
    props.history.push("/panel/dashboard");
  }, 3000);

  const saveConfig = async () => {
    const ShoplineShopId = globalState.getLocalStorage("shopLineShopId");
    const ebayShopId = globalState.getLocalStorage("ebayShopId");
    const payload = {
      source: {
        shopId: ShoplineShopId,
        marketplace: "shopline",
      },
      target: {
        shopId: ebayShopId,
        marketplace: "ebay",
      },
      data: [
        {
          group_code: "settings",
          data: {
            match_from_ebay: [
              {
                shopline_attribute: "sku",
                ebay_attribute: "SKU",
              },
              {
                shopline_attribute: "title",
                ebay_attribute: "Title",
              },
            ],
            ebay_locations: [
              {
                country: "AU",
                zipcode: JSON.parse(
                  globalState.getLocalStorage("ebay_locations")
                )?.zipcode,
                location: JSON.parse(
                  globalState.getLocalStorage("ebay_locations")
                )?.location,
              },
            ],
            autoEndProduct: true,
            appToEbay: {
              autoProductSync: {
                productSync: {
                  title: true,
                  quantity: true,
                  category: true,
                  barcode: true,
                  description: true,
                  main_images: true,
                  item_specfics: true,
                  price: true,
                  variation_images: true,
                  weight: true,
                },
              },
            },
            syncOrderShipment: {
              syncTrackingDetails: true,
              mappingOfShippingCarrier: false,
            },
          },
        },
      ],
    };
    let { success } = await postMethod(saveDataAPI, payload);
    if (success) {
      const fetchSavePolicies = async (req, res, next) => {
        const payloadData = {
          multitype: ["shipping", "payment", "return"],
          shop_id: ebayShopId,
          site_id: 15,
          refreshPolicies: 1,
        };
        let { success } = await postMethod(getPoliciesAPI, payloadData);
      };
      fetchSavePolicies();
    }
  };

  useEffect(() => {
    saveConfig();
    let a = 0;
    const interval = setInterval(logCountUp, 1000);
    function logCountUp() {
      a += 33.333;
      setPercentage(a);
      if (a > 100) clearInterval(interval);
    }
  }, []);

  return (
    <Loader
      type="Loader3"
      title="You have successfully Onboarded."
      subtitle="Setting up your Account"
      percentage={percentage}
    />
  );
};

export default withRouter(Completed);
