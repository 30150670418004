/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import {
  Button,
  Card,
  FallBack,
  FlexLayout,
  Grid,
  Modal,
  PageHeader,
  TextField,
  TextStyles,
  Toast,
  ToastWrapper,
} from "@cedcommerce-integration/ounce-ui";
import { Search, Plus } from "react-feather";
import CreatePopover from "./components/CreatePopover";
import "./categorytemplate.css";
import { getMethod, postMethod } from "../../../../apiMethods/methods";
import { useEffect, useState } from "react";
import {
  getProductAPI,
  getProductCountAPI,
  getProfileDataAPI,
  getProfileDataCountAPI,
} from "../../../../APIs/PanelAPI";
import NestedTableComponent from "../../../OunceComponents/NestedTableComponent";
import NestedPagination from "../../../OunceComponents/NestedPagination";
import ViewRulesModal from "./components/ViewRulesModal";
import {
  TemplateGridPageSkeleton,
  TemplateGridSkeleton,
} from "../Skeleton/CategoryListingSkeleton";
import { useDebounce } from "../../../shared/DebounceComponent";
import { NoSearchTemp, NoTemplate } from "../../../shared/IconSvg";
import { getPoliciesAPI } from "../../../../APIs/loginAPI";
import { globalState } from "../../../../services/globalState";
import FallbackGrid from "../../../shared/FallbackGrid";
import ModalGridSkeleton from "../Skeleton/ModalGridSkeleton";
import NoImage from "../../../../assets/images/notfound.png";

const CategoryTemplate = (props) => {
  const gridColumns = [
    {
      align: "left",
      dataIndex: "name",
      key: "name",
      title: "Name",
      width: 140,
      visible: true,
    },
    {
      align: "left",
      dataIndex: "category",
      key: "category",
      title: "Category",
      visible: true,
    },
    {
      align: "left",
      dataIndex: "rules",
      key: "rules",
      title: "Rules",
      width: 140,
      visible: true,
    },
    {
      align: "left",
      dataIndex: "total_product",
      key: "total_product",
      title: "Total products",
      width: 220,
      visible: true,
    },
    {
      align: "center",
      dataIndex: "action",
      key: "action",
      title: "Actions",
      fixed: "right",
      width: 100,
      visible: true,
    },
  ];
  /* STATE VARIABLES START */
  const [skeleton, setSkeleton] = useState(true);
  const [gridSkeleton, setGridSkeleton] = useState(true);
  // template data
  const [allTemplates, setAllTemplates] = useState([]);
  const [allTemplatesCount, setAllTemplatesCount] = useState(null);
  // pagination
  const [pageSizeOptions, setPageSizeOptions] = useState([
    {
      label: "10",
      value: "10",
    },
    {
      label: "25",
      value: "25",
    },
    {
      label: "50",
      value: "50",
    },
    {
      label: "100",
      value: "100",
    },
  ]);
  const [payloadState, setPayloadState] = useState({
    activePage: 1,
    count: 10,
  });
  const [activePage, setActivePage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [viewActivePage, setViewActivePage] = useState(1);
  const [viewPageSize, setViewPageSize] = useState(10);
  const [viewProduct, setViewProduct] = useState(false);
  const [totalProductsData, setTotalProductsData] = useState([]);
  const [viewTotalCount, setViewTotalCount] = useState(0);
  const [row, setRow] = useState("");
  const [flag, setFlag] = useState(false);
  const [modalGridSkeleton, setModalGridSkeleton] = useState(false);
  //search field
  const [inputValue, setInputValue] = useState("");
  // view rules
  const [viewRules, setViewRules] = useState({
    active: false,
    rulesData: [],
    orAnd: "",
  });
  // no category template found
  const [noCategoryTemplateFound, setNoCategoryTemplateFound] = useState(false);
  // no search order found
  const [fallbackStatusNoSearchFound, setFallbackStatusNoSearchFound] =
    useState(false);
  //toast
  const [successToast, setSuccessToast] = useState({
    active: false,
    message: "",
    success: false,
  });
  const [errorToast, setErrorToast] = useState({
    active: false,
    message: "",
    success: false,
  });
  // refresh policy loader
  const [refreshPoliciesModalActive, setRefreshPoliciesModalActive] =
    useState(false);
  const [refreshLoader, setRefreshLoader] = useState(false);

  const [locale, setLocale] = useState("");

  /* STATE VARIABLES END */
  const columns = [
    {
      align: "left",
      dataIndex: "image",
      title: "Image",
      width: 250,
      visible: true,
    },
    {
      align: "left",
      title: "Name",
      dataIndex: "name",
      width: 250,
      visible: true,
    },
  ];
  const val = useDebounce(inputValue, setInputValue);

  const getProductListing = async (row) => {
    setModalGridSkeleton(true);
    const payload = {
      "filter[profile.profile_name][1]": row?.name,
      target_marketplace: "eyJtYXJrZXRwbGFjZSI6ImFsbCIsInNob3BfaWQiOm51bGx9",
      count: viewPageSize,
      activePage: viewActivePage,
    };
    let { success: successViewCount, data: viewDataCount } = await getMethod(
      getProductCountAPI,
      payload
    );
    if (successViewCount) {
      setViewTotalCount(viewDataCount?.count);
    }
    let { success, data, code } = await getMethod(getProductAPI, payload);
    if (success) {
      setTotalProductsData(data?.rows);
    } else if (
        code === "token_expired" ||
        code === "invalid_token" ||
        code === "future_token" ||
        code === "token_decode_error"
      ) {
        props.history.push("/tokenexpired");
      }
    setFlag(true);
    setModalGridSkeleton(false);
  };

  const parsedContent = (row, total_count, product_count) => {
    let prepareSentence;
    if (
      row.hasOwnProperty("product_update_in_progress") &&
      row["product_update_in_progress"]
    ) {
      prepareSentence = (
        <FlexLayout direction="vertical" spacing="mediumTight" wrap="noWrap">
          <FlexLayout spacing="mediumTight">
            <TextStyles
              textcolor="light"
              lineHeight="LH-2.0"
              type="Paragraph"
              paragraphTypes="MD-1.4"
            >
              Assigning Product
            </TextStyles>
            <div className="custom-spinner"></div>
          </FlexLayout>
          <TextStyles
            textcolor="light"
            lineHeight="LH-2.0"
            type="Paragraph"
            paragraphTypes="MD-1.4"
          >
            {`${product_count?.[0]?.count ?? 0} Assigned`}
          </TextStyles>
        </FlexLayout>
      );
    } else {
      prepareSentence =
        product_count?.[0]?.count > 0 ? (
          <Button
            type="TextButton"
            content={`${product_count?.[0]?.count ?? 0}`}
            onClick={() => {
              getProductListing(row);
              setViewProduct(!viewProduct);
              setRow(row);
            }}
          />
        ) : (
          <>0</>
        );
    }
    return prepareSentence;
  };

  const getParseRowData = (rows) => {
    let parsedData = rows.map((row, i) => {
      const {
        total_count,
        name,
        category_id,
        data,
        product_count,
      } = row;
      let parsedRowData = {};
      parsedRowData["key"] = category_id;
      parsedRowData["name"] = (
        <TextStyles
          type="Paragraph"
          paragraphTypes="MD-1.4"
          lineHeight="LH-2.0"
          textcolor="light"
          content={name}
        />
      );
      parsedRowData["category"] = (
        <TextStyles
          type="Paragraph"
          paragraphTypes="MD-1.4"
          lineHeight="LH-2.0"
          textcolor="light"
          content={data?.[0]?.primaryCategoryMappingName}
        />
      );
      parsedRowData["rules"] = (
        <Button
          type="TextButton"
          content="View Rules"
          onClick={() =>
            setViewRules({
              active: !viewRules.active,
              rulesData: data?.[0]?.prepareQuery?.queryArray,
              orAnd: data?.[0]?.prepareQuery?.querySentence.includes("or")
                ? "OR"
                : data?.[0]?.prepareQuery?.querySentence.includes("and")
                ? "AND"
                : "",
            })
          }
        />
      );
      parsedRowData["total_product"] = parsedContent(
        row,
        total_count,
        product_count
      );
      parsedRowData["total_product_value"] = total_count ?? 0;
      parsedRowData["action"] = (
        <CreatePopover record={row} getAllProfiles={getAllProfiles} />
      );
      return parsedRowData;
    });
    setAllTemplates(parsedData);
  };
  const getAllProfiles = async (payload, skeleton = false) => {
    const time1 = new Date();
    let ms1 = time1.getTime();
    setFallbackStatusNoSearchFound(false);
    if (skeleton) setGridSkeleton(true);
    payload = {
      ...payload,
      useRefinProduct: true,
    };
    let { success, data, code } = await getMethod(getProfileDataAPI, payload);
    let { success: successCount, total_count: dataCount } = await getMethod(
      getProfileDataCountAPI,
      payload
    );
    const time2 = new Date();
    let ms2 = time2.getTime();
    let timeOut = ms2 - ms1;
    if (success) {
      if (
        JSON.stringify(payload).includes("name") &&
        data?.["rows"].length === 0
      ) {
        setFallbackStatusNoSearchFound(true);
      } else if (data?.rows.length === 0) {
        setNoCategoryTemplateFound(true);
      }
      getParseRowData(data?.rows);
    } else if (
        code === "token_expired" ||
        code === "invalid_token" ||
        code === "future_token" ||
        code === "token_decode_error"
      ) {
        props.history.push("/tokenexpired");
      }
    if (successCount) {
      setAllTemplatesCount(dataCount);
    }
    if (timeOut < 500) {
      setTimeout(() => {
        setGridSkeleton(false);
        setSkeleton(false);
      }, 500 - timeOut);
    } else {
      setGridSkeleton(false);
      setSkeleton(false);
    }
  };

  useEffect(() => {
    let payload = {};
    if (val) {
      payload["name"] = val;
    }
    let totalCountReceived = allTemplates.filter(
      (e) => e?.total_product_value !== 0
    );
    let timer = setInterval(() => {
      getAllProfiles({ ...payload, activePage: activePage, count: pageSize });
    }, 5000);
    if (totalCountReceived.length === 0) {
      clearInterval(timer);
    }
    return () => {
      clearInterval(timer);
    };
  }, [val, activePage, pageSize, payloadState, allTemplates]);

  useEffect(() => {
    let payload = {};
    if (val) {
      payload["name"] = val;
    }
    getAllProfiles(
      { ...payload, activePage: activePage, count: pageSize },
      true
    );
  }, [val, activePage, pageSize, payloadState]);

  const refreshPolicies = async () => {
    setRefreshLoader(true);
    const ebayShopId = globalState.getLocalStorage("ebayShopId");
    const payloadData = {
      multitype: ["shipping", "payment", "return"],
      shop_id: ebayShopId,
      site_id: 15,
      refreshPolicies: 1,
    };
    let { success, message, code } = await postMethod(
      getPoliciesAPI,
      payloadData
    );
    if (success) {
      setSuccessToast({
        active: true,
        message: message,
        success: success,
      });
    } else if (code) {
      if (
        code === "token_expired" ||
        code === "invalid_token" ||
        code === "future_token" ||
        code === "token_decode_error"
      ) {
        props.history.push("/tokenexpired");
      }
    } else {
      setErrorToast({
        active: true,
        message: message,
        success: success,
      });
    }
    setRefreshLoader(false);
    setRefreshPoliciesModalActive(false);
  };

  const toastMarkup = successToast.active ? (
    <Toast
      message={successToast.message}
      onDismiss={() =>
        setSuccessToast({ active: false, message: "", success: false })
      }
      timeout={2000}
      type={successToast.success ? "success" : "error"}
    />
  ) : errorToast.active ? (
    <Toast
      message={errorToast.message}
      onDismiss={() =>
        setErrorToast({ active: false, message: "", success: false })
      }
      timeout={2000}
      type="error"
    />
  ) : null;

  useEffect(() => {
    if (fallbackStatusNoSearchFound)
      setLocale(
        <FallBack
          FallBackHeight="NormalPage"
          illustration={NoSearchTemp}
          title="No Category Template Found!"
          subTitle="Your request does not match the listed Template."
        />
      );
  }, [fallbackStatusNoSearchFound]);

  const tabledata = totalProductsData.map((data, index) => {
    return {
      key: index.toString(),
      product_type: data?.product_type,
      name: (
        <TextStyles
          textcolor="light"
          type="Paragraph"
          paragraphTypes="MD-1.4"
          lineHeight="LH-2.0"
        >
          {data?.title}
        </TextStyles>
      ),
      image: data?.main_image ? (
        <img
          src={data?.main_image}
          width="60px"
          height="60px"
          style={{ borderRadius: "4px" }}
          alt=""
        />
      ) : (
        <img
          src={NoImage}
          width="60px"
          height="60px"
          style={{ borderRadius: "4px" }}
          alt=""
        />
      ),
    };
  });

  useEffect(() => {
    if (flag) {
      getProductListing(row);
    }
  }, [viewActivePage, viewPageSize]);

  return (
    <>
      {noCategoryTemplateFound ? (
        <>
          <PageHeader
            title="Category Template"
            action={
              <Button
                onAction={() => setRefreshPoliciesModalActive(true)}
                thickness="thin"
                type="Outlined"
              >
                Refresh Policy
              </Button>
            }
            description={
              "Category Templates simplify the process of uploading and managing new products on eBay."
            }
          />
          <FallBack
            FallBackHeight="NormalPage"
            illustration={NoTemplate}
            title="No Category Template Found!"
            subTitle="You haven't created a category template yet. Please create one now."
            action={
              <Button
                icon={<Plus color="#fff" size={16} />}
                onAction={() => props.history.push("/panel/create-template")}
                thickness="large"
                type="Primary"
              >
                Create Template
              </Button>
            }
          />
        </>
      ) : (
        <>
          {skeleton ? (
            <TemplateGridPageSkeleton />
          ) : (
            <>
              <PageHeader
                action={
                  <FlexLayout spacing="mediumTight">
                    <Button
                      onAction={() => setRefreshPoliciesModalActive(true)}
                      type="Outlined"
                      thickness="thin"
                    >
                      Refresh Policy
                    </Button>
                    <Button
                      type="Primary"
                      thickness="thin"
                      onClick={() =>
                        props.history.push("/panel/create-template")
                      }
                    >
                      Create Template
                    </Button>
                  </FlexLayout>
                }
                title="Category Template"
                description={
                  "Category Templates simplify the process of uploading and managing new products on eBay."
                }
              />
              <Card>
                <Card cardType="Bordered" extraClass="ced-filter__wrap">
                  <FlexLayout spacing="tight" wrap="wrap">
                    <TextField
                      thickness="thin"
                      showHelp=""
                      placeHolder="Search Templates"
                      innerPreIcon={<Search size={20} />}
                      value={inputValue}
                      onChange={(e) => setInputValue(e)}
                      clearButton
                      clearFunction={() => setInputValue("")}
                    />
                  </FlexLayout>
                </Card>
                {gridSkeleton ? (
                  <TemplateGridSkeleton />
                ) : fallbackStatusNoSearchFound ? (
                  <FallbackGrid
                    local={locale}
                    columns={gridColumns}
                    dataSource={allTemplates}
                  />
                ) : (
                  <>
                    <NestedTableComponent
                      columns={gridColumns}
                      dataSource={allTemplates}
                      expandable={false}
                    />
                    <Card cardType="Bordered" extraClass="ced-pagination__wrap">
                      <NestedPagination
                        totalCount={allTemplatesCount}
                        getProductsApi={getAllProfiles}
                        pageSizeOptions={pageSizeOptions}
                        activePage={activePage}
                        setActivePage={setActivePage}
                        pageSize={pageSize}
                        setPageSize={setPageSize}
                        payloadState={payloadState}
                        setPayloadState={setPayloadState}
                      />
                    </Card>
                  </>
                )}
              </Card>
            </>
          )}
          <ViewRulesModal viewRules={viewRules} setViewRules={setViewRules} />
        </>
      )}
      {/* view total products */}
      <Modal
        closeOnEscape={true}
        overlayClose={true}
        modalSize="medium"
        open={viewProduct}
        heading="Products"
        close={() => {
          setViewProduct(!viewProduct);
          setViewActivePage(1);
          setViewPageSize(10);
          setFlag(false);
          setTotalProductsData([]);
        }}
      >
        {modalGridSkeleton ? (
          <ModalGridSkeleton />
        ) : (
          <>
            <Grid
              columns={[...columns].filter((col) => col?.visible)}
              dataSource={tabledata}
            />

            <Card cardType="Bordered" extraClass="ced-pagination__wrap">
              <NestedPagination
                pageSize={viewPageSize}
                setPageSize={setViewPageSize}
                activePage={viewActivePage}
                setActivePage={setViewActivePage}
                pageSizeOptions={pageSizeOptions}
                totalCount={viewTotalCount}
                payloadState={payloadState}
                setPayloadState={setPayloadState}
              />
            </Card>
          </>
        )}
      </Modal>
      <Modal
        closeOnEscape={!refreshLoader}
        overlayClose={!refreshLoader}
        open={refreshPoliciesModalActive}
        close={() =>
          refreshLoader
            ? false
            : setRefreshPoliciesModalActive(!refreshPoliciesModalActive)
        }
        heading="Refresh Policies"
        modalSize="small"
        primaryAction={{
          content: "Refresh",
          loading: refreshLoader,
          onClick: () => refreshPolicies(),
        }}
      >
        <FlexLayout direction="vertical" spacing="tight" wrap="noWrap">
          <TextStyles
            alignment="left"
            fontweight="extraBold"
            lineHeight="LH-2.0"
            paragraphTypes="MD-1.4"
            textcolor="dark"
            type="Paragraph"
            utility="none"
          >
            Are you sure you want to refresh policies?
          </TextStyles>
        </FlexLayout>
      </Modal>
      <ToastWrapper>{toastMarkup}</ToastWrapper>
    </>
  );
};

export default CategoryTemplate;
