/* eslint-disable react-hooks/exhaustive-deps */
import {
  Card,
  FlexLayout,
  Modal,
  TextStyles,
} from "@cedcommerce-integration/ounce-ui";
import { useEffect, useState } from "react";
import {
  CountryLogo,
  eBay_logoSVG,
  ShoplineIconSVG,
} from "../../../../shared/IconSvg";
import { getMethod, postMethod } from "../../../../../apiMethods/methods";
import { getAccountsAPI, tokenUpdateApi } from "../../../../../APIs/loginAPI";
import { globalState } from "../../../../../services/globalState";
import { environment } from "../../../../../environment/environment";
import AccountSettingSkeleton from "../../Skeleton/AccountSettingSkeleton";

const AccountSettings = (props) => {

  const [reconnectAccountLoader, setReconnectAccountLoader] = useState(false);
  const [ebaySellerId, setEbaySellerId] = useState("");
  const [shoplineDetails, setShoplineDetails] = useState({
    shopUrl: "",
    name: "",
    email: "",
  });
  const [accountSkeleton, setAccountSkeleton] = useState(true);
  const [errModal, setErrModal] = useState(false);

  const getAccounts = async () => {
    const time1 = new Date();
    let ms1 = time1.getTime();
    setAccountSkeleton(true);
    let { success, data,code } = await getMethod(getAccountsAPI);
    const time2 = new Date();
    let ms2 = time2.getTime();
    let timeOut = ms2 - ms1;
    if (success) {
      if (
        data?.ebay &&
        data?.ebay?.installed &&
        Array.isArray(data?.ebay?.installed) &&
        data?.ebay?.installed.length > 0
      ) {
        setEbaySellerId(data?.ebay?.installed?.[0]?.username);
      }
      if (
        data?.shopline &&
        data?.shopline?.installed &&
        Array.isArray(data?.shopline?.installed) &&
        data?.shopline?.installed.length > 0
      ) {
        const { domain, email, username } = data?.shopline?.installed?.[0] ?? {};
        setShoplineDetails({ shopUrl: domain, email: email, name: username });
      }
    } else if (
        code === "token_expired" ||
        code === "invalid_token" ||
        code === "future_token" ||
        code === "token_decode_error"
      ) {
        props.history.push("/tokenexpired");
      }
    if (timeOut < 500) {
      setTimeout(() => {
        setAccountSkeleton(false);
      }, 500 - timeOut);
    } else setAccountSkeleton(false);
  };

  useEffect(() => {
    getAccounts();
  }, []);

  const updateTokenApi = async () => {
    setReconnectAccountLoader(true);
    const ShoplineShopId = globalState.getLocalStorage("shopLineShopId");
    const ebayShopId = globalState.getLocalStorage("ebayShopId");
    let postData = {
      shop_id: ebayShopId,
      source: {
        shopId: ShoplineShopId,
        marketplace: "shopline",
      },

      target: {
        shopId: ebayShopId,
        marketplace: "ebay",
      },
    };
    const { success, code } = await postMethod(tokenUpdateApi, postData);
    if (!success) {
      let test = window.open(
        `${
          environment.API_ENDPOINT
        }connector/get/installationForm?code=ebay&site_id=15&mode=sandbox&bearer=${globalState.getLocalStorage(
          "auth_token"
        )}`,
        "popUpWindow",
        "height=500,width=800,left=100,top=100,resizable=yes,scrollbars=yes,toolbar=yes,menubar=no,location=no,directories=no, status=yes"
      );
    } else if (
        code === "token_expired" ||
        code === "invalid_token" ||
        code === "future_token" ||
        code === "token_decode_error"
      ) {
        props.history.push("/tokenexpired");
      }
    setReconnectAccountLoader(false);
  };

  return (
    <>
      {accountSkeleton ? (
        <AccountSettingSkeleton />
      ) : (
        <>
          <Card
            title="Accounts"
            subTitle={"Get an overview of your active account credentials."}
            extraClass="header-pb-16"
          >
            <FlexLayout direction="vertical" spacing="loose" wrap="noWrap">
              <Card
                title={
                  <TextStyles
                    type="Paragraph"
                    paragraphTypes="MD-1.4"
                    lineHeight="LH-2.0"
                    fontweight="bold"
                  >
                    eBay
                  </TextStyles>
                }
                action={eBay_logoSVG}
                cardType="Bordered"
                extraClass="settingPage--section header-pb-16"
              >
                <FlexLayout spacing="tight">
                  <Card cardType="Bordered" extraClass="country-logo">
                    {CountryLogo}
                  </Card>
                  <FlexLayout
                    direction="vertical"
                    spacing="extraTight"
                    wrap="noWrap"
                  >
                    <TextStyles
                      lineHeight="LH-2.0"
                      paragraphTypes="MD-1.4"
                      textcolor="dark"
                      type="Paragraph"
                    >
                      Australia
                    </TextStyles>
                    <FlexLayout spacing="extraTight">
                      <TextStyles
                        lineHeight="LH-2.0"
                        paragraphTypes="MD-1.4"
                        textcolor="light"
                        type="Paragraph"
                        fontweight="bold"
                      >
                        Seller ID:
                      </TextStyles>
                      <TextStyles
                        lineHeight="LH-2.0"
                        paragraphTypes="MD-1.4"
                        textcolor="light"
                        type="Paragraph"
                      >
                        {ebaySellerId}
                      </TextStyles>
                    </FlexLayout>
                  </FlexLayout>
                </FlexLayout>
              </Card>
              <Card
                title={
                  <TextStyles
                    type="Paragraph"
                    paragraphTypes="MD-1.4"
                    lineHeight="LH-2.0"
                    fontweight="bold"
                  >
                    Shopline
                  </TextStyles>
                }
                subTitle={shoplineDetails.shopUrl}
                action={ShoplineIconSVG}
                cardType="Bordered"
                extraClass="settingPage--section header-pb-16"
              >
                <FlexLayout direction="vertical" spacing="loose" wrap="noWrap">
                  <FlexLayout
                    direction="vertical"
                    spacing="extraTight"
                    wrap="noWrap"
                  >
                    <TextStyles
                      lineHeight="LH-2.0"
                      paragraphTypes="MD-1.4"
                      textcolor="dark"
                      type="Paragraph"
                      fontweight="bold"
                    >
                      {shoplineDetails.name}
                    </TextStyles>
                    <TextStyles
                      lineHeight="LH-2.0"
                      paragraphTypes="MD-1.4"
                      textcolor="light"
                      type="Paragraph"
                    >
                      {shoplineDetails.email}
                    </TextStyles>
                  </FlexLayout>
                </FlexLayout>
              </Card>
            </FlexLayout>
          </Card>
          <Modal
            closeOnEscape={true}
            overlayClose={true}
            open={errModal}
            close={() => {
              setErrModal(!errModal);
            }}
            heading="Reconnect eBay Account"
            modalSize="small"
            primaryAction={{
              content: "Confirm Reconnect",
              loading: reconnectAccountLoader,
              onClick: () => {
                updateTokenApi();
              },
            }}
          >
            Are you sure you want to reconnect your eBay account?
          </Modal>
        </>
      )}
    </>
  );
};

export default AccountSettings;
