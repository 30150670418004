/* eslint-disable react-hooks/exhaustive-deps */
import {
  Alert,
  Card,
  FallBack,
  FlexLayout,
  Modal,
  Tabs,
  TextStyles,
  Toast,
  ToastWrapper,
} from "@cedcommerce-integration/ounce-ui";
import React, { useCallback, useEffect, useState } from "react";
import { withRouter } from "react-router-dom";
import { getMethod } from "../../../../../../Apirequest/productListingApi";
import { productStatusAPI } from "../../../../../../APIs/PanelAPI";

import {
  ActiveBadge,
  AllBadge,
  ErrorBadge,
  InactiveBadge,
  NotUploadedBadge,
} from "./IconSvg";
import { ProductPageSkeleton } from "../../../Skeleton/ProductListing";
import Uploaded from "./Uploaded";
import EndedComponent from "./Ended";
import ErrorComponent from "./Error";
import Notuploaded from "./NotUploaded";
import UploadedWithError from "./UploadedWithError";
import All from "./AllComponent";
import FallbackGrid from "../../../../../shared/FallbackGrid";
import { NoProductsvg } from "../../IconSvg";
import StatusData from "./StatusData";

export const listingContext = React.createContext(null);
const ProductStatusData = [
  {
    text: "All",
    description: "Total no. of products.",
    icon: AllBadge,
  },
  {
    text: "Uploaded",
    description:
      "List of products that have been uploaded to eBay marketplace.",
    icon: ActiveBadge,
  },
  {
    text: "Not Uploaded",
    description:
      "Total no. of products imported from Shopline to the app but not uploaded in eBay marketplace.",
    icon: NotUploadedBadge,
  },
  {
    text: "Ended",
    description:
      "Products status set as `In Ended` if not visible in eBay marketplace.",
    icon: InactiveBadge,
  },
  {
    text: "Errors",
    description: "Total no. of products with errors that need to be corrected.",
    icon: ErrorBadge,
  },
];

const ProductList = (props) => {
  const { productGridskeleton, setProductGridskeleton, templateNameOptions } =
    props;
  // actions
  const [actionsModal, setActionsModal] = useState({
    active: false,
    content1: "",
    content2: "",
    api: "",
    methodName: "",
    payload: {},
    loader: false,
  });
  const [actionApiReturnMsg, setActionApiReturnMsg] = useState("");

  const [selected, setSelected] = useState(
    props?.location?.state?.tabFromDashboard
      ? props?.location?.state?.tabFromDashboard
      : "all"
  );
  const [massAction, setMassAction] = useState(false);
  const [totalSelectedRows, setTotalSelectedRows] = useState([]);
  const [selectedRowsObj, setSelectedRowsObj] = useState({});

  const handleTabChange = useCallback((e) => {
    setSelected(e);
  }, []);
  //   toast after delete template
  const [errorToast, setErrorToast] = useState(false);
  const [successToast, setSuccessToast] = useState(false);
  const [tabsCount, setTabsCount] = useState({
    all: 0,
    uploaded: 0,
    notUploaded: 0,
    uploadedWithError: 0,
    ended: 0,
    errors: 0,
  });
  // eslint-disable-next-line no-unused-vars
  const [locale, setLocale] = useState(
    <FallBack
      FallBackHeight="NormalPage"
      illustration={NoProductsvg}
      title="No Product Available!"
      subTitle="Your request does not match the listed Products."
    />
  );
  const [fallbackStatusNoProductsAll, setFallbackStatusNoProductsAll] =
    useState(false);
  const [
    fallbackStatusNoProductsUploaded,
    setFallbackStatusNoProductsUploaded,
  ] = useState(false);
  const [
    fallbackStatusNoProductsNotUploaded,
    setFallbackStatusNoProductsNotUploaded,
  ] = useState(false);
  const [
    fallbackStatusNoProductsUploadedError,
    setFallbackStatusNoProductsUploadedError,
  ] = useState(false);
  const [fallbackStatusNoProductsEnded, setFallbackStatusNoProductsEnded] =
    useState(false);
  const [fallbackStatusNoProductsErrors, setFallbackStatusNoProductsErrors] =
    useState(false);
  // fallback - no products

  const [close, setClose] = useState(true);
  const [columns, setColumns] = useState([
    {
      dataIndex: "image",
      visible: true,
      key: "image",
      title: "Image",
      width: 76,
    },
    {
      dataIndex: "title",
      visible: true,
      key: "title",
      title: "Title",
      width: 200,
    },
    {
      dataIndex: "price",
      visible: true,
      key: "price",
      title: "Price",
      width: 150,
    },
    {
      dataIndex: "quantity",
      visible: true,
      key: "quantity",
      title: "Quantity",
      width: 140,
    },
    {
      dataIndex: "status",
      visible: true,
      key: "status",
      title: "Status",
      width: 170,
    },
    {
      dataIndex: "sku",
      visible: false,
      key: "sku",
      title: "SKU",
      width: 140,
    },
    {
      dataIndex: "template",
      visible: false,
      key: "template",
      title: "Template",
      width: 140,
    },
    {
      dataIndex: "product_type",
      visible: false,
      key: "product_type",
      title: "Product Type",
      width: 160,
    },
    {
      dataIndex: "brand",
      visible: false,
      key: "brand",
      title: "Vendor",
      width: 160,
    },
    {
      align: "center",
      fixed: "right",
      dataIndex: "action",
      visible: true,
      key: "action",
      title: "Actions",
      width: 100,
    },
  ]);
  // eslint-disable-next-line no-unused-vars
  const [variantColumns, setVariantColumns] = useState([
    {
      key: "XYZ",
      dataIndex: "XYZ",
      visible: true,
      width: 90,
    },
    {
      dataIndex: "image",
      visible: true,
      key: "image",
      title: "Image",
      width: 76,
    },
    {
      dataIndex: "title",
      visible: true,
      key: "title",
      title: "Title",
    },
    {
      dataIndex: "price",
      visible: true,
      key: "price",
      title: "Price",
      width: 300,
    },
    {
      dataIndex: "quantity",
      visible: true,
      key: "quantity",
      title: "Quantity",
      width: 260,
    },
  ]);

  const toggleErrorActive = useCallback(() => {
    setErrorToast((active) => !active);
  }, []);

  const toggleSuccessActive = useCallback(() => {
    setSuccessToast((active) => !active);
  }, []);

  const toastMarkup = successToast ? (
    <Toast
      message={actionApiReturnMsg}
      onDismiss={toggleSuccessActive}
      timeout={2000}
      type="success"
    />
  ) : errorToast ? (
    <Toast
      message={actionApiReturnMsg}
      onDismiss={toggleErrorActive}
      timeout={2000}
      type="error"
    />
  ) : null;

  const getStatusWiseCount = async () => {
    const time1 = new Date();
    let ms1 = time1.getTime();
    let { success, data, code } = await getMethod(productStatusAPI); // 3 times
    const time2 = new Date();
    let ms2 = time2.getTime();
    let timeOut = ms2 - ms1;
    if (success) {
      let tempTabsCount = { ...tabsCount };
      tempTabsCount["all"] = 0;
      data.forEach((status, index) => {
        tempTabsCount["all"] += status?.total;
        if (status?._id === "Active") tempTabsCount["uploaded"] = status?.total;
        else if (status?._id === null)
          tempTabsCount["notUploaded"] = status?.total;
        else if (status?._id === "Ended")
          tempTabsCount["ended"] = status?.total;
        else if (status?._id === "Error")
          tempTabsCount["errors"] = status?.total;
        else tempTabsCount["uploadedWithError"] = status?.total;
      });
      if (tempTabsCount["all"] === 0) {
        setFallbackStatusNoProductsAll(true);
      } else {
        setFallbackStatusNoProductsAll(false);
      }
      if (tempTabsCount["uploaded"] === 0) {
        setFallbackStatusNoProductsUploaded(true);
      } else {
        setFallbackStatusNoProductsUploaded(false);
      }
      if (tempTabsCount["notUploaded"] === 0) {
        setFallbackStatusNoProductsNotUploaded(true);
      } else {
        setFallbackStatusNoProductsNotUploaded(false);
      }
      if (tempTabsCount["uploadedWithError"] === 0) {
        setFallbackStatusNoProductsUploadedError(true);
      } else {
        setFallbackStatusNoProductsUploadedError(false);
      }
      if (tempTabsCount["ended"] === 0) {
        setFallbackStatusNoProductsEnded(true);
      } else {
        setFallbackStatusNoProductsEnded(false);
      }
      if (tempTabsCount["errors"] === 0) {
        setFallbackStatusNoProductsErrors(true);
      } else {
        setFallbackStatusNoProductsErrors(false);
      }
      setTabsCount(tempTabsCount);
    } else if (
        code === "token_expired" ||
        code === "invalid_token" ||
        code === "future_token" ||
        code === "token_decode_error"
      ) {
        props.history.push("/tokenexpired");
      }
    if (timeOut < 500) {
      setTimeout(() => {
        setProductGridskeleton(false);
      }, 500 - timeOut);
    } else setProductGridskeleton(false);
  };

  useEffect(() => {
    getStatusWiseCount();
  }, []);

  return (
    <>
      {productGridskeleton ? (
        <ProductPageSkeleton />
      ) : (
        <FlexLayout direction="vertical" spacing="loose" wrap="noWrap">
          {close && (
            <Alert
              destroy={false}
              icon
              onClose={() => setClose(false)}
              type="info"
            >
              The product count displayed in the menu below includes both the
              base product and its variants.
            </Alert>
          )}
          <Card extraClass="ced-product__grid">
            <FlexLayout direction="vertical" spacing="extraLoose" wrap="noWrap">
              <>
                <Tabs
                  alignment="horizontal"
                  onChange={(e) => {
                    handleTabChange(e);
                  }}
                  selected={selected}
                  value={[
                    {
                      badge: true,
                      badgeContent: tabsCount.all,
                      badgeTextColor: "light",
                      content: "All",
                      customBgColors: "#9984DB",
                      id: "all",
                    },
                    {
                      badge: true,
                      badgeContent: tabsCount.uploaded,
                      badgeTextColor: "light",
                      customBgColors: "#269E6C",
                      content: "Uploaded",
                      id: "uploaded",
                    },
                    {
                      badge: true,
                      badgeContent: tabsCount.notUploaded,
                      badgeTextColor: "dark",
                      customBgColors: "#FEC84B",
                      content: "Not Uploaded",
                      id: "not_uploaded",
                    },
                    {
                      badge: true,
                      badgeContent: tabsCount.ended,
                      badgeTextColor: "dark",
                      customBgColors: "#E0E1E3",
                      content: "Ended",
                      id: "ended",
                    },
                    {
                      badge: true,
                      badgeContent: tabsCount.errors,
                      badgeTextColor: "light",
                      customBgColors: "#FF0000",
                      content: "Errors",
                      id: "errors",
                    },
                  ]}
                >
                  <StatusData
                    ProductStatusData={ProductStatusData}
                    Title="Product Statuses"
                  />
                  {selected === "all" ? (
                    <All
                      columns={columns}
                      variantColumns={variantColumns}
                      totalCount={tabsCount.uploaded}
                      setColumns={setColumns}
                      actionsModal={actionsModal}
                      setActionsModal={setActionsModal}
                      massAction={massAction}
                      setProductGridskeleton={setProductGridskeleton}
                      totalSelectedRows={totalSelectedRows}
                      setTotalSelectedRows={setTotalSelectedRows}
                      selectedRowsObj={selectedRowsObj}
                      setSelectedRowsObj={setSelectedRowsObj}
                      locale1={locale}
                      fallbackStatusNoProductsAll={fallbackStatusNoProductsAll}
                      getStatusWiseCount={getStatusWiseCount}
                      templateNameOptions={templateNameOptions}
                    />
                  ) : selected === "uploaded" ? (
                    <Uploaded
                      columns={columns}
                      variantColumns={variantColumns}
                      totalCount={tabsCount.uploaded}
                      setColumns={setColumns}
                      actionsModal={actionsModal}
                      setActionsModal={setActionsModal}
                      massAction={massAction}
                      totalSelectedRows={totalSelectedRows}
                      setTotalSelectedRows={setTotalSelectedRows}
                      selectedRowsObj={selectedRowsObj}
                      setSelectedRowsObj={setSelectedRowsObj}
                      locale1={locale}
                      fallbackStatusNoProductsUploaded={
                        fallbackStatusNoProductsUploaded
                      }
                      getStatusWiseCount={getStatusWiseCount}
                      templateNameOptions={templateNameOptions}
                      name="Active"
                    />
                  ) : selected === "not_uploaded" ? (
                    <Notuploaded
                      columns={columns}
                      variantColumns={variantColumns}
                      totalCount={tabsCount.notUploaded}
                      setColumns={setColumns}
                      actionsModal={actionsModal}
                      setActionsModal={setActionsModal}
                      massAction={massAction}
                      totalSelectedRows={totalSelectedRows}
                      setTotalSelectedRows={setTotalSelectedRows}
                      selectedRowsObj={selectedRowsObj}
                      setSelectedRowsObj={setSelectedRowsObj}
                      locale1={locale}
                      fallbackStatusNoProductsNotUploaded={
                        fallbackStatusNoProductsNotUploaded
                      }
                      getStatusWiseCount={getStatusWiseCount}
                      templateNameOptions={templateNameOptions}
                      name="NotUploaded"
                    />
                  ) : selected === "uploaded_with_error" ? (
                    fallbackStatusNoProductsUploadedError ? (
                      <FallbackGrid
                        columns={columns}
                        dataSource={[]}
                        rowSelection={{}}
                        local={locale}
                      />
                    ) : (
                      <UploadedWithError
                        columns={columns}
                        variantColumns={variantColumns}
                        totalCount={tabsCount.uploadedWithError}
                        setColumns={setColumns}
                        actionsModal={actionsModal}
                        setActionsModal={setActionsModal}
                        massAction={massAction}
                        name="withError"
                      />
                    )
                  ) : selected === "ended" ? (
                    <EndedComponent
                      columns={columns}
                      variantColumns={variantColumns}
                      totalCount={tabsCount.ended}
                      setColumns={setColumns}
                      actionsModal={actionsModal}
                      setActionsModal={setActionsModal}
                      massAction={massAction}
                      totalSelectedRows={totalSelectedRows}
                      setTotalSelectedRows={setTotalSelectedRows}
                      selectedRowsObj={selectedRowsObj}
                      setSelectedRowsObj={setSelectedRowsObj}
                      locale1={locale}
                      fallbackStatusNoProductsEnded={
                        fallbackStatusNoProductsEnded
                      }
                      getStatusWiseCount={getStatusWiseCount}
                      templateNameOptions={templateNameOptions}
                    />
                  ) : selected === "errors" ? (
                    <ErrorComponent
                      columns={columns}
                      variantColumns={variantColumns}
                      totalCount={tabsCount.errors}
                      setColumns={setColumns}
                      actionsModal={actionsModal}
                      setActionsModal={setActionsModal}
                      massAction={massAction}
                      totalSelectedRows={totalSelectedRows}
                      setTotalSelectedRows={setTotalSelectedRows}
                      selectedRowsObj={selectedRowsObj}
                      setSelectedRowsObj={setSelectedRowsObj}
                      locale1={locale}
                      fallbackStatusNoProductsErrors={
                        fallbackStatusNoProductsErrors
                      }
                      getStatusWiseCount={getStatusWiseCount}
                      templateNameOptions={templateNameOptions}
                      name="Error"
                    />
                  ) : null}
                </Tabs>
                <Modal
                  closeOnEscape={true}
                  overlayClose={true}
                  modalSize="small"
                  open={actionsModal.active}
                  heading={
                    actionsModal.content1 === "Upload Products"
                      ? "Upload Products"
                      : actionsModal.content1
                  }
                  primaryAction={{
                    content: actionsModal.content1,
                    loading: actionsModal.loader,
                    type:
                      actionsModal.content2 === "end" ? "Danger" : "Primary",
                    onClick: async () => {
                      sessionStorage.removeItem("_id");
                      setActionsModal({ ...actionsModal, loader: true });
                      const { api, methodName, payload } = actionsModal;
                      let { success, message, data, code } = await methodName(
                        api,
                        payload
                      );
                      if (success) {
                        setSuccessToast(true);
                      } else if (code) {
                        if (
                          code === "token_expired" ||
                          code === "invalid_token" ||
                          code === "future_token" ||
                          code === "token_decode_error"
                        ) {
                          props.history.push("/tokenexpired");
                        }
                      } else {
                        setErrorToast(true);
                      }
                      if (message) {
                        setActionApiReturnMsg(message);
                      } else {
                        setActionApiReturnMsg(data);
                      }
                      setActionsModal({
                        active: false,
                        content1: "",
                        content2: "",
                        api: "",
                        methodName: "",
                        payload: {},
                        loader: false,
                      });
                      setMassAction(!massAction);
                      setTotalSelectedRows([]);
                      setSelectedRowsObj({});
                    },
                  }}
                  close={() => {
                    setActionsModal({
                      active: false,
                      content1: "",
                      api: "",
                      methodName: "",
                      payload: {},
                      loader: false,
                    });
                  }}
                >
                  <TextStyles>
                    Are you sure you want to {actionsModal.content2} selected
                    products?
                  </TextStyles>
                </Modal>
                <ToastWrapper>{toastMarkup}</ToastWrapper>
              </>
            </FlexLayout>
          </Card>
        </FlexLayout>
      )}
    </>
  );
};

export default withRouter(ProductList);
