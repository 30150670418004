import {
  Card,
  FlexLayout,
  Modal,
  TextStyles,
} from "@cedcommerce-integration/ounce-ui";
import React from "react";

const ViewRulesModal = ({ viewRules, setViewRules }) => {
  const { rulesData, orAnd } = viewRules;
  return (
    <Modal
      closeOnEscape={true}
      overlayClose={true}
      open={viewRules.active}
      heading="Product Rules"
      close={() => {
        setViewRules({ active: !viewRules, rulesData: [], orAnd: "" });
      }}
    >
      <FlexLayout direction="vertical" spacing="tight" wrap="noWrap">
        {rulesData.map((rule, index) => {
          const {
            value,
            attributeLabel,
            conditionLabel,
          } = rule;
          return (
            <FlexLayout direction="vertical" spacing="tight" wrap="noWrap" key={`rule-${index}`}>
              <Card cardType="Subdued">
                <FlexLayout desktopWidth="33" tabWidth="33" mobileWidth="33">
                  <TextStyles
                    type="Paragraph"
                    paragraphTypes="MD-1.4"
                    lineHeight="LH-2.0"
                  >
                    {attributeLabel}
                  </TextStyles>
                  <TextStyles
                    type="Paragraph"
                    paragraphTypes="MD-1.4"
                    lineHeight="LH-2.0"
                    fontweight="extraBold"
                    alignment="center"
                  >
                    {conditionLabel}
                  </TextStyles>
                  <TextStyles
                    type="Paragraph"
                    paragraphTypes="MD-1.4"
                    alignment="right"
                    lineHeight="LH-2.0"
                  >
                    {value}
                  </TextStyles>
                </FlexLayout>
              </Card>
              {rulesData.length - 1 !== index && (
                <TextStyles
                  type="Paragraph"
                  paragraphTypes="MD-1.4"
                  alignment="center"
                  utility="line-separator"
                >
                  {orAnd}
                </TextStyles>
              )}
            </FlexLayout>
          );
        })}
      </FlexLayout>
    </Modal>
  );
};

export default ViewRulesModal;
