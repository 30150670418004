/* eslint-disable react-hooks/exhaustive-deps */
import {
  AdvanceFilter,
  Button,
  Card,
  CheckBox,
  FlexChild,
  FlexLayout,
  Popover,
  Radio,
  Select,
  Tag,
  TextField,
} from "@cedcommerce-integration/ounce-ui";
import React, { useEffect, useState } from "react";
import { ChevronDown, ChevronUp, Filter, Search } from "react-feather";
import { useDebounce } from "../../../../../shared/DebounceComponent";

const Filters = ({
  columns,
  setColumns,
  setActivePage,
  payloadState,
  setPayloadState,
  templateNameOptions,
}) => {
  const [searchedValue, setSearchedValue] = useState("");
  const [active, setActive] = useState(false);
  const [openQuantTag, setOpenQuantTag] = useState(false);
  const [openPriceTag, setOpenPriceTag] = useState(false);
  const [flag, setFlag] = useState(false);
  const [colOptions, setColOptions] = useState([
    {
      label: "Price",
      value: "price",
      checked: true,
    },
    {
      label: "Quantity",
      value: "quantity",
      checked: true,
    },
    {
      label: "Template",
      value: "template",
      checked: true,
    },
    {
      label: "Status",
      value: "status",
      checked: true,
    },
    {
      label: "Vendor",
      value: "brand",
      checked: false,
    },
  ]);
  // advance filters
  const [productType, setProductType] = useState({
    simple: false,
    variation: false,
  });
  const [templateNameValue, setTemplateNameValue] = useState("");
  const [showTags, setShowTags] = useState(false);
  // disable state
  const [disableApply, setDisableApply] = useState(true);
  const [disableReset, setDisableReset] = useState(true);

  const [quantity, setQuantity] = useState({
    minimum_quantity: "",
    maximum_quantity: "",
  });
  const [price, setPrice] = useState({
    minimum_price: "",
    maximum_price: "",
  });
  // tags
  const [tags, setTags] = useState({
    typeTag: { simple: false, variation: false },
    templateName: "",
    quantityTag: {
      min_quantity: "",
      max_quantity: "",
    },
    priceTag: {
      min_price: "",
      max_price: "",
    },
  });
  // debouncing
  const val = useDebounce(searchedValue, setSearchedValue);

  const handleType = (type, type2) => {
    let tempType = { ...productType };
    tempType[`${type}`] = true;
    tempType[`${type2}`] = false;
    setProductType(tempType);
  };
  const handleQuantity = (field, e) => {
    let tempQuantity = { ...quantity };
    tempQuantity[`${field}_quantity`] = e;
    setQuantity(tempQuantity);
  };

  const handlePrice = (field, e) => {
    let tempPrice = { ...price };
    tempPrice[`${field}_price`] = e;
    setPrice(tempPrice);
  };

  useEffect(() => {
    let tempColumns = [...columns];
    columns.forEach((column, index) => {
      let temp = colOptions.find((col) => column.key === col.value);
      if (temp) {
        tempColumns[index]["visible"] = temp.checked;
      }
    });
    setColumns(tempColumns);
  }, [colOptions]);

  useEffect(() => {
    if (flag) {
      setActivePage(1);
      if (!val) {
        let tempPayload = { ...payloadState };
        tempPayload["activePage"] = 1;
        delete tempPayload["or_filter[items.title][3]"];
        delete tempPayload["or_filter[source_product_id][3]"];
        delete tempPayload["or_filter[items.sku][3]"];
        setPayloadState(tempPayload);
      } else {
        setPayloadState({
          ...payloadState,
          activePage: 1,
          "or_filter[items.title][3]": searchedValue,
          "or_filter[source_product_id][3]": searchedValue,
          "or_filter[items.sku][3]": searchedValue,
        });
      }
    }
  }, [val]);

  useEffect(() => {
    setFlag(true);
  }, []);

  const renderColumnsChoicelist = () => {
    return (
      <FlexLayout direction="vertical" wrap="noWrap" spacing="loose">
        {colOptions.map((col, i) => (
          <CheckBox
            key={i}
            labelVal={col.label}
            checked={colOptions[i].checked}
            onClick={() => {
              let temp = [...colOptions];
              temp[i].checked = !temp[i].checked;
              setColOptions(temp);
            }}
          />
        ))}
      </FlexLayout>
    );
  };

  const typeFilter = (typeObj, payloadState, tags) => {
    let flag = false;
    let temp = "";
    let payload = { activePage: 1, count: 10 };
    if (Object.keys(typeObj).length === 0) {
      typeObj = { ...productType };
    }
    Object.keys(typeObj).forEach((key, index) => {
      if (typeObj[key]) {
        temp = key;
        flag = true;
      }
    });
    if (flag) {
      payload["filter[type][1]"] = temp;
      tags["typeTag"]["simple"] = false;
      tags["typeTag"]["variation"] = false;
      tags["typeTag"][temp] = true;
      return { ...payloadState, ...payload, tags };
    } else {
      let tempPayload = { ...payloadState };
      delete tempPayload["filter[type][1]"];
      return { ...tempPayload, tags };
    }
  };

  const templateFilter = (template, payloadState, tags) => {
    delete payloadState?.["tags"];
    let flag = false;
    let payload = { activePage: 1, count: 10 };
    if (!template && templateNameValue) {
      template = templateNameValue;
      flag = true;
    }
    if (flag) {
      payload["filter[profile.profile_name][1]"] = templateNameValue;
      tags["templateName"] = templateNameValue;
      return { ...payloadState, ...payload, tags };
    } else {
      let tempPayload = { ...payloadState };
      delete tempPayload["filter[profile.profile_name][1]"];
      return { ...tempPayload, tags };
    }
  };

  const quantityFilter = (quantityObj, payloadState, tags) => {
    delete payloadState?.["tags"];
    let tempData = [];
    let payload = { activePage: 1, count: 10 };
    if (Object.keys(quantityObj).length === 0) {
      quantityObj = { ...quantity };
    }
    Object.keys(quantityObj).forEach((key, index) => {
      if (quantityObj[key]) {
        tempData[index] = quantityObj[key];
      } else tempData[index] = "";
    });
    let tempPayload = { ...payloadState };
    tempData.forEach((data, index) => {
      if (data) {
        if (index) {
          payload["filter[items.quantity][7][to]"] = data;
          tags["quantityTag"]["max_quantity"] = data;
        } else {
          payload["filter[items.quantity][7][from]"] = data;
          tags["quantityTag"]["min_quantity"] = data;
        }
      } else if (index) {
        delete tempPayload["filter[items.quantity][7][to]"];
        tags["quantityTag"]["max_quantity"] = "";
      } else {
        delete tempPayload["filter[items.quantity][7][from]"];
        tags["quantityTag"]["min_quantity"] = "";
      }
    });
    return { ...tempPayload, ...payload, tags };
  };

  const priceFilter = (priceObj, payloadState, tags) => {
    delete payloadState?.["tags"];
    let tempData = [];
    let payload = { activePage: 1, count: 10 };
    if (Object.keys(priceObj).length === 0) {
      priceObj = { ...price };
    }
    Object.keys(priceObj).forEach((key, index) => {
      if (priceObj[key]) {
        tempData[index] = priceObj[key];
      } else tempData[index] = "";
    });
    let tempPayload = { ...payloadState };
    tempData.forEach((data, index) => {
      if (data) {
        if (index) {
          payload["filter[items.price][7][to]"] = data;
          tags["priceTag"]["max_price"] = data;
        } else {
          payload["filter[items.price][7][from]"] = data;
          tags["priceTag"]["min_price"] = data;
        }
      } else if (index) {
        delete tempPayload["filter[items.price][7][to]"];
        tags["priceTag"]["max_price"] = "";
      } else {
        delete tempPayload["filter[items.price][7][from]"];
        tags["priceTag"]["min_price"] = "";
      }
    });
    return { ...tempPayload, ...payload, tags };
  };

  const applyFilter = (filterData = {}, msg = "") => {
    let tempPayload = {};
    let payload = {};
    if (msg === "type") {
      tempPayload = typeFilter(filterData, payloadState, tags);
    } else if (msg === "template") {
      tempPayload = templateFilter(filterData, payloadState, tags);
    } else if (msg === "quantity") {
      tempPayload = quantityFilter(filterData, payloadState, tags);
    } else if (msg === "price") {
      tempPayload = priceFilter(filterData, payloadState, tags);
    } else {
      tempPayload = typeFilter({}, payloadState, tags);
      tempPayload = templateFilter(false, tempPayload, tempPayload?.tags);
      tempPayload = quantityFilter({}, tempPayload, tempPayload?.tags);
      tempPayload = priceFilter({}, tempPayload, tempPayload?.tags);
    }
    setTags({ ...tempPayload?.tags });
    delete tempPayload?.["tags"];
    payload = { ...payload, ...tempPayload };
    setPayloadState(payload);
  };

  const reset = () => {
    let filter;
    filter = { ...productType };
    filter["simple"] = false;
    filter["variation"] = false;
    setProductType(filter);
    filter = templateNameValue;
    filter = "";
    setTemplateNameValue(filter);
    filter = { ...quantity };
    filter["minimum_quantity"] = "";
    filter["maximum_quantity"] = "";
    setQuantity(filter);
    filter = { ...price };
    filter["minimum_price"] = "";
    filter["maximum_price"] = "";
    setPrice(filter);
    let tempTag = { ...tags };
    tempTag["typeTag"]["simple"] = false;
    tempTag["typeTag"]["variation"] = false;
    tempTag["templateName"] = "";
    tempTag["quantityTag"]["min_quantity"] = "";
    tempTag["quantityTag"]["max_quantity"] = "";
    tempTag["priceTag"]["min_price"] = "";
    tempTag["priceTag"]["max_price"] = "";
    setTags(tempTag);
    let payload = { activePage: 1, count: 10 };
    setPayloadState(payload);
  };

  const clearFilter = () => {
    for (let x in tags) {
      let flag = false;
      if (typeof tags[x] === "object") {
        for (let y in tags[x]) {
          if (tags[x][y]) flag = true;
        }
      } else if (tags[x]) {
        flag = true;
      }
      if (!flag) {
        let tempTag;
        switch (x) {
          case "typeTag":
            tempTag = { ...productType };
            for (let field in tempTag) {
              tempTag[field] = false;
            }
            setProductType(tempTag);
            break;
          case "templateName":
            setTemplateNameValue("");
            break;
          case "quantityTag":
            tempTag = { ...quantity };
            for (let field in tempTag) {
              tempTag[field] = "";
            }
            setQuantity(tempTag);
            break;
          case "priceTag":
            tempTag = { ...price };
            for (let field in tempTag) {
              tempTag[field] = "";
            }
            setPrice(tempTag);
            break;
          default:
            return;
        }
      }
    }
  };

  const productTypeTag = () => {
    let tag;
    Object.keys(tags.typeTag).forEach((key) => {
      if (tags.typeTag[key]) {
        let type = key === "simple" ? "Simple" : "Variant";
        tag = (
          <Tag
            destroy={() => {
              let tempType = { ...productType };
              tempType["simple"] = false;
              tempType["variation"] = false;
              setProductType(tempType);
              applyFilter(tempType, "type");
              let { typeTag } = tags;
              typeTag["simple"] = false;
              typeTag["variation"] = false;
            }}
          >
            Product Type: {type}
          </Tag>
        );
      }
    });
    return tag;
  };

  const templateNameTag = () => {
    let tag;
    if (tags?.templateName) {
      tag = (
        <Tag
          destroy={() => {
            setTemplateNameValue("");
            applyFilter(true, "template");
            let tempTemplateTag = { ...tags };
            tempTemplateTag["templateName"] = "";
            setTags(tempTemplateTag);
          }}
        >
          Template Name: {tags?.templateName}
        </Tag>
      );
    }
    return tag;
  };

  const quantityTag = () => {
    let tag;
    let temp = [];
    let count = 0;
    Object.keys(tags.quantityTag).forEach((key, index) => {
      if (tags.quantityTag[key]) {
        temp[index] = tags.quantityTag[key];
        count++;
      }
    });
    if (count) {
      tag =
        count > 1 ? (
          <Popover
            open={openQuantTag}
            activator={
              <Tag
                count={"+1"}
                destroy={() => {
                  let tempQuantity = { ...quantity };
                  tempQuantity["minimum_quantity"] = "";
                  tempQuantity["maximum_quantity"] = "";
                  setQuantity(tempQuantity);
                  applyFilter(tempQuantity, "quantity");
                  setOpenQuantTag(!openQuantTag);
                  let { quantityTag } = tags;
                  quantityTag["min_quantity"] = "";
                  quantityTag["max_quantity"] = "";
                }}
                popover
                togglePopup={() => setOpenQuantTag(!openQuantTag)}
                toggle={openQuantTag}
              >
                Quantity: Min({temp[0]})
              </Tag>
            }
            onClose={() => setOpenQuantTag(!openQuantTag)}
            popoverContainer="body"
          >
            <FlexLayout spacing="mediumTight">
              <Tag>Min: {temp[0]}</Tag>
              <Tag>Max: {temp[1]}</Tag>
            </FlexLayout>
          </Popover>
        ) : (
          <Tag
            destroy={() => {
              let tempQuantity = { ...quantity };
              tempQuantity["minimum_quantity"] = "";
              tempQuantity["maximum_quantity"] = "";
              setQuantity(tempQuantity);
              applyFilter(tempQuantity, "quantity");
              let { quantityTag } = tags;
              quantityTag["min_quantity"] = "";
              quantityTag["max_quantity"] = "";
            }}
            togglePopup={() => setOpenQuantTag(!openQuantTag)}
          >
            Quantity: {temp[0] ? `Min(${temp[0]})` : `Max(${temp[1]})`}
          </Tag>
        );
    }
    return tag;
  };

  const priceTag = () => {
    let tag;
    let temp = [];
    let count = 0;
    Object.keys(tags.priceTag).forEach((key, index) => {
      if (tags.priceTag[key]) {
        temp[index] = tags.priceTag[key];
        count++;
      }
    });
    if (count) {
      tag =
        count > 1 ? (
          <Popover
            open={openPriceTag}
            activator={
              <Tag
                count={"+1"}
                destroy={() => {
                  let tempPrice = { ...price };
                  tempPrice["minimum_price"] = "";
                  tempPrice["maximum_price"] = "";
                  setPrice(tempPrice);
                  applyFilter(tempPrice, "price");
                  setOpenPriceTag(!openPriceTag);
                  let { priceTag } = tags;
                  priceTag["min_price"] = "";
                  priceTag["max_price"] = "";
                }}
                popover
                togglePopup={() => setOpenPriceTag(!openPriceTag)}
                toggle={openPriceTag}
              >
                Price: Min({temp[0]})
              </Tag>
            }
            onClose={() => setOpenPriceTag(!openPriceTag)}
            popoverContainer="body"
          >
            <FlexLayout spacing="mediumTight">
              <Tag>Min: {temp[0]}</Tag>
              <Tag>Max: {temp[1]}</Tag>
            </FlexLayout>
          </Popover>
        ) : (
          <Tag
            destroy={() => {
              let tempPrice = { ...price };
              tempPrice["minimum_price"] = "";
              tempPrice["maximum_price"] = "";
              setPrice(tempPrice);
              applyFilter(tempPrice, "price");
              let { priceTag } = tags;
              priceTag["min_price"] = "";
              priceTag["max_price"] = "";
            }}
            togglePopup={() => setOpenPriceTag(!openPriceTag)}
          >
            Price: {temp[0] ? `Min(${temp[0]})` : `Max(${temp[1]})`}
          </Tag>
        );
    }
    return tag;
  };

  useEffect(() => {
    // aaply button
    let applyDisabled = true;
    for (let x in productType) {
      if (productType[x]) applyDisabled = false;
    }
    for (let x in quantity) {
      if (quantity[x]) applyDisabled = false;
    }
    for (let x in price) {
      if (price[x]) applyDisabled = false;
    }
    if (templateNameValue) applyDisabled = false;
    if (applyDisabled) setDisableApply(true);
    else setDisableApply(false);
    // reset button
    let resetDisabled = true;
    for (let x in tags) {
      if (typeof tags[x] === "object") {
        for (let y in tags[x]) {
          if (tags[x][y]) {
            resetDisabled = false;
          }
        }
      } else if (tags[x]) {
        resetDisabled = false;
      }
    }
    if (resetDisabled) {
      setDisableReset(true);
      setShowTags(false);
    } else {
      setDisableReset(false);
      setShowTags(true);
    }
  }, [productType, templateNameValue, quantity, price, tags]);

  return (
    <Card cardType="Bordered" extraClass="ced-filter__wrap">
      <FlexLayout spacing="tight" direction="vertical" wrap="noWrap">
        <FlexLayout spacing="tight" wrap="wrap">
          <TextField
            thickness="thin"
            showHelp=""
            placeHolder="Enter Title or SKU"
            innerPreIcon={<Search size={20} />}
            onChange={(e) => {
              setSearchedValue(e);
            }}
            value={searchedValue}
            clearButton
            clearFunction={() => setSearchedValue("")}
          />
          <Popover
            open={active}
            activator={
              <Button
                icon={
                  active ? <ChevronUp size={20} /> : <ChevronDown size={20} />
                }
                iconAlign="right"
                onClick={() => setActive(!active)}
                type="Outlined"
              >
                Customize Grid
              </Button>
            }
            onClose={() => setActive(false)}
            popoverWidth={156}
          >
            <div className="custom-popover-action">
              <FlexLayout
                direction="vertical"
                childWidth="fullWidth"
                halign="center"
                wrap="noWrap"
              >
                {renderColumnsChoicelist()}
              </FlexLayout>
            </div>
          </Popover>
          <AdvanceFilter
            button="Filter"
            disableApply={disableApply}
            disableReset={disableReset}
            filters={[
              {
                children: (
                  <FlexLayout direction="vertical" spacing="loose">
                    <Radio
                      checked={productType.simple}
                      id="1"
                      labelVal="Simple"
                      onClick={() => handleType("simple", "variation")}
                    />
                    <Radio
                      checked={productType.variation}
                      id="2"
                      labelVal="Variant"
                      onClick={() => handleType("variation", "simple")}
                    />
                  </FlexLayout>
                ),
                name: "Product Type",
              },
              {
                children: (
                  <Select
                    placeholder="Select Template"
                    onChange={(e) => setTemplateNameValue(e)}
                    options={templateNameOptions}
                    value={templateNameValue}
                  />
                ),
                name: "Template Name",
              },
              {
                children: (
                  <FlexLayout
                    direction="vertical"
                    spacing="tight"
                    wrap="noWrap"
                  >
                    <TextField
                      name="Minimum Quantity"
                      placeHolder="Enter Quantity"
                      type="number"
                      value={quantity.minimum_quantity}
                      onChange={(e) => handleQuantity("minimum", e)}
                    />
                    <TextField
                      name="Maximum Quantity"
                      placeHolder="Enter Quantity"
                      type="number"
                      value={quantity.maximum_quantity}
                      onChange={(e) => handleQuantity("maximum", e)}
                    />
                  </FlexLayout>
                ),
                name: "Quantity",
              },
              {
                children: (
                  <FlexLayout
                    direction="vertical"
                    spacing="tight"
                    wrap="noWrap"
                  >
                    <TextField
                      name="Minimum Price"
                      placeHolder="Enter Price"
                      type="number"
                      value={price.minimum_price}
                      onChange={(e) => handlePrice("minimum", e)}
                    />
                    <TextField
                      name="Maximum Price"
                      placeHolder="Enter Price"
                      type="number"
                      value={price.maximum_price}
                      onChange={(e) => handlePrice("maximum", e)}
                    />
                  </FlexLayout>
                ),
                name: "Price",
              },
            ]}
            heading="Filters"
            icon={<Filter color="#2a2a2a" size={20} />}
            type="Outlined"
            onApply={() => {
              applyFilter();
            }}
            resetFilter={() => {
              reset();
            }}
            onClose={clearFilter}
          />
        </FlexLayout>
        {showTags && (
          <FlexChild childWidth="fullWidth">
            <FlexLayout spacing="tight">
              {productTypeTag()}
              {templateNameTag()}
              {quantityTag()}
              {priceTag()}
            </FlexLayout>
          </FlexChild>
        )}
      </FlexLayout>
    </Card>
  );
};

export default Filters;
