import {
  Card,
  FlexChild,
  FlexLayout,
  PageHeader,
  Skeleton,
} from "@cedcommerce-integration/ounce-ui";
import React from "react";

export const DashboardSkeleton = () => {
  return (
    <>
      <PageHeader
        action={<Skeleton height="20px" width="150px" type="custom" />}
        title={<Skeleton height="20px" width="200px" type="custom" />}
      />
      <Card
        title={<Skeleton height="25px" width="150px" type="custom" />}
        action={<Skeleton height="25px" width="100px" type="custom" />}
      >
        <FlexLayout direction="vertical" spacing="loose" wrap="noWrap">
          {[1, 2, 3, 4, 5].map((index) => {
            return (
              <FlexLayout
                spacing="loose"
                wrap="noWrap"
                valign="start"
                key={index}
              >
                <Skeleton type="custom" width="20px" height="20px" rounded />
                <FlexChild desktopWidth="75" mobileWidth="75" tabWidth="75">
                  <FlexLayout
                    direction="vertical"
                    spacing="mediumTight"
                    wrap="noWrap"
                    childWidth="fullWidth"
                  >
                    <Skeleton type="line" line={1} height="20px" />
                    <Skeleton type="custom" width="75%" height="15px" />
                  </FlexLayout>
                </FlexChild>
              </FlexLayout>
            );
          })}
        </FlexLayout>
      </Card>
    </>
  );
};

export const StatusSkelton = () => {
  return (
    <Card
      cardType="Subdued"
      title={<Skeleton type="custom" width="150px" height="15px" />}
      action={
        <FlexLayout spacing="loose" wrap="noWrap" valign="center">
          <Skeleton type="custom" width="100px" height="15px" />
        </FlexLayout>
      }
    >
      <FlexLayout
        spacing="loose"
        wrap="wrap"
        desktopWidth="25"
        tabWidth="50"
        mobileWidth="50"
      >
        <Skeleton type="custom" width="100%" height="150px" />
        <Skeleton type="custom" width="100%" height="150px" />
        <Skeleton type="custom" width="100%" height="150px" />
        <Skeleton type="custom" width="100%" height="150px" />
      </FlexLayout>
    </Card>
  );
};

export const ActivitySkelton = () => {
  return (
    <>
      <FlexLayout direction="vertical" spacing="extraLoose" wrap="noWrap">
        <Skeleton height="130px" type="line" />
        <FlexLayout direction="vertical" spacing="loose" wrap="noWrap">
          {[1, 2, 3].map((index) => {
            return (
              <FlexLayout key={index} spacing="loose" direction="vertical" wrap="noWrap">
                <FlexLayout
                  spacing="loose"
                  wrap="noWrap"
                  valign="start"
                  key={index}
                >
                  <Skeleton type="custom" width="20px" height="20px" rounded />
                  <FlexChild desktopWidth="75" mobileWidth="75" tabWidth="75">
                    <FlexLayout
                      direction="vertical"
                      spacing="mediumTight"
                      wrap="noWrap"
                      childWidth="fullWidth"
                    >
                      <Skeleton type="line" line={1} height="18px" />
                      <Skeleton type="custom" width="75%" height="14px" />
                    </FlexLayout>
                  </FlexChild>
                </FlexLayout>
                <hr className="custom-hr" />
              </FlexLayout>
            );
          })}
        </FlexLayout>
      </FlexLayout>
    </>
  );
};
