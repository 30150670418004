import { Pagination } from "@cedcommerce-integration/ounce-ui";
import React from "react";

function NestedPagination({
  totalCount = 0,
  pageSizeOptions,
  activePage,
  setActivePage,
  pageSize,
  setPageSize,
  payloadState,
  setPayloadState,
}) {
  return (
    <Pagination
      countPerPage={pageSize}
      currentPage={activePage}
      onCountChange={(count, c) => {
        setActivePage(1);
        setPageSize(count);
      }}
      onEnter={(e) => setActivePage(e)}
      onNext={() => {
        setActivePage(activePage + 1);
      }}
      onPrevious={() => {
        setActivePage(activePage - 1);
      }}
      optionPerPage={pageSizeOptions}
      totalitem={totalCount}
    />
  );
}

export default NestedPagination;
