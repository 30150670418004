import { requests } from "../services/request";

export async function getCategoriesApi(obj) {
  return await requests.postRequest("ebay/category/getCategories", obj);
}

export async function getPoliciesApi1(filter) {
  return await requests.getRequest(
    `ebay/policy/getPolicies?filter[type][1]=${filter.policy}&filter[shop_id][1]=${filter.shop_id}`
  );
}

export async function getCategoryFeatures(params) {
  return await requests.getRequest(
    `ebay/category/categoryFeatures?category_id=${params.category_id}&site_id=15&shop_id=${params.shop_id}`
  );
}

export async function getAttributesCategoryWise(params) {
  return await requests.getRequest(
    `ebay/category/getAttributes?category_id=${params.category_id}&site_id=15&shop_id=${params.shop_id}`
  );
}

export async function getAttributesByProductQuery() {
  return await requests.getRequest(
    "connector/product/getAttributesByProductQuery?marketplace=shopline&query=(price>-1)"
  );
}

export async function getCategoryPrediction(filter) {
  return await requests.postRequest(
    "ebay/category/categoryPredictions",
    filter
  );
}

export async function getParentCategories(filter) {
  return await requests.postRequest(
    "ebay/category/getParentCategories",
    filter
  );
}

export async function getSourceAttributes(filters = {}) {
  return await requests.postRequest(
    "connector/source/getFilterAttributes",
    filters
  );
}

export async function getAllConnected() {
  return await requests.getRequest("connector/get/all");
}

export async function getRunQuery(payload) {
  return await requests.postRequest("connector/profile/getQueryProducts",payload);
}
export async function getRunQueryCount(payload) {
  return await requests.postRequest(
    "connector/profile/getQueryProductsCount",
    payload
  );
}

export async function getSavedTemplate(payload) {
  return await requests.postRequest("connector/profile/saveProfile",payload);
}

export async function getEditTemplate(payload) {
  return await requests.getRequest("connector/profile/getProfileData", payload);
}