import { Card, Skeleton, FlexLayout, FlexChild } from "@cedcommerce-integration/ounce-ui";
import React from "react";

export const MarketplaceConfigSkeleton = () => {
  return (
    <Card>
      <FlexLayout spacing="mediumLoose" direction="vertical" wrap="noWrap">
        {/* PRODUCT SETTIGNS */}
        <FlexLayout direction="vertical" spacing="mediumLoose" wrap="noWrap">
          <FlexLayout halign="fill">
            <FlexLayout direction="vertical" spacing="tight" wrap="noWrap">
              <Skeleton height="10px" width="100px" type="custom" />
              <Skeleton height="10px" width="220px" type="custom" />
            </FlexLayout>
            <Skeleton height="40px" width="70px" type="custom" />
          </FlexLayout>
          <Card cardType="Bordered">
            <FlexLayout spacing="extraTight" direction="vertical" wrap="noWrap">
              <FlexLayout spacing="loose">
                <Skeleton height="20px" width="36px" type="custom" />
                <Skeleton height="10px" width="100px" type="custom" />
              </FlexLayout>
              <div style={{ marginLeft: "50px" }}>
                <FlexLayout spacing="loose" direction="vertical" wrap="noWrap">
                  <FlexLayout
                    direction="vertical"
                    spacing="mediumTight"
                    wrap="noWrap"
                  >
                    <Skeleton height="10px" width="300px" type="line" />
                    <Skeleton height="10px" width="220px" type="custom" />
                  </FlexLayout>
                  <FlexLayout spacing="loose" wrap="noWrap" valign="center">
                    <Skeleton height="20px" width="20px" type="custom" />
                    <Skeleton height="10px" width="80px" type="custom" />
                  </FlexLayout>
                  <hr className="custom-hr" />
                  <FlexLayout
                    desktopWidth="25"
                    tabWidth="50"
                    mobileWidth="50"
                    spacing="mediumLoose"
                  >
                    <FlexLayout spacing="loose" wrap="noWrap" valign="center">
                      <Skeleton height="20px" width="20px" type="custom" />
                      <Skeleton height="10px" width="80px" type="custom" />
                    </FlexLayout>
                    <FlexLayout spacing="loose" wrap="noWrap" valign="center">
                      <Skeleton height="20px" width="20px" type="custom" />
                      <Skeleton height="10px" width="80px" type="custom" />
                    </FlexLayout>
                    <FlexLayout spacing="loose" wrap="noWrap" valign="center">
                      <Skeleton height="20px" width="20px" type="custom" />
                      <Skeleton height="10px" width="80px" type="custom" />
                    </FlexLayout>
                    <FlexLayout spacing="loose" wrap="noWrap" valign="center">
                      <Skeleton height="20px" width="20px" type="custom" />
                      <Skeleton height="10px" width="80px" type="custom" />
                    </FlexLayout>
                  </FlexLayout>
                  <FlexLayout
                    desktopWidth="25"
                    tabWidth="50"
                    mobileWidth="50"
                    spacing="mediumLoose"
                  >
                    <FlexLayout spacing="loose" wrap="noWrap" valign="center">
                      <Skeleton height="20px" width="20px" type="custom" />
                      <Skeleton height="10px" width="80px" type="custom" />
                    </FlexLayout>
                    <FlexLayout spacing="loose" wrap="noWrap" valign="center">
                      <Skeleton height="20px" width="20px" type="custom" />
                      <Skeleton height="10px" width="80px" type="custom" />
                    </FlexLayout>
                    <FlexLayout spacing="loose" wrap="noWrap" valign="center">
                      <Skeleton height="20px" width="20px" type="custom" />
                      <Skeleton height="10px" width="80px" type="custom" />
                    </FlexLayout>
                  </FlexLayout>
                  <FlexLayout
                    desktopWidth="25"
                    tabWidth="50"
                    mobileWidth="50"
                    spacing="mediumLoose"
                  >
                    <FlexLayout spacing="loose" wrap="noWrap" valign="center">
                      <Skeleton height="20px" width="20px" type="custom" />
                      <Skeleton height="10px" width="80px" type="custom" />
                    </FlexLayout>
                    <FlexLayout spacing="loose" wrap="noWrap" valign="center">
                      <Skeleton height="20px" width="20px" type="custom" />
                      <Skeleton height="10px" width="80px" type="custom" />
                    </FlexLayout>
                    <FlexLayout spacing="loose" wrap="noWrap" valign="center">
                      <Skeleton height="20px" width="20px" type="custom" />
                      <Skeleton height="10px" width="80px" type="custom" />
                    </FlexLayout>
                  </FlexLayout>
                </FlexLayout>
              </div>
            </FlexLayout>
          </Card>
        </FlexLayout>

        {/* END PRODUCT */}
        <Card cardType="Bordered">
          <FlexLayout spacing="extraTight" direction="vertical" wrap="noWrap">
            <FlexLayout spacing="loose">
              <Skeleton height="20px" width="36px" type="custom" />
              <Skeleton height="10px" width="100px" type="custom" />
            </FlexLayout>
            <div style={{ marginLeft: "50px" }}>
              <FlexLayout spacing="loose" direction="vertical" wrap="noWrap">
                <FlexLayout
                  direction="vertical"
                  spacing="mediumTight"
                  wrap="noWrap"
                >
                  <Skeleton height="10px" width="300px" type="line" />
                  <Skeleton height="10px" width="220px" type="custom" />
                </FlexLayout>
              </FlexLayout>
            </div>
          </FlexLayout>
        </Card>
        {/* ITEM LOCATION */}
        <Card cardType="Bordered">
          <FlexLayout spacing="mediumLoose" direction="vertical" wrap="noWrap">
            <Skeleton height="10px" width="120px" type="custom" />

            <FlexLayout
              spacing="mediumTight"
              direction="vertical"
              wrap="noWrap"
            >
              <Skeleton height="10px" type="line" />
              <Skeleton height="10px" type="line" />
              <Skeleton height="10px" type="line" />
            </FlexLayout>
            <FlexLayout
              spacing="loose"
              desktopWidth="33"
              tabWidth="50"
              mobileWidth="100"
            >
              <Skeleton height="44px" type="line" />
              <Skeleton height="44px" type="line" />
              <Skeleton height="44px" type="line" />
            </FlexLayout>
          </FlexLayout>
        </Card>

        {/* Match products from eBay */}
        <Card cardType="Bordered">
          <FlexLayout spacing="mediumLoose" direction="vertical" wrap="noWrap">
            <FlexLayout spacing="extraTight" direction="vertical" wrap="noWrap">
              <FlexLayout spacing="loose">
                <Skeleton height="20px" width="36px" type="custom" />
                <Skeleton height="10px" width="100px" type="custom" />
              </FlexLayout>
              <div style={{ marginLeft: "50px" }}>
                <FlexLayout
                  direction="vertical"
                  spacing="mediumLoose"
                  wrap="noWrap"
                >
                  <FlexLayout
                    spacing="loose"
                    direction="vertical"
                    wrap="noWrap"
                  >
                    <FlexLayout
                      direction="vertical"
                      spacing="mediumTight"
                      wrap="noWrap"
                    >
                      <Skeleton height="10px" width="300px" type="line" />
                      <Skeleton height="10px" width="300px" type="line" />
                      <Skeleton height="10px" width="300px" type="line" />
                      <Skeleton height="10px" width="300px" type="line" />
                    </FlexLayout>
                  </FlexLayout>
                  {/* Add shipping attribute */}
                  <FlexLayout halign="end">
                    <Skeleton height="36px" width="150px" type="custom" />
                  </FlexLayout>
                  {/* Shopline Carrier Amazon Carrier */}
                  <FlexLayout spacing="tight">
                    <FlexChild childWidth="fullWidth">
                      <FlexLayout
                        spacing="loose"
                        desktopWidth="50"
                        tabWidth="50"
                        mobileWidth="50"
                      >
                        <Skeleton height="38px" width="150px" type="line" />
                        <Skeleton height="38px" width="150px" type="line" />
                      </FlexLayout>
                    </FlexChild>
                    <Skeleton height="40px" width="40px" type="custom" />
                  </FlexLayout>
                  <FlexLayout spacing="tight">
                    <FlexChild childWidth="fullWidth">
                      <FlexLayout
                        spacing="loose"
                        desktopWidth="50"
                        tabWidth="50"
                        mobileWidth="50"
                      >
                        <Skeleton height="38px" width="150px" type="line" />
                        <Skeleton height="38px" width="150px" type="line" />
                      </FlexLayout>
                    </FlexChild>
                    <Skeleton height="40px" width="40px" type="custom" />
                  </FlexLayout>
                </FlexLayout>
              </div>
            </FlexLayout>
          </FlexLayout>
        </Card>
        <hr className="custom-hr" />

        {/* ORDER SETTINGS */}
        <FlexLayout direction="vertical" spacing="mediumLoose" wrap="noWrap">
          <FlexLayout halign="fill">
            <FlexLayout direction="vertical" spacing="tight" wrap="noWrap">
              <Skeleton height="10px" width="100px" type="custom" />
              <Skeleton height="10px" width="220px" type="custom" />
            </FlexLayout>
            <Skeleton height="40px" width="70px" type="custom" />
          </FlexLayout>
          <Card cardType="Bordered">
            <FlexLayout
              spacing="mediumLoose"
              direction="vertical"
              wrap="noWrap"
            >
              <FlexLayout
                spacing="extraTight"
                direction="vertical"
                wrap="noWrap"
              >
                <FlexLayout spacing="loose">
                  <Skeleton height="20px" width="36px" type="custom" />
                  <Skeleton height="10px" width="100px" type="custom" />
                </FlexLayout>
                <div style={{ marginLeft: "50px" }}>
                  <FlexLayout
                    direction="vertical"
                    spacing="mediumLoose"
                    wrap="noWrap"
                  >
                    <FlexLayout
                      spacing="loose"
                      direction="vertical"
                      wrap="noWrap"
                    >
                      <FlexLayout
                        direction="vertical"
                        spacing="mediumTight"
                        wrap="noWrap"
                      >
                        <Skeleton height="10px" width="300px" type="line" />
                        <Skeleton height="10px" width="300px" type="line" />
                        <Skeleton height="10px" width="220px" type="custom" />
                      </FlexLayout>
                    </FlexLayout>

                    {/* sync tracking details */}
                    <FlexLayout
                      direction="vertical"
                      spacing="extraLoose"
                      wrap="noWrap"
                    >
                      <FlexLayout
                        spacing="extraTight"
                        direction="vertical"
                        wrap="noWrap"
                      >
                        <FlexLayout spacing="loose">
                          <Skeleton height="20px" width="36px" type="custom" />
                          <Skeleton height="10px" width="100px" type="custom" />
                        </FlexLayout>
                        <div style={{ marginLeft: "50px" }}>
                          <FlexLayout
                            spacing="loose"
                            direction="vertical"
                            wrap="noWrap"
                          >
                            <FlexLayout
                              direction="vertical"
                              spacing="mediumTight"
                              wrap="noWrap"
                            >
                              <Skeleton
                                height="10px"
                                width="300px"
                                type="line"
                              />
                            </FlexLayout>
                          </FlexLayout>
                        </div>
                      </FlexLayout>
                      <FlexLayout
                        spacing="extraTight"
                        direction="vertical"
                        wrap="noWrap"
                      >
                        <FlexLayout spacing="loose">
                          <Skeleton height="20px" width="36px" type="custom" />
                          <Skeleton height="10px" width="100px" type="custom" />
                        </FlexLayout>
                        <div style={{ marginLeft: "50px" }}>
                          <FlexLayout
                            spacing="loose"
                            direction="vertical"
                            wrap="noWrap"
                          >
                            <FlexLayout
                              direction="vertical"
                              spacing="mediumTight"
                              wrap="noWrap"
                            >
                              <Skeleton
                                height="10px"
                                width="300px"
                                type="line"
                              />
                            </FlexLayout>
                          </FlexLayout>
                        </div>
                      </FlexLayout>
                    </FlexLayout>
                    {/* Add shipping attribute */}
                    <FlexLayout halign="end">
                      <Skeleton height="36px" width="150px" type="custom" />
                    </FlexLayout>
                    {/* Shopline Carrier Amazon Carrier */}
                    <FlexLayout spacing="tight">
                      <FlexChild childWidth="fullWidth">
                        <FlexLayout
                          spacing="loose"
                          desktopWidth="50"
                          tabWidth="50"
                          mobileWidth="50"
                        >
                          <Skeleton height="38px" width="150px" type="line" />
                          <Skeleton height="38px" width="150px" type="line" />
                        </FlexLayout>
                      </FlexChild>
                      <Skeleton height="40px" width="40px" type="custom" />
                    </FlexLayout>
                    <FlexLayout spacing="tight">
                      <FlexChild childWidth="fullWidth">
                        <FlexLayout
                          spacing="loose"
                          desktopWidth="50"
                          tabWidth="50"
                          mobileWidth="50"
                        >
                          <Skeleton height="38px" width="150px" type="line" />
                          <Skeleton height="38px" width="150px" type="line" />
                        </FlexLayout>
                      </FlexChild>
                      <Skeleton height="40px" width="40px" type="custom" />
                    </FlexLayout>
                  </FlexLayout>
                </div>
              </FlexLayout>
            </FlexLayout>
          </Card>
        </FlexLayout>
      </FlexLayout>
    </Card>
  );
};

export const ProductSettingsSkeleton = () => {
  return (
    <Card
      title={<Skeleton height="14px" width="100px" type="custom" />}
      subTitle={<Skeleton height="14px" width="200px" type="custom" />}
      action={<Skeleton height="40px" width="70px" type="custom" />}
    >
      <FlexLayout spacing="mediumLoose" direction="vertical" wrap="noWrap">
        {/* PRODUCT SETTIGNS */}
        <FlexLayout direction="vertical" spacing="mediumLoose" wrap="noWrap">
          <Card cardType="Bordered">
            <FlexLayout spacing="extraTight" direction="vertical" wrap="noWrap">
              <FlexLayout spacing="loose">
                <Skeleton height="20px" width="36px" type="custom" />
                <Skeleton height="10px" width="100px" type="custom" />
              </FlexLayout>
              <div style={{ marginLeft: "50px" }}>
                <FlexLayout spacing="loose" direction="vertical" wrap="noWrap">
                  <FlexLayout
                    direction="vertical"
                    spacing="mediumTight"
                    wrap="noWrap"
                  >
                    <Skeleton height="10px" width="300px" type="line" />
                    <Skeleton height="10px" width="220px" type="custom" />
                  </FlexLayout>
                  <FlexLayout spacing="loose" wrap="noWrap" valign="center">
                    <Skeleton height="20px" width="20px" type="custom" />
                    <Skeleton height="10px" width="80px" type="custom" />
                  </FlexLayout>
                  <hr className="custom-hr" />
                  <FlexLayout
                    desktopWidth="25"
                    tabWidth="50"
                    mobileWidth="50"
                    spacing="mediumLoose"
                  >
                    <FlexLayout spacing="loose" wrap="noWrap" valign="center">
                      <Skeleton height="20px" width="20px" type="custom" />
                      <Skeleton height="10px" width="80px" type="custom" />
                    </FlexLayout>
                    <FlexLayout spacing="loose" wrap="noWrap" valign="center">
                      <Skeleton height="20px" width="20px" type="custom" />
                      <Skeleton height="10px" width="80px" type="custom" />
                    </FlexLayout>
                    <FlexLayout spacing="loose" wrap="noWrap" valign="center">
                      <Skeleton height="20px" width="20px" type="custom" />
                      <Skeleton height="10px" width="80px" type="custom" />
                    </FlexLayout>
                    <FlexLayout spacing="loose" wrap="noWrap" valign="center">
                      <Skeleton height="20px" width="20px" type="custom" />
                      <Skeleton height="10px" width="80px" type="custom" />
                    </FlexLayout>
                  </FlexLayout>
                  <FlexLayout
                    desktopWidth="25"
                    tabWidth="50"
                    mobileWidth="50"
                    spacing="mediumLoose"
                  >
                    <FlexLayout spacing="loose" wrap="noWrap" valign="center">
                      <Skeleton height="20px" width="20px" type="custom" />
                      <Skeleton height="10px" width="80px" type="custom" />
                    </FlexLayout>
                    <FlexLayout spacing="loose" wrap="noWrap" valign="center">
                      <Skeleton height="20px" width="20px" type="custom" />
                      <Skeleton height="10px" width="80px" type="custom" />
                    </FlexLayout>
                    <FlexLayout spacing="loose" wrap="noWrap" valign="center">
                      <Skeleton height="20px" width="20px" type="custom" />
                      <Skeleton height="10px" width="80px" type="custom" />
                    </FlexLayout>
                  </FlexLayout>
                  <FlexLayout
                    desktopWidth="25"
                    tabWidth="50"
                    mobileWidth="50"
                    spacing="mediumLoose"
                  >
                    <FlexLayout spacing="loose" wrap="noWrap" valign="center">
                      <Skeleton height="20px" width="20px" type="custom" />
                      <Skeleton height="10px" width="80px" type="custom" />
                    </FlexLayout>
                    <FlexLayout spacing="loose" wrap="noWrap" valign="center">
                      <Skeleton height="20px" width="20px" type="custom" />
                      <Skeleton height="10px" width="80px" type="custom" />
                    </FlexLayout>
                    <FlexLayout spacing="loose" wrap="noWrap" valign="center">
                      <Skeleton height="20px" width="20px" type="custom" />
                      <Skeleton height="10px" width="80px" type="custom" />
                    </FlexLayout>
                  </FlexLayout>
                </FlexLayout>
              </div>
            </FlexLayout>
          </Card>
        </FlexLayout>

        {/* END PRODUCT */}
        <Card cardType="Bordered">
          <FlexLayout spacing="extraTight" direction="vertical" wrap="noWrap">
            <FlexLayout spacing="loose">
              <Skeleton height="20px" width="36px" type="custom" />
              <Skeleton height="10px" width="100px" type="custom" />
            </FlexLayout>
            <div style={{ marginLeft: "50px" }}>
              <FlexLayout spacing="loose" direction="vertical" wrap="noWrap">
                <FlexLayout
                  direction="vertical"
                  spacing="mediumTight"
                  wrap="noWrap"
                >
                  <Skeleton height="10px" width="300px" type="line" />
                  <Skeleton height="10px" width="220px" type="custom" />
                </FlexLayout>
              </FlexLayout>
            </div>
          </FlexLayout>
        </Card>
        {/* ITEM LOCATION */}
        <Card cardType="Bordered">
          <FlexLayout spacing="mediumLoose" direction="vertical" wrap="noWrap">
            <Skeleton height="10px" width="120px" type="custom" />

            <FlexLayout
              spacing="mediumTight"
              direction="vertical"
              wrap="noWrap"
            >
              <Skeleton height="10px" type="line" />
              <Skeleton height="10px" type="line" />
              <Skeleton height="10px" type="line" />
            </FlexLayout>
            <FlexLayout
              spacing="loose"
              desktopWidth="33"
              tabWidth="50"
              mobileWidth="100"
            >
              <Skeleton height="44px" type="line" />
              <Skeleton height="44px" type="line" />
              <Skeleton height="44px" type="line" />
            </FlexLayout>
          </FlexLayout>
        </Card>

        {/* Match products from eBay */}
        <Card cardType="Bordered">
          <FlexLayout spacing="mediumLoose" direction="vertical" wrap="noWrap">
            <FlexLayout spacing="extraTight" direction="vertical" wrap="noWrap">
              <FlexLayout spacing="loose">
                <Skeleton height="20px" width="36px" type="custom" />
                <Skeleton height="10px" width="100px" type="custom" />
              </FlexLayout>
              <div style={{ marginLeft: "50px" }}>
                <FlexLayout
                  direction="vertical"
                  spacing="mediumLoose"
                  wrap="noWrap"
                >
                  <FlexLayout
                    spacing="loose"
                    direction="vertical"
                    wrap="noWrap"
                  >
                    <FlexLayout
                      direction="vertical"
                      spacing="mediumTight"
                      wrap="noWrap"
                    >
                      <Skeleton height="10px" width="300px" type="line" />
                      <Skeleton height="10px" width="300px" type="line" />
                      <Skeleton height="10px" width="300px" type="line" />
                      <Skeleton height="10px" width="300px" type="line" />
                    </FlexLayout>
                  </FlexLayout>
                  {/* Add shipping attribute */}
                  <FlexLayout halign="end">
                    <Skeleton height="36px" width="150px" type="custom" />
                  </FlexLayout>
                  {/* Shopline Carrier Amazon Carrier */}
                  <FlexLayout spacing="tight">
                    <FlexChild childWidth="fullWidth">
                      <FlexLayout
                        spacing="loose"
                        desktopWidth="50"
                        tabWidth="50"
                        mobileWidth="50"
                      >
                        <Skeleton height="38px" width="150px" type="line" />
                        <Skeleton height="38px" width="150px" type="line" />
                      </FlexLayout>
                    </FlexChild>
                    <Skeleton height="40px" width="40px" type="custom" />
                  </FlexLayout>
                  <FlexLayout spacing="tight">
                    <FlexChild childWidth="fullWidth">
                      <FlexLayout
                        spacing="loose"
                        desktopWidth="50"
                        tabWidth="50"
                        mobileWidth="50"
                      >
                        <Skeleton height="38px" width="150px" type="line" />
                        <Skeleton height="38px" width="150px" type="line" />
                      </FlexLayout>
                    </FlexChild>
                    <Skeleton height="40px" width="40px" type="custom" />
                  </FlexLayout>
                </FlexLayout>
              </div>
            </FlexLayout>
          </FlexLayout>
        </Card>
      </FlexLayout>
    </Card>
  );
};

export const OrderSettingsSkeleton = () => {
  return (
    <Card
      title={<Skeleton height="14px" width="100px" type="custom" />}
      subTitle={<Skeleton height="14px" width="200px" type="custom" />}
      action={<Skeleton height="40px" width="70px" type="custom" />}
    >
      {/* ORDER SETTINGS */}

      <Card cardType="Bordered">
        <FlexLayout spacing="extraTight" direction="vertical" wrap="noWrap">
          <FlexLayout spacing="loose">
            <Skeleton height="20px" width="36px" type="custom" />
            <Skeleton height="10px" width="100px" type="custom" />
          </FlexLayout>

          <div style={{ marginLeft: "50px" }}>
            <FlexLayout
              direction="vertical"
              spacing="mediumLoose"
              wrap="noWrap"
            >
              <FlexLayout spacing="loose" direction="vertical" wrap="noWrap">
                <FlexLayout
                  direction="vertical"
                  spacing="mediumTight"
                  wrap="noWrap"
                >
                  <Skeleton height="10px" width="300px" type="line" />
                  <Skeleton height="10px" width="300px" type="line" />
                  <Skeleton height="10px" width="220px" type="custom" />
                </FlexLayout>
              </FlexLayout>

              {/* sync tracking details */}
              <FlexLayout
                direction="vertical"
                spacing="extraLoose"
                wrap="noWrap"
              >
                <FlexLayout
                  spacing="extraTight"
                  direction="vertical"
                  wrap="noWrap"
                >
                  <FlexLayout spacing="loose">
                    <Skeleton height="20px" width="36px" type="custom" />
                    <Skeleton height="10px" width="100px" type="custom" />
                  </FlexLayout>
                  <div style={{ marginLeft: "50px" }}>
                    <FlexLayout
                      spacing="loose"
                      direction="vertical"
                      wrap="noWrap"
                    >
                      <FlexLayout
                        direction="vertical"
                        spacing="mediumTight"
                        wrap="noWrap"
                      >
                        <Skeleton height="10px" width="300px" type="line" />
                      </FlexLayout>
                    </FlexLayout>
                  </div>
                </FlexLayout>
                <FlexLayout
                  spacing="extraTight"
                  direction="vertical"
                  wrap="noWrap"
                >
                  <FlexLayout spacing="loose">
                    <Skeleton height="20px" width="36px" type="custom" />
                    <Skeleton height="10px" width="100px" type="custom" />
                  </FlexLayout>
                  <div style={{ marginLeft: "50px" }}>
                    <FlexLayout
                      spacing="loose"
                      direction="vertical"
                      wrap="noWrap"
                    >
                      <FlexLayout
                        direction="vertical"
                        spacing="mediumTight"
                        wrap="noWrap"
                      >
                        <Skeleton height="10px" width="300px" type="line" />
                      </FlexLayout>
                    </FlexLayout>
                  </div>
                </FlexLayout>
              </FlexLayout>
              {/* Add shipping attribute */}
              <FlexLayout halign="end">
                <Skeleton height="36px" width="150px" type="custom" />
              </FlexLayout>
              {/* Shopline Carrier Amazon Carrier */}
              <FlexLayout spacing="tight">
                <FlexChild childWidth="fullWidth">
                  <FlexLayout
                    spacing="loose"
                    desktopWidth="50"
                    tabWidth="50"
                    mobileWidth="50"
                  >
                    <Skeleton height="38px" width="150px" type="line" />
                    <Skeleton height="38px" width="150px" type="line" />
                  </FlexLayout>
                </FlexChild>
                <Skeleton height="40px" width="40px" type="custom" />
              </FlexLayout>
              <FlexLayout spacing="tight">
                <FlexChild childWidth="fullWidth">
                  <FlexLayout
                    spacing="loose"
                    desktopWidth="50"
                    tabWidth="50"
                    mobileWidth="50"
                  >
                    <Skeleton height="38px" width="150px" type="line" />
                    <Skeleton height="38px" width="150px" type="line" />
                  </FlexLayout>
                </FlexChild>
                <Skeleton height="40px" width="40px" type="custom" />
              </FlexLayout>
            </FlexLayout>
          </div>
        </FlexLayout>
      </Card>
    </Card>
  );
};
