import {
  Avatar,
  Card,
  FlexChild,
  FlexLayout,
  TextStyles,
} from "@cedcommerce-integration/ounce-ui";
import React from "react";
import { Mail, Phone } from "react-feather";
import user from "../../../../../assets/icons/user.svg";
const CustomerInfo = ({ customer }) => {
  return (
    <Card title={"Customer Information"}>
      <FlexLayout
        direction="vertical"
        spacing="loose"
        wrap="noWrap"
        childWidth="fullWidth"
      >
        <FlexLayout wrap="noWrap" spacing="mediumTight" valign="start">
          <Avatar
            color="black"
            image={user}
            size="large"
            text="Jon Doe"
            type="circle"
          />
          <FlexChild>
            <>
              <TextStyles
                fontweight="bold"
                lineHeight="LH-2.0"
                paragraphTypes="MD-1.4"
                textcolor="dark"
                type="Paragraph"
              >
                Name
              </TextStyles>
              <TextStyles
                fontweight="normal"
                lineHeight="LH-2.0"
                paragraphTypes="MD-1.4"
                textcolor="light"
                type="Paragraph"
                utility="inte-world_break"
              >
                {customer?.name}
              </TextStyles>
            </>
          </FlexChild>
        </FlexLayout>
        <hr className="custom-hr m-0" />
        <FlexLayout wrap="noWrap" spacing="mediumTight" valign="start">
          <Mail size={18} />
          <TextStyles
            fontweight="normal"
            lineHeight="LH-2.0"
            paragraphTypes="MD-1.4"
            textcolor="light"
            type="Paragraph"
            utility="inte-world_break"
          >
            {customer?.email}
          </TextStyles>
        </FlexLayout>
        {customer?.phone && (
          <FlexLayout wrap="noWrap" spacing="mediumTight" valign="start">
            <Phone size={18} />
            <TextStyles
              fontweight="normal"
              lineHeight="LH-2.0"
              paragraphTypes="MD-1.4"
              textcolor="light"
              type="Paragraph"
              utility="inte-world_break"
            >
              {customer?.phone}
            </TextStyles>
          </FlexLayout>
        )}
      </FlexLayout>
    </Card>
  );
};

export default CustomerInfo;
