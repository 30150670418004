import { NewSidebar } from "@cedcommerce-integration/ounce-ui";
import { withRouter } from "react-router-dom";
import logo from "../../assets/images/logo.svg";
import mlogo from "../../assets/images/m-logo.svg";
import { Menu } from "./Menu";

const PanelSidebar = (props) => {

  function onChange(e) {
    window.scrollTo(0,0)
    if (e.path.includes("https:")) window.open(e.path);
    else props.history.push(e.path);
  }

  function getCurrentPath(path) {
    const newpAth = "/" + path.split("/")[1] + "/" + path.split("/")[3];
    return newpAth;
  }

  return (
    <div className="mob-sidebar">
      <NewSidebar
        menu={Menu}
        mobileLogo={<img src={mlogo} width="33" alt="img" />}
        logo={<img src={logo} width="150" alt="img" />}
        path={getCurrentPath(window.location.pathname)}
        onChange={(e) => onChange(e)}
      />
    </div>
  );
};

export default withRouter(PanelSidebar);
