/* eslint-disable react-hooks/exhaustive-deps */
import {
  Button,
  Card,
  FlexLayout,
  FormChild,
  FormElement,
  TextField,
  TextStyles,
} from "@cedcommerce-integration/ounce-ui";
import { useEffect, useState } from "react";
import { withRouter } from "react-router-dom";
import { postMethod } from "../../../../../Apirequest/productListingApi";
import { saveDataAPI } from "../../../../../APIs/PanelAPI";
import { globalState } from "../../../../../services/globalState";
import CurrencySettingSkeleton from "./CurrencySettingSkeleton";

const CurrencySettings = ({
  currency,
  setCurrency,
  setSuccessToast,
  setErrorToast,
  skeleton,
  buttonDisabled,
  setButtonDisabled,
  ...props
}) => {
  const ebayShopId = globalState.getLocalStorage("ebayShopId");
  const shopLineShopId = globalState.getLocalStorage("shopLineShopId");
  const [saveLoader, setSaveLoader] = useState(false);
  const [prevState, setPrevState] = useState({});
  // error
  const [errorsState, setErrorsState] = useState({
    currencyField: new Array(2).fill(false),
  });

  const prePareData = () => {
    let payload = {
      source: {
        shopId: shopLineShopId,
        marketplace: "shopline",
      },
      target: {
        shopId: ebayShopId,
        marketplace: "ebay",
      },
      data: [
        {
          group_code: "settings",
          data: {
            currencyConversion: {
              shoplineCurrencyName: "INR",
              shoplineCurrencyValue: 1,
              ebayCurrencyName: "AUD",
              ebayCurrencyValue: currency.aud,
            },
          },
        },
      ],
    };
    return payload;
  };

  const handleErrors = () => {
    let tempErrors = { ...errorsState };
    let flag = false;
    Object.keys(currency).forEach((value, index) => {
      if (Number(currency[value]) === 0 || !currency[value]) {
        tempErrors["currencyField"][index] = true;
        flag = true;
      }
    });
    setErrorsState(tempErrors);
    return flag;
  };

  const saveMarketPlace = async () => {
    setSaveLoader(true);
    let payload = prePareData();
    let returnedError = handleErrors();
    if (!returnedError) {
      let { success, message, code } = await postMethod(saveDataAPI, payload);
      if (success) {
        setSuccessToast({
          active: true,
          message: "Conversion rate has been updated successfully",
          success: success,
        });
      } else if (code) {
        if (
          code === "token_expired" ||
          code === "invalid_token" ||
          code === "future_token" ||
          code === "token_decode_error"
        ) {
          props.history.push("/tokenexpired");
        }
      } else {
        setErrorToast({
          active: true,
          message: message,
          success: success,
        });
      }
    } else if (currency["aud"] && Number(currency["aud"]) === 0) {
      setErrorToast({
        active: true,
        message: "Currency should be greater than 0",
        success: true,
      });
    } else {
      setErrorToast({
        active: true,
        message: "Please fill all the required fields.",
        success: true,
      });
    }
    setSaveLoader(false);
  };

  useEffect(() => {
    let tempPrevState = {};
    tempPrevState["ebayCurrencyName"] = currency?.ebayCurrencyName;
    tempPrevState["ebayCurrencyValue"] = currency?.ebayCurrencyValue;
    tempPrevState["shoplineCurrencyName"] = currency?.shoplineCurrencyName;
    tempPrevState["shoplineCurrencyValue"] = currency?.shoplineCurrencyValue;
    setPrevState({ ...tempPrevState });
  }, []);

  useEffect(() => {
    let tempCurrentState = {
      ebayCurrencyName: currency?.ebayCurrencyName,
      ebayCurrencyValue: currency?.ebayCurrencyValue,
      shoplineCurrencyName: currency?.shoplineCurrencyName,
      shoplineCurrencyValue: currency?.shoplineCurrencyValue,
    };
    if (JSON.stringify(prevState) === JSON.stringify(tempCurrentState)) {
      setButtonDisabled(true);
    }
  }, [currency]);

  return (
    <>
      {skeleton ? (
        <CurrencySettingSkeleton />
      ) : (
        <Card
          title={"Currency Converter"}
          subTitle={
            "Enabling this setting automates currency conversion from your Shopline store to your eBay account. This saves time and ensures accurate pricing for cross-border sales, eliminating the need for manual rate calculations when creating listings."
          }
          action={
            <Button
              thickness="thin"
              type="Primary"
              onClick={() => {
                saveMarketPlace();
                setButtonDisabled(true);
              }}
              loading={saveLoader}
              disable={buttonDisabled}
            >
              Save
            </Button>
          }
          extraClass="header-pb-16"
        >
          <FlexLayout direction="vertical" spacing="mediumTight" wrap="noWrap">
            <TextStyles
              fontweight="extraBold"
              lineHeight="LH-2.0"
              paragraphTypes="MD-1.4"
              textcolor="dark"
              type="Paragraph"
            >
              Please note that your Shopline store currency is INR, with this
              feature the app will automatically convert it to eBay AUD
              currency.
            </TextStyles>
            <FlexLayout
              spacing="loose"
              desktopWidth="50"
              tabWidth="50"
              mobileWidth="50"
            >
              <FormElement>
                <FormChild>
                  <TextField
                    type="number"
                    autocomplete="off"
                    error={errorsState.currencyField[0]}
                    innerPreIcon={currency?.shoplineCurrencyName}
                    value={currency?.shoplineCurrencyValue}
                    onChange={(e) => {
                      let temp = { ...currency };
                      temp["shoplineCurrencyValue"] = e;
                      setCurrency(temp);
                      let { currencyField } = errorsState;
                      currencyField[0] = false;
                    }}
                    disabled
                  />
                </FormChild>
              </FormElement>
              <FormElement>
                <FormChild>
                  <TextField
                    type="number"
                    autocomplete="off"
                    error={errorsState.currencyField[1]}
                    innerPreIcon={currency?.ebayCurrencyName}
                    value={currency?.ebayCurrencyValue}
                    onChange={(e) => {
                      if (e.match(/^[0-9]*[\.]?[0-9]*$/gm)) {
                        let temp = { ...currency };
                        temp["ebayCurrencyValue"] = e;
                        setCurrency(temp);
                        let { currencyField } = errorsState;
                        currencyField[1] = false;
                      }
                      setButtonDisabled(false);
                    }}
                  />
                </FormChild>
              </FormElement>
            </FlexLayout>
          </FlexLayout>
        </Card>
      )}
    </>
  );
};

export default withRouter(CurrencySettings);
