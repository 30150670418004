/* eslint-disable react-hooks/exhaustive-deps */
import {
  Button,
  Card,
  FlexChild,
  FlexLayout,
  Switcher,
  TextField,
  Toast,
  ToastWrapper,
} from "@cedcommerce-integration/ounce-ui";
import React, { useEffect, useState } from "react";
import { Trash2 } from "react-feather";
import { postMethod } from "../../../../../Apirequest/productListingApi";
import { saveDataAPI } from "../../../../../APIs/PanelAPI";
import { globalState } from "../../../../../services/globalState";
import { OrderSettingsSkeleton } from "../../Skeleton/MarketplaceConfigSkeleton";

function OrderSettings(props) {
  let {
    shipmentCheckbox,
    setShipmentCheckbox,
    orderMappingCarrierValues,
    setOrderMappingCarrierValues,
    syncShipment,
    setSyncShipment,
    skeleton,
    setSuccessToast,
    setErrorToast,
    buttonDisabled,
    setButtonDisabled,
  } = props;

  const ebayShopId = globalState.getLocalStorage("ebayShopId");
  const shopLineShopId = globalState.getLocalStorage("shopLineShopId");
  const [saveLoader, setSaveLoader] = useState(false);
  const [activeToast, setActiveToast] = useState(false);
  //error validation
  const [errorsValidation, setErrorsValidation] = useState({
    shippingCarrier: [],
  });
  const [prevState, setPrevState] = useState({});

  const MappingCarrierHandler = (e, index, fieldType) => {
    let temp = [...orderMappingCarrierValues];
    if (fieldType === "shopline_carrier") temp[index]["shoplineAttribute"] = e;
    if (fieldType === "ebay_carrier") temp[index]["ebayAttribute"] = e;
    setOrderMappingCarrierValues(temp);
  };

  const prePareData = () => {
    let payload = {
      source: {
        shopId: shopLineShopId,
        marketplace: "shopline",
      },
      target: {
        shopId: ebayShopId,
        marketplace: "ebay",
      },
      data: [
        {
          group_code: "settings",
          data: {
            syncOrderShipment: syncShipment
              ? {
                  syncTrackingDetails: shipmentCheckbox.syncTracking,
                  mappingOfShippingCarrier: shipmentCheckbox.mapping
                    ? orderMappingCarrierValues.map((mappingData) => {
                        return {
                          shoplineAttribute: mappingData.shoplineAttribute,
                          ebayAttribute: mappingData.ebayAttribute,
                        };
                      })
                    : false,
                }
              : false,
          },
        },
      ],
    };
    return payload;
  };

  const handleErrorsFunc = () => {
    let flag = false;
    let tempCarriers = orderMappingCarrierValues;
    let tempErrors = { ...errorsValidation };
    if (!syncShipment) return false;
    if (shipmentCheckbox.mapping) {
      tempCarriers.forEach((carrier, index) => {
        tempErrors["shippingCarrier"][index] = {};
        if (!carrier.shoplineAttribute) {
          tempErrors["shippingCarrier"][index]["shopline"] = true;
          flag = true;
        } else {
          tempErrors["shippingCarrier"][index]["shopline"] = false;
        }
        if (!carrier.ebayAttribute) {
          tempErrors["shippingCarrier"][index]["ebay"] = true;
          flag = true;
        } else {
          tempErrors["shippingCarrier"][index]["ebay"] = false;
        }
      });
    }
    setErrorsValidation(tempErrors);
    if (flag) setActiveToast(true);
    return flag;
  };

  const saveMarketPlace = async () => {
    setSaveLoader(true);
    let payload = prePareData();
    let returnedError = handleErrorsFunc();
    if (!returnedError) {
      let { success, message, code } = await postMethod(saveDataAPI, payload);
      if (success) {
        setSuccessToast({
          active: true,
          message: "Order settings updated successfully",
          success: success,
        });
      } else if (code) {
        if (
          code === "token_expired" ||
          code === "invalid_token" ||
          code === "future_token" ||
          code === "token_decode_error"
        ) {
          props.history.push("/tokenexpired");
        }
      } else {
        setErrorToast({
          active: true,
          message: message,
          success: success,
        });
      }
    }
    setSaveLoader(false);
  };

  const tagMarkup = activeToast ? (
    <Toast
      message="Please fill all the required fields."
      onDismiss={() => setActiveToast(false)}
      timeout={3000}
      type="error"
    />
  ) : (
    <></>
  );

  useEffect(() => {
    let tempPrevState = { }
    tempPrevState["syncShipment"] = syncShipment;
    tempPrevState["syncTracking"] = shipmentCheckbox.syncTracking;
    tempPrevState["mapping"] = shipmentCheckbox.mapping;
    tempPrevState["orderMappingCarrierValues"] = JSON.parse(
      JSON.stringify(orderMappingCarrierValues)
    );
    setPrevState({ ...tempPrevState });
  }, []);


  useEffect(() => {
    let tempCurrentState = {
      syncShipment: syncShipment,
      syncTracking: shipmentCheckbox.syncTracking,
      mapping: shipmentCheckbox.mapping,
      orderMappingCarrierValues: orderMappingCarrierValues,
    };
    if (
      JSON.stringify(prevState) === JSON.stringify(tempCurrentState)
    ) {
      setButtonDisabled(true);
    }
  }, [syncShipment, shipmentCheckbox, orderMappingCarrierValues]);

  return (
    <>
      {skeleton ? (
        <OrderSettingsSkeleton />
      ) : (
        <Card
          cardType="Default"
          title={"Order Settings"}
          subTitle={
            "Set your preferences for Orders’ syncing between your Shopine store and eBay marketplace."
          }
          action={
            <Button
              type="Primary"
              onClick={() => {
                saveMarketPlace();
                setButtonDisabled(true);
              }}
              loading={saveLoader}
              disable={buttonDisabled}
            >
              Save
            </Button>
          }
          extraClass="header-pb-16 "
        >
          {/* Shipment sync */}
          <Card cardType="Bordered" extraClass="title-break">
            <FlexLayout spacing="extraTight" direction="vertical" wrap="noWrap">
              <Switcher
                checked={syncShipment}
                name="Shipment Sync"
                onChange={() => {
                  setSyncShipment(!syncShipment);
                  setButtonDisabled(false);
                }}
                textAligh="right"
              />
              <div style={{ marginLeft: "4.72rem" }}>
                <FlexLayout direction="vertical" spacing="loose" wrap="noWrap">
                  <FlexLayout
                    direction="vertical"
                    spacing="loose"
                    wrap="noWrap"
                  >
                    <p className="inte__text--light inte__LineHeight--2">
                      The
                      <span className="inte__font--extraBold">
                        {" "}
                        Shipment Sync{" "}
                      </span>{" "}
                      button allows you to enable the option to synchronize the
                      shipment information from Shopline to eBay. When enabled,
                      the app will automatically update the shipment status and
                      tracking details on eBay when an order is fulfilled on
                      Shopline.
                    </p>
                    {syncShipment ? (
                      <FlexLayout
                        direction="vertical"
                        spacing="loose"
                        wrap="noWrap"
                      >
                        <FlexLayout
                          direction="vertical"
                          spacing="extraTight"
                          wrap="noWrap"
                        >
                          <Switcher
                            checked={shipmentCheckbox.syncTracking}
                            name="Sync Tracking Details"
                            onChange={() => {
                              let tempShpimentCheckbox = {
                                ...shipmentCheckbox,
                              };
                              tempShpimentCheckbox["syncTracking"] =
                                !shipmentCheckbox["syncTracking"];
                              setShipmentCheckbox(tempShpimentCheckbox);
                              setButtonDisabled(false);
                            }}
                            textAligh="right"
                          />
                          <div style={{ marginLeft: "4.72rem" }}>
                            <p className="inte__text--light inte__LineHeight--2">
                              Sync Order Fulfillment and Tracking Details with
                              Shopline. If you wish to fulfill Orders without
                              tracking,{" "}
                              <span className="inte__font--extraBold">
                                ‘Disable’{" "}
                              </span>{" "}
                              the option.
                            </p>
                          </div>
                        </FlexLayout>
                        <FlexLayout
                          direction="vertical"
                          spacing="extraTight"
                          wrap="noWrap"
                        >
                          <Switcher
                            checked={shipmentCheckbox.mapping}
                            name="Mapping of Shipping Carrier"
                            onChange={() => {
                              let tempShpimentCheckbox = {
                                ...shipmentCheckbox,
                              };
                              tempShpimentCheckbox["mapping"] =
                                !shipmentCheckbox["mapping"];
                              setShipmentCheckbox(tempShpimentCheckbox);
                              setButtonDisabled(false);
                            }}
                            textAligh="right"
                          />
                          <div style={{ marginLeft: "4.72rem" }}>
                            <p className="inte__text--light inte__LineHeight--2">
                              Map Shopline shipping carriers with eBay shipping,
                              including the carrier names and served countries.
                            </p>
                          </div>
                        </FlexLayout>
                        {shipmentCheckbox.mapping ? (
                          <FlexLayout halign="end">
                            <Button
                              type="Outlined"
                              onClick={() => {
                                let temp = [...orderMappingCarrierValues];
                                temp.push({
                                  shoplineAttribute: "",
                                  ebayAttribute: "",
                                });
                                setOrderMappingCarrierValues(temp);
                                setButtonDisabled(false);
                              }}
                            >
                              Add Shipping Carrier
                            </Button>
                          </FlexLayout>
                        ) : (
                          <></>
                        )}
                      </FlexLayout>
                    ) : (
                      <></>
                    )}
                  </FlexLayout>
                  {syncShipment && shipmentCheckbox.mapping ? (
                    orderMappingCarrierValues.map((mappedValue, index) => {
                      let { shoplineAttribute, ebayAttribute } = mappedValue;
                      console.log(mappedValue);
                      return (
                        <FlexLayout key={`key-${index}`} spacing="tight" valign="end">
                          <FlexChild childWidth="fullWidth">
                            <FlexLayout
                              spacing="loose"
                              desktopWidth="50"
                              tabWidth="50"
                              mobileWidth="50"
                            >
                              <TextField
                                required
                                error={
                                  errorsValidation["shippingCarrier"][index]?.[
                                    "shopline"
                                  ]
                                }
                                autocomplete="off"
                                name="Shopline Carrier"
                                value={shoplineAttribute}
                                onChange={(e) => {
                                  MappingCarrierHandler(
                                    e,
                                    index,
                                    "shopline_carrier"
                                  );
                                  let { shippingCarrier } = errorsValidation;
                                  if (
                                    shippingCarrier[index]?.hasOwnProperty(
                                      "shopline"
                                    )
                                  ) {
                                    shippingCarrier[index]["shopline"] = false;
                                  }
                                  setButtonDisabled(false);
                                }}
                                type="text"
                              />
                              <TextField
                                required
                                error={
                                  errorsValidation["shippingCarrier"][index]?.[
                                    "ebay"
                                  ]
                                }
                                autocomplete="off"
                                name="eBay Carrier"
                                value={ebayAttribute}
                                onChange={(e) => {
                                  MappingCarrierHandler(
                                    e,
                                    index,
                                    "ebay_carrier"
                                  );
                                  let { shippingCarrier } = errorsValidation;
                                  if (
                                    shippingCarrier[index]?.hasOwnProperty(
                                      "ebay"
                                    )
                                  ) {
                                    shippingCarrier[index]["ebay"] = false;
                                  }
                                  setButtonDisabled(false);
                                }}
                                type="text"
                              />
                            </FlexLayout>
                          </FlexChild>
                          <Button
                            icon={<Trash2 />}
                            type="DangerOutlined"
                            thickness="large"
                            onClick={() => {
                              let temp = [...orderMappingCarrierValues];
                              temp.splice(index, 1);
                              setOrderMappingCarrierValues(temp);
                              let tempErrors = { ...errorsValidation };
                              tempErrors["shippingCarrier"].splice(index, 1);
                              setErrorsValidation(tempErrors);
                              setButtonDisabled(false);
                            }}
                            disable={orderMappingCarrierValues.length === 1}
                          />
                        </FlexLayout>
                      );
                    })
                  ) : (
                    <></>
                  )}
                </FlexLayout>
              </div>
            </FlexLayout>
          </Card>
          <ToastWrapper>{tagMarkup}</ToastWrapper>
        </Card>
      )}
    </>
  );
}

export default OrderSettings;
