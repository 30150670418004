/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { Redirect, Route, Switch, withRouter } from "react-router-dom";
import { getMethod, postMethod } from "./apiMethods/methods";
import { getAccountsAPI } from "./APIs/loginAPI";
import Dashboard from "./Components/dashboard/Dashboard";
import CategoryTemplate from "./Components/Ebay/Panel/CategoryTemplate/CategoryTemplate";
import CreateTemplate from "./Components/Ebay/Panel/CategoryTemplate/CreateTemplate";
import Configuration from "./Components/Ebay/Panel/Configuration/Configuration";
import OngoingActivity from "./Components/Ebay/Panel/OngoingActivity/OngoingActivity";
import OrderListing from "./Components/Ebay/Panel/Orders/OrderListing";
import OrderView from "./Components/Ebay/Panel/Orders/OrderView";
import Products from "./Components/Ebay/Panel/products/productListing/Products";
import ViewProducts from "./Components/Ebay/Panel/products/viewProduct/ViewProducts";
import PanelLayout5 from "./PanelLayout";
import { globalState } from "./services/globalState";
import { freshChatWidget } from "./constants/constant";
import { capitalizeFirstLetter } from "./function";
import { parseJwt } from "./App";

const Panel = (props) => {
  const [showDot, setShowDot] = useState(false);
  const [connectorAllData, setConnectorAllData] = useState({});
  let [msgCountInWebpack, setMsgCountInWebpack] = useState(0);

  const checkDatatobenotify = (msg) => {
    let receivedObj = JSON.parse(msg);
    let arr = [];
    let tempIds = sessionStorage.getItem("_id");
    if (tempIds) {
      arr = tempIds.split(",");
    }

    if (msg.includes("message")) {
      if (!arr.length && receivedObj?.["feed"]?.["_id"]?.["$oid"]) {
        arr.push(receivedObj?.["feed"]?.["_id"]?.["$oid"]);
        sessionStorage.setItem("_id", arr);
        setShowDot(true);
      }
    }
    setMsgCountInWebpack(msgCountInWebpack++);
  };
  const webSocketInit = (props, callback) => {
    const Token = globalState.getLocalStorage("auth_token");
    const userId = globalState.getLocalStorage("user_id");
    const clientId = 9;
    if ("WebSocket" in window) {
      const ws = new WebSocket(
        "wss://a5zls8ce93.execute-api.us-east-2.amazonaws.com/beta"
      );
      ws.onopen = function () {
        // just after opening connection its required to send identity to server
        ws.send(
          '{ "action": "identity","client_id":' +
            clientId +
            ',"customer_id":"' +
            userId +
            '","token":"' +
            Token +
            '"}'
        );
      };
      ws.onmessage = function (evt) {
        const received_msg = evt.data;
        checkDatatobenotify(received_msg);
      };
      ws.onclose = function () {
        // websocket is closed.
        webSocketInit(props, callback);
      };
    } else {
      // The browser doesn't support WebSocket
    }
  };

  const getAccounts = async () => {
    let { success, data, code } = await getMethod(getAccountsAPI);
    if (success) {
      setConnectorAllData(data);
      if (
        data?.shopline &&
        data?.shopline?.installed &&
        Array.isArray(data?.shopline?.installed) &&
        data?.shopline?.installed.length > 0
      ) {
        const { domain, email } = data?.shopline?.installed?.[0] ?? {};
        window.user_email = email;
        window.domain = domain;
      }
    } else if (
      code === "token_expired" ||
      code === "invalid_token" ||
      code === "future_token" ||
      code === "token_decode_error"
    ) {
      props.history.push("/tokenexpired");
    }
  };

  useEffect(() => {
    getAccounts();
    webSocketInit();
  }, []);

  const win = window;

  useEffect(() => {
    const script = document.createElement("script");
    script.setAttribute("chat", true);
    script.src = "//in.fw-cdn.com/30398529/224630.js";
    document.head.appendChild(script);
    return () => {
      // Removal of the older script
      const scriptToRemove = document.querySelector(
        'script[src="//in.fw-cdn.com/30398529/224630.js"]'
      );
      const scriptToRemoveId = document.getElementById("freshchat-js-sdk");
      scriptToRemove?.parentNode?.removeChild(scriptToRemove);
      scriptToRemoveId?.parentNode?.removeChild(scriptToRemoveId);
      win?.fcWidget?.destroy();
    }
  }, []);

  const freshchatAPICall = async () => {
    const authToken = sessionStorage.getItem("auth_token");
    const token = parseJwt(authToken);

    const marketplace = connectorAllData?.ebay?.title ?? "eBay";
    const platform = connectorAllData?.shopline?.title ?? "Shopline";
    const status = connectorAllData?.ebay?.installed ?? [];

    const { success, data } = await postMethod(
      "frontend/freshchat/getRestoreId",
      {}
    );

    let userData = {};
    if (success && data) {
      let user = data["data"];
      userData = {
        email: user.email ?? "",
        siteId: token.user_id,
        externalId: token.user_id,
        name: user.name ?? "",
        shopURL: user?.shops[0].domain ?? "",
        restoreId: user?.["freshchat_restore_id"] ?? null,
      };
    }

    const script = document.createElement("script");
    script.setAttribute("chat", true);
    script.src = "//in.fw-cdn.com/30398529/224630.js";
    document.head.appendChild(script);

    win.fcSettings = {
      onInit: function () {
        win.fcWidgetMessengerConfig = {
          tags: ["shopline_welcome"],
          externalId: userData?.externalId,
          restoreId: userData?.restoreId ?? null,
          // open: true,
          siteId: userData?.siteId,
        };
        win?.fcWidget?.user?.get(function (resp) {
          win?.fcWidget?.user?.setProperties({
            Config: status.length ? "Yes" : "No",
            Platform: capitalizeFirstLetter(platform),
            Marketplace: marketplace,
            Status: "Trial",
          });
        });
        win?.fwcrm?.on("widget:loaded", function () {
          win?.fcWidget?.setTags(["shopline_welcome"]);
          win?.fcWidget?.setExternalId(userData?.externalId);
          win?.fcWidget?.user?.setFirstName(userData?.name?.split(" ")?.[0]);
          win?.fcWidget?.user?.setEmail(userData?.email);
        });
        win?.fcWidget?.on("user:created", function (resp) {
          const status = resp?.status,
            data = resp?.data;
          if (status === 200 && !userData?.restoreId) {
            if (data?.restoreId) {
              saveRestoreFunc(data?.restoreId);
            }
          }
        });
      },
    };
  };
  async function saveRestoreFunc(id) {
    await postMethod("frontend/freshchat/saveRestoreId", {
      freshchat_restore_id: id,
    });
  }
  useEffect(() => {
    if (Object.keys(connectorAllData)?.length > 0) {
      freshchatAPICall();
    } else {
      win?.fcWidget?.destroy();
    }
  }, [connectorAllData]);

  return (
    <PanelLayout5 showDot={showDot} setShowDot={setShowDot}>
      <Switch>
        <Route
          exact
          path="/panel"
          render={() => <Redirect to="/panel/dashboard" />}
        />
        <Route
          path="/panel/dashboard"
          render={(props) => {
            return <Dashboard msgCountInWebpack={msgCountInWebpack} />;
          }}
        />
        <Route path="/panel/category-template" component={CategoryTemplate} />
        <Route path="/panel/create-template" component={CreateTemplate} />
        <Route path="/panel/products" component={Products} />
        <Route path="/panel/view-products" component={ViewProducts} />
        <Route path="/panel/settings" component={Configuration} />
        <Route path="/panel/order-listing" component={OrderListing} />
        <Route path="/panel/order-view" component={OrderView} />
        <Route
          path="/panel/ongoingactivity"
          render={(props) => {
            return (
              <OngoingActivity
                showDot={showDot}
                setShowDot={setShowDot}
                msgCountInWebpack={msgCountInWebpack}
              />
            );
          }}
        />
      </Switch>
    </PanelLayout5>
  );
};

export default withRouter(Panel);
