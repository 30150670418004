/* eslint-disable no-unused-vars */
/* eslint-disable array-callback-return */
/* eslint-disable react-hooks/exhaustive-deps */
import {
  Alert,
  Button,
  Card,
  CheckBox,
  FlexChild,
  FlexLayout,
  Grid,
  List,
  LRLayout,
  Modal,
  Radio,
  Select,
  TextField,
  TextStyles,
} from "@cedcommerce-integration/ounce-ui";
import React, { useState, useEffect } from "react";
import { Trash2 } from "react-feather";
import {
  getRunQuery,
  getRunQueryCount,
  getSourceAttributes,
} from "../../../../../Apirequest/templatesApi";
import NestedPagination from "../../../../OunceComponents/NestedPagination";
import { globalState } from "../../../../../services/globalState";
import { RuleGroupSekeleton } from "../../Skeleton/CategoryAddEditSkeleton";
import ModalGridSkeleton from "../../Skeleton/ModalGridSkeleton";
import NoImage from "../../../../../assets/images/notfound.png";

export const columns = [
  {
    align: "left",
    dataIndex: "image",
    title: "Image",
    width: 50,
    visible: true,
  },
  {
    title: "Name",
    dataIndex: "name",
    width: 250,
    visible: true,
  },
];

function RuleGroup(props) {
  const shoplineShopId = globalState.getLocalStorage("shopLineShopId");

  const {
    templateRef,
    sentenceQuery,
    setSentenceQuery,
    filtersArray,
    setFiltersArray,
    query,
    setQuery,
    validationErrors,
    setValidationErrors,
    setSaveErrorToast,
    setrunQueryCountDisableSave,
    condition,
    setCondition,
  } = props;
  const [filterAttributes, setFilterAttributes] = useState([]);
  const [requiredAttributes, setRequiredAttributes] = useState([
    { title: "Title" },
    { title: "Sku" },
    { title: "Tags" },
    { title: "Price" },
    { title: "Weight" },
    { title: "Quantity" },
    { title: "Vendor" },
    { title: "Type" },
  ]);
  const filterConditionsforDropdown = [{ label: "Equals", value: "==" }];
  const filterConditionsforQueryString = [
    { label: "Equals", value: "==" },
    { label: "Not Equals", value: "!=" },
    { label: "Contains", value: "%LIKE%" },
    { label: "Does Not Contains", value: "!%LIKE%" },
  ];
  const filterConditionsforQueryNumber = [
    { label: "Equals", value: "==" },
    { label: "Not Equals", value: "!=" },
    { label: "Greater Than", value: ">" },
    { label: "Less Than", value: "<" },
    { label: "Greater Than Equal To", value: ">=" },
    { label: "Less Than Equal To", value: "<=" },
  ];
  let ruleConditionOptions = [];
  const allfilterConditions = [
    { label: "Equals", value: "==" },
    { label: "Not Equals", value: "!=" },
    { label: "Contains", value: "%LIKE%" },
    { label: "Does Not Contains", value: "!%LIKE%" },
    { label: "Greater Than", value: ">" },
    { label: "Less Than", value: "<" },
    { label: "Greater Than Equal To", value: ">=" },
    { label: "Less Than Equal To", value: "<=" },
  ];
  const [typeList, setTypeList] = useState([]);
  const [productTypeList, setProductTypeList] = useState([]);
  const [vendorList, setVendorList] = useState([]);
  const [collectionList, setCollectionList] = useState([]);
  const [categoriesList, setCategoriesList] = useState([]);
  const [variantAttributesList, setVariantAttributesList] = useState([]);
  const [productStatusList, setProductStatusList] = useState([]);
  // view product
  const [viewProduct, setViewProduct] = useState(false);
  const [viewProductsModalSkeleton, setViewProductsModalSkeleton] =
    useState(true);

  const [runQueryProductCount, setRunQueryProductCount] = useState(null);
  const [runQueryProductData, setRunQueryProductData] = useState([]);
  const [runQueryLoader, setRunQueryLoader] = useState(false);

  // pagination
  const [activePage, setActivePage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [pageSizeOptions, setPageSizeOptions] = useState([
    {
      label: "10",
      value: "10",
    },
    {
      label: "15",
      value: "15",
    },
    {
      label: "20",
      value: "20",
    },
    {
      label: "25",
      value: "25",
    },
    {
      label: "50",
      value: "50",
    },
  ]);
  const [payloadState, setPayloadState] = useState({
    // onPage: 1,
    activePage: 1,
    limit: 10,
  });
  // skeleton state
  const [ruleGroupSkeleton, setRuleGroupSkeleton] = useState(true);

  const hitFilterAttributes = async () => {
    const time1 = new Date();
    let ms1 = time1.getTime();
    setRuleGroupSkeleton(true);
    let { success, data } = await getSourceAttributes({
      source: { marketplace: "shopline", shopId: shoplineShopId },
    });
    const time2 = new Date();
    let ms2 = time2.getTime();
    let timeOut = ms2 - ms1;
    if (success && data && Array.isArray(data) && data.length) {
      const attributeOptions = data.map((object) => {
        const { code, title, options } = object;
        if (title === "Type") setTypeList(options);
        if (title === "Product type") setProductTypeList(options);
        if (title === "Vendor") setVendorList(options);
        if (title === "Categories") setCategoriesList(options);
        if (title === "Variant attributes") setVariantAttributesList(options);
        if (title === "Product status") setProductStatusList(options);

        let returnedObj = {};
        requiredAttributes.some((attribute) => {
          if (attribute.title === title) {
            returnedObj = { label: title, value: code };
            console.log(returnedObj)
          }
        });
        return returnedObj;
      });
      setFilterAttributes(attributeOptions);
    }
    if (timeOut < 500) {
      setTimeout(() => {
        setRuleGroupSkeleton(false);
      }, 500 - timeOut);
    } else setRuleGroupSkeleton(false);
  };

  const ruleConditionOptionsFunc = (levelValue) => {
    let options = [];
    [
      "brand",
      "type",
      "product_type",
      "collection.collection_id",
      "product_category",
      "variant_attributes",
      "source_status",
    ].includes(levelValue)
      ? (options = filterConditionsforDropdown)
      : ["title", "tags", "description", "sku"].includes(levelValue)
      ? (options = filterConditionsforQueryString)
      : ["price", "quantity", "weight"].includes(levelValue) &&
        (options = filterConditionsforQueryNumber);
    ruleConditionOptions = [...options];
    return options;
  };

  const changeHandler = (value, index, fieldType, filterTypes) => {
    let tempFilters = [...filtersArray];
    tempFilters[0][index][fieldType] = value;
    if (fieldType === "attribute" || fieldType === "condition") {
      tempFilters[0][index][`${fieldType}Label`] = filterTypes.find(
        (e) => e.value === value
      )?.label;
    }
    if (fieldType === "attribute") {
      tempFilters[0][index]["condition"] = "";
      tempFilters[0][index]["value"] = "";
      if (
        [
          "brand",
          "type",
          "product_type",
          "collection.collection_id",
          "product_category",
          "variant_attributes",
          "source_status",
        ].includes(value)
      ) {
        tempFilters[0][index]["condition"] = "==";
        tempFilters[0][index]["conditionLabel"] = "Equal";
        let tempErrors = { ...validationErrors };

        tempErrors["ruleGroupValidation"][index] = { condition: false };
        setValidationErrors(tempErrors);
      }
    }
    if (fieldType === "condition") tempFilters[0][index]["value"] = "";

    setFiltersArray(tempFilters);
  };

  const tabledata = runQueryProductData.map((data, index) => {
    return {
      key: index.toString(),
      product_type: data?.product_type,
      name: <TextStyles textcolor="light" type="Paragraph" paragraphTypes="MD-1.4" lineHeight="LH-2.0">{data?.title}</TextStyles>,
      image: data?.main_image ? (
        <img
          src={data?.main_image}
          width="60px"
          height="60px"
          style={{ borderRadius: "4px" }}
          alt=""
        />
      ) : (
        <img
          src={NoImage}
          width="60px"
          height="60px"
          style={{ borderRadius: "4px" }}
          alt=""
        />
      ),
    };
  });
  const filtersStructure = () => {
    let tempArr = filtersArray[0].map((level, index) => {
      return (
        <FlexLayout childWidth="fullWidth" spacing="loose" key={`level-${index}`}>
          <FlexChild desktopWidth="33" tabWidth="50" mobileWidth="50">
            <Select
              error={
                validationErrors["ruleGroupValidation"]?.[index]?.["attribute"]
              }
              placeholder="Please Select"
              value={level["attribute"]}
              options={filterAttributes}
              onChange={(e) => {
                changeHandler(e, index, "attribute", filterAttributes);
                let { ruleGroupValidation } = validationErrors;
                if (ruleGroupValidation.length) {
                  let tempObj = {};
                  tempObj["attribute"] = false;
                  ruleGroupValidation[index] = tempObj;
                }
              }}
            />
          </FlexChild>
          <FlexChild desktopWidth="33" tabWidth="50" mobileWidth="50">
            <Select
              error={
                validationErrors["ruleGroupValidation"]?.[index]?.["condition"]
              }
              placeholder="Please Select"
              value={level["condition"]}
              options={ruleConditionOptionsFunc(level["attribute"])}
              onChange={(e) => {
                changeHandler(e, index, "condition", ruleConditionOptions);
                let { ruleGroupValidation } = validationErrors;
                if (ruleGroupValidation.length) {
                  ruleGroupValidation[index]["condition"] = false;
                }
              }}
              disabled={
                level["attribute"] === "" ||
                [
                  "brand",
                  "type",
                  "product_type",
                  "collection.collection_id",
                  "product_category",
                  "variant_attributes",
                  "source_status",
                ].includes(level["attribute"])
              }
            />
          </FlexChild>
          <FlexChild desktopWidth="33" tabWidth="100" mobileWidth="100">
            <FlexLayout
              childWidth="fullWidth"
              spacing="loose"
              wrap="noWrap"
              halign="evenly"
            >
              <FlexChild desktopWidth={"100"} tabWidth="100" mobileWidth="100">
                {[
                  "brand",
                  "type",
                  "product_type",
                  "collection.collection_id",
                  "product_category",
                  "variant_attributes",
                  "source_status",
                ].includes(level["attribute"]) ? (
                  <Select
                    error={
                      validationErrors["ruleGroupValidation"]?.[index]?.[
                        "value"
                      ]
                    }
                    placeholder="Please Select"
                    value={level["value"]}
                    options={
                      level["attribute"] === "type"
                        ? typeList
                        : level["attribute"] === "brand"
                        ? vendorList
                        : level["attribute"] === "product_type"
                        ? productTypeList
                        : level["attribute"] === "product_category"
                        ? categoriesList
                        : level["attribute"] === "collection.collection_id"
                        ? collectionList
                        : level["attribute"] === "source_status"
                        ? productStatusList
                        : level["attribute"] === "variant_attributes" &&
                          variantAttributesList
                    }
                    onChange={(e) => {
                      changeHandler(e, index, "value");
                      let { ruleGroupValidation } = validationErrors;
                      if (ruleGroupValidation.length) {
                        ruleGroupValidation[index]["value"] = false;
                      }
                    }}
                    disabled={
                      level["attribute"] === "" || level["condition"] === ""
                    }
                  />
                ) : (
                  <TextField
                    error={
                      validationErrors["ruleGroupValidation"]?.[index]?.[
                        "value"
                      ]
                    }
                    value={level.value}
                    onChange={(e) => {
                      changeHandler(e, index, "value");
                      let { ruleGroupValidation } = validationErrors;
                      if (ruleGroupValidation.length) {
                        ruleGroupValidation[index]["value"] = false;
                      }
                    }}
                    disabled={
                      level["attribute"] === "" || level["condition"] === ""
                    }
                  />
                )}
              </FlexChild>
              {filtersArray[0].length > 1 && (
                <FlexChild>
                  <Button
                    thickness="large"
                    iconAlign="left"
                    iconRound={false}
                    type="DangerOutlined"
                    icon={<Trash2 size={20} />}
                    onClick={() => {
                      let tempFiltersArrayGroup = [...filtersArray];
                      tempFiltersArrayGroup[0].splice(index, 1);
                      setFiltersArray(tempFiltersArrayGroup);

                      let tempError = { ...validationErrors };
                      tempError["ruleGroupValidation"].splice(index, 1);
                      setValidationErrors(tempError);
                    }}
                  />
                </FlexChild>
              )}
            </FlexLayout>
          </FlexChild>
        </FlexLayout>
      );
    });
    return tempArr;
  };

  const prepareQuery = () => {
    let flag = false;
    let tempErrors = { ...validationErrors };
    filtersArray[0].forEach((filterObj, index) => {
      let errorsObj = {"test":false};
      if (!filterObj?.attribute) {
        errorsObj["attribute"] = true;
        flag = true;
      } else {
        errorsObj["attribute"] = false;
      }

      if (!filterObj?.condition) {
        errorsObj["condition"] = true;
        flag = true;
      } else {
        errorsObj["condition"] = false;
      }
      if (!filterObj?.value) {
        errorsObj["value"] = true;
        flag = true;
      } else {
        errorsObj["value"] = false;
      }
      tempErrors["ruleGroupValidation"][index] = errorsObj;
    });
    setValidationErrors(tempErrors);
    if (!flag) {
      let queryMaker = "(";
      filtersArray[0].forEach((conditionObj, index) => {
        if (index > 0) {
          if (condition === "or") {
            queryMaker += ") || (";
          } else queryMaker += " && ";
        }
        Object.keys(conditionObj).forEach((key, innerMostIndex) => {
          if (innerMostIndex <= 2) {
            queryMaker += conditionObj[key];
          }
          if (innerMostIndex <= 1) queryMaker += " ";
        });
        if (index === filtersArray[0].length - 1) {
          queryMaker += ")";
        }
      });
      setQuery(queryMaker);
    } else {
      setSaveErrorToast(true);
    }
  };

  const hitRunQueryApi = async (payloadData = {}) => {
    const time1 = new Date();
    let ms1 = time1.getTime();
    setViewProductsModalSkeleton(true);
    props.setQuery(query);
    setRunQueryLoader(true);
    let payload = {
      ...payloadState,
      query: query,
      source: {
        marketplace: "shopline",
        shopId: props.shoplineShopId,
      },
      target: {
        marketplace: "ebay",
        shopId: props.shop_id,
      },
      useForcedRefineProductTable: true,
      check_profile_id: "",
      useRefinProduct: true,
    };
    let { success: countSuccess, data: countData } = await getRunQueryCount(
      payload
    );
    if (countSuccess) {
      setRunQueryProductCount(countData?.count);
    }
    payload = { ...payload, ...payloadData };
    let { success, data } = await getRunQuery(payload);
    const time2 = new Date();
    let ms2 = time2.getTime();
    let timeOut = ms2 - ms1;
    if (success) {
      setRunQueryProductData(data?.rows);
      if (data?.count === 0) {
        setrunQueryCountDisableSave(true);
      } else {
        setrunQueryCountDisableSave(false);
      }
    }
    if (timeOut < 500) {
      setTimeout(() => {
        setRunQueryLoader(false);
        setViewProductsModalSkeleton(false);
      }, 500 - timeOut);
    } else {
      setRunQueryLoader(false);
      setViewProductsModalSkeleton(false);
    }
  };

  const getParsedQuerySentence = () => {
    let parsedSentence = filtersArray[0].reduce(
      (previousQuery, currentQuery, currentIndex, originalArray) => {
        let { attribute, value } = currentQuery;
        let attributeLabel = filterAttributes.find(
          (obj) => obj.value === attribute
        )?.label;
        let conditionLabel = allfilterConditions.find(
          (obj) => obj.value === currentQuery["condition"]
        )?.label;
        let parsedQuery = "";
        if (attributeLabel) {
          parsedQuery = `(${attributeLabel ?? ""} ${
            conditionLabel ?? ""
          } ${value})`;
        }

        let mergeQuery =
          currentIndex !== originalArray.length - 1 && parsedQuery !== ""
            ? `${previousQuery} ${parsedQuery} ${condition}`
            : `${previousQuery} ${parsedQuery}`;
        if (mergeQuery.length === 1 && mergeQuery === " ") {
          return "";
        }
        return mergeQuery;
      },
      ""
    );
    setSentenceQuery(parsedSentence);
  };

  useEffect(() => {
    hitFilterAttributes();
  }, []);

  useEffect(() => {
    hitRunQueryApi({ activePage: activePage, limit: pageSize });
  }, [query, activePage, pageSize]);

  useEffect(() => {
    getParsedQuerySentence();
    setQuery("")
  }, [filtersArray, condition]);

  return (
    <>
      {ruleGroupSkeleton ? (
        <RuleGroupSekeleton />
      ) : (
        <>
          <LRLayout
            required={true}
            title={"Assign Rule to the Template"}
            lrHelpText={
              <FlexLayout
                direction="vertical"
                spacing="extraTight"
                wrap="noWrap"
              >
                <TextStyles
                  type="Paragraph"
                  paragraphTypes="MD-1.4"
                  textcolor="light"
                  lineHeight="LH-2.0"
                >
                  You can create a Rule Group or query for your products by
                  allowing specific filter options that can be applied to the
                  template. These Rules help to apply settings on specific
                  products.
                </TextStyles>
                <List type="disc">
                  <TextStyles type="Paragraph" paragraphTypes="MD-1.4">
                    Select <b>Any Condition </b> to fetch the products
                    fulfilling any of the conditions created.
                  </TextStyles>
                  <TextStyles type="Paragraph" paragraphTypes="MD-1.4">
                    Select <b>All Condition </b> to fetch the products
                    fulfilling all the conditions created.
                  </TextStyles>
                  <TextStyles type="Paragraph" paragraphTypes="MD-1.4">
                    Click on <b>Add More</b> to add multiple conditions in the
                    Rule Group.
                  </TextStyles>
                  <TextStyles type="Paragraph" paragraphTypes="MD-1.4">
                    Click on <b>Run Query</b> to fetch the number of the
                    products on the basis of the Rule Groups created.
                  </TextStyles>
                </List>
              </FlexLayout>
            }
          >
            <Card
              cardType="Plain"
              extraClass="header-pb-16"
              title={
                <TextStyles
                  fontweight="bold"
                  type="SubHeading"
                  subheadingTypes="XS-1.6"
                  lineHeight="LH-2.4"
                >
                  Rule Group
                </TextStyles>
              }
            >
              <span ref={(e) => (templateRef.current["ruleGroup"] = e)}>
                {" "}
                <FlexLayout direction="vertical" spacing="loose">
                  <CheckBox
                    checked={props.override}
                    description="Select this option if you wish to override listings with templates already assigned."
                    id="two"
                    labelVal="Override Listings"
                    name="Override Listings"
                    onClick={() => {
                      props.setOverride(!props.override);
                      setQuery("");
                    }}
                  />
                  <FlexLayout spacing="loose">
                    <TextStyles type="Paragraph" paragraphTypes="MD-1.4">
                      Product Must Match
                    </TextStyles>
                    <FlexLayout spacing="loose">
                      <Radio
                        key={1}
                        id="1"
                        labelVal="Any Condition"
                        checked={condition === "or"}
                        onClick={() => {
                          setCondition("or");
                          setQuery("");
                        }}
                        value={condition}
                      />
                      <Radio
                        key={2}
                        id="2"
                        labelVal="All Conditions"
                        checked={condition === "and"}
                        onClick={() => {
                          setCondition("and");
                          setQuery("");
                        }}
                        value={condition}
                      />
                    </FlexLayout>
                  </FlexLayout>
                  {filtersStructure()}
                  {sentenceQuery.length > 0 ? (
                    <TextStyles
                      type="Paragraph"
                      paragraphTypes="MD-1.4"
                      utility="category-helptext"
                    >
                      {sentenceQuery}
                    </TextStyles>
                  ) : (
                    <></>
                  )}
                  <FlexLayout spacing="loose" halign="end">
                    <Button
                      type="Outlined"
                      onClick={() => {
                        let tempfiltersArray = [...filtersArray];
                        let tempObj = {
                          attribute: "",
                          condition: "",
                          value: "",
                        };
                        tempfiltersArray[0].push(tempObj);
                        setFiltersArray(tempfiltersArray);
                        setQuery("");
                      }}
                    >
                      Add More
                    </Button>
                    <Button
                      type="Primary"
                      onClick={() => {
                        prepareQuery();
                      }}
                      loading={runQueryLoader}
                      disable={!filtersArray[0][0]["value"]}
                    >
                      Run Query
                    </Button>
                  </FlexLayout>
                  {query !== "" && (
                    <Alert type="info" destroy={false}>
                      <FlexLayout spacing="extraTight">
                        <TextStyles type="Paragraph" paragraphTypes="MD-1.4">
                          Total {runQueryProductCount} products affected
                        </TextStyles>
                        {runQueryProductCount > 0 && (
                          <Button
                            type="TextButton"
                            content="View Products"
                            onClick={() => {
                              setViewProduct(!viewProduct);
                            }}
                          />
                        )}
                      </FlexLayout>
                    </Alert>
                  )}
                </FlexLayout>
              </span>
            </Card>
          </LRLayout>
          {/*Affected Product View - Model Start*/}
          <Modal
            closeOnEscape={true}
            overlayClose={true}
            modalSize="medium"
            open={viewProduct}
            heading="Affected Products"
            close={() => {
              setViewProduct(!viewProduct);
              setActivePage(1);
              setPageSize(10);
            }}
          >
            {viewProductsModalSkeleton ? (
              <ModalGridSkeleton />
            ) : (
              <>
                {" "}
                <Grid columns={columns} dataSource={tabledata} />
                <Card cardType="Bordered" extraClass="ced-pagination__wrap">
                  <NestedPagination
                    pageSize={pageSize}
                    setPageSize={setPageSize}
                    activePage={activePage}
                    setActivePage={setActivePage}
                    pageSizeOptions={pageSizeOptions}
                    totalCount={runQueryProductCount}
                    payloadState={payloadState}
                    setPayloadState={setPayloadState}
                    getProductsApi={hitRunQueryApi}
                  />
                </Card>
              </>
            )}
          </Modal>
          {/*Affected Product View - Model End*/}
        </>
      )}
    </>
  );
}

export default RuleGroup;
