import OnboardingHeader from "../Onboarding/OnboardingHeader"
import Footer from "../../shared/Footer";


const OnboardingLayout = ({ children }) => {
  return (
    <>
      <div
        className="pt-30 pb-100 onboarding"
        style={{
          width: "initial",
          margin: "auto",
          maxWidth: "700px",
        }}
      >
        <OnboardingHeader />
        {children}
      </div>
      <Footer/>
    </>
  );
};

export default OnboardingLayout;
