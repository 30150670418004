import { AppWrapper, BodyLayout } from "@cedcommerce-integration/ounce-ui";
import Footer from "./Components/shared/Footer";
import PanelHeader from "./Components/shared/PanelHeader";
import PanelSidebar from "./Components/shared/PanelSidebar";

const PanelLayout = ({ showDot, setShowDot, children }) => {
  return (
    <AppWrapper>
      <PanelHeader showDot={showDot} setShowDot={setShowDot} />
      <PanelSidebar />
      <BodyLayout>{children}</BodyLayout>
      <Footer />
    </AppWrapper>
  );
};

export default PanelLayout;
