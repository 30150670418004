import {
  Button,
  Modal,
  Popover,
  TextStyles,
  Toast,
  ToastWrapper,
} from "@cedcommerce-integration/ounce-ui";
import { useCallback, useState } from "react";
import { Edit, MoreVertical, Trash2 } from "react-feather";
import { withRouter } from "react-router-dom";
import { getMethod } from "../../../../../apiMethods/methods";
import { deleteProfileAPI } from "../../../../../APIs/PanelAPI";

const CreatePopover = (props) => {
  const { record, getAllProfiles } = props;

  const [PopoverEnable, setPopoverEnable] = useState(false);
  const [toggleReject, setToggleReject] = useState({
    active: false,
    content: "",
    api: deleteProfileAPI,
    methodName: getMethod,
    payload: {},
    loader: false,
  });
  //   toast after delete template
  const [errorToast, setErrorToast] = useState(false);
  const [successToast, setSuccessToast] = useState(false);
  // toast for edit/delete in case of assigning product
  const [errorToastAssignProduct, setErrorToastAssignProduct] = useState({
    active: false,
    message: "",
    success: false,
  });

  const toggleErrorActive = useCallback(() => {
    setErrorToast((active) => !active);
  }, []);

  const toggleSuccessActive = useCallback(() => {
    setSuccessToast((active) => !active);
  }, []);

  const toastMarkup = successToast ? (
    <Toast
      message="Template deleted successfully"
      onDismiss={toggleSuccessActive}
      timeout={2000}
      type="success"
    />
  ) : errorToast ? (
    <Toast
      message="Template Deletion is Not Successful"
      onDismiss={toggleErrorActive}
      timeout={2000}
      type="error"
    />
  ) : null;

  const toastAssignProductMarkup = errorToastAssignProduct.active ? (
    <Toast
      message={errorToastAssignProduct.message}
      onDismiss={() =>
        setErrorToastAssignProduct({
          active: false,
          message: "",
          success: false,
        })
      }
      timeout={2000}
      type="error"
    />
  ) : null;

  return (
    <>
      <Popover
        open={PopoverEnable === props.key}
        popoverWidth={155}
        onClose={() => {
          PopoverEnable === props.key
            ? setPopoverEnable(false)
            : setPopoverEnable(props.key);
        }}
        activator={
          <Button
            icon={<MoreVertical size={20} />}
            type="Outlined"
            onClick={() => {
              PopoverEnable === props.key
                ? setPopoverEnable(false)
                : setPopoverEnable(props.key);
            }}
          />
        }
      >
        <div className="custom-popover-action">
          <Button
            type="Outlined"
            length="fullBtn"
            halign="Start"
            onClick={() => {
              const { _id } = record;
              if (
                record.hasOwnProperty("product_update_in_progress") &&
                record["product_update_in_progress"]
              ) {
                setErrorToastAssignProduct({
                  active: true,
                  message: "Kindly wait, some products are still assigning.",
                  success: true,
                });
              } else {
                  window.scrollTo(0, 0);
                  props.history.push(
                    `/panel/create-template?id=${_id?.["$oid"]}`
                  );
              }
            }}
            icon={<Edit size={20} />}
          >
            Edit
          </Button>

          <Button
            type="DangerOutlined"
            length="fullBtn"
            halign="Start"
            onClick={() => {
              const { $oid: templateId } = record?._id ?? {};
              if (
                record.hasOwnProperty("product_update_in_progress") &&
                record["product_update_in_progress"]
              ) {
                setErrorToastAssignProduct({
                  active: true,
                  message: "Kindly wait, some products are still assigning.",
                  success: true,
                });
              } else {
                setToggleReject({
                  active: true,
                  content:
                    "Deleting this template will unassign the products under the template. Do you wish to continue with deletion?",
                  api: deleteProfileAPI,
                  methodName: getMethod,
                  payload: {
                    id: templateId,
                  },
                });
              }
            }}
            icon={<Trash2 size={20} />}
          >
            Delete
          </Button>
        </div>
      </Popover>

      <Modal
        closeOnEscape={true}
        overlayClose={true}
        modalSize="small"
        open={toggleReject.active}
        heading="Permission Required"
        primaryAction={{
          content: "Confirm",
          loading: toggleReject.loader,
          type: "Danger",
          onClick: async () => {
            setToggleReject({ ...toggleReject, loader: true });
            const { api, methodName, payload } = toggleReject;
            let { success } = await methodName(api, payload);
            if (success) {
              setSuccessToast(true);
            } else {
              setErrorToast(true);
            }
            getAllProfiles(
              {
                count: 10,
                activePage: 1,
              },
              true
            );
            setToggleReject({
              active: false,
              content: "",
              api: deleteProfileAPI,
              methodName: getMethod,
              payload: {},
              loader: false,
            });
          },
        }}
        close={() => {
          setToggleReject({
            active: false,
            content: "",
            api: deleteProfileAPI,
            methodName: getMethod,
            payload: {},
            loader: false,
          });
        }}
      >
        <TextStyles>{toggleReject.content}</TextStyles>
      </Modal>
      <ToastWrapper>{toastMarkup}</ToastWrapper>
      <ToastWrapper>{toastAssignProductMarkup}</ToastWrapper>
    </>
  );
};

export default withRouter(CreatePopover);
