/* eslint-disable react-hooks/exhaustive-deps */
import {
  Alert,
  Button,
  Card,
  FallBack,
  FlexLayout,
  Modal,
  PageHeader,
  Skeleton,
  Tabs,
  TextStyles,
  Toast,
  ToastWrapper,
  ToolTip,
} from "@cedcommerce-integration/ounce-ui";
import React, { useCallback, useEffect, useState } from "react";
import { OrderPageSkeleton } from "../Skeleton/OrderListingSearch";
import { withRouter } from "react-router-dom";
import { getMethod, postMethod } from "../../../../apiMethods/methods";
import { fetOrderAPI, getOrderAPI } from "../../../../APIs/PanelAPI";
import { globalState } from "../../../../services/globalState";
import Failed from "./components/Failed";
import FulFilled from "./components/Fulfilled";
import Unfulfilled from "./components/Unfulfilled";
import { RefreshCw } from "react-feather";
import { NoOrder } from "../../../shared/IconSvg";
import All from "./components/All";
import StatusData from "../products/productListing/Components/StatusData";
import {
  ActiveBadge,
  AllBadge,
  ErrorBadge,
  UnshippedBadge,
} from "../products/productListing/Components/IconSvg";
import {
  getPaymentChannelsAPI
} from "../../../../APIs/loginAPI";

const OrderStatusData = [
  {
    text: "All",
    description: "Total no. of orders fetched from the eBay marketplace.",
    icon: AllBadge,
  },
  {
    text: "Fulfilled",
    description: "Total no. of orders fulfilled from Shopline store.",
    icon: ActiveBadge,
  },
  {
    text: "Unfulfilled",
    description:
      "Total no.of orders created on Shopline store but are still pending fulfillment.",
    icon: UnshippedBadge,
  },
  {
    text: "Failed",
    description:
      "Total no. of orders that failed to create in the Shopline store.",
    icon: ErrorBadge,
  },
];

const OrderListing = (props) => {
  const eBayShopId = globalState.getLocalStorage("ebayShopId");
  const shoplineShopId = globalState.getLocalStorage("shopLineShopId");
  const [selected, setselected] = useState(
    props?.location?.state?.tabFromDashboard
      ? props?.location?.state?.tabFromDashboard
      : "all"
  );
  const [fetchLoader, setFetchLoader] = useState(false);

  // skeleton states
  const [orderGridSkeleton, setOrderGridSkeleton] = useState(true);
  const [tabsCount, setTabsCount] = useState({
    all: 0,
    fulfilled: 0,
    unfulfilled: 0,
    failed: 0,
  });
  const [fetchOrderModalActive, setFetchOrderModalActive] = useState(false);
  const [paymentMode, setPaymentMode] = useState(false);
  const [bannerLoader, setBannerLoader] = useState(false);
  //toast
  const [successToast, setSuccessToast] = useState({
    active: false,
    message: "",
    success: false,
  });
  const [errorToast, setErrorToast] = useState({
    active: false,
    message: "",
    success: false,
  });
  // fallback - no orders
  const [fallbackStatusNoOrdersAll, setFallbackStatusNoOrdersAll] =
    useState(false);
  // fallback - tab orders
  const [fallbackStatusNoOrdersFulfilled, setFallbackStatusNoOrdersFulfilled] =
    useState(false);
  const [
    fallbackStatusNoOrdersUnfulfilled,
    setFallbackStatusNoOrdersUnfulfilled,
  ] = useState(false);
  const [fallbackStatusNoOrdersFailed, setFallbackStatusNoOrdersFailed] =
    useState(false);
  // no search order found

  const handleTabChange = useCallback((e) => {
    setselected(e);
  }, []);

  const tableColumns = [
    {
      align: "left",
      dataIndex: "order_id",
      title: "Order ID",
      width: 200,
      visible: true,
    },
    {
      title: "Customer Name",
      dataIndex: "full_name",
      width: 200,
      visible: true,
    },
    {
      title: "Created Date",
      dataIndex: "created_date",
      width: 200,
      visible: true,
    },
    {
      title: "Order Status",
      dataIndex: "order_status",
      width: 200,
      visible: true,
    },
    {
      title: "Quantity",
      dataIndex: "quantity",
      width: 150,
      visible: true,
    },
    {
      title: "Price",
      dataIndex: "price",
      width: 150,
      visible: true,
    },
    {
      align: "center",
      dataIndex: "actions",
      title: "Actions",
      fixed: "right",
      width: 100,
      visible: true,
    },
  ];

  const getTabsCountFunc = async () => {
    const time1 = new Date();
    let ms1 = time1.getTime();
    let tempTabsCount = { ...tabsCount };
    let payload = {
      "filter[object_type][1]": "source_order",
      "filter[marketplace_shop_id][1]": eBayShopId,
      activePage: 1,
    };
    let { success, data, code } = await getMethod(getOrderAPI, payload);
    const time2 = new Date();
    let ms2 = time2.getTime();
    let timeOut = ms2 - ms1;
    if (success) {
      tempTabsCount["all"] = data?.count;
      data?.rows.forEach((row, index) => {
        if (row?.targets[0]?.status === "failed") tempTabsCount["failed"] += 1;
        if (row?.targets[0]?.status === "Created")
          tempTabsCount["unfulfilled"] += 1;
        if (row?.targets[0]?.status === "Shipped")
          tempTabsCount["fulfilled"] += 1;
      });
    } else if (
        code === "token_expired" ||
        code === "invalid_token" ||
        code === "future_token" ||
        code === "token_decode_error"
      ) {
        props.history.push("/tokenexpired");
      }
    if (tempTabsCount?.all === 0) {
      setFallbackStatusNoOrdersAll(true);
    } else {
      setFallbackStatusNoOrdersAll(false);
    }
    if (tempTabsCount?.failed === 0) {
      setFallbackStatusNoOrdersFailed(true);
    } else {
      setFallbackStatusNoOrdersFailed(false);
    }
    if (tempTabsCount?.fulfilled === 0) {
      setFallbackStatusNoOrdersFulfilled(true);
    } else {
      setFallbackStatusNoOrdersFulfilled(false);
    }
    if (tempTabsCount?.unfulfilled === 0) {
      setFallbackStatusNoOrdersUnfulfilled(true);
    } else {
      setFallbackStatusNoOrdersUnfulfilled(false);
    }
    setTabsCount(tempTabsCount);
    if (timeOut < 500) {
      setTimeout(() => {
        setOrderGridSkeleton(false);
      }, 500 - timeOut);
    } else setOrderGridSkeleton(false);
  };

  const fetchOrder = () => {
    setFetchOrderModalActive(true);
  };
  const PaymnentMode = async () => {
    setBannerLoader(true);
    let response = await getMethod(getPaymentChannelsAPI);
    if (response?.data === null || response?.data?.length === 0) {
      setPaymentMode(true);
    } else {
      setPaymentMode(false);
    }

    setBannerLoader(false);
  };

  useEffect(() => {
    getTabsCountFunc();
    PaymnentMode();
  }, []);

  const toastMarkup = successToast.active ? (
    <Toast
      message={successToast.message}
      onDismiss={() =>
        setSuccessToast({ active: false, message: "", success: false })
      }
      timeout={2000}
      type={successToast.success ? "success" : "error"}
    />
  ) : errorToast.active ? (
    <Toast
      message={errorToast.message}
      onDismiss={() =>
        setErrorToast({ active: false, message: "", success: false })
      }
      timeout={2000}
      type="error"
    />
  ) : null;

  return (
    <>
      <>
        {orderGridSkeleton ? (
          <OrderPageSkeleton />
        ) : fallbackStatusNoOrdersAll ? (
          <>
            <PageHeader
              title="Orders"
              description="Order List enable you to fetch orders from eBay Marketplace instantly and create them on your Shopline store."
            />
            <div style={{ margin: "10px 0 30px" }}>
              {bannerLoader ? (
                <Skeleton height="50px" line={1} type="line" width="50px" />
              ) : paymentMode ? (
                <Alert destroy={false} icon type="warning">
                  Order synchronization on Shopline is pending due to the
                  unavailability of the custom payment method. Kindly add a
                  custom payment method from the Payment Settings section of the
                  Configuration tab of the app.
                </Alert>
              ) : null}
            </div>
            <FallBack
              FallBackHeight="NormalPage"
              illustration={NoOrder}
              title="No Order Found!"
              subTitle="Seems like you haven't received any orders yet!"
              action={
                <Button
                  icon={<RefreshCw size={16} color="#fff" />}
                  onAction={fetchOrder}
                  thickness="large"
                  type="Primary"
                >
                  Fetch Orders
                </Button>
              }
            />
          </>
        ) : (
          <>
            <PageHeader
              action={
                <ToolTip
                  helpText="The feature helps you fetch all the orders instantly from the marketplace along with an automated real-time update at an interval of 15 minutes."
                  popoverContainer="element"
                  position="bottom"
                  extraClass="notification-tooltip right-unset"
                  open={false}
                >
                  <Button type="Primary" thickness="thin" onClick={fetchOrder}>
                    Fetch Orders
                  </Button>
                </ToolTip>
              }
              title="Orders"
              description="Order List enable you to fetch orders from eBay Marketplace instantly and create them on your Shopline store."
            />
            <Card>
              {/* Tabs */}
              <Tabs
                alignment="horizontal"
                onChange={(e) => {
                  handleTabChange(e);
                }}
                selected={selected}
                value={[
                  {
                    badge: true,
                    badgeContent: tabsCount.all,
                    badgeTextColor: "light",
                    content: "All",
                    customBgColors: "#9984DB",
                    id: "all",
                  },
                  {
                    badge: true,
                    badgeContent: tabsCount.fulfilled,
                    badgeTextColor: "light",
                    customBgColors: "#269E6C",
                    content: "Fulfilled",
                    id: "fulfilled",
                  },
                  {
                    badge: true,
                    badgeContent: tabsCount.unfulfilled,
                    customBgColors: "#F6CECB",
                    content: "Unfulfilled",
                    id: "unfulfilled",
                  },
                  {
                    badge: true,
                    badgeContent: tabsCount.failed,
                    badgeTextColor: "light",
                    customBgColors: "#FF0000",
                    content: "Failed",
                    id: "failed",
                  },
                ]}
              >
                <StatusData
                  ProductStatusData={OrderStatusData}
                  Title="Order Statuses"
                />
                {selected === "all" ? (
                  <>
                    <All
                      tableColumns={tableColumns}
                      fallbackStatusNoOrdersAll={fallbackStatusNoOrdersAll}
                    />
                  </>
                ) : selected === "fulfilled" ? (
                  <>
                    <FulFilled
                      tableColumns={tableColumns}
                      fallbackStatusNoOrdersFulfilled={
                        fallbackStatusNoOrdersFulfilled
                      }
                    />
                  </>
                ) : selected === "unfulfilled" ? (
                  <>
                    <Unfulfilled
                      tableColumns={tableColumns}
                      fallbackStatusNoOrdersUnfulfilled={
                        fallbackStatusNoOrdersUnfulfilled
                      }
                    />
                  </>
                ) : selected === "failed" ? (
                  <>
                    <Failed
                      tableColumns={tableColumns}
                      fallbackStatusNoOrdersFailed={
                        fallbackStatusNoOrdersFailed
                      }
                    />
                  </>
                ) : null}
              </Tabs>
            </Card>
          </>
        )}
      </>
      {/* Bulk Upoload Product Modal */}
      <Modal
        closeOnEscape={true}
        overlayClose={true}
        open={fetchOrderModalActive}
        close={() => setFetchOrderModalActive(!fetchOrderModalActive)}
        heading="Fetch Order"
        modalSize="small"
        primaryAction={{
          content: "Fetch",
          loading: fetchLoader,
          onClick: async () => {
            setFetchLoader(true);
            let payload = { shop_id: shoplineShopId, page: 1 };
            let { success, data, code } = await postMethod(
              fetOrderAPI,
              payload
            );
            if (success) {
              setSuccessToast({
                active: true,
                message: data,
                success: success,
              });
            } else if (code) {
              if (
                code === "token_expired" ||
                code === "invalid_token" ||
                code === "future_token" ||
                code === "token_decode_error"
              ) {
                props.history.push("/tokenexpired");
              }
            } else {
              setErrorToast({
                active: true,
                message: data,
                success: success,
              });
            }
            setFetchOrderModalActive(false);
            setFetchLoader(false);
          },
        }}
      >
        <FlexLayout direction="vertical" spacing="tight" wrap="noWrap">
          <TextStyles
            alignment="left"
            fontweight="extraBold"
            lineHeight="LH-2.0"
            paragraphTypes="MD-1.4"
            textcolor="dark"
            type="Paragraph"
            utility="none"
          >
            Are you sure you want to initiate fetch order?
          </TextStyles>
        </FlexLayout>
      </Modal>
      <ToastWrapper>{toastMarkup}</ToastWrapper>
    </>
  );
};

export default withRouter(OrderListing);
