import {
  Card,
  FlexChild,
  FlexLayout,
  Grid,
  PageHeader,
  Skeleton,
} from "@cedcommerce-integration/ounce-ui";
import React from "react";
import "./skelton.css";
export const CategoryListingSkeleton = () => {
  const key = [1, 2, 3, 4, 5, 6];
  const dataSoureces = key.map((val, index) => {
    return {
      key: index.toString(),
      name: <Skeleton height="20px" type="custom" width="80%" />,
      category: <Skeleton height="20px" type="line" line={2} />,
      rules: (
        <div className="skeleton--center">
          <Skeleton height="20px" line={1} type="custom" width="80%" />
        </div>
      ),
      total_product: (
        <div className="skeleton--center">
          <Skeleton height="20px" line={1} type="custom" width="80%" />
        </div>
      ),
      action: (
        <FlexLayout halign="center">
          <Skeleton height="40px" line={1} type="custom" width="40px" />
        </FlexLayout>
      ),
    };
  });

  const columns = [
    {
      align: "left",
      dataIndex: "name",
      title: <Skeleton height="20px" line={1} type="custom" width="100%" />,
      width: 200,
    },
    {
      align: "left",
      dataIndex: "category",
      title: <Skeleton height="20px" type="custom" width="200px" />,
    },
    {
      align: "center",
      dataIndex: "rules",
      title: <Skeleton height="20px" line={1} type="custom" width="100%" />,
      width: 160,
    },
    {
      align: "center",
      dataIndex: "total_product",
      title: <Skeleton height="20px" line={1} type="custom" width="100%" />,
      width: 160,
    },
    {
      align: "center",
      dataIndex: "action",
      title: <Skeleton height="20px" line={1} type="custom" width="100%" />,
      fixed: "right",
      width: 100,
    },
  ];
  return (
    <>
      <PageHeader
        action={<Skeleton height="20px" width="150px" type="custom" />}
        title={<Skeleton height="20px" width="200px" type="custom" />}
      />
      <Card cardType="Default">
        <FlexLayout spacing="tight" direction="vertical" wrap="noWrap">
          <FlexLayout desktopWidth="50" tabWidth="100" mobileWidth="100">
            <FlexLayout spacing="tight" wrap="noWrap">
              <FlexChild desktopWidth="50" tabWidth="50" mobileWidth="50">
                <Skeleton type="custom" line={1} width="100%" height="35px" />
              </FlexChild>
            </FlexLayout>
          </FlexLayout>
          <Grid
            dataSource={dataSoureces}
            columns={columns}
            scrollX={800}
            size="middle"
            tableLayout="fixed"
          />
          <div className="inte-Pagination">
            <FlexLayout halign="fill" spacing="tight">
              <FlexChild desktopWidth="50" tabWidth="50" mobileWidth="50">
                <Skeleton height="39px" line={1} type="custom" width="300px" />
              </FlexChild>
              <FlexChild desktopWidth="50" tabWidth="50" mobileWidth="50">
                <FlexLayout halign="end">
                  <div className="align_right">
                    <Skeleton
                      height="39px"
                      line={1}
                      type="custom"
                      width="300px"
                    />
                  </div>
                </FlexLayout>
              </FlexChild>
            </FlexLayout>
          </div>
        </FlexLayout>
      </Card>
    </>
  );
};

export const TemplateGridPageSkeleton = () => {
  const key = [1, 2, 3, 4, 5, 6];
  const dataSources = key.map((val, index) => {
    return {
      key: index.toString(),
      name: <Skeleton height="20px" type="custom" width="80%" />,
      category: <Skeleton height="20px" type="line" line={1} />,
      rules: (
        <div className="skeleton--center">
          <Skeleton height="20px" line={1} type="custom" width="80%" />
        </div>
      ),
      total_product: (
        <div className="skeleton--center">
          <Skeleton height="20px" line={1} type="custom" width="80%" />
        </div>
      ),
      action: (
        <FlexLayout halign="center">
          <Skeleton height="40px" line={1} type="custom" width="40px" />
        </FlexLayout>
      ),
    };
  });
  const columns = [
    {
      align: "left",
      dataIndex: "name",
      title: <Skeleton height="20px" line={1} type="custom" width="100%" />,
      width: 200,
    },
    {
      align: "left",
      dataIndex: "category",
      title: <Skeleton height="20px" type="custom" width="200px" />,
    },
    {
      align: "center",
      dataIndex: "rules",
      title: <Skeleton height="20px" line={1} type="custom" width="100%" />,
      width: 160,
    },
    {
      align: "center",
      dataIndex: "total_product",
      title: <Skeleton height="20px" line={1} type="custom" width="100%" />,
      width: 160,
    },
    {
      align: "center",
      dataIndex: "action",
      title: <Skeleton height="20px" line={1} type="custom" width="100%" />,
      fixed: "right",
      width: 100,
    },
  ];
  return (
    <>
      <PageHeader
        action={
          <FlexLayout spacing="mediumTight">
            <Skeleton height="37px" width="150px" type="custom" />
            <Skeleton height="37px" width="150px" type="custom" />
          </FlexLayout>
        }
        title="Category Template"
        description={
          "Category Templates simplify the process of uploading and managing new products on eBay."
        }
      />
      <Card cardType="Default">
        <Card extraClass="ced-filter__wrap" cardType="Bordered">
          <Skeleton type="custom" line={1} width="270px" height="38px" />
        </Card>
        <Grid
          dataSource={dataSources}
          columns={columns}
          scrollX={800}
          size="middle"
          tableLayout="fixed"
        />
        <Card cardType="Bordered" extraClass="ced-pagination__wrap">
          <div className="inte-Pagination">
            <FlexLayout halign="fill" spacing="tight">
              <FlexChild desktopWidth="50" tabWidth="50" mobileWidth="50">
                <Skeleton height="39px" line={1} type="custom" width="300px" />
              </FlexChild>
              <FlexChild desktopWidth="50" tabWidth="50" mobileWidth="50">
                <FlexLayout halign="end">
                  <div className="align_right">
                    <Skeleton
                      height="39px"
                      line={1}
                      type="custom"
                      width="300px"
                    />
                  </div>
                </FlexLayout>
              </FlexChild>
            </FlexLayout>
          </div>
        </Card>
      </Card>
    </>
  );
};

export const TemplateGridSkeleton = () => {
  const key = [1, 2, 3, 4, 5, 6];
  const dataSources = key.map((val, index) => {
    return {
      key: index.toString(),
      name: <Skeleton height="20px" type="custom" width="80%" />,
      category: <Skeleton height="20px" type="line" line={1} />,
      rules: (
        <div className="skeleton--center">
          <Skeleton height="20px" line={1} type="custom" width="80%" />
        </div>
      ),
      total_product: (
        <div className="skeleton--center">
          <Skeleton height="20px" line={1} type="custom" width="80%" />
        </div>
      ),
      action: (
        <FlexLayout halign="center">
          <Skeleton height="40px" line={1} type="custom" width="40px" />
        </FlexLayout>
      ),
    };
  });
  const columns = [
    {
      align: "left",
      dataIndex: "name",
      title: <Skeleton height="20px" line={1} type="custom" width="100%" />,
      width: 200,
    },
    {
      align: "left",
      dataIndex: "category",
      title: <Skeleton height="20px" type="custom" width="200px" />,
    },
    {
      align: "center",
      dataIndex: "rules",
      title: <Skeleton height="20px" line={1} type="custom" width="100%" />,
      width: 160,
    },
    {
      align: "center",
      dataIndex: "total_product",
      title: <Skeleton height="20px" line={1} type="custom" width="100%" />,
      width: 160,
    },
    {
      align: "center",
      dataIndex: "action",
      title: <Skeleton height="20px" line={1} type="custom" width="100%" />,
      fixed: "right",
      width: 100,
    },
  ];
  return (
    <>
      <Grid
        dataSource={dataSources}
        columns={columns}
        scrollX={800}
        size="middle"
        tableLayout="fixed"
      />
      <Card cardType="Bordered" extraClass="ced-pagination__wrap">
        <div className="inte-Pagination">
          <FlexLayout halign="fill" spacing="tight">
            <FlexChild desktopWidth="50" tabWidth="50" mobileWidth="50">
              <Skeleton height="39px" line={1} type="custom" width="300px" />
            </FlexChild>
            <FlexChild desktopWidth="50" tabWidth="50" mobileWidth="50">
              <FlexLayout halign="end">
                <div className="align_right">
                  <Skeleton
                    height="39px"
                    line={1}
                    type="custom"
                    width="300px"
                  />
                </div>
              </FlexLayout>
            </FlexChild>
          </FlexLayout>
        </div>
      </Card>
    </>
  );
};
