/*Empty State Icons*/
export const NoProductsvg = (
  <svg
    width="200"
    height="200"
    viewBox="0 0 200 200"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect width="200" height="200" rx="100" fill="#F0F2FA" />
    <path
      d="M181.54 159.08C183.495 159.08 185.08 157.495 185.08 155.54C185.08 153.585 183.495 152 181.54 152C179.585 152 178 153.585 178 155.54C178 157.495 179.585 159.08 181.54 159.08Z"
      fill="white"
    />
    <path
      d="M180.035 22.0526C181.774 22.0526 183.185 20.6423 183.185 18.9026C183.185 17.1629 181.774 15.7526 180.035 15.7526C178.295 15.7526 176.885 17.1629 176.885 18.9026C176.885 20.6423 178.295 22.0526 180.035 22.0526Z"
      fill="#C5B8EA"
    />
    <path
      d="M167.885 35.7526C171.751 35.7526 174.885 32.6186 174.885 28.7526C174.885 24.8866 171.751 21.7526 167.885 21.7526C164.019 21.7526 160.885 24.8866 160.885 28.7526C160.885 32.6186 164.019 35.7526 167.885 35.7526Z"
      fill="white"
    />
    <path
      d="M37.8848 185.753C41.7507 185.753 44.8848 182.619 44.8848 178.753C44.8848 174.887 41.7507 171.753 37.8848 171.753C34.0188 171.753 30.8848 174.887 30.8848 178.753C30.8848 182.619 34.0188 185.753 37.8848 185.753Z"
      fill="white"
    />
    <path
      d="M191.885 157C192.989 157 193.885 156.105 193.885 155C193.885 153.895 192.989 153 191.885 153C190.78 153 189.885 153.895 189.885 155C189.885 156.105 190.78 157 191.885 157Z"
      fill="#C5B8EA"
    />
    <path
      d="M35.5128 30.301C38.3573 30.301 40.6633 27.995 40.6633 25.1505C40.6633 22.306 38.3573 20 35.5128 20C32.6683 20 30.3623 22.306 30.3623 25.1505C30.3623 27.995 32.6683 30.301 35.5128 30.301Z"
      fill="white"
    />
    <path
      d="M19.0123 30.3C20.4759 30.3 21.6623 29.1136 21.6623 27.65C21.6623 26.1864 20.4759 25 19.0123 25C17.5487 25 16.3623 26.1864 16.3623 27.65C16.3623 29.1136 17.5487 30.3 19.0123 30.3Z"
      fill="#DCDAE3"
    />
    <path
      d="M34.3906 195C36.0475 195 37.3906 193.657 37.3906 192C37.3906 190.343 36.0475 189 34.3906 189C32.7338 189 31.3906 190.343 31.3906 192C31.3906 193.657 32.7338 195 34.3906 195Z"
      fill="#C5B8EA"
    />
    <g filter="url(#filter0_i_1711_45594)">
      <path
        d="M157.896 170.553H38.2767C31.3514 170.553 26 165.784 26 160.219V38.3338C26 32.5045 31.6662 28 38.2767 28H157.896C164.822 28 170.173 32.7694 170.173 38.3338V160.219C170.173 165.784 164.507 170.553 157.896 170.553Z"
        fill="white"
      />
    </g>
    <g filter="url(#filter1_i_1711_45594)">
      <circle cx="164" cy="164" r="20" fill="#D92D20" />
    </g>
    <path
      d="M161.147 152.573H166.772L165.272 168.199H162.647L161.147 152.573Z"
      fill="white"
    />
    <ellipse cx="163.96" cy="173.199" rx="2.8127" ry="2.50018" fill="white" />
    <g filter="url(#filter2_i_1711_45594)">
      <path
        d="M154.285 111.004C153.903 110.242 154.666 107.855 154.285 107.094C152.76 104.808 150.275 104.326 146.463 103.183C141.126 101.66 137.778 99.3654 133.585 98.9845C132.441 98.9845 131.297 98.6036 130.535 98.6036C118.717 97.461 108.806 95.5566 87.077 68.8956C84.7898 66.2295 83.6461 63.5634 81.3589 60.8973C79.0716 58.612 75.2595 61.2781 70.3038 62.4208C69.1601 62.8016 68.3977 62.8016 67.2541 62.8016C63.442 62.8016 58.8675 62.4208 54.6741 62.0399C48.956 61.2781 52.3869 58.2312 45.5251 102.031C45.1439 105.459 44.7627 108.506 44.3815 111.934C44.0002 114.6 44.0002 117.647 44.0002 120.313C44.0002 121.456 44.3815 123.36 45.5251 123.36C63.8232 125.265 75.6407 124.503 104.613 124.122C119.099 124.122 133.585 124.122 148.071 121.456L151.502 121.075C154.551 120.694 157.601 119.552 157.601 117.647C157.601 114.981 155.047 113.67 154.285 111.004Z"
        fill="#A9A6E2"
      />
    </g>
    <g filter="url(#filter3_i_1711_45594)">
      <path
        d="M133.583 99.3657L130.152 109.268C111.473 108.507 92.4122 96.6996 82.5007 81.4647C79.0698 76.1325 76.4014 70.4194 72.5893 65.849C72.5893 65.849 71.4456 64.3255 69.9208 63.1828C74.8765 62.0402 78.6886 59.3741 80.9759 61.6594C82.8819 63.5637 83.6444 65.0872 85.1692 66.9916C85.5504 67.7533 85.9316 68.1342 86.3128 68.8959C86.3128 69.2768 86.694 69.2768 86.694 69.6577C87.8377 71.1812 89.3625 72.7047 90.5061 74.2281C90.8873 74.609 91.2686 74.9899 91.6498 75.7516C93.1746 77.2751 94.3182 78.7986 95.8431 80.3221C96.2243 80.703 96.6055 81.0838 96.9867 81.8456C98.5116 83.3691 99.6552 84.8926 101.18 86.0352C101.561 86.4161 102.324 86.7969 102.705 87.5587C104.23 88.7013 105.373 89.8439 106.517 90.9865C107.279 91.3674 107.661 91.7483 108.423 92.51C109.567 93.2718 110.329 94.0335 111.473 94.4144C112.235 94.7952 112.998 95.1761 113.379 95.557C115.285 96.6996 117.191 97.4614 119.097 98.2231C119.859 98.604 120.622 98.604 121.384 98.9848C124.434 99.7466 127.102 100.127 130.152 100.508C131.296 98.9848 132.439 98.9848 133.583 99.3657Z"
        fill="url(#paint0_linear_1711_45594)"
      />
    </g>
    <g filter="url(#filter4_i_1711_45594)">
      <path
        d="M157.601 118.028C157.601 119.933 154.17 121.075 151.501 121.456L148.07 121.837C133.966 124.503 119.098 124.503 104.612 124.503C75.6405 124.503 64.2042 125.265 45.5248 123.741C44 123.741 44 121.837 44 120.694C44 117.647 44 114.981 44.3812 112.315C54.6739 113.077 98.1319 115.362 131.297 111.934C140.446 110.792 148.833 109.649 155.313 107.745C155.695 108.507 156.076 109.268 156.457 110.03C157.22 112.315 157.601 114.981 157.601 118.028Z"
        fill="url(#paint1_linear_1711_45594)"
      />
    </g>
    <g filter="url(#filter5_i_1711_45594)">
      <path
        d="M156.458 109.65C149.596 111.936 142.734 113.459 135.491 113.84C134.348 113.84 133.204 113.84 132.442 113.459C131.298 113.078 131.298 112.317 130.917 111.555C130.917 111.174 130.917 110.793 130.917 110.412C130.536 106.603 130.155 102.414 130.155 98.6052C135.11 98.986 140.447 99.3669 146.928 101.652C150.74 102.795 153.408 104.699 154.933 107.365C155.696 108.127 156.077 108.889 156.458 109.65Z"
        fill="#F0EDFA"
      />
    </g>
    <path
      d="M86.3162 68.5158C86.3162 68.8967 86.3162 68.8967 86.3162 68.5158L81.3604 72.3245C80.9792 72.3245 80.9792 72.7054 80.598 72.7054C80.2168 72.7054 79.8356 72.7054 79.8356 72.3245C79.4544 71.9437 79.4544 71.1819 80.2168 70.801L85.1726 66.9923H85.5538C85.5538 67.3732 85.935 68.1349 86.3162 68.5158Z"
      fill="#F0EDFA"
    />
    <path
      d="M92.0315 74.9885C92.0315 75.3694 92.0315 75.3694 91.6503 75.7503C90.1254 77.2738 88.6006 78.7973 86.6946 80.3208C86.3133 80.3208 86.3133 80.7016 85.9321 80.7016C85.5509 80.7016 85.1697 80.7016 85.1697 80.3208C84.7885 79.9399 84.7885 79.1781 85.5509 78.7973C87.0758 77.6547 88.6006 76.1312 90.1255 74.6077C90.5067 74.2268 90.5067 74.2268 90.8879 74.2268C90.8879 74.2268 91.6503 74.6077 92.0315 74.9885Z"
      fill="#F0EDFA"
    />
    <path
      d="M96.9886 81.0832C96.9886 81.4641 96.9886 81.4641 96.6074 81.845C95.0826 83.3685 93.1765 85.2728 91.6517 86.7963C91.2705 87.1772 91.2705 87.1772 90.8893 87.1772C90.5081 87.1772 90.1269 87.1772 90.1269 86.7963C89.7456 86.4154 89.7456 85.6537 90.1269 85.2728C91.6517 83.7493 93.5577 81.845 95.0826 80.3215C95.4638 79.9406 95.4638 79.9406 95.845 79.9406C96.2262 79.9406 96.6074 80.7024 96.9886 81.0832Z"
      fill="#F0EDFA"
    />
    <path
      d="M102.704 86.417C102.704 86.7979 102.704 86.7979 102.323 87.1788C100.417 88.7023 98.5112 90.6066 97.3675 92.511C96.9863 92.8919 96.9863 92.8919 96.6051 92.8919C96.2239 92.8919 96.2239 92.8919 95.8427 92.511C95.4615 92.1301 95.0803 91.3684 95.4615 90.9875C96.9863 89.0832 98.8924 87.1788 100.798 85.2744C100.798 85.2744 101.18 85.2744 101.18 84.8936C101.942 85.6553 102.323 86.0362 102.704 86.417Z"
      fill="#F0EDFA"
    />
    <path
      d="M108.423 90.9875C108.423 90.9875 108.423 91.3684 108.423 90.9875C107.279 93.2728 106.135 95.558 104.61 97.8432C104.229 98.2241 103.848 98.605 103.467 98.605H103.086C102.704 98.2241 102.323 97.4623 102.704 97.0815C103.848 94.7962 104.992 92.511 106.135 90.6066L106.517 90.2258C107.279 90.2258 107.66 90.6066 108.423 90.9875Z"
      fill="#F0EDFA"
    />
    <path
      d="M113.379 94.4148L112.236 101.27C112.236 102.032 111.473 102.413 111.092 102.413C110.33 102.413 109.948 101.651 109.948 100.89L111.092 93.2722C111.092 92.8913 111.092 92.8913 111.473 92.8913C112.236 93.653 112.617 94.0339 113.379 94.4148Z"
      fill="#F0EDFA"
    />
    <path
      d="M121.383 97.4615C121.002 99.3659 120.621 101.651 120.239 103.555C120.239 103.936 119.477 104.317 119.096 104.317C119.096 104.317 119.096 104.317 118.715 104.317C117.952 104.317 117.571 103.555 117.952 102.794C118.333 100.889 118.715 98.6041 119.096 96.6998C119.096 96.6998 119.096 96.6998 119.096 96.3189C119.858 97.0807 120.621 97.4615 121.383 97.4615Z"
      fill="#F0EDFA"
    />
    <g filter="url(#filter6_i_1711_45594)">
      <path
        d="M64.5858 91.3681C69.8492 91.3681 74.1161 87.1051 74.1161 81.8463C74.1161 76.5876 69.8492 72.3245 64.5858 72.3245C59.3224 72.3245 55.0555 76.5876 55.0555 81.8463C55.0555 87.1051 59.3224 91.3681 64.5858 91.3681Z"
        fill="white"
      />
    </g>
    <g filter="url(#filter7_i_1711_45594)">
      <path
        d="M64.5835 85.6541C66.6888 85.6541 68.3956 83.9488 68.3956 81.8453C68.3956 79.7418 66.6888 78.0366 64.5835 78.0366C62.4781 78.0366 60.7714 79.7418 60.7714 81.8453C60.7714 83.9488 62.4781 85.6541 64.5835 85.6541Z"
        fill="#F0EDFA"
      />
    </g>
    <defs>
      <filter
        id="filter0_i_1711_45594"
        x="26"
        y="23.7864"
        width="144.173"
        height="146.767"
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feBlend
          mode="normal"
          in="SourceGraphic"
          in2="BackgroundImageFix"
          result="shape"
        />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dy="-4.21361" />
        <feGaussianBlur stdDeviation="7.49086" />
        <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
        <feColorMatrix
          type="matrix"
          values="0 0 0 0 0.262745 0 0 0 0 0.105882 0 0 0 0 0.737255 0 0 0 0.2 0"
        />
        <feBlend
          mode="normal"
          in2="shape"
          result="effect1_innerShadow_1711_45594"
        />
      </filter>
      <filter
        id="filter1_i_1711_45594"
        x="144"
        y="139"
        width="45"
        height="45"
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feBlend
          mode="normal"
          in="SourceGraphic"
          in2="BackgroundImageFix"
          result="shape"
        />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dx="8" dy="-7" />
        <feGaussianBlur stdDeviation="2.5" />
        <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
        <feColorMatrix
          type="matrix"
          values="0 0 0 0 0.596078 0 0 0 0 0.121569 0 0 0 0 0.0862745 0 0 0 0.28 0"
        />
        <feBlend
          mode="normal"
          in2="shape"
          result="effect1_innerShadow_1711_45594"
        />
      </filter>
      <filter
        id="filter2_i_1711_45594"
        x="44.0002"
        y="51"
        width="122.601"
        height="73.5981"
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feBlend
          mode="normal"
          in="SourceGraphic"
          in2="BackgroundImageFix"
          result="shape"
        />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dx="16" dy="-20" />
        <feGaussianBlur stdDeviation="4.5" />
        <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
        <feColorMatrix
          type="matrix"
          values="0 0 0 0 0.262745 0 0 0 0 0.105882 0 0 0 0 0.737255 0 0 0 0.3 0"
        />
        <feBlend
          mode="normal"
          in2="shape"
          result="effect1_innerShadow_1711_45594"
        />
      </filter>
      <filter
        id="filter3_i_1711_45594"
        x="69.9208"
        y="56.7621"
        width="66.6621"
        height="52.5063"
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feBlend
          mode="normal"
          in="SourceGraphic"
          in2="BackgroundImageFix"
          result="shape"
        />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dx="3" dy="-5" />
        <feGaussianBlur stdDeviation="2" />
        <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
        <feColorMatrix
          type="matrix"
          values="0 0 0 0 0.262745 0 0 0 0 0.105882 0 0 0 0 0.737255 0 0 0 1 0"
        />
        <feBlend
          mode="normal"
          in2="shape"
          result="effect1_innerShadow_1711_45594"
        />
      </filter>
      <filter
        id="filter4_i_1711_45594"
        x="44"
        y="107.745"
        width="116.601"
        height="19.9488"
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feBlend
          mode="normal"
          in="SourceGraphic"
          in2="BackgroundImageFix"
          result="shape"
        />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dx="3" dy="3" />
        <feGaussianBlur stdDeviation="3" />
        <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
        <feColorMatrix
          type="matrix"
          values="0 0 0 0 0.262745 0 0 0 0 0.105882 0 0 0 0 0.737255 0 0 0 1 0"
        />
        <feBlend
          mode="normal"
          in2="shape"
          result="effect1_innerShadow_1711_45594"
        />
      </filter>
      <filter
        id="filter5_i_1711_45594"
        x="128.155"
        y="98.6052"
        width="28.3035"
        height="20.2349"
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feBlend
          mode="normal"
          in="SourceGraphic"
          in2="BackgroundImageFix"
          result="shape"
        />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dx="-2" dy="5" />
        <feGaussianBlur stdDeviation="3" />
        <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
        <feColorMatrix
          type="matrix"
          values="0 0 0 0 0.262745 0 0 0 0 0.105882 0 0 0 0 0.737255 0 0 0 0.28 0"
        />
        <feBlend
          mode="normal"
          in2="shape"
          result="effect1_innerShadow_1711_45594"
        />
      </filter>
      <filter
        id="filter6_i_1711_45594"
        x="55.0555"
        y="72.3245"
        width="21.0605"
        height="21.0436"
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feBlend
          mode="normal"
          in="SourceGraphic"
          in2="BackgroundImageFix"
          result="shape"
        />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dx="2" dy="2" />
        <feGaussianBlur stdDeviation="1.5" />
        <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
        <feColorMatrix
          type="matrix"
          values="0 0 0 0 0.262745 0 0 0 0 0.105882 0 0 0 0 0.737255 0 0 0 0.25 0"
        />
        <feBlend
          mode="normal"
          in2="shape"
          result="effect1_innerShadow_1711_45594"
        />
      </filter>
      <filter
        id="filter7_i_1711_45594"
        x="58.7714"
        y="78.0366"
        width="9.62427"
        height="12.6174"
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feBlend
          mode="normal"
          in="SourceGraphic"
          in2="BackgroundImageFix"
          result="shape"
        />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dx="-2" dy="5" />
        <feGaussianBlur stdDeviation="3" />
        <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
        <feColorMatrix
          type="matrix"
          values="0 0 0 0 0.262745 0 0 0 0 0.105882 0 0 0 0 0.737255 0 0 0 0.28 0"
        />
        <feBlend
          mode="normal"
          in2="shape"
          result="effect1_innerShadow_1711_45594"
        />
      </filter>
      <linearGradient
        id="paint0_linear_1711_45594"
        x1="101.752"
        y1="60.7621"
        x2="120.966"
        y2="113.39"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#413BBC" />
        <stop offset="1" stopColor="#A9A6E2" />
      </linearGradient>
      <linearGradient
        id="paint1_linear_1711_45594"
        x1="100.8"
        y1="107.745"
        x2="102.283"
        y2="128.479"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#413BBC" />
        <stop offset="1" stopColor="#A9A6E2" />
      </linearGradient>
    </defs>
  </svg>
);

export const CircleIcon = ({ color }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
  >
    <rect width="16" height="16" rx="8" fill={color} />
  </svg>
);

export const ActiveCircle = (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
  >
    <rect width="16" height="16" rx="8" fill="#269E6C" />
  </svg>
);

export const InprogressCircle = (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
  >
    <rect width="16" height="16" rx="8" fill="#FF8277" />
  </svg>
);

export const NotUploadedCircle = (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
  >
    <rect width="16" height="16" rx="8" fill="#FEC84B" />
  </svg>
);

export const InactiveCircle = (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
  >
    <rect width="16" height="16" rx="8" fill="#E0E1E3" />
  </svg>
);