import { Card, Skeleton, FlexLayout, FlexChild } from "@cedcommerce-integration/ounce-ui";

const PaymentSettingsSkeleton = () => {
  return (
    <Card>
      {/* ORDER SETTINGS */}
      <FlexLayout direction="vertical" spacing="mediumLoose" wrap="noWrap">
        <Card cardType="Bordered">
          <FlexLayout direction="vertical" spacing="extraLoose">
            {/* sync tracking details */}
            <FlexLayout halign="fill">
              <FlexLayout spacing="tight" direction="vertical" wrap="noWrap">
                <Skeleton height="14px" width="100px" type="custom" />
                <FlexLayout
                  spacing="mediumTight"
                  direction="vertical"
                  wrap="noWrap"
                >
                  <Skeleton height="14px" width="200px" type="custom" />
                  <Skeleton height="14px" width="100px" type="custom" />
                </FlexLayout>
              </FlexLayout>
              <Skeleton height="40px" width="65px" type="custom" />
            </FlexLayout>

            {/* Shopline Carrier Amazon Carrier */}
            <FlexLayout direction="vertical" spacing="loose" wrap="noWrap">
              <FlexLayout valign="center" halign="fill" spacing="loose">
                <FlexChild childWidth="fullWidth">
                  <Skeleton height="44px" width="150px" type="line" />
                </FlexChild>
                <Skeleton height="44px" width="44px" type="custom" />
              </FlexLayout>
            </FlexLayout>
          </FlexLayout>
        </Card>
      </FlexLayout>
    </Card>
  );
};

export default PaymentSettingsSkeleton;
