import { Card, FlexLayout, TextStyles } from "@cedcommerce-integration/ounce-ui";
import React from "react";
import ebaylogo from "../../../../../assets/icons/ebay.svg";
import shopline from "../../../../../assets/icons/shopline.svg";

const OrderInfo = ({ attributes, shoplineOrderId }) => {
  return (
    <Card title="Order ID">
      <FlexLayout direction="vertical" wrap="noWrap" spacing="loose">
        <FlexLayout wrap="noWrap" spacing="loose" valign="start">
          <img src={ebaylogo} alt="eBay" />
          <FlexLayout
            desktopWidth="100"
            tabWidth="100"
            mobileWidth="100"
            direction="vertical"
            spacing="extraTight"
          >
            <TextStyles
              fontweight="bold"
              lineHeight="LH-2.0"
              paragraphTypes="MD-1.4"
              textcolor="dark"
              type="Paragraph"
            >
              {attributes?.key}
            </TextStyles>

            <TextStyles lineHeight="LH-2.0" textcolor="light">
              {attributes?.value}
            </TextStyles>
          </FlexLayout>
        </FlexLayout>
        {shoplineOrderId && (
          <FlexLayout wrap="noWrap" spacing="loose" valign="start">
            <img src={shopline} alt="Shopline" />
            <FlexLayout
              desktopWidth="100"
              tabWidth="100"
              mobileWidth="100"
              direction="vertical"
              spacing="extraTight"
            >
              <TextStyles
                fontweight="bold"
                lineHeight="LH-2.0"
                paragraphTypes="MD-1.4"
                textcolor="dark"
                type="Paragraph"
              >
                Shopline Order ID
              </TextStyles>

              <TextStyles lineHeight="LH-2.0" textcolor="light">
                {shoplineOrderId}
              </TextStyles>
            </FlexLayout>
          </FlexLayout>
        )}
      </FlexLayout>
    </Card>
  );
};

export default OrderInfo;
