/* eslint-disable react-hooks/exhaustive-deps */
import {
  Badge,
  Card,
  FlexChild,
  FlexLayout,
  Grid,
  PageHeader,
  TextStyles,
} from "@cedcommerce-integration/ounce-ui";
import React, { useEffect, useState } from "react";
import OrderViewSkeleton from "../Skeleton/OrderViewSkeleton";
import CustomerInfo from "./components/CustomerInfo";
import OrderInfo from "./components/OrderInfo";
import ShippingAddress from "./components/ShippingAddress";
import { withRouter } from "react-router-dom";
import queryString from "query-string";
import { getSingleOrderAPI } from "../../../../APIs/PanelAPI";
import { getMethod } from "../../../../apiMethods/methods";
import { globalState } from "../../../../services/globalState";
import NoProductImage from "../../../../assets/images/notfound.png";

const OrderView = (props) => {
  const ebayShopId = globalState.getLocalStorage("ebayShopId");

  const [pageSkeleton, setpageSkeleton] = useState(true);
  const [id, setId] = useState("");
  const [orderData, setOrderData] = useState([]);
  const [customerInformation, setCutomerInformation] = useState({});
  const [attributes, setAttributes] = useState({});
  const [shoplineOrderId, setShoplineOrderId] = useState("");
  const [shippingAddress, setShippingAddress] = useState({});
  const [status, setStatus] = useState("");
  const [orderName, setOrderName] = useState("");
  // eslint-disable-next-line no-unused-vars
  const [badges, setBadges] = useState({
    Shipped: { text: "Fulfilled", color: "#269E6C", txtColor: "light" },
    Created: { text: "Unfulfilled", color: "#F6CECB", txtColor: "dark" },
    failed: { text: "Failed", color: "#ff8277", txtColor: "light" },
  });

  const Columns = [
    {
      dataIndex: "image",
      key: "image",
      title: "Image",
      width: 100,
      visible: true,
    },
    {
      dataIndex: "name",
      key: "name",
      title: "Name",
      width: 250,
      visible: true,
    },
    {
      dataIndex: "quantity",
      key: "quantity",
      title: "Quantity",
      width: 130,
      fixed: "right",
      visible: true,
    },
    {
      dataIndex: "price_unit",
      key: "price_unit",
      title: "Price/Unit",
      width: 130,
      fixed: "right",
      visible: true,
    },
  ];

  const getSingleOrderFunc = async () => {
    const time1 = new Date();
    let ms1 = time1.getTime();
    setpageSkeleton(true);
    let payload = {
      cif_order_id: id,
      marketplace_shop_id: ebayShopId,
      marketplace: "ebay",
    };
    let { success, data, code } = await getMethod(getSingleOrderAPI, payload);
    const time2 = new Date();
    let ms2 = time2.getTime();
    let timeOut = ms2 - ms1;
    if (success) {
      let {
        items,
        shipping_address,
        shipping_charge,
        customer,
        sub_total,
        targets,
        taxes,
        total,
        attributes,
        marketplace_reference_id,
      } = data;
      setOrderName(marketplace_reference_id);
      setStatus(targets[0]?.status);
      setCutomerInformation(customer);
      setAttributes(attributes[0]);
      setShoplineOrderId(targets?.[0]?.order_id);
      shipping_address?.[0] && setShippingAddress(shipping_address?.[0]);
      let tempOrderData = [];
      tempOrderData = items.map((orderData, index) => {
        let tempObj = {};
        tempObj["key"] = index;
        tempObj["image"] = orderData?.image ? (
          <img
            src={orderData.image}
            alt="title"
            width={48}
            height={48}
            style={{ borderRadius: "4px" }}
          />
        ) : (
          <img
            src={NoProductImage}
            alt="title"
            width={48}
            height={48}
            style={{ borderRadius: "4px" }}
          />
        );
        tempObj["name"] = (
          <FlexLayout direction="vertical" spacing="mediumTight" wrap="noWrap">
            <TextStyles
              fontweight="bold"
              paragraphTypes="MD-1.4"
              textcolor="dark"
              type="Paragraph"
            >
              {orderData?.title}
            </TextStyles>
            <FlexLayout spacing="extraTight" wrap="noWrap">
              <TextStyles
                fontweight="bold"
                paragraphTypes="MD-1.4"
                textcolor="dark"
                type="Paragraph"
              >
                SKU:
              </TextStyles>
              <TextStyles
                paragraphTypes="MD-1.4"
                textcolor="light"
                type="Paragraph"
              >
                {orderData?.sku}
              </TextStyles>
            </FlexLayout>
          </FlexLayout>
        );
        tempObj["quantity"] = (
          <TextStyles
            paragraphTypes="MD-1.4"
            textcolor="light"
            type="Paragraph"
          >
            {`${orderData?.qty} unit`}
          </TextStyles>
        );
        tempObj["price_unit"] = (
          <TextStyles
            paragraphTypes="MD-1.4"
            textcolor="light"
            type="Paragraph"
          >
            {`$${orderData?.price}`}
          </TextStyles>
        );
        return tempObj;
      });
      tempOrderData.push({
        key: tempOrderData.lenth - 1,
        quantity: (
          <FlexLayout direction="vertical" spacing="mediumTight" wrap="noWrap">
            {sub_total?.price ? (
              <TextStyles
                fontweight="extraBold"
                paragraphTypes="MD-1.4"
                textcolor="dark"
                type="Paragraph"
              >
                Subtotal Price
              </TextStyles>
            ) : null}
            {shipping_charge?.price ? (
              <TextStyles
                fontweight="extraBold"
                paragraphTypes="MD-1.4"
                textcolor="dark"
                type="Paragraph"
              >
                Shipping Charge
              </TextStyles>
            ) : null}
            {taxes?.tax ? (
              <TextStyles
                fontweight="extraBold"
                paragraphTypes="MD-1.4"
                textcolor="dark"
                type="Paragraph"
              >
                Estimated Tax
              </TextStyles>
            ) : null}
          </FlexLayout>
        ),
        price_unit: (
          <FlexLayout direction="vertical" spacing="mediumTight" wrap="noWrap">
            {sub_total?.price ? (
              <TextStyles
                paragraphTypes="MD-1.4"
                textcolor="light"
                type="Paragraph"
                utility="charges"
              >
                 {`$${sub_total?.price}`}
              </TextStyles>
            ) : null}
            {shipping_charge?.price ? (
              <TextStyles
                paragraphTypes="MD-1.4"
                textcolor="light"
                type="Paragraph"
                utility="charges"
              >
                {`$${shipping_charge?.price}`}
              </TextStyles>
            ) : null}
            {taxes?.tax ? (
              <TextStyles
                paragraphTypes="MD-1.4"
                textcolor="light"
                type="Paragraph"
              >
                ${}
              </TextStyles>
            ) : null}
          </FlexLayout>
        ),
      });
      tempOrderData.push({
        key: tempOrderData.lenth - 1,
        quantity: (
          <TextStyles
            fontweight="extraBold"
            paragraphTypes="MD-1.4"
            textcolor="dark"
            type="Paragraph"
          >
            Total
          </TextStyles>
        ),
        price_unit: (
          <TextStyles
            paragraphTypes="MD-1.4"
            textcolor="light"
            type="Paragraph"
          >
            {`$${total?.price}`}
          </TextStyles>
        ),
      });
      setOrderData(tempOrderData);
    } else if (
        code === "token_expired" ||
        code === "invalid_token" ||
        code === "future_token" ||
        code === "token_decode_error"
      ) {
        props.history.push("/tokenexpired");
      }
    if (timeOut < 500) {
      setTimeout(() => {
        setpageSkeleton(false);
      }, 500 - timeOut);
    } else setpageSkeleton(false);
  };

  const getParams = () => {
    let { id } = queryString.parse(props.location.search);
    if (id) {
      setId(id);
    }
  };

  useEffect(() => {
    getParams();
  }, []);

  useEffect(() => {
    if (id) {
      getSingleOrderFunc();
    }
  }, [id]);

  return (
    <>
      {pageSkeleton ? (
        <OrderViewSkeleton />
      ) : (
        <>
          <PageHeader
            metaData={
              <Badge
                badgeTextColor={badges[status]?.txtColor}
                customBgColor={badges[status]?.color}
              >
                {badges[status]?.text}
              </Badge>
            }
            reverseNavigation
            title={`Order #${orderName}`}
            onClick={() => props.history.push("/panel/order-listing")}
          />
          <FlexLayout spacing="loose" valign="start">
            {/* Order Details */}
            <FlexChild desktopWidth="66" tabWidth="100" mobileWidth="100">
              <Card title={"Order Details"} extraClass="order-details">
                <Grid
                  columns={Columns}
                  dataSource={orderData}
                  tableLayout="fixed"
                  scrollX={750}
                />
              </Card>
            </FlexChild>
            {/* Right Cards Info */}
            <FlexChild desktopWidth="33" tabWidth="100" mobileWidth="100">
              <FlexLayout
                spacing="loose"
                desktopWidth="100"
                tabWidth="50"
                mobileWidth="100"
              >
                <CustomerInfo customer={customerInformation} />
                {/* <ShippingMethod /> */}
                <OrderInfo
                  attributes={attributes}
                  shoplineOrderId={shoplineOrderId}
                />
                {Object.keys(shippingAddress).length > 0 && (
                  <ShippingAddress shippingAddress={shippingAddress} />
                )}
              </FlexLayout>
            </FlexChild>
          </FlexLayout>
        </>
      )}
    </>
  );
};

export default withRouter(OrderView);
