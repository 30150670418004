import React, { useEffect } from "react";
import "./App.css";
import { Redirect, Route, Switch, withRouter } from "react-router-dom";
import Auth from "./Components/Auth/auth";
import Onboarding from "./Components/Ebay/Onboarding/Onboarding";
import RedirectMessage from "./Components/ShowMessage/RedirectMessage";
import Panel from "./Panel";
import Completed from "./Components/ShowMessage/Completed";
import queryString from "query-string";
import { globalState } from "./services/globalState";
import { environment } from "./environment/environment";
import Client, {
  shared,
  Redirect as ShoplineRedirect,
} from "@shoplinedev/appbridge";
import { ShowLogOutScreen } from "./Components/Auth/login/LogoutScreen";
import Payment from "./Components/Ebay/Onboarding/Payment";
import { mountClarityScript, windowClarity } from './clarityScript';

export function parseJwt(token) {
  const base64Url = token.split(".")[1];
  const base64 = base64Url.replace(/-/g, "+").replace(/_/g, "/");
  const jsonPayload = decodeURIComponent(
    atob(base64)
      .split("")
      .map(function (c) {
        return "%" + ("00" + c.charCodeAt(0).toString(16)).slice(-2);
      })
      .join("")
  );

  return JSON.parse(jsonPayload);
}

const App = (props) => {
  let Search = queryString.parse(props.location.search);
  if (Search?.handle) {
    globalState.setLocalStorage("handle", Search.handle);
  }

  useEffect(() => {
    authorization();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const authorization = async () => {
    if (Search?.user_token || Search?.admin_user_token) {
      const tmp = Search?.user_token || Search?.admin_user_token;
      autoRedirect(tmp);
    } else {
      fetch(`${environment.API_ENDPOINT}shoplinehome/onboard/userLogin`, {
        method: "POST",
        headers: {
          Accept: "application/json, text/plain, */*",
          "Content-Type": "application/json",
        },
        body: JSON.stringify(Search),
      })
        .then((res) => res.json())
        .then((res) => {
          if (res.success) {
            autoRedirect(res.data.token);
          } else {
            const Handle = globalState.getLocalStorage("handle");
            if (res?.data?.url) {
              const app = Client.createApp({
                appKey: Search.appkey,
                host: shared.getHost(),
              });
              const redirect = ShoplineRedirect.create(app);
              return redirect.replaceTo(res.data.url);
            } else if (Handle) {
              const payload = { handle: Handle };
              fetch(
                `${environment.API_ENDPOINT}shoplinehome/onboard/userLogin`,
                {
                  method: "POST",
                  headers: {
                    Accept: "application/json, text/plain, */*",
                    "Content-Type": "application/json",
                  },
                  body: JSON.stringify(payload),
                }
              )
                .then((res) => res.json())
                .then((res) => {
                  if (res.success) {
                    autoRedirect(res.data.token);
                  }
                });
            }
          }
        });
    }
  };

  const autoRedirect = (token) => {
    const tokenDecoded = parseJwt(token);
    globalState.setLocalStorage(`user_authenticated`, `true`);
    globalState.setLocalStorage(`auth_token`, token);
    globalState.removeLocalStorage(
      `${tokenDecoded["user_id"]}_showInstagramWarning`
    );
    globalState.removeLocalStorage(
      `${tokenDecoded["user_id"]}_showPaymentWarning`
    );
    props.history.push(`/auth`);
  };
  const roleType = sessionStorage.getItem("role_type");
  useEffect(() => {
    setInterval(() => { 
      window.location.href.includes("admin_user_token") &&
      sessionStorage.setItem("role_type", "admin");
    if (roleType !== "admin" && process.env.REACT_APP_ENV === "production") {
        mountClarityScript(window, document, "clarity", "script", process.env.REACT_APP_CLARITY_ID);
    }
    }, 10000);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []); 
  
  useEffect(() => {
    const location = props?.location?.pathname?.split('/');
    windowClarity(location?.[location?.length - 1]);
  }, [props.location]);
  
  return (
    <Switch>
      <Route
        exact
        path={"/"}
        render={() => {
          return <Redirect to={"/auth"} />;
        }}
      />
      <Route path={"/auth"} component={Auth} />
      <Route path={"/show/message"} component={RedirectMessage} />
      <Route path={"/show/completed"} component={Completed} />
      <Route path={"/onboarding"} component={Onboarding} />
      <Route path={"/payment"} component={Payment} />
      <Route path={"/panel"} component={Panel} />
      <Route path={"/tokenexpired"} component={ShowLogOutScreen} />
    </Switch>
  );
};

export default withRouter(App);
