import {
  Card,
  FlexChild,
  FlexLayout,
  Grid,
  PageHeader,
  Skeleton,
} from "@cedcommerce-integration/ounce-ui";
import React from "react";

const OrderViewSkeleton = () => {
  const dataSoureces = [
    {
      image: <Skeleton height="48px" line={1} type="custom" width="48px" />,
      name: (
        <div className="order_name">
          <FlexLayout direction="vertical" spacing="tight">
            <Skeleton height="20px" line={1} type="custom" width="90%" />
            <Skeleton height="20px" line={1} type="custom" width="70%" />
          </FlexLayout>
        </div>
      ),
      quantity: <Skeleton height="20px" line={1} type="custom" width="80%" />,
      price_unit: <Skeleton height="20px" line={1} type="custom" width="80%" />,
    },
    {
      image: <Skeleton height="48px" line={1} type="custom" width="48px" />,
      name: (
        <div className="order_name">
          <FlexLayout direction="vertical" spacing="tight">
            <Skeleton height="20px" line={1} type="custom" width="90%" />
            <Skeleton height="20px" line={1} type="custom" width="70%" />
          </FlexLayout>
        </div>
      ),
      quantity: <Skeleton height="20px" line={1} type="custom" width="80%" />,
      price_unit: <Skeleton height="20px" line={1} type="custom" width="80%" />,
    },
    {
      image: <Skeleton height="48px" line={1} type="custom" width="48px" />,
      name: (
        <div className="order_name">
          <FlexLayout direction="vertical" spacing="tight">
            <Skeleton height="20px" line={1} type="custom" width="90%" />
            <Skeleton height="20px" line={1} type="custom" width="70%" />
          </FlexLayout>
        </div>
      ),
      quantity: <Skeleton height="20px" line={1} type="custom" width="80%" />,
      price_unit: <Skeleton height="20px" line={1} type="custom" width="80%" />,
    },
    {
      quantity: (
        <FlexLayout direction="vertical" spacing="tight">
          <Skeleton height="20px" line={1} type="custom" width="80%" />
          <Skeleton height="20px" line={1} type="custom" width="80%" />
        </FlexLayout>
      ),
      price_unit: (
        <FlexLayout direction="vertical" spacing="tight">
          <Skeleton height="20px" line={1} type="custom" width="40%" />
          <Skeleton height="20px" line={1} type="custom" width="30%" />
        </FlexLayout>
      ),
    },
    {
      quantity: <Skeleton height="20px" line={1} type="custom" width="60%" />,
      price_unit: <Skeleton height="20px" line={1} type="custom" width="40%" />,
    },
  ];
  const columns = [
    {
      dataIndex: "image",
      key: "image",
      title: <Skeleton height="20px" line={1} type="custom" width="70%" />,
      width: 100,
    },
    {
      dataIndex: "name",
      key: "name",
      title: <Skeleton height="20px" line={1} type="custom" width="70%" />,
      width: 250,
    },
    {
      dataIndex: "quantity",
      key: "quantity",
      title: <Skeleton height="20px" line={1} type="custom" width="70%" />,
      width: 130,
    },
    {
      dataIndex: "price_unit",
      key: "price_unit",
      title: <Skeleton height="20px" line={1} type="custom" width="70%" />,
      width: 130,
    },
  ];

  return (
    <>
      <PageHeader
        title={<Skeleton height="20px" width="200px" type="custom" />}
      />
      <FlexLayout spacing="loose" valign="start">
        <FlexChild desktopWidth="66" tabWidth="100" mobileWidth="100">
          <Card
            title={
              <Skeleton height="16px" line={1} type="custom" width="150px" />
            }
          >
            <Grid
              tableLayout="auto"
              columns={columns}
              dataSource={dataSoureces}
            />
          </Card>
        </FlexChild>
        <FlexChild desktopWidth="33" tabWidth="100" mobileWidth="100">
          <FlexLayout spacing="extraLoose" direction="vertical">
            <Card
              title={
                <Skeleton height="16px" line={1} type="custom" width="150px" />
              }
            >
              <FlexLayout spacing="extraLoose" direction="vertical">
                <FlexLayout spacing="loose">
                  <Skeleton
                    height="40px"
                    line={1}
                    type="custom"
                    width="40px"
                    rounded
                  />
                  <FlexLayout direction="vertical" spacing="tight">
                    <Skeleton
                      height="15px"
                      line={1}
                      type="custom"
                      width="60px"
                    />
                    <Skeleton
                      height="14px"
                      line={1}
                      type="custom"
                      width="150px"
                    />
                  </FlexLayout>
                </FlexLayout>
                <div className="border-hr">
                  <FlexLayout spacing="loose" wrap="noWrap">
                    <Skeleton
                      height="16px"
                      line={1}
                      type="custom"
                      width="20px"
                    />
                    <FlexChild
                      desktopWidth="100"
                      tabWidth="100"
                      mobileWidth="100"
                    >
                      <Skeleton
                        height="16px"
                        line={1}
                        type="custom"
                        width="100%"
                      />
                    </FlexChild>
                  </FlexLayout>
                </div>
                <FlexLayout spacing="loose" wrap="noWrap">
                  <Skeleton height="16px" line={1} type="custom" width="20px" />
                  <FlexChild
                    desktopWidth="100"
                    tabWidth="100"
                    mobileWidth="100"
                  >
                    <Skeleton
                      height="16px"
                      line={1}
                      type="custom"
                      width="100%"
                    />
                  </FlexChild>
                </FlexLayout>
              </FlexLayout>
            </Card>
            <Card
              title={
                <Skeleton height="16px" line={1} type="custom" width="150px" />
              }
            >
              <FlexLayout wrap="noWrap" spacing="loose" valign="start">
                <Skeleton height="40px" line={1} type="custom" width="40px" />
                <FlexChild desktopWidth="100" tabWidth="100" mobileWidth="100">
                  <FlexLayout
                    spacing="tight"
                    direction="vertical"
                    wrap="noWrap"
                  >
                    <Skeleton
                      height="15px"
                      line={1}
                      type="custom"
                      width="60px"
                    />
                    <FlexLayout
                      halign="fill"
                      valign="center"
                      spacing="loose"
                      wrap="noWrap"
                    >
                      <Skeleton
                        height="14px"
                        line={1}
                        type="custom"
                        width="150px"
                      />
                      <Skeleton
                        height="14px"
                        line={1}
                        type="custom"
                        width="80px"
                      />
                    </FlexLayout>
                  </FlexLayout>
                </FlexChild>
              </FlexLayout>
            </Card>
            <Card
              title={
                <Skeleton height="16px" line={1} type="custom" width="150px" />
              }
            >
              <FlexLayout wrap="noWrap" spacing="loose" valign="start">
                <Skeleton height="40px" line={1} type="custom" width="40px" />
                <FlexChild desktopWidth="100" tabWidth="100" mobileWidth="100">
                  <FlexLayout
                    spacing="tight"
                    direction="vertical"
                    wrap="noWrap"
                  >
                    <Skeleton
                      height="15px"
                      line={1}
                      type="custom"
                      width="60px"
                    />
                    <FlexLayout
                      halign="fill"
                      valign="center"
                      spacing="loose"
                      wrap="noWrap"
                    >
                      <Skeleton
                        height="14px"
                        line={1}
                        type="custom"
                        width="150px"
                      />
                      <Skeleton
                        height="14px"
                        line={1}
                        type="custom"
                        width="80px"
                      />
                    </FlexLayout>
                  </FlexLayout>
                </FlexChild>
              </FlexLayout>
            </Card>
            <Card
              title={
                <Skeleton height="16px" line={1} type="custom" width="150px" />
              }
            >
              <Skeleton height="18px" line={2} type="line" width="100%" />
            </Card>
          </FlexLayout>
        </FlexChild>
      </FlexLayout>
    </>
  );
};

export default OrderViewSkeleton;
