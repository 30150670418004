/* eslint-disable react-hooks/exhaustive-deps */
import {
  Alert,
  Button,
  Card,
  FlexChild,
  FlexLayout,
  Select,
  Skeleton,
  TextStyles,
  Toast,
  ToastWrapper,
  ToolTip,
} from "@cedcommerce-integration/ounce-ui";
import React, { useEffect, useState } from "react";
import { RefreshCcw } from "react-feather";
import { withRouter } from "react-router-dom";
import { getMethod, postMethod } from "../../../apiMethods/methods";
import {
  getPaymentChannelsAPI,
  getPaymentSettingsStatus,
  getSavedPaymentChannelsAPI,
  savePaymentChannelsAPI,
  skipPaymentChannels,
} from "../../../APIs/loginAPI";
import { globalState } from "../../../services/globalState";
import OnboardingLayout from "../layout/OnboardingLayout";

export const parsePaymentChannelOptions = (paymentMethods) => {
  let preparedOptions = paymentMethods.map((method) => ({
    label: method?.pay_channel_name,
    value: method?.pay_channel_id,
  }));
  preparedOptions.unshift({ label: "Please Select", value: "" });
  return preparedOptions;
};

const subTitleText = () => {
  return (
    <p className="inte__text--light inte__LineHeight--2">
      Please select your preferred Custom Payment method for successful order
      sync between channels. <br />
      <Button
        onClick={() =>
          window.open(
            "https://docs.cedcommerce.com/shopline/cedcommerce-ebay-integration/?section=how-to-create-custom-payment-method",
            "_blank"
          )
        }
        type="TextButton"
      >
        Learn more
      </Button>{" "}
      about custom payment method in Shopline.
    </p>
  );
};

const Payment = (props) => {
  let BodyRender = (
    <div
      className="pt-30 pb-100"
      style={{
        width: "initial",
        margin: "auto",
        maxWidth: "800px",
      }}
    >
      <Card extraClass="mt-20" title="">
        <Skeleton line={3} type="line" />
      </Card>
    </div>
  );
  const [savedPreviousPaymentChannel, setSavedPreviousPaymentChannel] =
    useState("");
  const [savePaymentChannel, setSavePaymentChannel] = useState("");
  const [savePaymentChannelSaveBtnLoader, setSavePaymentChannelSaveBtnLoader] =
    useState(false);
  const [paymentChannelOptions, setPaymentChannelOptions] = useState([]);
  const [noPaymentChannelsFound, setnoPaymentChannelsFound] = useState(false);
  const [paymentChannelSkeletonStatus, setPaymentChannelSkeletonStatus] =
    useState(true);
  const [savedPaymentChannelLoader, setSavedPaymentChannelLoader] =
    useState(true);
  const [refreshOptionsBtnLoader, setRefreshOptionsBtnLoader] = useState(false);
  const [skipPayment, setSkipPayment] = useState(false);

  //toast
  const [successToast, setSuccessToast] = useState({
    active: false,
    message: "",
    success: false,
  });
  const [errorToast, setErrorToast] = useState({
    active: false,
    message: "",
    success: false,
  });

  const savePaymentChannelFunc = async (selectedPaymentChannel) => {
    setSavePaymentChannelSaveBtnLoader(true);
    const payload = {
      source_shop_id: globalState.getLocalStorage("shopLineShopId"),
      target_shop_id: globalState.getLocalStorage("ebayShopId"),
      payment_id: savePaymentChannel,
    };
    let { success, code } = await postMethod(
      savePaymentChannelsAPI,
      payload
    );
    if (success) {
      setSuccessToast({
        active: true,
        message: "Custom payment method updated successfully",
        success: success,
      });
    } else if (code) {
      if (
        code === "token_expired" ||
        code === "invalid_token" ||
        code === "future_token" ||
        code === "token_decode_error"
      ) {
        props.history.push("/tokenexpired");
      }
    } else {
      setErrorToast({
        active: true,
        message: "Payment channel save is not successfull",
        success: success,
      });
    }
    setSavePaymentChannelSaveBtnLoader(false);
  };

  const callFunc = async () => {
    let { success, data } = await getMethod(getSavedPaymentChannelsAPI);
    if (success) {
      if (data?.value?.payment_channel) {
        setSavedPreviousPaymentChannel(data?.value?.payment_channel);
      }
    } else {
      setRefreshOptionsBtnLoader(true);
      let { success, data, code } = await getMethod(getPaymentChannelsAPI);
      if (
        data === null ||
        data?.length === 0
      ) {
        setnoPaymentChannelsFound(true);
      } else if (success) {
          setPaymentChannelOptions(parsePaymentChannelOptions(data));
          setnoPaymentChannelsFound(false);
        } else if (
            code === "token_expired" ||
            code === "invalid_token" ||
            code === "future_token" ||
            code === "token_decode_error"
          ) {
            props.history.push("/tokenexpired");
          }
      setRefreshOptionsBtnLoader(false);
    }
    setSavedPaymentChannelLoader(false);
    setPaymentChannelSkeletonStatus(false);
  };

  const skipPaymentFunc = async () => {
   await getMethod(skipPaymentChannels);
    props.history.push("/panel/dashboard");
  };

  const skipPaymnentMode = async () => {
    let { success } = await getMethod(getPaymentSettingsStatus);
    setSkipPayment(success);
  };

  useEffect(() => {
    callFunc();
    skipPaymnentMode();
  }, []);

  useEffect(() => {
    if (successToast.active) {
      setTimeout(() => {
        props.history.push("/show/completed");
      }, 1000);
    }
  }, [successToast]);

  const toastMarkup = successToast.active ? (
    <Toast
      message={successToast.message}
      onDismiss={() =>
        setSuccessToast({ active: false, message: "", success: false })
      }
      timeout={2000}
      type={successToast.success ? "success" : "error"}
    />
  ) : errorToast.active ? (
    <Toast
      message={errorToast.message}
      onDismiss={() =>
        setErrorToast({ active: false, message: "", success: false })
      }
      timeout={2000}
      type="error"
    />
  ) : null;

  if (!savedPaymentChannelLoader) {
    if (savedPreviousPaymentChannel || skipPayment) {
      props.history.push("/panel/dashboard");
    } else {
      BodyRender = (
        <>
          <OnboardingLayout>
            <Card cardType="Shadowed" extraClass="mt-20">
              <FlexLayout
                direction="vertical"
                spacing="mediumLoose"
                wrap="noWrap"
              >
                <Card
                  cardType="Bordered"
                  title="Payment Method"
                  subTitle={subTitleText()}
                  extraClass="payment-method"
                >
                  <FlexLayout
                    direction="vertical"
                    spacing="loose"
                    wrap="noWrap"
                  >
                    <TextStyles
                      type="Paragraph"
                      paragraphTypes="MD-1.4"
                      fontweight="bold"
                      textcolor="light"
                      utility="text-italic"
                    >
                      Once Onboarded, you can change your Custom Payment Method
                      directly from the configuration tab of the app.
                    </TextStyles>
                    {paymentChannelSkeletonStatus ? (
                      <Skeleton line={1} type="line" />
                    ) : noPaymentChannelsFound ? (
                      <FlexLayout valign="start" halign="fill" spacing="loose">
                        <FlexChild childWidth="fullWidth">
                          {refreshOptionsBtnLoader ? (
                            <Skeleton
                              type="custom"
                              width="100%"
                              height="44px"
                            />
                          ) : (
                            <Alert
                              destroy={false}
                              onClose={function noRefCheck() {}}
                              type="info"
                            >
                              <TextStyles
                                lineHeight="LH-2.0"
                                paragraphTypes="MD-1.4"
                                textcolor="dark"
                                type="Paragraph"
                                utility="none"
                              >
                                Custom Payment Method is currenlty not set for
                                Shopline.
                              </TextStyles>
                            </Alert>
                          )}
                        </FlexChild>
                        {/* <div className="custom-btn-loader"> */}
                        <ToolTip
                          helpText="Click the button to fetch your Custom Payment Method from Shopline."
                          popoverContainer="element"
                          position="top"
                          open={false}
                        >
                          <Button
                            icon={<RefreshCcw width={18} />}
                            type="Outlined"
                            onClick={() => callFunc()}
                            loading={refreshOptionsBtnLoader}
                          />
                        </ToolTip>
                        {/* </div> */}
                      </FlexLayout>
                    ) : (
                      <FlexLayout valign="center" halign="fill" spacing="loose">
                        <FlexChild childWidth="fullWidth">
                          {refreshOptionsBtnLoader ? (
                            <Skeleton
                              type="custom"
                              width="100%"
                              height="44px"
                            />
                          ) : (
                            <Select
                              placeholder="Please Select"
                              onChange={(e) => {
                                setSavePaymentChannel(e);
                              }}
                              onblur={function noRefCheck() {}}
                              options={paymentChannelOptions}
                              value={savePaymentChannel}
                            />
                          )}
                        </FlexChild>

                        <ToolTip
                          helpText="Click the button to fetch your Custom Payment Method from Shopline."
                          popoverContainer="element"
                          position="top"
                          open={false}
                        >
                          <Button
                            icon={<RefreshCcw size={18} />}
                            type="Outlined"
                            onClick={() => callFunc()}
                            loading={refreshOptionsBtnLoader}
                          />
                        </ToolTip>
                      </FlexLayout>
                    )}
                  </FlexLayout>
                </Card>
                {refreshOptionsBtnLoader ? (
                  ""
                ) : paymentChannelOptions?.length === 0 ||
                  paymentChannelOptions === undefined ? (
                  <FlexLayout direction="vertical" spacing="loose" valign="end">
                    <TextStyles textcolor="light">
                      Also, at your convenience, you can continue with the
                      onboarding process while skipping the custom payment
                      method and add it afterward from the Configuration tab of
                      the app.
                    </TextStyles>
                    <Button type="Outlined" onClick={() => skipPaymentFunc()}>
                      Skip
                    </Button>
                  </FlexLayout>
                ) : (
                  <FlexLayout halign="end">
                    <Button
                      type="Primary"
                      onClick={() => savePaymentChannelFunc()}
                      disable={savePaymentChannel === ""}
                      loading={savePaymentChannelSaveBtnLoader}
                    >
                      Save
                    </Button>
                  </FlexLayout>
                )}
              </FlexLayout>
            </Card>
          </OnboardingLayout>
          <ToastWrapper>{toastMarkup}</ToastWrapper>
        </>
      );
    }
  }
  return BodyRender;
};

export default withRouter(Payment);
