/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import NestedTableComponent from "../../../../../OunceComponents/NestedTableComponent";
import {
  ActionList,
  Button,
  Card,
  FallBack,
  FlexLayout,
  TextStyles,
} from "@cedcommerce-integration/ounce-ui";
import {
  ChevronDown,
  DollarSign,
  FileText,
  RefreshCw,
  Trash2,
  Upload,
} from "react-feather";
import NestedPagination from "../../../../../OunceComponents/NestedPagination";
import { getMethod } from "../../../../../../Apirequest/productListingApi";
import {
  getProductAPI,
  getProductCountAPI,
} from "../../../../../../Apirequest/productApiEndpoints";
import { withRouter } from "react-router-dom";
import { listingContext } from "./ProductList";
import Filters from "./Filter";
import { endFromeBay, uploadProductAPI } from "../../../../../../APIs/PanelAPI";
import { postMethod } from "../../../../../../apiMethods/methods";
import { globalState } from "../../../../../../services/globalState";
import { importProductFromShoplineAPI } from "../../../../../../APIs/loginAPI";
import { NoProductSearch } from "../../../../../shared/IconSvg";
import FallbackGrid from "../../../../../shared/FallbackGrid";
import { ProductGridSkeleton } from "../../../Skeleton/ProductListing";
import NoImage from "../../../../../../assets/images/notfound.png";
import { Error } from "./IconSvg";

function UploadedWithError(props) {
  let {
    columns,
    variantColumns,
    setColumns,
    setActionsModal,
    massAction,
  } = props;

  const shopLineShopId = globalState.getLocalStorage("shopLineShopId");
  const ebayShopId = globalState.getLocalStorage("ebayShopId");

  const [gridSkeleton, setGridSkeleton] = useState(true);
  const [gridData, setGridData] = useState([]);
  const [errorModal, setErrorModal] = useState(false);
  const [rowselect, setrowselect] = useState([]);
  const [selectedRows, setSelectedRows] = useState([]);
  // eslint-disable-next-line no-unused-vars
  const [pageSizeOptions, setPageSizeOptions] = useState([
    {
      label: "10",
      value: "10",
    },
    {
      label: "25",
      value: "25",
    },
    {
      label: "50",
      value: "50",
    },
    {
      label: "100",
      value: "100",
    },
  ]);
  const [pageSize, setPageSize] = useState(10);
  const [activePage, setActivePage] = useState(1);
  const [active, setActive] = useState(false);
  const [totalCount, setTotalCount] = useState(0);
  const [payloadState, setPayloadState] = useState({
    activePage: 1,
    count: 10,
  });
  const [
    fallbackStatusNoSearchProductsUploadedError,
    setFallbackStatusNoSearchProductsUploadedError,
  ] = useState(false);
  const [locale, setLocale] = useState("");
  // skeleton states

  const getProducts = async (payload) => {
    setFallbackStatusNoSearchProductsUploadedError(false);
    setGridSkeleton(true);
    payload["filter[items.status][1]"] = "Active";
    payload["filter[items.errors][12]"] = "1";

    let { success: getProductSuccess, data: getProductData } = await getMethod(
      getProductAPI,
      payload
    );
    let { success: getProductCountSuccess, data: getProductCountData } =
      await getMethod(getProductCountAPI, {
        ...payload,
        productOnly: true,
        sortBy: "_id",
      });
    if (getProductSuccess) {
      if (getProductData?.["rows"]) {
        let tempProductData = [];
        tempProductData = getProductData?.["rows"].map(
          (productDataObj, index) => {
            let {
              main_image,
              title,
              type,
              product_type,
              variant_attributes,
              container_id,
              brand,
              source_product_id,
              items,
              profile,
            } = productDataObj;
            let tempRows = {};
            tempRows["source_product_id"] = source_product_id;
            tempRows["key"] = index;
            tempRows["image"] = main_image ? (
              <img
                src={main_image}
                width="60px"
                height="60px"
                style={{ borderRadius: "4px" }}
                alt=""
              />
            ) : (
              <img
                src={NoImage}
                width="60px"
                height="60px"
                style={{ borderRadius: "4px" }}
                alt=""
              />
            );
            tempRows["variants"] =
              type === "variation"
                ? variantData(items, source_product_id, tempRows["key"])
                : [];
            tempRows["title"] = (
              <FlexLayout
                direction="vertical"
                spacing="extraTight"
                wrap="noWrap"
              >
                <TextStyles
                  fontweight="bold"
                  lineHeight="LH-2.0"
                  paragraphTypes="MD-1.4"
                  textcolor="dark"
                  type="Paragraph"
                >
                  {title}
                </TextStyles>
                {renderBarcodeSku(items, source_product_id, variant_attributes)}
              </FlexLayout>
            );
            tempRows["price"] =
              variant_attributes.length === 0 ? (
                <TextStyles
                  paragraphTypes="MD-1.4"
                  textcolor="light"
                  type="Paragraph"
                >
                  {" "}
                  ${items[0]?.["price"]}
                </TextStyles>
              ) : (
                renderPrice(items, source_product_id)
              );
            tempRows["quantity"] =
              variant_attributes.length === 0 ? (
                <TextStyles
                  content={`${items[0]?.["quantity"]} in Stock`}
                  paragraphTypes="MD-1.4"
                  textcolor={items[0]?.["quantity"] ? "light" : "negative"}
                  type="Paragraph"
                />
              ) : (
                renderQuanity(items, source_product_id)
              );
            tempRows["status"] = (
              <span className="errorBtn">
                <Button
                  content="Errors"
                  type="TextButton"
                  icon={Error}
                  iconAlign="left"
                  onClick={() => {
                    setErrorModal(!errorModal);
                  }}
                />
              </span>
            );
            tempRows["template"] = profile?.profile_name ? (
              <Button
                content={profile?.profile_name}
                type="TextButton"
                onClick={() =>
                  props.history.push(
                    `/panel/create-template?id=${profile?.profile_id?.["$oid"]}`
                  )
                }
              />
            ) : (
              <>-</>
            );
            tempRows["brand"] = brand || "-";
            tempRows["product_type"] = product_type ? (
              <TextStyles
                paragraphTypes="MD-1.4"
                textcolor="light"
                type="Paragraph"
              >
                {" "}
                {product_type}
              </TextStyles>
            ) : (
              <></>
            );
            tempRows["action"] = (
              <Button
                content="View"
                type="TextButton"
                onClick={() =>
                  props.history.push(
                    `/panel/view-products?id=${container_id}&type=${type}`
                  )
                }
              />
            );
            return tempRows;
          }
        );
        setGridData(tempProductData);
      }
    }
    if (getProductCountSuccess) {
      if (
        (JSON.stringify(payload).includes("items.title") ||
          JSON.stringify(payload).includes("source_product_id") ||
          JSON.stringify(payload).includes("items.sku") ||
          JSON.stringify(payload).includes("type") ||
          JSON.stringify(payload).includes("items.quantity") ||
          JSON.stringify(payload).includes("items.price")) &&
        getProductCountData?.["count"] === 0
      ) {
        setFallbackStatusNoSearchProductsUploadedError(true);
      }
      let { count } = getProductCountData;
      setTotalCount(count);
    }
    setGridSkeleton(false);
  };

  const variantData = (items, source_product_id, parentKey = "") => {
    let variant = items
      .map((data, index) => {
        if (data.source_product_id !== source_product_id) {
          return {
            key: index,
            parentKey: parentKey,
            image: (
              <img
                src={data?.main_image ? data?.main_image : NoImage}
                width="60px"
                height="60px"
                style={{ borderRadius: "4px" }}
                alt=""
              />
            ),
            title: (
              <FlexLayout
                direction="vertical"
                spacing="extraTight"
                wrap="noWrap"
              >
                <TextStyles
                  fontweight="bold"
                  lineHeight="LH-2.0"
                  paragraphTypes="MD-1.4"
                  textcolor="dark"
                  type="Paragraph"
                >
                  {data?.title ? data?.title : "-"}
                </TextStyles>
                {data?.["sku"] ? (
                  <FlexLayout spacing="extraTight" wrap="noWrap">
                    <TextStyles
                      utility="text-nowrap"
                      fontweight="bold"
                      paragraphTypes="MD-1.4"
                      textcolor="dark"
                      type="Paragraph"
                    >
                      SKU:
                    </TextStyles>
                    <TextStyles
                      fontweight="bold"
                      paragraphTypes="MD-1.4"
                      textcolor="light"
                      type="Paragraph"
                    >
                      {data?.["sku"]}
                    </TextStyles>
                  </FlexLayout>
                ) : (
                  <></>
                )}
                {data?.["barcode"] ? (
                  <FlexLayout spacing="extraTight" wrap="noWrap">
                    <TextStyles
                      utility="text-nowrap"
                      fontweight="bold"
                      paragraphTypes="MD-1.4"
                      textcolor="dark"
                      type="Paragraph"
                    >
                      Barcode:
                    </TextStyles>
                    <TextStyles
                      fontweight="bold"
                      paragraphTypes="MD-1.4"
                      textcolor="light"
                      type="Paragraph"
                    >
                      {data?.["barcode"]}
                    </TextStyles>
                  </FlexLayout>
                ) : (
                  <></>
                )}
              </FlexLayout>
            ),
            price: (
              <TextStyles
                paragraphTypes="MD-1.4"
                textcolor="light"
                type="Paragraph"
              >
                {data?.price || data?.price === 0 ? `$${data?.price}` : "-"}
              </TextStyles>
            ),
            quantity: (
              <TextStyles
                paragraphTypes="MD-1.4"
                textcolor="light"
                type="Paragraph"
              >
                {data?.quantity || data?.quantity === 0
                  ? `${data?.quantity}`
                  : "-"}
              </TextStyles>
            ),
            type: "child",
          };
        }
      })
      .filter((items) => items !== undefined);
    return variant;
  };

  const renderBarcodeSku = (items, source_product_id, variant_attributes) => {
    let temp = "";
    if (!variant_attributes?.length) {
      temp = (
        <>
          {items[0]?.["sku"] ? (
            <FlexLayout spacing="extraTight" wrap="noWrap">
              <TextStyles
                utility="text-nowrap"
                fontweight="bold"
                paragraphTypes="MD-1.4"
                textcolor="dark"
                type="Paragraph"
              >
                SKU:
              </TextStyles>
              <TextStyles
                fontweight="bold"
                paragraphTypes="MD-1.4"
                textcolor="light"
                type="Paragraph"
              >
                {items[0]?.["sku"]}
              </TextStyles>
            </FlexLayout>
          ) : (
            <></>
          )}
          {items[0]?.["barcode"] ? (
            <FlexLayout spacing="extraTight" wrap="noWrap">
              <TextStyles
                utility="text-nowrap"
                fontweight="bold"
                paragraphTypes="MD-1.4"
                textcolor="dark"
                type="Paragraph"
              >
                Barcode:
              </TextStyles>
              <TextStyles
                fontweight="bold"
                paragraphTypes="MD-1.4"
                textcolor="light"
                type="Paragraph"
              >
                {items[0]?.["barcode"]}
              </TextStyles>
            </FlexLayout>
          ) : (
            <></>
          )}
        </>
      );
    }
    return temp;
  };

  const renderPrice = (items, source_product_id) => {
    let temp = "";
    if (items.length === 2) {
      items.forEach((item) => {
        if (source_product_id !== item.source_product_id) {
          temp = (
            <TextStyles
              content={`$${item?.["price"]}`}
              paragraphTypes="MD-1.4"
              textcolor={item?.["price"] ? "light" : "negative"}
              type="Paragraph"
            />
          );
        }
      });
    } else {
      let min = null;
      let max = 0;
      items.forEach((item) => {
        if (source_product_id !== item.source_product_id) {
          if (max < item.price) {
            max = item.price;
          }
          if (min === null || min > item.price) {
            min = item.price;
          }
        }
      });
      temp = (
        <TextStyles
          content={`$${min} ~ $${max}`}
          paragraphTypes="MD-1.4"
          textcolor="light"
          type="Paragraph"
        />
      );
    }
    return temp;
  };

  const renderQuanity = (items, source_product_id) => {
    let temp = "";
    let tempQuant = 0;
    items.forEach((item) => {
      if (source_product_id !== item.source_product_id) {
        tempQuant += item.quantity;
      }
    });
    temp = (
      <FlexLayout direction="vertical" wrap="noWrap" spacing="extraTight">
        <TextStyles
          content={`${tempQuant} in Stock`}
          paragraphTypes="MD-1.4"
          textcolor={tempQuant ? "light" : "negative"}
          type="Paragraph"
          fontweight="bold"
          lineHeight="LH-2.0"
        />
        <TextStyles
          paragraphTypes="MD-1.4"
          textcolor="light"
          type="Paragraph"
          fontweight="normal"
          lineHeight="LH-2.0"
        >
          {items?.length - 1} Variants
        </TextStyles>
      </FlexLayout>
    );
    return temp;
  };

  const rowSelection = {
    onChange: (selectedRowKeys, selectedRows) => {
      setSelectedRows(selectedRows);
      setrowselect(selectedRowKeys);
    },
    getCheckboxProps: (record) => ({
      name: record.name,
    }),
  };

  useEffect(() => {
    getProducts({
      ...payloadState,
      activePage: activePage,
      count: pageSize,
    });
    setrowselect([]);
    setSelectedRows([]);
  }, [activePage, pageSize, payloadState, massAction]);

  useEffect(() => {
    if (fallbackStatusNoSearchProductsUploadedError) {
      setLocale(
        <FallBack
          FallBackHeight="NormalPage"
          illustration={NoProductSearch}
          title="No Product Available!"
          subTitle="Your request does not match the listed Products."
        />
      );
    }
  }, [fallbackStatusNoSearchProductsUploadedError]);

  return (
    <>
      <listingContext.Provider>
        <Filters
          columns={columns}
          setColumns={setColumns}
          setActivePage={setActivePage}
          payloadState={payloadState}
          setPayloadState={setPayloadState}
        />
      </listingContext.Provider>
      {rowselect.length === 0 ? (
        ""
      ) : (
        <div className="ced-selected__row">
          <FlexLayout spacing="mediumTight" valign="center">
            <TextStyles textcolor="light">
              {rowselect.length} listings selected
            </TextStyles>
            {/* <TextStyles textcolor="light">
                    1 listing(s) selected
                  </TextStyles> */}
            <ActionList
              open={active}
              activator={
                <Button
                  thickness="thin"
                  icon={<ChevronDown />}
                  iconAlign="right"
                  onClick={() => setActive(!active)}
                  type="Outlined"
                >
                  Select Actions
                </Button>
              }
              onClose={() => setActive(false)}
              options={[
                {
                  items: [
                    {
                      content: "Upload Products",
                      onClick: () => {
                        let postData = [];
                        selectedRows.forEach((selectedRow) => {
                          let { source_product_id } = selectedRow;
                          postData.push(source_product_id);
                        });
                        setActionsModal({
                          active: true,
                          content1: "Upload Products",
                          content2: "upload",
                          api: uploadProductAPI,
                          loader: false,
                          methodName: postMethod,
                          payload: {
                            source_product_ids: postData,
                            source: {
                              marketplace: "shopline",
                              shopId: shopLineShopId,
                            },
                            target: {
                              marketplace: "ebay",
                              shopId: ebayShopId,
                            },
                          },
                        });
                      },
                      prefixIcon: <Upload size={20} />,
                    },
                    {
                      content: "Sync Inventory",
                      onClick: () => {
                        let postData = [];
                        selectedRows.forEach((selectedRow) => {
                          let { source_product_id } = selectedRow;
                          postData.push(source_product_id);
                        });
                        setActionsModal({
                          active: true,
                          content1: "Sync Inventory",
                          content2: "sync inventory of",
                          api: uploadProductAPI,
                          loader: false,
                          methodName: postMethod,
                          payload: {
                            source_product_ids: postData,
                            source: {
                              marketplace: "shopline",
                              shopId: shopLineShopId,
                            },
                            target: {
                              marketplace: "ebay",
                              shopId: ebayShopId,
                            },
                            operationType: "inventory_sync",
                          },
                        });
                      },
                      prefixIcon: <FileText size={20} />,
                    },
                    {
                      content: "Sync Price",
                      onClick: () => {
                        let postData = [];
                        selectedRows.forEach((selectedRow) => {
                          let { source_product_id } = selectedRow;
                          postData.push(source_product_id);
                        });
                        setActionsModal({
                          active: true,
                          content1: "Sync Price",
                          content2: "sync price of",
                          api: uploadProductAPI,
                          loader: false,
                          methodName: postMethod,
                          payload: {
                            source_product_ids: postData,
                            source: {
                              marketplace: "shopline",
                              shopId: shopLineShopId,
                            },
                            target: {
                              marketplace: "ebay",
                              shopId: ebayShopId,
                            },
                            operationType: "price_sync",
                          },
                        });
                      },
                      prefixIcon: <DollarSign size={20} />,
                    },
                    {
                      content: "Sync Product",
                      onClick: () => {
                        let postData = "";
                        selectedRows.forEach((selectedRow, index) => {
                          if (index !== 0 && index !== selectedRow - 1)
                            postData += ",";
                          let { source_product_id } = selectedRow;
                          postData += source_product_id;
                        });
                        setActionsModal({
                          active: true,
                          content1: "Sync Product",
                          content2: "sync",
                          api: importProductFromShoplineAPI,
                          loader: false,
                          methodName: postMethod,
                          payload: {
                            marketplace: "shopline",
                            data: {
                              ids: postData,
                            },
                            source: {
                              marketplace: "shopline",
                              shopId: shopLineShopId,
                            },
                            target: {
                              marketplace: "ebay",
                              shopId: ebayShopId,
                            },
                          },
                        });
                      },
                      prefixIcon: <RefreshCw size={20} />,
                    },
                    {
                      content: "End from eBay",
                      onClick: () => {
                        let postData = [];
                        selectedRows.forEach((selectedRow) => {
                          let { source_product_id } = selectedRow;
                          postData.push(source_product_id);
                        });
                        setActionsModal({
                          active: true,
                          content1: "End Now",
                          content2: "end",
                          api: endFromeBay,
                          loader: false,
                          methodName: postMethod,
                          payload: {
                            source_product_ids: postData,
                            source: {
                              marketplace: "shopline",
                              shopId: shopLineShopId,
                            },
                            target: {
                              marketplace: "ebay",
                              shopId: ebayShopId,
                            },
                          },
                        });
                      },
                      prefixIcon: <Trash2 size={20} />,
                    },
                  ],
                },
              ]}
            />
          </FlexLayout>
        </div>
      )}
      {gridSkeleton ? (
        <ProductGridSkeleton />
      ) : fallbackStatusNoSearchProductsUploadedError ? (
        <FallbackGrid
          columns={columns}
          dataSource={gridData}
          rowSelection={{}}
          local={locale}
        />
      ) : (
        <>
          <NestedTableComponent
            columns={columns}
            dataSource={gridData}
            variantColumns={variantColumns}
            rowSelection={{
              ...rowSelection,
              checkStrictly: false,
            }}
          />
          <Card cardType="Bordered" extraClass="ced-pagination__wrap">
            <NestedPagination
              totalCount={totalCount}
              pageSizeOptions={pageSizeOptions}
              activePage={activePage}
              setActivePage={setActivePage}
              pageSize={pageSize}
              setPageSize={setPageSize}
              payloadState={payloadState}
              setPayloadState={setPayloadState}
            />
          </Card>
        </>
      )}
    </>
  );
}

export default withRouter(UploadedWithError);
