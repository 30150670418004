import { Card, FlexLayout, Grid, Skeleton } from "@cedcommerce-integration/ounce-ui";
import React from "react";
import "./skelton.css";
const ModalGridSkeleton = () => {
  const key = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10];
  const dataSources = key.map((val, index) => {
    return {
      key: index.toString(),
      image: <Skeleton height="20px" type="custom" width="50" />,
      name: <Skeleton height="20px" type="custom" width="250" />,
    };
  });

  const columns = [
    {
      align: "left",
      dataIndex: "image",
      title: <Skeleton height="20px" type="custom" width="100%" />,
    },
    {
      title: <Skeleton height="20px" type="custom" width="100%" />,
      dataIndex: "name",
      visible: true,
    },
  ];
  return (
    <>
      <Grid
        dataSource={dataSources}
        columns={columns}
        size="middle"
        tableLayout="fixed"
      />
      <Card cardType="Bordered" extraClass="ced-pagination__wrap">
        <div className="inte-Pagination skeleton-pagination">
          <FlexLayout halign="fill" spacing="extraTight">
            <Skeleton height="39px" type="custom" width="150px" />
            <Skeleton height="39px" type="custom" width="150px" />
          </FlexLayout>
        </div>
      </Card>
    </>
  );
};

export default ModalGridSkeleton;
