/* eslint-disable react-hooks/exhaustive-deps */
import {
  Accordion,
  Button,
  Card,
  FallBack,
  FlexChild,
  FlexLayout,
  Modal,
  Notification,
  Progressbar,
  Skeleton,
  TextStyles,
} from "@cedcommerce-integration/ounce-ui";
import React, { useEffect, useState } from "react";
import { withRouter } from "react-router-dom";
import { getMethod } from "../../apiMethods/methods";
import { getActivitesAPI, getOngoingActivitiesAPI } from "../../APIs/PanelAPI";
import { ActivitySkelton } from "../Ebay/Panel/Skeleton/DashboadSkeleton";
import { NoNotification } from "../shared/IconSvg";
import { parsedErrorList } from "../Ebay/Panel/OngoingActivity/OngoingActivity";

export const removeBracket = (text) => {
  return text.replace(/[()]/g, "");
};

const customDate = (date) => {
  const dt_date1 = new Date(date);
  const dt_date2 = new Date();
  const date1_time_stamp = dt_date1.getTime();
  const date2_time_stamp = dt_date2.getTime();
  const calc = new Date(date2_time_stamp - date1_time_stamp);
  const msPerMinute = 60 * 1000;
  const msPerHour = msPerMinute * 60;
  const msPerDay = msPerHour * 24;
  const msPerMonth = msPerDay * 30;
  const msPerYear = msPerDay * 365;
  const returnDateformat =
    dt_date1.getDate() < 10 ? "0" + dt_date1.getDate() : dt_date1.getDate();
  const returnDate =
    dt_date1.getMonth() +
    1 +
    "/" +
    returnDateformat +
    "/" +
    dt_date1.getFullYear();
  if (calc < msPerMinute) {
    if (Math.round(calc / 1000) >= 0)
      return Math.round(calc / 1000) + " seconds ago";
    else {
      return "0 seconds ago";
    }
  } else if (calc < msPerHour) {
    return Math.round(calc / msPerMinute) + " minutes ago";
  } else if (calc < msPerDay) {
    return Math.round(calc / msPerHour) + " hours ago";
  } else if (calc < msPerMonth) {
    return returnDate;
  } else if (calc < msPerYear) {
    return returnDate;
  } else {
    return returnDate;
  }
};

function Activities(props) {
  const { msgCountInWebpack, refreshBtnClickedCount, dispatch } = props;
  const [notificationData, setNotificationData] = useState([]);
  const [ongoingActivitiesData, setOngoingActivitiesData] = useState([]);
  // no activity fallback
  const [noActivityStatus, setNoActivityStatus] = useState(false);
  const [noOngoingActivityStatus, setNoOngoingActivityStatus] = useState(false);
  const [noCompletedActivityStatus, setNoCompletedActivityStatus] =
    useState(false);
  // skeleton
  const [timeOutLoader, setTimeOutLoader] = useState(true);
  const [ongoingActivitySkeleton, setOngoingActivitySkeleton] = useState(true);
  const [completedActivitySkeleton, setCompletedActivitySkeleton] =
    useState(true);

  const [errorModal2, setErrorModal2] = useState(false);
  const [errorModalData, setErrModalData] = useState([{}]);
  const [errorModalIndex, setErrorModalIndex] = useState(0);
  const [clicked, setClicked] = useState(0);

  const ActivitiesFunc = async function (hasWebsocket) {
    let payload2 = {
      count: 3,
      activepage: 1,
    };
    const payload = {
      count: 1,
      activepage: 1,
    };
    if (hasWebsocket) {
      setCompletedActivitySkeleton(true);
      setOngoingActivitySkeleton(true);
    }
    let { success: completedSuccess, data: completedData } = await getMethod(
      getActivitesAPI,
      payload2
    );
    let {
      success: ongoingSuccess,
      data: ongoingData,
      code,
    } = await getMethod(getOngoingActivitiesAPI, payload);
    if (completedSuccess) {
      if (completedData?.rows?.length) {
        let tempErroModalData = [];
        let activites = completedData.rows.map((activity, index) => {
          let heading = "",
            description = "";
          tempErroModalData.push(parsedErrorList(activity?.additional_data));
          if (activity?.message?.includes("</br>")) {
            heading = activity?.message?.split("</br>")[0];
            description = activity?.message?.split("</br>")[1];
            return {
              text: removeBracket(heading),
              description: description,
              subdescription: activity?.created_at,
              type:
                activity?.severity === "error"
                  ? "danger"
                  : activity?.severity === "success"
                  ? "success"
                  : "info",
            };
          } else {
            return {
              text: removeBracket(activity?.message),
              subdescription: activity?.created_at,
              type:
                activity?.severity === "error"
                  ? "danger"
                  : activity?.severity === "success"
                  ? "success"
                  : "info",
            };
          }
        });
        if (!tempErroModalData.length) {
          tempErroModalData.push({});
        }
        setErrModalData(tempErroModalData);

        setNotificationData(activites);
      } else {
        setNotificationData([]);
      }
      if (completedData?.rows?.length === 0) {
        setNoCompletedActivityStatus(true);
      } else {
        setNoCompletedActivityStatus(false);
      }
    } else if (
        code === "token_expired" ||
        code === "invalid_token" ||
        code === "future_token" ||
        code === "token_decode_error"
      ) {
        props.history.push("/tokenexpired");
      }
    if (ongoingSuccess) {
      if (ongoingData?.rows?.length) {
        let activites = ongoingData.rows.map((activity, index) => {
          return {
            text: removeBracket(activity?.message),
            description: activity?.additional_data,
            subdescription: activity?.created_at,
            progress: activity?.progress,
            code: activity?.process_code,
          };
        });
        setOngoingActivitiesData(activites);
      } else {
        setOngoingActivitiesData([]);
      }
      if (ongoingData?.rows?.length === 0) {
        setNoOngoingActivityStatus(true);
      } else {
        setNoOngoingActivityStatus(false);
      }
    }
    if (hasWebsocket) {
      setCompletedActivitySkeleton(false);
      setOngoingActivitySkeleton(false);
    }
  };

  useEffect(() => {
    ActivitiesFunc(true);
  }, []);

  useEffect(() => {
    if (msgCountInWebpack > 0) {
      ActivitiesFunc(false);
    }
  }, [msgCountInWebpack]);

  useEffect(() => {
    if (noCompletedActivityStatus && noOngoingActivityStatus) {
      setNoActivityStatus(true);
    } else {
      setNoActivityStatus(false);
    }
  }, [noCompletedActivityStatus, noOngoingActivityStatus]);

  useEffect(() => {
    if (ongoingActivitySkeleton || completedActivitySkeleton) {
      setTimeOutLoader(true);
      dispatch({ type: "activities", value: true });
    } else {
      setTimeOutLoader(false);
      dispatch({ type: "activities", value: false });
    }
  }, [ongoingActivitySkeleton, completedActivitySkeleton]);

  useEffect(() => {
    if (refreshBtnClickedCount > 0) {
      ActivitiesFunc(true);
    }
  }, [refreshBtnClickedCount]);

  const handleToggle = (index) => {
    if (clicked === index) {
      setClicked(-1);
    } else {
      setClicked(index);
    }
  };

  return (
    <>
      {noActivityStatus ? (
        <Card
          cardType="Plain"
          title={
            <TextStyles
              type="SubHeading"
              subheadingTypes="SM-1.8"
              fontweight="extraBold"
            >
              Activities
            </TextStyles>
          }
          subTitle={
            <TextStyles
              type="SubHeading"
              subheadingTypes="XS-1.6"
              textcolor="light"
              lineHeight="LH-2.4"
            >
              Get an overview of all the ongoing and completed actions performed
              on the app.
            </TextStyles>
          }
        >
          <FallBack
            FallBackHeight="NormalPage"
            illustration={NoNotification}
            title="No Activities Found!"
          />
        </Card>
      ) : (
        <>
          <Card
            cardType="Plain"
            title={
              <TextStyles
                type="SubHeading"
                subheadingTypes="SM-1.8"
                fontweight="extraBold"
                lineHeight="LH-2.8"
              >
                Activities
              </TextStyles>
            }
            subTitle={
              <TextStyles
                type="SubHeading"
                subheadingTypes="XS-1.6"
                textcolor="light"
                lineHeight="LH-2.4"
              >
                Get an overview of all the ongoing and completed actions
                performed on the app.
              </TextStyles>
            }
            action={
              timeOutLoader ? (
                <Skeleton height="25px" width="80px" type="custom" />
              ) : (
                <Button
                  content="View all"
                  type="TextButton"
                  onClick={() => props.history.push("/panel/ongoingactivity")}
                />
              )
            }
          >
            {timeOutLoader ? (
              <ActivitySkelton />
            ) : (
              <FlexLayout
                direction="vertical"
                wrap="noWrap"
                spacing="extraLoose"
              >
                {ongoingActivitiesData.length > 0 && (
                  <FlexChild childWidth="fullWidth">
                    <Card title="Ongoing Activities" cardType="Subdued">
                      <FlexLayout
                        direction="vertical"
                        wrap="noWrap"
                        spacing="extraLoose"
                      >
                        {ongoingActivitiesData.map((activity) => {
                          return (
                            <>
                              <FlexLayout
                                direction="vertical"
                                spacing="tight"
                                wrap="noWrap"
                              >
                                <FlexLayout
                                  direction="vertical"
                                  wrap="noWrap"
                                  spacing="mediumTight"
                                >
                                  <TextStyles
                                    fontweight="bold"
                                    lineHeight="LH-2.0"
                                  >
                                    {activity.text}
                                  </TextStyles>
                                  {activity?.description?.length > 0 && (
                                    <TextStyles
                                      textcolor="light"
                                      lineHeight="LH-2.0"
                                    >
                                      {activity.code === "product_upload"
                                        ? `${activity.description?.product_count} of
                                        ${activity.description?.total_count} Products uploaded on eBay.`
                                        : activity.code === "price_sync"
                                        ? `${activity.description?.product_count} of
                                        ${activity.description?.total_count} Products price sync completed on eBay.`
                                        : activity.code === "inventory_sync" &&
                                          `${activity.description?.product_count} of
                                        ${activity.description?.total_count} Products inventory sync completed on eBay.`}
                                    </TextStyles>
                                  )}
                                  <TextStyles
                                    type="Paragraph"
                                    paragraphTypes="XS-1.2"
                                    textcolor="light"
                                    lineHeight="LH-1.6"
                                  >
                                    {customDate(activity?.subdescription)}
                                  </TextStyles>
                                </FlexLayout>
                                <Progressbar
                                  percentage={activity?.progress}
                                  progessThickness="none"
                                  message={`${Math.floor(activity?.progress)}%`}
                                />
                              </FlexLayout>
                            </>
                          );
                        })}
                      </FlexLayout>
                    </Card>
                  </FlexChild>
                )}
                {notificationData.length > 0 && (
                  <FlexChild>
                    <Card title="Completed Activities" cardType="Plain">
                      {notificationData.map((item, index) => {
                        return (
                          <>
                            <Notification
                              destroy={false}
                              desciption={
                                item?.description && (
                                  <p className="inte__text inte__text--light inte__font--normal inte__LineHeight--2 mt-5">
                                    {item?.description} <br />
                                    <Button
                                      type="TextButton"
                                      onClick={() => {
                                        setErrorModalIndex(index);
                                        setErrorModal2(!errorModal2);
                                      }}
                                    >
                                      {" "}
                                      Click Here
                                    </Button>{" "}
                                    to check Products with Errors
                                  </p>
                                )
                              }
                              subdesciption={customDate(item?.subdescription)}
                              type={item?.type}
                            >
                              <TextStyles
                                lineHeight="LH-2.0"
                                content={
                                  <span
                                    dangerouslySetInnerHTML={{
                                      __html: item?.text,
                                    }}
                                  ></span>
                                }
                                fontweight="bold"
                              />
                            </Notification>
                          </>
                        );
                      })}
                    </Card>
                  </FlexChild>
                )}
              </FlexLayout>
            )}
          </Card>
          <Modal
            closeOnEscape={true}
            overlayClose={true}
            open={errorModal2}
            close={() => setErrorModal2(!errorModal2)}
            heading="Errors"
            modalSize="medium"
            extraClass="variantModal"
          >
            {Object.keys(errorModalData[errorModalIndex])?.length ? (
              <>
                {Object.keys(errorModalData[errorModalIndex])?.map(
                  (item, index) => {
                    return (
                      <Accordion
                        key={index}
                        iconAlign="right"
                        active={clicked === index}
                        onClick={() => handleToggle(index)}
                        title={
                          <FlexLayout spacing="extraTight">
                            <TextStyles
                              fontweight="bold"
                              lineHeight="LH-2.0"
                              paragraphTypes="MD-1.4"
                              textcolor="dark"
                              type="Paragraph"
                            >
                              Product:
                            </TextStyles>
                            <TextStyles
                              fontweight="normal"
                              lineHeight="LH-2.0"
                              paragraphTypes="MD-1.4"
                              textcolor="dark"
                              type="Paragraph"
                            >
                              {" "}
                              {item}
                            </TextStyles>
                          </FlexLayout>
                        }
                      >
                        <>
                          {Object.keys(
                            errorModalData[errorModalIndex]?.[item]
                          )?.map((items) => {
                            return (
                              <React.Fragment key={index}>
                                <Card cardType="Default">
                                  <FlexLayout spacing="tight" wrap="noWrap">
                                    <>
                                      {
                                        errorModalData[errorModalIndex][item][
                                          items
                                        ]["icon"]
                                      }
                                    </>
                                    <FlexChild
                                      desktopWidth="100"
                                      tabWidth="100"
                                      mobileWidth="100"
                                    >
                                      <FlexLayout
                                        direction="vertical"
                                        spacing="tight"
                                      >
                                        <FlexLayout
                                          direction="vertical"
                                          spacing="extraTight"
                                        >
                                          <TextStyles
                                            fontweight="extraBold"
                                            lineHeight="LH-2.0"
                                            paragraphTypes="MD-1.4"
                                            textcolor="dark"
                                            type="Paragraph"
                                          >
                                            {
                                              errorModalData[errorModalIndex][
                                                item
                                              ][items]["value"]
                                            }
                                          </TextStyles>

                                          <TextStyles
                                            alignment="left"
                                            fontweight="normal"
                                            lineHeight="LH-2.0"
                                            paragraphTypes="MD-1.4"
                                            textcolor="light"
                                            type="Paragraph"
                                            utility="none"
                                          >
                                            {items}
                                          </TextStyles>
                                        </FlexLayout>
                                      </FlexLayout>
                                    </FlexChild>
                                  </FlexLayout>
                                </Card>
                              </React.Fragment>
                            );
                          })}
                        </>
                      </Accordion>
                    );
                  }
                )}
              </>
            ) : (
              <>No Error Message</>
            )}
          </Modal>
        </>
      )}
    </>
  );
}

export default withRouter(Activities);
